import { NgModule } from '@angular/core';

import { TabComponent } from './tab.component';
import { NavigationTabsComponent } from './navigation-tabs.component';

@NgModule({
  declarations: [
    TabComponent,
    NavigationTabsComponent,
  ],
  exports: [
    TabComponent,
    NavigationTabsComponent,
  ]
})
export class TabsModule { }
