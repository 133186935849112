<app-header class="sticky-mobile-header"></app-header>

<div class="scrollable-mobile-content">
  <router-outlet></router-outlet>
</div>

<footer>
  <div class="layout layout_one_up">
    <div>
      <div>
        Copyright 1995 - {{ currentYear }} American Medical Association. All rights reserved.
      </div>

      <ul class="clean-list">
        <li><a href="https://www.ama-assn.org/terms-use">Terms of Use</a></li>
        <li><a href="https://www.ama-assn.org/privacy-policy">Privacy Policy</a></li>
        <li><a href="https://www.ama-assn.org/code-conduct">Code of Conduct</a></li>
        <li><a href="https://www.ama-assn.org/accessibility-statement">Website Accessibility</a></li>
      </ul>
    </div>
  </div>
</footer>
