<div class="layout">

  <div class="content">
    <h2 class="header">Invite a Colleague</h2>

    <div>
      <form [formGroup]="service.form" (ngSubmit)="service.submitForm()">
				<p>Enter the name, email and state of the person you’re inviting to join the American Medical Association.</p>

				<div class="layout layout_two_up no-padding">
	        <div class="layout_two_up-primary">
	          <ama__validated-text-input__v1
              [formGroup]="service.form"
	            [itemLabel]="{name: 'First Name'}"
	            itemMaxLength="50"
	            fieldName="firstName"
	            itemPlaceholder="First Name">
	          </ama__validated-text-input__v1>
	        </div>
	        <div class="layout_two_up-secondary">
	          <ama__validated-text-input__v1
              [formGroup]="service.form"
	            [itemLabel]="{name: 'Last Name'}"
	            fieldName="lastName"
	            itemMaxLength="50"
	            itemPlaceholder="Last Name">
	          </ama__validated-text-input__v1>
	        </div>
	      </div>

        <div class="layout layout_two_up no-padding">
	        <div class="layout_two_up-primary">
	          <ama__validated-dropdown-input__v1
              [formGroup]="service.form"
	            [label]="{name: 'State'}"
	            fieldName="stateCode"
	            itemMaxLength="2"
	            placeholder="State"
              [options]="service.stateOptions">
	          </ama__validated-dropdown-input__v1>
	        </div>
	        <div class="layout_two_up-secondary">
	          <ama__validated-text-input__v1
              [formGroup]="service.form"
	            [itemLabel]="{name: 'Email'}"
	            fieldName="email"
              itemMaxLength="50"
              patternValidationMessage="Must be a valid email address"
	            itemPlaceholder="Email">
	          </ama__validated-text-input__v1>
	        </div>
	      </div>

	      <div class="margin-top-bottom--large">
					<p class="no-margin">Message:</p>
					<p class="padding-all--small background-grey">{{service.userName}} is inviting you to join the American Medical Association. Become a member today and start benefiting.</p>
	      </div>
        <div class="margin-bottom--large centered">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>

  <ama__template-modal__v1
    [visible]="service.isReferralSearchModalVisible"
    (on-close-requested)="service.closeReferralSearchModal()">
    <ng-template>
      <app-referral-search-modal></app-referral-search-modal>
    </ng-template>
	</ama__template-modal__v1>

  <ama__template-modal__v1
    [visible]="service.isReferralNoMatchModalVisible"
    (on-close-requested)="service.closeReferralNoMatchModal()">
    <ng-template>
      <app-referral-no-match-modal></app-referral-no-match-modal>
    </ng-template>
  </ama__template-modal__v1>
</div>
