<section id="membership-transaction-history">
  <app-card-header title="Membership Receipts">
    <div class="sub-header">
      Receipts are available from prior 2 years of membership transactions
    </div>
    <div class="content" *ngIf="!isFinishedLoading">
      <app-spinner></app-spinner>
    </div>
    <div class="blank-state" *ngIf="this.isFinishedLoading && !this.hasMembershipTransactionHistory">
      You have no transaction history to display. Please contact us if you believe this is in error.
    </div>
    <table *ngIf="this.isFinishedLoading && this.hasMembershipTransactionHistory">
      <thead>
        <th class="member-year-column-header">Member Year</th>
        <th>Date</th>
        <th>Transaction Type</th>
        <th>Amount</th>
        <th>Payment Method</th>
        <th></th>
      </thead>
      <tbody>
        <tr *ngFor="let historyItem of this.getMembershipTransactionHistory">
          <td class="member-year-column">{{historyItem.membershipYear}}</td>
          <td class="date-column">{{historyItem.paymentDate | date:'MM/dd/yyyy'}}</td>
          <td class="transaction-type-column">{{historyItem.transactionType}}</td>
          <td class="amount-column">{{historyItem.paymentAmount | currency: 'USD'}}</td>
          <td *ngIf="!isDigital(historyItem) && !!historyItem.checkNumber" class="payment-method-column">{{historyItem.paymentMethod | titlecase}} (#{{historyItem.checkNumber.trim()}})</td>
          <td *ngIf="!isDigital(historyItem) && !!historyItem.creditCardNumber" class="payment-method-column">{{historyItem.creditCardType}} (&bull;&bull;&bull;&bull; {{historyItem.creditCardNumber}})</td>
          <td *ngIf="isDigital(historyItem)" class="payment-method-column">{{historyItem.creditCardType}}</td>
          <td><a class="link" (click)="print(historyItem.paymentId)">Print</a></td>
        </tr>
      </tbody>
    </table>
  </app-card-header>
</section>
