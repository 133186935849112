<div class="toc-modal">
  <h5>TERMS AND CONDITIONS FOR AUTOMATIC RENEWAL AUTHORIZATION</h5>
  <p>Monthly installment payments with automatic renewal: Installment payments begin the month the membership
    transaction is made and continue until paid in full by December 31. You will receive a reminder notice each
    year
    on or around November 1. Unless you cancel your authorization in writing by the previous December 1, your
    designated account will be charged 1/12 of the annual membership dues beginning January of each year.</p>
  <p>Annual payment with automatic renewal: Your designated account will be charged immediately for the full
    amount
    of your annual membership dues. Thereafter, you will receive a reminder notice each year on or about November
    1.
    Your credit card will then be charged on or around January 1 of each year for AMA membership dues unless you
    cancel your authorization in writing, by the previous December 1.</p>
  <p>Physicians in a membership dues category that will transition at the time of renewal to a higher dues
    category
    will see the dues rates below at the time of renewal. The AMA will provide prior written notice of any change
    in
    the annual membership dues rate.</p>
  <ul>
    <li>Resident/fellow: $45</li>
    <li>First year in practice: $210</li>
    <li>Second year in practice: $315</li>
    <li>Regular practice: $420</li>
  </ul>
  <p>Cancellation of your automatic renewal authorization must be submitted in one of the following ways: </p>
  <ol>
    <li>
      <p>Email to msc@ama-assn.org</p>
    </li>
    <li>
      <p>Fax to (800) 262-3221 or</p>
    </li>
    <li>
      <p>Mail to AMA Member Relations:</p>
      <p>330 North Wabash Avenue Suite 39300</p>
      <p>Chicago, IL 60611-5885</p>
    </li>
  </ol>
  <p>Membership renewal will be at the full dues rate for your membership category.</p>
  <p class="close-link" style="display:block" (click)="closeModal()">Close</p>
</div>
