import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertIconComponent } from './icon-alert.component';
import { CloseIconComponent } from './icon-close.component';
import { ValidIconComponent } from './icon-valid.component';
import { ExternalIconComponent } from './icon-external.component';
import { LockIconComponent } from './icon-lock.component';
import { CheckmarkIconComponent } from './icon-checkmark.component'

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AlertIconComponent,
    CloseIconComponent,
    ValidIconComponent,
    ExternalIconComponent,
    LockIconComponent,
    CheckmarkIconComponent
  ],
  exports: [
    AlertIconComponent,
    CloseIconComponent,
    ValidIconComponent,
    ExternalIconComponent,
    LockIconComponent,
    CheckmarkIconComponent
  ],
})
export class IconsModule { }
