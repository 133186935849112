type DisplayType = 'Home' | 'Office' | 'Home Office' | 'Other';
export type MailingAddressAPIType = 'home' | 'office' | 'home-office' | 'other';

export interface AddressType {
  displayName: DisplayType;
  apiName: MailingAddressAPIType;
};

export const addressTypes: AddressType[] = [
  { displayName: 'Home', apiName: 'home' },
  { displayName: 'Office', apiName: 'office' },
  { displayName: 'Home Office', apiName: 'home-office' },
  { displayName: 'Other', apiName: 'other' },
];
