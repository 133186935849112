<span id="exitButton" (click)="closeDialog()"></span>
<form id="verify-modal" (ngSubmit)="onFormSubmit()">
  <fieldset>
    <div class="form-title">Address</div>
    <div class="suggested-address-form">
      <div class="form-item-radio">
        <div class="radio-tile"
             [class.radio-tile--selected]="model.selectedAddress === 'normalized'">
          <input
            [(ngModel)]="model.selectedAddress"
            type="radio"
            name="your_address"
            value="normalized"
            id="suggested_address"
            checked="checked"
            required>
          <label for="suggested_address" class="radio-tile__button"></label>

          <div class="radio-tile__content">
            <p class="radio-tile__title">Suggested Address</p>
            <address>
              {{ normalizedAddress?.lineOne }}<br>
              <span *ngIf="normalizedAddress?.lineTwo">
                {{ normalizedAddress?.lineTwo }}<br>
              </span>
              <span *ngIf="originalAddress?.lineThree">
                {{ normalizedAddress?.lineThree }}<br>
              </span>
              {{ normalizedAddress?.city }},
              {{ normalizedAddress?.stateTerritory }}
              {{ normalizedAddress?.zipCode}}
            </address>
          </div>
        </div>

        <div class="radio-tile"
             [class.radio-tile--selected]="model.selectedAddress === 'original'">
          <input
            [(ngModel)]="model.selectedAddress"
            type="radio"
            name="your_address"
            value="original"
            id="original_address">
          <label for="original_address" class="radio-tile__button"></label>

          <div class="radio-tile__content">
            <div>
                <p class="radio-tile__title">Original Address</p>
                <app-link (click)="openEditDialog(originalAddress)">Edit</app-link>
            </div>

            <address>
              {{ originalAddress?.lineOne }}<br>
              <span *ngIf="originalAddress?.lineTwo">
                {{ originalAddress?.lineTwo }}<br>
              </span>
              <span *ngIf="originalAddress?.lineThree">
                {{ originalAddress?.lineThree }}<br>
              </span>
              {{ originalAddress?.city }},
              {{ originalAddress?.stateTerritory }}
              {{ originalAddress?.zipCode}}
            </address>
          </div>
        </div>
      </div>
      <div class="card__button">
        <button (click)="onVerify()">Save</button>
        <p class="close-link" (click)="closeDialog()">Cancel</p>
      </div>
    </div>
  </fieldset>
</form>
