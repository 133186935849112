import { Component, OnInit } from '@angular/core';
import { UserService } from './../../../users/user.service';
import { UserProfileRecord } from './../../../users/user-credentials';

@Component({
  selector: 'app-name-change-link',
  templateUrl: './name-change-link.component.html'
})
export class NameChangeLinkComponent implements OnInit {
  emailAddressTo = 'data.verification@ama-assn.org';
  changeNameSubject = 'Legal%20first%2Flast%20name%20change%20request'
  changeNameBody = 'Additional%20information%20is%20required%20to%20process%20your%20request%2E%0DPlease%20provide%20a%20copy%20of%20your%20marriage%20license%2C%20passport%20or%20court%20issued%20document%20(a%20driver’s%20license%20is%20not%20acceptable%20documentation)%20and%20a%20brief%20explanation%20of%20the%20name%20change.%0D%0DThis%20information will be processed within 2 business days.';
  changeNameBody1 = 'Please%20describe%20the%20change%28s%29%20you%20would%20like%20to%20make%20to%20your%20name%20in%20the%20space%20provided%20below%2E%20Attach%20a%20copy%20of%20your%20marriage%20license%2C%20passport%20or%20court%2Dissued%20document%20%28not%20a%20driver%27s%20license%29%20to%20verify%20the%20change%2E%20Upon%20receipt%2C%20this%20request%20will%20be%20processed%20in%20two%20business%20days%2E'
                    .concat('%0D%0DDescription%20of%20name%20change%28s%29%3A%0D')
                    .concat('%0D%0D%2A%2APlease%20do%20not%20edit%20the%20information%20below%2A%2A')
                    .concat('%0DCurrent%20name%3A')
                    .concat(this.getDrPrefix)
                    .concat(this.user.firstName)
                    .concat('%20')
                    .concat(this.user.lastName)
                    .concat('%0DME%20number%3A%20')
                    .concat(this.user.meNumber);

  changeNameEmail = this.emailAddressTo
      .concat('?subject=')
      .concat(this.changeNameSubject)
      .concat('&body=')
      .concat(this.changeNameBody1);

  constructor( protected userService: UserService)
   { }

  ngOnInit() {
  }
  get user(): UserProfileRecord {
    return this.userService.profile;
  }
  get getDrPrefix() {
    if (this.userService.isInGroup('physician') || this.userService.isInGroup('resident')) {
      return `%20Dr%2E%20`;
    }
    return `%20`;
  }
}
