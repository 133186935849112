<div id="united-states-resident-validation-modal">
  <div class="card__spinner" *ngIf="showSpinner">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!showSpinner">
    <span id="exitButton" class="united-states-resident-validation-exit-button" (click)="onNoClick()"></span>
    <div class="center block">
      <div class="message-container">
        {{ this.newsletterTitle }} is a newsletter for US-based physicians. By clicking Accept, I certify that I live in the United States.
      </div>
      <div class="us-resident-button-group">
        <button (click)="onNoClick()" class="button cancel-button">Cancel</button>
        <button (click)="onButtonClick()" class="button confirm-button">I Accept</button>
      </div>
    </div>
  </div>
</div>

