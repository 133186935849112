import { Component, OnInit } from '@angular/core';
import {
  questionType, SubscriptionsService,
} from '../subscriptions.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertsService } from '../../ui-components/alerts/alerts.service';
import { getUnsubscribeFeedbackSuccessMessage, serverError } from '../../utils/alerts.utils';
import { StandardSurveyQuestion, SurveyResponse, UnsubscribeSurveyQuestion } from '../survey-type-definitions';
import { UserService } from '../../users/user.service';

const SINGLE_CHOICE_TYPE: questionType = 'single-answer';
const MULTIPLE_CHOICE_TYPE: questionType = 'multiple-answer';
const TEXT_TYPE: questionType = 'text';

@Component({
  selector: 'app-unsubscribe-survey',
  templateUrl: 'unsubscribe-survey.component.html'
})
export class UnsubscribeSurveyComponent implements OnInit {
  surveyQuestions: UnsubscribeSurveyQuestion[];
  subscribableId: string;
  subscribableTitle: string;
  surveyForm: FormGroup;

  constructor(
    protected subscriptionsService: SubscriptionsService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    protected alertService: AlertsService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.surveyForm = this.formBuilder.group({});
    this.subscriptionsService.getSurveyQuestions().subscribe(
      (questions) => {
        this.surveyQuestions =  questions;
        this.activatedRoute.queryParams.subscribe((queryParams) => {
          this.subscribableId = queryParams['subscribableId'];
          this.subscribableTitle = queryParams['title'];
        });
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  onUnsubscribe() {
    const surveyResponses: SurveyResponse[] = [];

    for (const questionIdControl in this.surveyForm.controls) {
      if (this.surveyForm.controls.hasOwnProperty(questionIdControl)) {
        if (this.getQuestionType(questionIdControl) === SINGLE_CHOICE_TYPE) {
          const answer = this.surveyForm.get(questionIdControl).value;

          if (answer) {
            surveyResponses.push({
              questionId: questionIdControl,
              optionIds: [answer],
            })
          }
        }

        if (this.getQuestionType(questionIdControl) === MULTIPLE_CHOICE_TYPE) {
          const answers = [];
          const multipleChoiceGroup = this.surveyForm.get(questionIdControl);
          for (const answer of this.getAnswers(questionIdControl)) {
            if (this.surveyForm.get(questionIdControl).get(answer.id).value) {
              answers.push(answer.id);
            }
          }

          if (answers.length > 0) {
            surveyResponses.push({
              questionId: questionIdControl,
              optionIds: answers,
            })
          }
        }

        if (this.getQuestionType(questionIdControl) === TEXT_TYPE) {
          const answer = this.surveyForm.get(questionIdControl).value;

          if (answer) {
            surveyResponses.push({
              questionId: questionIdControl,
              text: answer,
            })
          }
        }
      }
    }

    const surveyAnswers = ({
      subscribableId: this.subscribableId,
      responses: surveyResponses
    })

    if (surveyResponses.length > 0) {
      this.subscriptionsService.postSurveyAnswers(surveyAnswers).subscribe(
        () => {
          if (this.userService.profile) {
            this.alertService.displaySuccess(getUnsubscribeFeedbackSuccessMessage());
            this.router.navigate(['/subscriptions/my-subscriptions']);
          } else {
            this.router.navigate(['/unsubscribe-survey/thank-you']);
          }
        },
        (err) => {
          this.alertService.displayError(serverError);
        }
      );
    }
  }

  getQuestionType(questionId: string): string {
    const question = this.findQuestion(questionId);
    if (question) {
      return question.type;
    }
  }

  getAnswers(questionId: string) {
    const question = this.findQuestion(questionId);
    if (question) {
      return question.options;
    }
  }

  findQuestion(questionId: string): StandardSurveyQuestion {
    return this.surveyQuestions.find((question: StandardSurveyQuestion) => {
      return question.id === questionId;
    }) as StandardSurveyQuestion;
  }

  isSingleAnswerQuestion(question: UnsubscribeSurveyQuestion): boolean {
    return question.type === SINGLE_CHOICE_TYPE;
  }

  isMultipleAnswerQuestion(question: UnsubscribeSurveyQuestion): boolean {
    return question.type === MULTIPLE_CHOICE_TYPE;
  }

  isTextQuestion(question: UnsubscribeSurveyQuestion): boolean {
    return question.type === TEXT_TYPE;
  }
}
