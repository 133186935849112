import { Component, Input } from '@angular/core';
import { UserService } from 'app/users/user.service';
import { AdDetailsWithPayment, MembershipService } from 'app/membership-status/membership.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AutoRenewLoggingService } from 'app/auto-renew-logging/auto-renew-logging.service';
import { MatDialog } from '@angular/material/dialog';
import { TurnOnAutorenewModalComponent } from 'app/membership-status/modals/turn-on-autorenew-modal.component';
import { AutoRenewFormService } from 'app/membership-status/auto-renew-form.service';
import { ModalService } from 'app/ui-components/modals/modal.service';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
})
export class AdsComponent {
  @Input() adInfo: AdDetailsWithPayment;
  @Input() shouldShowAutoRenewAd: boolean = false;

  get isPhysician(): boolean {
    return this.userService.isPhysician;
  }

  // get environment(): any {
  //   return environment;
  // }

  get upgradeProfileURL(): string {
    //return this.environment.upgradeProfileURL;
    return window["env"]["upgradeProfileURL"];
  }

  constructor(
    private userService: UserService,
    private membershipService: MembershipService,
    private autoRenewLoggingService: AutoRenewLoggingService,
    private autoRenewFormService: AutoRenewFormService,
    private router: Router,
    protected dialog: MatDialog,
    private modalService: ModalService,
  ) { }

  clickAutoRenewAd() {
    this.autoRenewLoggingService.logClickedAd([]).pipe(
      finalize(() => {
        this.autoRenewFormService.setupAddresses();
        this.dialog.open(TurnOnAutorenewModalComponent, {
          width: this.modalService.getAppropriateWidthProperty(800),
          maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
          height: this.modalService.getAppropriateHeightProperty(),
          maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
          autoFocus: false,
          data: {selectedIncentive: null}
        }).afterClosed().subscribe(data => {
          if (data === 'turn-on') {
            this.router.navigate(['/membership']);
            this.membershipService.autorenewToggleState = true;
          }
        });
      })).subscribe();
  }
}
