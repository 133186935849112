import { Component } from '@angular/core';

import { MailingAddressRecord } from './mailing-address';
import { MailingAddressesService } from './mailing-addresses.service';
import {
  ActiveSection,
  ContactPreferencesNavigationService,
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { MailingAddressesFormComponent } from './form.component';
import { getAddSuccessMessage, serverError } from '../utils/alerts.utils';
import { FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '../ui-components/modals/modal.service';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-new-mailing-address',
  templateUrl: './form.component.html',
})
export class NewMailingAddressComponent extends MailingAddressesFormComponent {

  isEditMode: boolean;

  // @ts-ignore
  get model(): Partial<MailingAddressRecord> {
    return this.addressForm.value;
  };

  verificationSection: ActiveSection = 'verify-unsaved';
  formType = 'new';

  constructor(
    protected service: MailingAddressesService,
    protected navService: ContactPreferencesNavigationService,
    protected modalService: ModalService,
    protected userService: UserService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<MailingAddressesFormComponent>,
  ) {
    super(service, navService, alertService, formBuilder, dialogRef);
  }

  protected skipNormalizeStep() {
    this.service.addMailingAddress(this.model).pipe(
      finalize(() => {
        this.navService.setActiveSection(this.name, 'list');
      })
    ).subscribe(
      () => {
        this.alertService.displaySuccess(getAddSuccessMessage('mailing address'));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // no reason to be able to delete new ones
  canDelete(address?: MailingAddressRecord): boolean {
    return false;
  }

  onConfirmDelete(address: MailingAddressRecord) {
    return;
  }
}
