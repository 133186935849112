<section id="credit-cards">
  <div class="declined-header" *ngIf="isDeclinedCardExist">
    <div *ngIf="!isDeclinedCardDigital">
      <span *ngIf="this.declinedCard.expirationDateFlag === 'expired' else warningDeclined; " >
        Your credit card (··· {{this.declinedCard.creditCard.lastFourDigits}}) has expired.
      </span>
      <ng-template #warningDeclined>
        Sorry, we were unable to process your credit card (··· {{this.declinedCard.creditCard.lastFourDigits}}).
      </ng-template>
      <app-link
      (click)="openEditDialog()"
       class="list-component-edit-link"
      >
      Update card
      </app-link>
    </div>
    <div *ngIf="isDeclinedCardDigital">
      Your digital payment method was declined. Please add a primary credit card so that your payment can be processed.
    </div>
  </div>
</section>
