import { Component, OnInit, Inject, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { PaymentInformation, MembershipService, Product, JoinRenewParams, UpdateCreditCardRequest, CreditCard } from '../membership.service';
import { UserService } from 'app/users/user.service';
import { PaymentFormService } from '../payment/payment-form.service';
import { MailingAddressesService } from 'app/mailing-addresses/mailing-addresses.service';
import { MailingAddressRecord, MailingAddressParams } from 'app/mailing-addresses/mailing-address';
import { WalletService, CCardInfoFromWallet, AddCreditCardToWalletRequest, GoogleEnvironment } from '../wallet/wallet.service';
import { Repo } from 'app/utils/repo';
import { defaultRepo, allRecords } from 'app/utils/contact-records.utils';
import { serverError } from 'app/utils/alerts.utils';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import * as _ from 'lodash'
import { map } from 'rxjs/operators';
import { AutoRenewFormService } from '../auto-renew-form.service';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { CreditCardsWalletListComponent } from './../wallet/ccwallet-list.component';
import { AnalyticsService } from 'app/analytics/analytics.service';
import { ReadyToPayChangeResponse } from '@google-pay/button-angular';
import { NewCreditCardModalComponent } from './new-credit-card-modal.component';
import { TermsAndConditionsModalComponent } from './terms-and-conditions-modal.component';
import { IncentiveService } from '../incentive.service';

declare var componentAppleAvailable: Function;

@Component({
  selector: 'app-payment-option-modal',
  templateUrl: './payment-option-modal.component.html',
  styleUrls: ['./payment-option-modal.component.css']
})

export class PaymentOptionModalComponent implements OnInit {
  @Input() product: Product;
  @Input() isRenewFlag: boolean;
  @Input() yearDisplay: string;
  public _creditCardsInfo: Repo<CCardInfoFromWallet> = defaultRepo;
  public googleEnvironment: GoogleEnvironment;
  public showGoogleButton: boolean = false;
  private entityId: number = 0;
  protected status: string = 'active';
  public showSpinner: boolean = true;
  private _isChangeLinkClicked: boolean = false;
  public showAlternateRates: boolean = false;
  private buttonsDisabled: boolean = false;

  public formTitle = 'Welcome to the AMA';
  public formBodyRenewal;
  public buttonText = 'Join';
  public secondaryButtonText = 'View Additional Rates';

  appleAvailable: boolean = true;
  googleAvailable: boolean = true;

  get paymentInformation(): PaymentInformation {
    return this.membershipService.paymentInfo;
  }

  get defaultMailingAddress(): MailingAddressRecord {
    return this.mailingService.mailingAddresses.primary
  }

  public get creditCardsInfo() {
    return this._creditCardsInfo;
  }

  public get allCreditCards() {
    const creditCardsInfoSaved  = this.walletService.creditCardsInfoSaved;
    return allRecords(creditCardsInfoSaved);
  }

  constructor(
    private router: Router,
    private membershipService: MembershipService,
    private paymentFormService: PaymentFormService,
    private mailingService: MailingAddressesService,
    private alertService: AlertsService,
    private userService: UserService,
    public walletService: WalletService,
    public dialogRef: MatDialogRef<PaymentOptionModalComponent>,
    private autoRenewService: AutoRenewFormService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
    private incentiveService: IncentiveService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.product = data.product;
    this.isRenewFlag = data.isRenewFlag;
    this.yearDisplay = data.yearDisplay;
    this.amount = this.getPriceDisplayed();
  }

  ngOnInit() {
    if (this.isRenewFlag) {
      this.formTitle = 'Membership Renewal';
      this.formBodyRenewal = ' back ';
      this.buttonText = 'Renew';
      this.secondaryButtonText = 'View Additional Rates';
    }
    this.retrieveList();

    this.walletService.getGoogleEnvironment().subscribe(
          (environment) => {
            this.googleEnvironment = environment;
            this.paymentRequest.merchantInfo.merchantId = this.googleEnvironment.merchantId;
            this.paymentRequest.merchantInfo.merchantName = this.googleEnvironment.merchantName;
            this.paymentRequest.allowedPaymentMethods[0].tokenizationSpecification.parameters.gatewayMerchantId = this.googleEnvironment.gatewayMerchantId;
            this.showGoogleButton = this.googleEnvironment.active === 'Y' ? true : false;
          },
          (err) => {
            this.alertService.displayError(err.message || serverError);
          }
        );


    // loading apple pay related scripts
    // this line will only works on local
    //let url = `../../../assets/js/apple.js?version=${Date.now()}`
    let url = `/client/assets/js/apple.js?version=${Date.now()}`
    console.log (`apple js URL ${url}`)
    this.loadScript(url);  //when deploy inside EAR

    this.appleAvailable = componentAppleAvailable();
    console.log (`this.appleAvailable ${this.appleAvailable}`);

    if ( this.walletService.disableGoogle && !this.appleAvailable ) {
      this.newCreditCard();
    }
  }

  private loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  retrieveList(): void {
    this.walletService.getCreditCardListFromWallet(this.status).subscribe(
      (creditCardsInfo) => {
        this.creditCardsInfo = creditCardsInfo;
        this.walletService.creditCardsInfoSaved =  creditCardsInfo;
        this.entityId = this.walletService.entityId;
        // if a primary card exists, the new credit card fields don't show unless change link is clicked
        this.isChangeLinkClicked = !creditCardsInfo.primary;
        if (this.allCreditCards.length === 1 &&
          _.isEmpty(this.allCreditCards[0].creditCard)) {
          this.creditCardsInfo = defaultRepo;
          this.walletService.creditCardsInfoSaved = defaultRepo;
        }
        this.membershipService.getAllProducts().subscribe((response) => {
          //Business requirements 4/21/2023 Do not show alternative rate for M3
          if (this.getProductCode() === '72') {
            this.showAlternateRates = false;
          } else if (response.length > 1) {
            this.showAlternateRates = true;
          } else if (response.length === 1 && response[0].autorenewEligibilityIndicator){
            this.showAlternateRates = true;
          }
          this.showSpinner = false;
        });
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
        this.showSpinner = false;
      }
    );
  }

  public set creditCardsInfo(repo: Repo<CCardInfoFromWallet>) {
    this._creditCardsInfo = repo;
  }

  public get isChangeLinkClicked() {
    return this._isChangeLinkClicked;
  }

  public set isChangeLinkClicked(val: boolean) {
    this._isChangeLinkClicked = val;
  }

  /**
   * Gets the price to be displayed on the page for the default product.
   */
  getPriceDisplayed(): string {
    let price: string;
    if (this.product.halfYearDiscount) {
      price = this.product.halfYearDiscount.toString();
    } else {
      price = this.product.productPrice;
    }

    if (String(price).indexOf('.') >= 0) {
      return parseFloat(price).toFixed(2).toString();
    } else {
      return parseFloat(price).toFixed(0).toString();
    }
  }
  getOriginalPrice(): string {
    let price: string = this.product.productPrice;
    if (String(price).indexOf('.') >= 0) {
      return parseFloat(price).toFixed(2).toString();
    } else {
      return parseFloat(price).toFixed(0).toString();
    }
  }
  /**
   * If product with half year discount.
   */
  isHalfYearDiscount(): boolean {
    return !!this.product.halfYearDiscount
   }
   isProductAutorenewable(): boolean {
    return !!this.product.autorenewEligibilityIndicator
   }
  getProductYear() : number {
    return this.product.membershipYear;
  }
  getProductCode() : string {
    return this.product.productCode;
  }
/**
 * Opens the autorenew terms and conditions modal and tracks it.
*/
public openTocModal() {
  const dialogRef = this.dialog.open(TermsAndConditionsModalComponent, {
    width: this.modalService.getAppropriateWidthProperty(704),
    maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
    height: this.modalService.getAppropriateHeightProperty(),
    maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
    autoFocus: false,
    data: {isConfirmModal: true}
  });
}
  public showIframe() {
    this.analyticsService.additionalRatesClick()
    const url = window["env"]["joinRenewAlternateRates"];
    window.open(url, '_blank');
  }

  public newCreditCard()  {

      this.dialog.open(NewCreditCardModalComponent, {
        width: this.modalService.getAppropriateWidthProperty(502),
        maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
        height: this.modalService.getAppropriateHeightProperty(),
        maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
        autoFocus: false,
        data: {
          product: this.product,
          yearDisplay: this.yearDisplay,
          isRenewFlag: this.isRenewFlag
        }
      }).afterClosed().subscribe(data => {
        if (data === 'showIframe') {
          this.membershipService.showAlternateRatesIframe = true;
          this.router.navigateByUrl('/membership');
        } else if (data === 'success' && this.isRenewFlag) {
          this.closeDialog('success');
        } else if (data === 'success' && !this.isRenewFlag) {
          this.closeDialog('success');
        } else if (data === 'failure') {
          this.closeDialog('failure');
        } else if (data === undefined) {
          if (this.walletService.disableGoogle && !this.appleAvailable) {
            this.closeDialog(undefined);
          }
        }
      });
    }

  ////////////////////////////////google pay begin////////////////////////////////////////////////////////////////

  amount = '0.01';
  buttonType = 'buy';
  buttonColor = 'default';
  buttonLocale = '';
  existingPaymentMethodRequired = false;

  paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY'],
          allowedCardNetworks: ['AMEX', 'MASTERCARD', 'VISA'],
          billingAddressRequired: true,
          billingAddressParameters: {
          format: 'FULL',
          phoneNumberRequired: false
         }
        },
        tokenizationSpecification: {

          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'chase',
            gatewayMerchantId: null,
          }

          // type: 'DIRECT',
          // parameters: {
          //   protocolVersion: 'ECv1',
          //   publicKey: null,
          // },
        },
      },
    ],
    merchantInfo: {
      merchantId: null,
      merchantName: null,
    },
  };

  onLoadPaymentData = (event: CustomEvent<google.payments.api.PaymentData>): void => {
    console.log('load payment data', event.detail);
  };

  onError = (event: ErrorEvent): void => {
    console.error('error', event.error);
  };

  onPaymentDataAuthorized: google.payments.api.PaymentAuthorizedHandler = paymentData => {
    console.log('payment authorized -> ', paymentData);

    this.showSpinner = true;

    const googlePayload = this.buildGoogleData(paymentData);
    const membershipData = this.buildSubmittedDigitalMembershipData();
    let googleSubmittedData = Object.assign(membershipData, googlePayload);

    this.walletService.submitGooglePaymentData(googleSubmittedData).subscribe(
              (result) => {
                console.log("Google Submit payment result -> ");
                console.log(result);
                this.analyticsService.joinMembership(this.product, "googlePay");
                this.showSpinner = false;
                console.log("Spinner set to false");
                this.closeDialog('success');
                console.log("dialogRef.close('success')");
                this.updateAdvantage();
                return {transactionState: 'SUCCESS' };
              },
              (err) => {
                this.showSpinner = false;
                console.log("Spinner set to false");
                this.closeDialog('failure');
                console.log("dialogRef.close('failure')");
                this.alertService.displayError(err.message || serverError);
                return {transactionState: 'ERROR'}
              }
            );

    return {transactionState: 'SUCCESS' };
  };

  buildGoogleData(paymentData) {
    const billingAddress = {
      streetAddress: paymentData.paymentMethodData.info.billingAddress.address1,
      buildingDetails: paymentData.paymentMethodData.info.billingAddress.address2,
      deliveryDetails: paymentData.paymentMethodData.info.billingAddress.address3,
      city: paymentData.paymentMethodData.info.billingAddress.locality,
      stateTerritory: paymentData.paymentMethodData.info.billingAddress.administrativeArea,
      zipCode: paymentData.paymentMethodData.info.billingAddress.postalCode,
    }
    const googlePayloadData = {
      cardHolderName: paymentData.paymentMethodData.info.billingAddress.name,
      paymentToken: paymentData.paymentMethodData.tokenizationData.token,
      billingAddress: billingAddress
    };
    const  returData = {
      googleInfo : googlePayloadData
    }
    return  returData;
 }

  onReadyToPayChange = (event: CustomEvent<ReadyToPayChangeResponse>): void => {
    console.log('!!! ready to pay change isButtonVisible', event.detail.isButtonVisible);
    console.log('!!! ready to pay change isReadyToPay', event.detail.isReadyToPay);
    this.googleAvailable = event.detail.isReadyToPay;;
  };

  onClick = (event: Event): void => {
    console.log('click');
  };

  onClickPreventDefault = (event: Event): void => {
    console.log('prevent default');
    event.preventDefault();
  };

  ////////////////////////////////google pay end////////////////////////////////////////////////////////////////

  ////////////////////////////////apple pay start///////////////////////////////////////////////////////////////

  getDataToSubmit () {
    const request = this.buildSubmittedDigitalMembershipData();
    console.log('In getDataToSubmit');
    console.log(request);
    return JSON.stringify(request);
  }

  filterOptionalFormValues(object: any): any {
    return _.pickBy(object, (value) => value !== '');
  }

  buildSubmittedDigitalMembershipData() {
    //console.log (`this.navigationService.workflowConfiguration.workflowMembershipType ${JSON.stringify(this.navigationService.workflowConfiguration.workflowMembershipType)}`);
    return this.buildRenewFlowMembershipData();
  }

  getIncentive() {
    return {};
  }
  /**
   * Builds the membership data for existing physician/resident/student flow.
   */
   buildRenewFlowMembershipData(){
    let membershipData = {
      firstName: this.userService.profile.firstName,
      lastName: this.userService.profile.lastName,
      entityId: this.userService.profile.entityId,
      membershipYear: this.product.membershipYear,
      emailAddress: this.userService.profile.email,
      effortCode: this.product.effortCode,
      invoiceId: this.product.invoiceId,
      multiYearIndicator: this.product.multiyearIndicator,
      productCode: this.product.productCode,
      amount: this.amount,
      paymentInterval: '',
      incentive: this.getIncentive(),
      meNumber: this.userService.meNumber,
      autoRenew: this.product.autorenewEligibilityIndicator
    };

    return membershipData;
  }

  //This method is executing on Apple Pay Submittion is done
  onDomChange (event){
    //Track Result from APPLE Transaction
    console.log (`In domchange target ${event.target.innerText} !!!!!`);
    var resp = event.target.innerText;
    if (resp.indexOf ("SUCCESS") !== -1) {
      console.log (`Domchange success !!!!!`);
      this.analyticsService.joinMembership(this.product, "applePay");
      this.closeDialog('success');
      this.updateAdvantage();
      console.log (`After Advantage Update`);
    }
    else {
      console.log (`Domchange Failure !!!!!`);
      this.closeDialog('failure');
      this.alertService.displayError("Apple Pay transaction failed");
    }
  }

  closeDialog(message: string) {
    this.ngZone.run(() => {
      console.log ("closeDialog with message " + message);
      this.dialogRef.close(message);
      console.log ("this.dialogRef.close(message) was called");
    });
  }
  close(): void {
    this.dialogRef.close();
  }

  updateAdvantage() {
      this.membershipService.updateAdvantage(this.userService.profile,
        this.product,
        this.defaultMailingAddress,
        this.incentiveService.getGroupName()).subscribe(() => {
        console.log ('In Advantage Update');
      });
  }
}
