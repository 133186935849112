import { Component, EventEmitter, Output } from '@angular/core';
import { EmailRecord } from '../../../../email-addresses/email-address';
import { ModalService } from '../../../../ui-components/modals/modal.service';
import { UpdateEmailModalComponent } from '../../../../email-addresses/modals/update-email-modal.component';

@Component({
  selector: 'app-remove-secondary-email-modal',
  templateUrl: './remove-secondary-email-modal.component.html',
})
export class RemoveSecondaryEmailModalComponent extends UpdateEmailModalComponent {
  @Output() removeEmail = new EventEmitter<EmailRecord>();

  constructor(protected modalService: ModalService) {
    super(modalService);
  }

  onRemove(email: EmailRecord): void {
    this.disableButtons();
    this.removeEmail.emit(email);
  }

  closeModal(emailId: string): void {
    this.modalService.close(`remove-${emailId}`);
  }
}
