import { Component, Input } from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';

export const SELECTOR = 'ama__validated-dropdown-input__v1';

@Component({
  selector: SELECTOR,
  styleUrls: ['./ama__validated-dropdown-input__v1.component.scss'],
  templateUrl: './ama__validated-dropdown-input__v1.component.html',
})
export class AmaValidatedDropdownInputV1Component {
  @Input() public formGroup: FormGroup;
  @Input() public placeholder: string;
  @Input() public fieldName: string;
  @Input() public label: { name: string };
  @Input() public options: { key: string, value: string }[];

  private static tap(context: AbstractControl | null,
      onValuePresent: (value: AbstractControl) => boolean, defaultValue: boolean = false): boolean {
    if (context) {
      return onValuePresent(context);
    }
    return defaultValue;
  }

  public getField(): FormControl {
    return this.formGroup.get(this.fieldName) as FormControl;
  }

  public isFieldPresent(): boolean {
    return AmaValidatedDropdownInputV1Component.tap(this.getField(), field => field.hasError('required') && this.showValidation());
  }

  public isFieldValid() {
    return AmaValidatedDropdownInputV1Component.tap(this.getField(), field => field.valid && this.isFieldTouched());
  }

  public showValidationErrors() {
    return AmaValidatedDropdownInputV1Component.tap(this.getField(), field => !field.valid && this.showValidation());
  }

  public isFieldTouched() {
    return AmaValidatedDropdownInputV1Component.tap(this.getField(), field => field.touched);
  }

  public showValidation() {
    return AmaValidatedDropdownInputV1Component.tap(this.getField(), field => {
      if (field.validator) { return this.isFieldTouched(); }
      return this.isNotEmpty();
    });
  }

  protected isNotEmpty() {
    return AmaValidatedDropdownInputV1Component.tap(this.getField(), field => field.value !== '' && field.value !== undefined);
  }

  public onChange(event) {
    this.getField().setValue(event.currentTarget.value)
  }
}
