import { Input, OnChanges, SimpleChanges, Directive } from '@angular/core';

@Directive()
export class ButtonComponent implements OnChanges {
  @Input() disabled: boolean;

  ngOnChanges(changes: SimpleChanges) {
    this.disabled = !!(changes['disabled'] && changes['disabled'].currentValue);
  }
}
