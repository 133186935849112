<div class="newsletter-subscribe-button">
  <div class="button" *ngIf="!subscribed" (click)="subscribe.emit()" [class.button--unsubscribe]="subscribed">
    Subscribe
    <app-icon-lock *ngIf="membersOnly" color="white"></app-icon-lock>
  </div>

  <div class="subscribed-button button" *ngIf="subscribed"  [class.button--unsubscribe]="subscribed" >
    <div class="text">Subscribed</div>
    <span class="icon icon-checkmark white"></span>
  </div>

  <div class="newsletter-unsubscribe-link" *ngIf="subscribed" (click)="unsubscribe.emit()"> Unsubscribe </div>
</div>
