import { Component } from '@angular/core';
import { EmailAddressesService } from './email-addresses.service';
import { IFormComponent } from '../IFormComponent';
import { ContactPreferencesNavigationService } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { getAddSuccessMessage, serverError, getMarkDefaultSuccessMessage, primaryEmailInUseMessage } from '../utils/alerts.utils';
import { FormBuilder } from '@angular/forms';
import { EmailFormComponent } from './form.component';
import { EmailRecord } from './email-address';
import { finalize } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-email-address',
  templateUrl: './form.component.html',
})
export class NewEmailAddressComponent extends EmailFormComponent implements IFormComponent {

  isEditMode: boolean;
  subscribed: boolean;

  get model(): Partial<EmailRecord> {
    return this.emailForm.value;
  }

  get formData() {
    return {
      address: this.email.value,
    }
  }

  get isChangePrimaryModal() {
    return this.service.isChangePrimaryModal;
  }

  constructor(
    private service: EmailAddressesService,
    private navService: ContactPreferencesNavigationService,
    private alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<NewEmailAddressComponent>,
  ) {
    super(formBuilder);
  }

  onFormSubmit() {
    this.disableButton();
    this.service.addEmail(this.formData)
    .pipe(
      finalize(() => {
        this.navService.setActiveSection(this.name, 'list');
      })
    )
    .subscribe(
      () => {
        this.emailSent = true;
        this.alertService.displaySuccess("We've sent a verification email to your email address");

        if (this.isChangePrimaryModal) {
          this.setAsPrimary();
        }
        // this.onNoClick();
        this.service.isChangePrimaryModal = false;
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  /**
   * If we are on the change primary email modal, when we create a new email
   * we will also want to set that email as Primary
   */
  setAsPrimary() {
    const emailRecord = this.service.getEmailByAddress(this.formData.address);
    this.service.addNew = false;

    this.service.markAsDefault(emailRecord.id).pipe(
      finalize(() => {
      })
    ).subscribe(
      (emails) => {
        this.onNoClick();
        this.alertService.displaySuccess(getMarkDefaultSuccessMessage('email address'));
      },
      (err) => {
        if (err.status === 422) {
          this.alertService.displayError(primaryEmailInUseMessage);
        } else {
          this.alertService.displayError(err.message || serverError);
        }
      }
    );

  }

  onNoClick(): void {
    this.service.isChangePrimaryModal = false;
    this.dialogRef.close();
  }

  isDefault(id?): boolean {
    return false;
  }
}
