import { Component, OnInit, Inject } from '@angular/core';

import { MailingAddressRecord } from './mailing-address';
import { MailingAddressesService } from './mailing-addresses.service';
import {
  ActiveSection,
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { MailingAddressesFormComponent } from './form.component';
import { getUpdateSuccessMessage, serverError } from '../utils/alerts.utils';
import { FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '../ui-components/modals/modal.service';
import { UserService } from '../users/user.service';
import { Repo } from '../utils/repo';
import { defaultRepo } from '../utils/contact-records.utils';

@Component({
  selector: 'app-edit-mailing-address',
  templateUrl: './form.component.html',
})
export class EditMailingAddressComponent extends MailingAddressesFormComponent implements OnInit {
  // @ts-ignore
  model: MailingAddressRecord;
  checked = true;
  isEditMode = true;

  verificationSection: ActiveSection = 'verify-existing';
  name: ContactType = 'mailing address';
  formType = 'edit';

  private _addresses: Repo<MailingAddressRecord> = defaultRepo;
  get addresses() {
    return this._addresses;
  }
  set addresses(repo: Repo<MailingAddressRecord>) {
    this._addresses = repo;
  }

  constructor(
    protected service: MailingAddressesService,
    protected navService: ContactPreferencesNavigationService,
    private modalService: ModalService,
    private userService: UserService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditMailingAddressComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super(service, navService, alertService, formBuilder, dialogRef);
  }

  ngOnInit() {
    this.addressForm.patchValue(this.data.model);
    this.model = this.data.model;

    this.service.getMailingAddresses().subscribe(
      (addresses) => {
        this.addresses = addresses;
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  isDefault(address: MailingAddressRecord): boolean {
    if (!this.addresses.primary || !address) {
      return false;
    }
    return this.addresses.primary.id === address.id;
  }

  protected skipNormalizeStep() {
    this.service.updateMailingAddress(this.data.model.id, this.data.model).pipe(
      finalize(() => {
        this.navService.setActiveSection(this.name, 'list');
      })
    ).subscribe(
      () => {
        this.alertService.displaySuccess(getUpdateSuccessMessage('mailing address'));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  canDelete(): boolean {
    const isPhysician = this.userService.isPhysician;
    return !isPhysician || (isPhysician && !this.isDefault(this.data.model));
  }

  onConfirmDelete(address: MailingAddressRecord) {
    if (this.canDelete()) {
      this.dialogRef.close('delete');
    }
  }
}
