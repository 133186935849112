import { AlertsService } from './alerts.service';
import { Input, OnDestroy, OnInit, Directive } from '@angular/core';

@Directive()
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id: string;
  isVisible = false;
  initialMessage = false;

  constructor(protected service: AlertsService) { }

  ngOnInit(): void {
    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.service.remove(this.id);
  }

  show(): void {
    this.isVisible = true;
    this.initialMessage = true;
  }

  hide(): void {
    this.isVisible = false;
  }
}
