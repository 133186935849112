import { Component, ContentChild, EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { assertNotUndefinedOrNull, Maybe } from '../maybe';

export const SELECTOR = 'ama__template-modal__v1';
const VISIBLE_ATTRIBUTE = 'visible';
const ON_CLOSE_REQUESTED_ATTRIBUTE = 'on-close-requested';
const ON_CLOSE_COMPLETED_ATTRIBUTE = 'on-close-completed';

class ScrollLock {
  private _locked: boolean = false;
  private _overflow: string | null = null;

  public lock(): void {
    if (!this._locked) {
      this._locked = true;
      this._overflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    }
  }

  public release(): void {
    if (this._locked) {
      document.body.style.overflow = this._overflow;
      this._locked = false;
    }
  }
}

@Component({
  selector: SELECTOR,
  styleUrls: [ './ama__template-modal__v1.component.scss' ],
  templateUrl: './ama__template-modal__v1.component.html',
})
export class AmaTemplateModalV1Component implements OnDestroy {
  @Output(ON_CLOSE_REQUESTED_ATTRIBUTE)
  private _onCloseRequestedEventEmitter = new EventEmitter<void>();

  @Output(ON_CLOSE_COMPLETED_ATTRIBUTE)
  private _onCloseCompletedEventEmitter = new EventEmitter<void>();

  @ContentChild(TemplateRef, { static: true })
  private _template: Maybe<TemplateRef<object>>;

  private _visible: boolean;
  private _lock = new ScrollLock();

  @Input(VISIBLE_ATTRIBUTE)
  public set visible(visible: Maybe<boolean>) {
    this._visible = assertNotUndefinedOrNull(visible, SELECTOR, VISIBLE_ATTRIBUTE);
    if (this._visible) {
      this._lock.lock();
    } else {
      this._lock.release();
    }
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  public get template(): Maybe<TemplateRef<object>> {
    return this._template;
  }

  public onCloseIconClick(): void {
    this._onCloseRequestedEventEmitter.emit();
  }

  public ngOnDestroy(): void {
    this._lock.release();
  }

  public fadeAnimationCompleted(): void {
    if (!this._visible) {
      this._onCloseCompletedEventEmitter.emit();
    }
  }
}
