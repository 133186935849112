import { Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { EmailRecord, SubscriptionEmails } from '../../../email-addresses/email-address';

import * as _ from 'lodash';

import { AlertsService } from '../../../ui-components/alerts/alerts.service';
import { allRecords } from '../../../utils/contact-records.utils';
import { EmailAddressesService } from '../../../email-addresses/email-addresses.service';
import { HasAssociatedSubscribablesComponent } from '../../../email-addresses/has-associated-subscribables.component';
import { ModalService } from '../../../ui-components/modals/modal.service';
import { serverError, updateSubscriptionEmailSuccess } from '../../../utils/alerts.utils';
import { Repo } from '../../../utils/repo';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChangePrimaryEmailComponent } from 'app/email-addresses/app-change-primary-email/app-change-primary-email.component';

@Component({
  selector: 'app-subscription-emails-form',
  templateUrl: './subscription-emails-form.component.html',
})
export class SubscriptionEmailsFormComponent extends HasAssociatedSubscribablesComponent implements OnInit {
  @Input() emailRepo: Repo<EmailRecord>;
  @Input() subscriptionEmails: SubscriptionEmails;
  @Output() subscriptionEmailsChanged = new EventEmitter<SubscriptionEmails>();
  @ViewChild('f', { static: true }) updateEmailForm;

  selectedEmails: SubscriptionEmails;
  showSubscriptionEmailOptions = false;
  buttonDisabled = false;
  noEmailSelected = false;
  private modalId: string;

  get primaryEmail() {
    return this.subscriptionEmails.primary;
  }

  get secondaryEmailOptions() {
    return this.emailRepo.others;
  }

  get allEmails() {
    return allRecords(this.emailRepo);
  }

  get currentAlternateEmail() {
    return this.subscriptionEmails.alternate;
  }

  get selectedAlternateEmail() {
    return this.selectedEmails.alternate;
  }

  set selectedAlternateEmail(email: EmailRecord) {
    this.selectedEmails.alternate = email;
  }

  getAlternateEmailFromForm(): string {
    if (this.updateEmailForm &&
      this.updateEmailForm.form &&
      this.updateEmailForm.form.get('alternate') &&
      this.updateEmailForm.form.get('alternate')) {
      return this.updateEmailForm.form.get('alternate').value;
    }
  }

  constructor(
    private service: EmailAddressesService,
    private alertService: AlertsService,
    private modalService: ModalService,
    protected dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedEmails = _.cloneDeep(this.subscriptionEmails);
  }

  // AR-15 task AR-197: Open MatDialog for changing my primary email
  onChangeEmail() {
    this.service.buttonValue = 'Save';
    const dialogRef = this.dialog.open(ChangePrimaryEmailComponent, {
      width: this.modalService.getAppropriateWidthProperty(560),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {isConfirmModal: false},

    });
     dialogRef.afterClosed().subscribe(result => {
      this.service.getEmails().subscribe(getEmailsResult => {
        this.subscriptionEmails = getEmailsResult;
      });
     });
  }

  onCancel() {
    this.hideSubscriptionEmailOptions();
    this.selectedEmails.alternate = this.currentAlternateEmail;
    this.noEmailSelected = false;
  }

  onFormSubmit() {
    if (this.getAlternateEmailFromForm() === '') {
      this.noEmailSelected = true;
      return;
    } else {
      this.noEmailSelected = false;
    }
    this.selectedAlternateEmail = this.findEmailById(this.getAlternateEmailFromForm());
    if (this.currentAlternateEmail && this.hasSubscriptions(this.currentAlternateEmail)) {
      this.modalId = `update-${this.currentAlternateEmail.id}`;
      this.modalService.open(this.modalId);
    } else {
      this.onUpdate();
    }
  }

  onUpdate() {
    this.disableButton();
    this.service.updateSecondarySubscriptionEmail(this.selectedAlternateEmail).pipe(
        finalize(() => {
          this.hideSubscriptionEmailOptions();
          this.enableButton();
          this.modalService.close(this.modalId);
        })
      ).subscribe(
      (emails) => {
        this.subscriptionEmails = emails as SubscriptionEmails;
        this.subscriptionEmailsChanged.emit(this.subscriptionEmails);
        this.alertService.displaySuccess(updateSubscriptionEmailSuccess);
      },
      (err) => {
        this.alertService.displayError(serverError);
      }
    );
  }

  removeSecondaryEmail() {
    if (this.hasSubscriptions(this.currentAlternateEmail)) {
      this.modalId = `remove-${this.currentAlternateEmail.id}`;
      this.modalService.open(this.modalId);
    } else {
      this.onRemoveSecondary();
    }
  }

  onRemoveSecondary() {
    this.disableButton();
    this.service.removeSecondarySubscriptionEmail().pipe(
      finalize(() => {
        this.hideSubscriptionEmailOptions();
        this.enableButton();
        this.modalService.close(this.modalId);
      })
    ).subscribe(
      (emails) => {
        this.subscriptionEmails = emails as SubscriptionEmails;
        this.subscriptionEmailsChanged.emit(this.subscriptionEmails);
        this.alertService.displaySuccess(updateSubscriptionEmailSuccess);
      },
      (err) => {
        this.alertService.displayError(serverError);
      }
    );
  }

  findEmailById(id: string) {
    return _.find(this.allEmails, (email) => {
      return email.id === id;
    });
  }

  private displaySubscriptionEmailOptions() {
    this.showSubscriptionEmailOptions = true;
  }

  private hideSubscriptionEmailOptions() {
    this.showSubscriptionEmailOptions = false;
  }

  private selectDefaultEmail() {
    if (!this.subscriptionEmails.alternate) {
      this.selectedEmails.alternate = this.secondaryEmailOptions[0];
    }
  }

  disableButton() {
    this.buttonDisabled = true;
  }

  enableButton() {
    this.buttonDisabled = false;
  }

  selectCurrentAlternateEmailIfAvailable(email) {
    if (!!this.currentAlternateEmail) {
      return this.currentAlternateEmail.id === email.id;
    }
  }

  get currentAlternateEmailId(): string {
    if (!!this.currentAlternateEmail) {
      return this.currentAlternateEmail.id;
    }
    return '';
  }
}
