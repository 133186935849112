<div [@visibilityChanged]="isVisible" *ngIf="initialMessage" class="alert {{ className }}">
  <div class="layout layout_one_up">
    <div class="alert_container" *ngIf="isVisible">
      <app-icon-alert *ngIf="className === 'alert-error'" color="white"></app-icon-alert>
      <app-icon-valid *ngIf="className === 'alert-success'" color="white"></app-icon-valid>
      <p class="alert_message">
        {{ message }}
      </p>
      <a class="alert_close"
        (click)="hide()"
        href="javascript://">
        <app-icon-close color="white"></app-icon-close>
      </a>
    </div>
  </div>
</div>
