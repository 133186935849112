import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardListComponent } from './card-list.component';
import { SpinnersModule } from '../spinners/spinners.module';
import { CardHeaderComponent } from './card-header.component';

@NgModule({
  imports: [
    CommonModule,
    SpinnersModule,
  ],
  declarations: [
    CardListComponent,
    CardHeaderComponent,
  ],
  exports: [
    CardListComponent,
    CardHeaderComponent,
  ],
})
export class CardsModule { }
