import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { InputAutofocusDirective } from '../directives/autofocus.directive';
import { AmaValidatedTextInputV1Component } from './ama__validated-text-input__v1.component';

@NgModule({
  declarations: [AmaValidatedTextInputV1Component, InputAutofocusDirective],
  exports: [AmaValidatedTextInputV1Component],
  imports: [ReactiveFormsModule, CommonModule, TextMaskModule],
})
export class AmaValidatedTextInputV1Module {}
