<div class="form-item form-item-no-padding" [formGroup]="formGroup">
    <label [for]="fieldName" class="form-item_label show">{{ label.name }}</label>
    <select (change)="onChange($event)" [formControlName]="fieldName"
            [id]="fieldName"
            class="dropdown-select"
            [ngClass]="{'is-valid valid': isFieldValid(), 'has-error error': isFieldTouched() && !isFieldValid()}">
        <option class="dropdown-select_option--disabled" disabled value="null">{{ placeholder }}</option>
        <option *ngFor="let option of options" [value]="option.key">
            {{ option.value }}
        </option>
    </select>
    <label class="error-message" *ngIf="isFieldPresent()">
        Field is required.
    </label>
</div>
