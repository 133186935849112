<div class="form-item layout layout_two_up-large" [formGroup]="questionFormGroup">
  <legend class="align-left text--heavy">{{ question.text }}</legend>
  <div class="layout_two_up-large-primary float-left align-left">
    <div>
      <div *ngFor="let answer of question.options.slice(0, answersHalfPoint)" class="margin-top-bottom--small">
        <input [formControlName]="answer.id"
               type="checkbox"
               [id]="getAnswerId(answer)" >
        <label [for]="getAnswerId(answer)">{{ answer.text }}</label>
      </div>
    </div>
  </div>

  <div class="layout_two_up-large-secondary float-right align-left">
    <div>
      <div *ngFor="let answer of question.options.slice(answersHalfPoint, numbersOfAnswers)">
        <input [formControlName]="answer.id"
               type="checkbox"
               [id]="getAnswerId(answer)" >
        <label [for]="getAnswerId(answer)">{{ answer.text }}</label>
      </div>
    </div>
  </div>
</div>
