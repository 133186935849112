import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

export const SELECTOR = 'ama__validated-text-input__v1';

@Component({
  selector: SELECTOR,
  styleUrls: ['./ama__validated-text-input__v1.component.scss'],
  templateUrl: './ama__validated-text-input__v1.component.html',
})
export class AmaValidatedTextInputV1Component {
  @Input() public formGroup: FormGroup;
  @Input() public itemPlaceholder: string;
  @Input() public fieldName: string;
  @Input() public itemLabel: { name: string };
  @Input() public patternValidationMessage: string;
  @Input() public itemMaxLength: string;
  @Input() public focus: string;
  @Input() public delayAutofocus: number;
  @Input() public fieldMask = false;

  public getField(): AbstractControl | null {
    return this.formGroup.get(this.fieldName);
  }

  public isFieldPresent() {
    const field = this.getField();
    if (!field) { return false; }
    return field.hasError('required') && this.showValidation();
  }

  public isFieldValid() {
    const field = this.getField();
    if (!field) { return false; }
    return field.valid && this.showValidation();
  }

  public showValidationErrors() {
    const field = this.getField();
    if (!field) { return false; }
    return !field.valid && this.showValidation();
  }

  public isFieldTouched() {
    const field = this.getField();
    if (!field) { return false; }
    return field.touched;
  }

  public showValidation() {
    const field = this.getField();
    if (field && field.validator) { return this.isFieldTouched(); }
    return this.isNotEmpty();
  }

  public patternIsInvalid(): boolean {
    const field = this.getField();
    if (!field) { return false; }
    return field.touched &&
      field.errors &&
      field.errors['pattern'];
  }

  protected isNotEmpty() {
    const field = this.getField();
    if (!field) { return false; }
    return (field.value !== '' && !(field.value === null) && !(field.value === undefined)) || field.dirty;
  }
}
