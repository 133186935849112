import { Component, EventEmitter, Input, Output } from '@angular/core';
import { assertNotUndefinedOrNull, Maybe } from '../maybe';

export const SELECTOR = 'ama__fade-animation__v1';
const VISIBLE_ATTRIBUTE = 'visible';
const ON_ANIMATION_COMPLETED_ATTRIBUTE = 'on-animation-completed';

/* tslint:disable:no-use-before-declare */

export abstract class AmaFadeAnimationV1ComponentState {
  public abstract visibleClassApplied: boolean;
  public abstract contentPresentOnTheDom: boolean;
  public visibilityEnabled(_: AmaFadeAnimationV1Component): void {}
  public visibilityDisabled(_: AmaFadeAnimationV1Component): void {}
  public onTransitionEnd(_: AmaFadeAnimationV1Component): void {}
}

export class VoidState extends AmaFadeAnimationV1ComponentState {
  public static INSTANCE = new VoidState();

  public visibleClassApplied = false;
  public contentPresentOnTheDom = false;

  public visibilityEnabled(component: AmaFadeAnimationV1Component): void {
    component.state = VisibleState.INSTANCE;
  }

  public visibilityDisabled(component: AmaFadeAnimationV1Component): void {
    component.state = HiddenState.INSTANCE;
  }
}

export class HiddenState extends AmaFadeAnimationV1ComponentState {
  public static INSTANCE = new HiddenState();

  public visibleClassApplied = false;
  public contentPresentOnTheDom = false;

  public visibilityEnabled(component: AmaFadeAnimationV1Component): void {
    component.state = FadingInState.INSTANCE;
  }
}

export class FadingInState extends AmaFadeAnimationV1ComponentState {
  public static INSTANCE = new FadingInState();

  public visibleClassApplied = true;
  public contentPresentOnTheDom = true;

  public visibilityDisabled(component: AmaFadeAnimationV1Component): void {
    component.state = FadingOutState.INSTANCE;
  }

  public onTransitionEnd(component: AmaFadeAnimationV1Component): void {
    component.state = VisibleState.INSTANCE;
    component.emitAnimationCompletedEvent();
  }
}

export class VisibleState extends AmaFadeAnimationV1ComponentState {
  public static INSTANCE = new VisibleState();

  public visibleClassApplied = true;
  public contentPresentOnTheDom = true;

  public visibilityDisabled(component: AmaFadeAnimationV1Component): void {
    component.state = FadingOutState.INSTANCE;
  }
}

export class FadingOutState extends AmaFadeAnimationV1ComponentState {
  public static INSTANCE = new FadingOutState();

  public visibleClassApplied = false;
  public contentPresentOnTheDom = true;

  public visibilityEnabled(component: AmaFadeAnimationV1Component): void {
    component.state = FadingInState.INSTANCE;
  }

  public onTransitionEnd(component: AmaFadeAnimationV1Component): void {
    component.state = HiddenState.INSTANCE;
    component.emitAnimationCompletedEvent();
  }
}

/* tslint:enable:no-use-before-declare */

@Component({
  selector: SELECTOR,
  styleUrls: [ './ama__fade-animation__v1.component.scss' ],
  templateUrl: './ama__fade-animation__v1.component.html',
})
export class AmaFadeAnimationV1Component {
  private _state: AmaFadeAnimationV1ComponentState = VoidState.INSTANCE;

  @Output(ON_ANIMATION_COMPLETED_ATTRIBUTE)
  private _onAnimationCompletedEventEmitter = new EventEmitter<void>();

  @Input(VISIBLE_ATTRIBUTE)
  public set visible(visible: Maybe<boolean>) {
    if (assertNotUndefinedOrNull(visible, SELECTOR, VISIBLE_ATTRIBUTE)) {
      this._state.visibilityEnabled(this);
    } else {
      this._state.visibilityDisabled(this);
    }
  }

  public set state(newState: AmaFadeAnimationV1ComponentState) {
    this._state = newState;
  }

  public get visibleClassApplied(): boolean {
    return this._state.visibleClassApplied;
  }

  public get contentPresentOnTheDom(): boolean {
    return this._state.contentPresentOnTheDom;
  }

  public onTransitionEnd(_: TransitionEvent): void {
    this._state.onTransitionEnd(this);
  }

  public emitAnimationCompletedEvent(): void {
    this._onAnimationCompletedEventEmitter.emit();
  }
}
