import { EmailRecord } from '../../../../email-addresses/email-address';
import { UpdateEmailModalComponent } from '../../../../email-addresses/modals/update-email-modal.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-update-subscription-email-modal',
  templateUrl: './update-subscription-email-modal.component.html',
})
export class UpdateSubscriptionEmailModalComponent extends UpdateEmailModalComponent {
  @Input() currentEmail: EmailRecord;
  @Input() selectedEmail: EmailRecord;

  onUpdate(email: EmailRecord): void {
    this.updateEmail.emit(email);
  }
}
