import { Component, OnInit } from '@angular/core';
import { SogiComponent } from './sogi.component';
import { SogiService } from './sogi.service';
import { MatDialog } from '@angular/material/dialog';
import { SogiFindRecord } from './sogi-find';
import { ModalService } from 'app/ui-components/modals/modal.service';

@Component({
  selector: 'app-list-sogi',
  templateUrl: './list.component.html'
})
export class ListSogiComponent implements OnInit {
  data: SogiFindRecord;

  constructor(
    private sogiService: SogiService,
    protected dialog: MatDialog,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.sogiService.findSogiInfo().subscribe(
      (result) => {
         this.data = result;
      }
    )
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(SogiComponent, {
      width: this.modalService.getAppropriateWidthProperty(845),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {sogiRecord: this.data}
    });
    // refresh the section's info
    dialogRef.afterClosed().subscribe(result => { this.getData()});
  }

  /**
   * This method checks if the user has ANY SOGI data on file
   * If the user has any data inputted for genderdesc, sexualdesc, and/or transgender desc
   * we return true.
   * else we return false
   */
  get hasSOGIData(): boolean {
    if (!!this.data) {
      if (this.data.genderdesc || this.data.sexualdesc || this.data.transgenderInd) {
        return true;
      }
    }
    return false;
  }
}
