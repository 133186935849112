<div class="template-modal" *ngIf="isVisible">
  <div class="template-modal__body width-70">

    <span class="template-modal__close">
      <ama__close-icon__v1 
        (on-click)="onCloseIconClick()">
      </ama__close-icon__v1>
    </span>

    <div>
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>

  </div>
</div>

<ama__fade-animation__v1 
  [visible]="isVisible"
  (on-animation-completed)="fadeAnimationCompleted()">
    <div class="template-modal__background"></div>
</ama__fade-animation__v1>
