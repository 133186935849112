import { ContactNumberRecord } from './contact-number';
import { PhoneNumbersService } from '../phone-numbers/phone-numbers.service';
import { FaxNumbersService } from '../fax-numbers/fax-numbers.service';
import { IFormComponent } from '../IFormComponent';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { getAddSuccessMessage, serverError } from '../utils/alerts.utils';
import { ContactNumberFormComponent } from './form.component';
import { FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AnalyticsService } from 'app/analytics/analytics.service';

export class NewContactNumberComponent extends ContactNumberFormComponent implements IFormComponent {
  readonly createNew = true;
  name: ContactType;
  buttonsDisabled = false;
  subscribed: boolean;


  // @ts-ignore
  get model(): Partial<ContactNumberRecord> {
    return this.contactNumberForm.value;
  }

  constructor(
    protected service: PhoneNumbersService | FaxNumbersService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected analyticsService : AnalyticsService
  ) {
    super(service, navService, alertService, formBuilder);
  }

  onFormSubmit(): void {
    this.disableButton();
    this.onNoClick();
    const fdata = this.formData;
    this.service.addContactNumber(this.formData).pipe(
      finalize(() => {
        this.navService.setActiveSection(this.name, 'list');
        this.enableButton();
      })
    ).subscribe(
      () => {
        if (this.name.includes('fax number')){
          this.analyticsService.contactInfoUpdate('fax', 'add', fdata.type, fdata.number );
         }else{
          this.analyticsService.contactInfoUpdate('phone', 'add', fdata.type, fdata.number );
        }
        this.alertService.displaySuccess(getAddSuccessMessage(this.name));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  /**
   * Since the new element either automatically becomes the primary (if there is
   * not one currently), or you have to choose it to become primary from outside
   * the dialog, we return false in all cases.
   */
  isDefault(id?): boolean {
    return false;
  }

  /**
   * Function that should be overridden and extended in inheriting components.
   */
  onNoClick(): void {
  }

  isMobile(number: any) {
    return false;
  }

  canDelete() {
    return false;
  }

  onConfirmDelete() { }
}
