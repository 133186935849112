import { Injectable } from '@angular/core';

import { createRecord, allRecords } from '../utils/contact-records.utils';
import { EmailHolder, EmailRecord, SubscriptionEmails } from './email-address';
import { EmailAddressesMemoryAPI } from './email-addresses.memory-api';
import { MaxRecordsConfig } from '../../config/max-database-records.config';
import { Repo } from '../utils/repo';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { AnalyticsService } from 'app/analytics/analytics.service';


@Injectable()
export class EmailAddressesService {
  activeSection = 'list';
  disableAdd = false;
  private url = '/api/email-addresses';
  private addEmailUrl = '/api/verify-addemail';
  private updateEmailUrl = '/api/verify-updateemail';
  private changeEmailUrl = '/api/change-email';
  private emailHolderUrl = 'api/email-holder';
  subscriptionEmails: SubscriptionEmails;
  contactPreferences: Repo<EmailRecord>;
  private limit;
  popupClosed = false;

  /**
   * The isChangePrimaryModal boolean var is used to
   * manipulate the new and list email components
   *
   * The addNew boolean var is used to open and close the
   * add new email section on the Change Primary Email Modal
   *
   * The buttonValue var is the name of the button on the Change Primary Email Modal
   * (it will change depending on whether or not the modal is popping on the newsletters page or the landing page)
  */
  isChangePrimaryModal: boolean;
  addNew: boolean = false;
  buttonValue = 'save';

  constructor(
    private httpClient: HttpClient,
    private memoryAPI: EmailAddressesMemoryAPI,
    private analyticsService: AnalyticsService,
    maxRecordsConfig: MaxRecordsConfig,
  ) {
    this.limit = maxRecordsConfig.emailAddresses;
  }

  addEmail(params: Partial<EmailRecord>): Observable<Repo<EmailRecord>> {
    return this.httpClient.post(this.addEmailUrl, {address: params.address}).pipe(
      map((response: ApiResponse<any>) => {
        if (response?.data.id) {
          const id = response?.data.id;
          this.analyticsService.contactInfoUpdate('email', 'add', '', params.address );
          return this.checkEmailCount(
            this.memoryAPI.addEmail(createRecord(params, id))
          );
        }
      })
    );
  }
  getEmailByAddress(emailAddr: string): EmailRecord {
    return this.memoryAPI.findByAddress(emailAddr);
  }

  getEmails(): Observable<Repo<EmailRecord>> {
    if (!!this.contactPreferences && !this.popupClosed) {
      return of(this.checkEmailCount(this.contactPreferences));
    }

    return this.httpClient.get(`${this.url}/contact-preferences`).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        this.contactPreferences = data;
        this.memoryAPI.repo = data;
        return this.checkEmailCount(data);
      })
    );
  }

  markAsDefault(id: string): Observable<Repo<EmailRecord>> {
    const body = { emailAddressId: id };
    return this.httpClient.put(`${this.url}/primary`, body).pipe(
      map(() => {
        this.contactPreferences = this.memoryAPI.markAsDefault(id);
        this.subscriptionEmails.primary = this.contactPreferences.primary;
        this.analyticsService.contactInfoUpdate('email', 'make primary', '', this.subscriptionEmails.primary.address );
        return this.contactPreferences;
      })
    );
  }

  deleteEmail(id: string): Observable<Repo<EmailRecord>> {
    const body = { emailAddressId: id };
    return this.httpClient.request('delete', this.url, { body: body }).pipe(
      map(() => {
          return this.checkEmailCount(
          this.memoryAPI.deleteEmail(id)
        );
      })
    );
  }
  updateEmail(id: string, params: Partial<EmailRecord>): Observable<Repo<EmailRecord>> {
    const body = { emailAddressId: id, address: params.address };
    return this.httpClient.post(this.updateEmailUrl, body).pipe(
      map((response: ApiResponse<any>) => {
        if(response?.data.id){
          this.contactPreferences = this.memoryAPI.updateEmail(id, params);
          this.analyticsService.contactInfoUpdate('email', 'edit', '', params.address );
          return this.contactPreferences;
        }
      })
    );
  }

  
  getEmailHolder(uuid: string): Observable<EmailHolder> {
    const params = new HttpParams().set('uuid', uuid);
     return this.httpClient.get(this.emailHolderUrl, {params: params}).pipe(
      map ((response: ApiResponse<EmailHolder>) => {
        return response.data; 
      })
     );
  }

  changeEmail(uuid: string): Observable<any> {
    return this.httpClient.post(this.changeEmailUrl, uuid).pipe(
      map((response: ApiResponse<EmailHolder>) => {
        if(response.data){
          const holder = response.data;
        }
       return response
      })
    );
  }
  
  updateSecondarySubscriptionEmail(email: EmailRecord): Observable<SubscriptionEmails> {
    const body = { emailAddressId: email.id };
     return this.httpClient.put(`${this.url}/subscription-alternate`, body).pipe(
       map(() => {
        this.subscriptionEmails = null;
        return {
          primary: this.memoryAPI.getEmails().primary,
          alternate: email,
        }
       })
     );
  }

  removeSecondarySubscriptionEmail(): Observable<SubscriptionEmails> {
    const body = { };
    return this.httpClient.put(`${this.url}/subscription-alternate`, body).pipe(
      map(() => {
        this.subscriptionEmails = null;
        return {
          primary: this.memoryAPI.getEmails().primary,
          alternate: null,
        }
      })
    );
  }

  getSubscriptionEmails(): Observable<SubscriptionEmails> {
    if (!!this.subscriptionEmails) {
      return of(this.subscriptionEmails);
    }

    return this.httpClient.get(`${this.url}/subscriptions`).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        this.subscriptionEmails = data;
        return data;
      })
    );
  }

  private checkEmailCount(emails: Repo<EmailRecord>): Repo<EmailRecord> {
    this.disableAdd = allRecords(emails).length >= this.limit;
    return emails;
  }
}
