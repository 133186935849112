import { Input, OnInit, Inject, ViewChild, Directive } from '@angular/core';

import { ContactNumberRecord } from './contact-number';
import { PhoneNumbersService } from '../phone-numbers/phone-numbers.service';
import { FaxNumbersService } from '../fax-numbers/fax-numbers.service';
import { IFormComponent } from '../IFormComponent';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { getUpdateSuccessMessage, serverError } from '../utils/alerts.utils';
import { ContactNumberFormComponent } from './form.component';
import { FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Repo } from 'app/utils/repo';
import { defaultRepo } from 'app/utils/contact-records.utils';
import { UserService } from 'app/users/user.service';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Directive()
export class EditContactNumberComponent extends ContactNumberFormComponent implements IFormComponent, OnInit {
  @Input() model: Partial<ContactNumberRecord>;

  private _contactNumbers: Repo<ContactNumberRecord> = defaultRepo;
  name: ContactType;
  buttonsDisabled = false;
  private dataLoaded: boolean;
  readonly createNew = false;
  subscribed: boolean;
  isEditMode: boolean;

  constructor(
    protected service: PhoneNumbersService | FaxNumbersService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected analyticsService: AnalyticsService,
  ) {
    super(service, navService, alertService, formBuilder);
    this.model = data.model;
  }

  ngOnInit() {
    this.contactNumberForm.patchValue(this.model);
    this.service.getContactNumbers().subscribe(
      (numbers) => {
        this.contactNumbers = numbers;
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      } // isloading
    );
  }

  set contactNumbers(repo: Repo<ContactNumberRecord>) {
    this.dataLoaded = true;
    this._contactNumbers = repo;
  }

  get contactNumbers(): Repo<ContactNumberRecord> {
    return this._contactNumbers;
  }

  /**
   * This checks if the input phone number or fax number is the 'primary' number.
   */
  isDefault(id: string): boolean {
    if (!id || !this.contactNumbers.primary) {
      return false; // check if exist
    }
    return id === this.contactNumbers.primary.id;
  }

  /**
   * Checks the title variable of the component,
   * isPhone checks if it is the same as the \phone-numbers\edit.component.ts
   *
   */
  isPhone(title: any): boolean {
    return title === 'Phone';
  }

  /**
   * Checks the title variable of the component,
   * isFax checks if it is the same as the \fax-numbers\edit.component.ts
   *
   */
  isFax(title: any): boolean {
    return title === 'Fax';
  }
  isMobile(number: any): boolean {
    // todo add type
    if (number._parent.value.type === 'mobile') {
      return true;
    }
    return false;
  }

  get isPhysician(): boolean {
    return this.userService.isPhysician;
  }

  /**
   * This function is only applicable to phone numbers in contact-numbers.
   *
   * I can delete any of my phone numbers if I am not a physician.
   * If I am a physician, I cannot remove my primary (isDefault) phone number.
   */
  canDelete(id?): boolean {
    return !this.isPhysician || (this.isPhysician && !this.isDefault(id));
  }

  onNoClick(): void {
    // implemented in phone and fax
  }

  onFormSubmit(): void {
    this.disableButton();
    const fdata = this.formData;
    this.service.updateContactNumber(this.model.id, this.formData).pipe(
      finalize(() => {
        this.navService.setActiveSection(this.name, 'list');
        this.onNoClick();
      })
    ).subscribe(
      () => {
        if (this.name.includes('fax number')){
          this.analyticsService.contactInfoUpdate('fax', 'edit', fdata.type, fdata.number );
        }else{
          this.analyticsService.contactInfoUpdate('phone', 'edit', fdata.type, fdata.number );
        }

        this.alertService.displaySuccess(getUpdateSuccessMessage(this.name));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      });
  }

}
