import { Component, OnInit } from '@angular/core';
import { ReferralFormService } from './referral-form.service';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html'
})
export class ReferralsComponent implements OnInit {

  constructor( public service: ReferralFormService ) {}

  ngOnInit() {
    this.service.setupBlankForm();
  }
}
