import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'toString'})
export class NumberToStringConverterPipe implements PipeTransform {

  constructor() {}

  transform(value: number): string {
    return `${ value}`
  }
}
