import { EventEmitter, Output } from '@angular/core';
import { MailingAddressesService } from '../mailing-addresses.service';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../../contact-preferences/contact-preferences-navigation.service';
import { MailingAddress, MailingAddressRecord } from '../mailing-address';
import { AlertsService } from '../../ui-components/alerts/alerts.service';

export class VerifyMailingAddressComponent {

  get normalizedAddress(): MailingAddress {
    return this.service.verificationResult.normalized;
  }

  get originalAddress(): MailingAddress {
    return this.service.verificationResult.original;
  }

  get editingAddress(): Partial<MailingAddressRecord> {
    return this.service.originalAddress;
  }

  model = {
    selectedAddress: 'normalized',
  };

  name: ContactType = 'mailing address';

  constructor(
    protected service: MailingAddressesService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
  ) { }

  onFormSubmit() { }

  protected getAddressRecord(address: MailingAddress, type: string): Partial<MailingAddressRecord> {
    const addressRecord: Partial<MailingAddressRecord> = {
      type: type,
      lineOne: address.lineOne,
      city: address.city,
      stateTerritory: address.stateTerritory,
      zipCode: address.zipCode,
    };

    if (address.lineTwo) {
      addressRecord.lineTwo = address.lineTwo;
    }

    if (address.lineThree) {
      addressRecord.lineThree = address.lineThree;
    }

    return addressRecord;
  }
}
