import { NgModule } from '@angular/core';
import { ContactUsLinkComponent } from './contact-us-link.component';

@NgModule({
  declarations: [
    ContactUsLinkComponent
  ],
  exports: [
    ContactUsLinkComponent
  ]
})
export class ContactUsLinkModule { }
