import { Component, Inject, Output, EventEmitter } from '@angular/core';

import { FaxNumbersService } from './fax-numbers.service';
import { FaxNumberType, dropdownFaxNumberTypes } from './fax-number-types';
import { EditContactNumberComponent } from '../contact-numbers/edit.component';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { finalize } from 'rxjs/operators';
import { getUpdateSuccessMessage } from 'app/utils/alerts.utils';
import { ContactNumberRecord } from 'app/contact-numbers/contact-number';
import { UserService } from 'app/users/user.service';
import { AnalyticsService } from 'app/analytics/analytics.service';

export function getDeleteSuccessMessage(contactType: string): string {
  return `Your ${contactType} was successfully deleted!`;
}
export const serverError = 'We’re having trouble connecting to our server. Try reloading or checking your internet connection.';

@Component({
  selector: 'app-edit-fax-number',
  templateUrl: '../contact-numbers/form.component.html',
    })
export class EditFaxNumberComponent extends EditContactNumberComponent {
  @Output() deleteNumber = new EventEmitter<ContactNumberRecord>();

  readonly title = 'Fax';
  readonly typesLabel = 'Fax Number Type*';
  readonly numberPlaceholder = 'Fax Number*';
  readonly extPlaceholder = 'Extension';
  isEditMode = true;

  numberTypes: FaxNumberType[] = dropdownFaxNumberTypes;
  name: ContactType = 'fax number';

  constructor(
    protected service: FaxNumbersService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditFaxNumberComponent>,
    protected modalService: ModalService,
    protected dialog: MatDialog,
    protected userService: UserService,
    protected analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: any, // TODO fix type
  ) {
    super(service, navService, alertService, formBuilder, userService, data, analyticsService);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.navService.setActiveSection(this.name, 'list');

  }
  onConfirmDelete(number) {
    this.deleteNumber.emit(number);
    this.enableButton();
    this.onDelete(this.data.model);
    this.modalService.open(number.id);
  }

  canDelete() {
    return true;
  }

  onDelete(number) {
    this.service.deleteContactNumber(number.id).pipe(
        finalize(() => {
               })
      ).subscribe(
      () => {
        this.onNoClick();
        this.analyticsService.contactInfoUpdate('fax', 'remove', number.type, number.number );
        this.alertService.displaySuccess(getDeleteSuccessMessage(this.name));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }
}
