import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-united-states-resident-validation-modal',
  templateUrl: './united-states-resident-validation-modal.component.html'
})
export class UnitedStatesResidentValidationModalComponent implements OnInit {
  public showSpinner: boolean = true;

  public newsletterTitle: string;

  public isMobile$ = this.modalService.isScreenMobile$.pipe(
    map(isMobile => isMobile)
  );

  constructor(protected dialogRef: MatDialogRef<UnitedStatesResidentValidationModalComponent>,
    protected modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.newsletterTitle = data.newsletterTitle;
    }

  ngOnInit() {
    this.showSpinner = false;
  }

  onNoClick() {
    this.dialogRef.close('did-not-accept');
  }

  onButtonClick() {
    this.dialogRef.close('accepted');
  }
}
