<div class="referral-search">
  <h4 class="center margin-top-bottom">Is this your colleague?</h4>

  <p class="referral-search__text text-small">Confirm your colleague’s information by selecting a profile below, or skip this step.</p>

  <form [formGroup]="formService.matchesForm" class="referral-search__modal">
    <div class="referral-search__results-container">
      <div *ngFor="let match of formService.referralMatches" class="margin-top--small">
        <input
          type="radio"
          [formGroup]="formService.matchesForm"
          formControlName="matches"
          value={{match.id}}
          id={{match.id}}>
        <label for={{match.id}} class="no-margin">
          <div class="referral-search__result">
            <p>{{match.labelName}} {{match.personType}}</p>
            <p class="bold text-small--secondary">{{match.medicalSchoolName}}</p>
          </div>
        </label>
      </div>
    </div>

    <div>
      <input
        type="radio"
        [formGroup]="formService.matchesForm"
        formControlName="matches"
        value="0"
        id="0">
      <label for="0" class="referral-search__none-of-these">
        <div class="referral-search__result">
          <p>Skip this step</p>
        </div>
      </label>
    </div>
  </form>

  <div class="margin-top-bottom--large centered">
    <button (click)="formService.submitMatchesForm()">Send Email Invitation</button>
  </div>

</div>
