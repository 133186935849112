import { UserService } from '../../users/user.service';
//import { environment } from '../../../environments/environment';
import { MembershipService, AdDetailsWithPayment } from 'app/membership-status/membership.service';
import { Observable } from 'rxjs';
import {Directive, OnInit} from '@angular/core';
import { AutoRenewLoggingService } from 'app/auto-renew-logging/auto-renew-logging.service';

@Directive()
export class NavigationComponent implements OnInit {
  adObservable: Observable<AdDetailsWithPayment>;

  constructor(
    protected userService: UserService,
    protected membershipService: MembershipService,
    protected autoRenewLoggingService: AutoRenewLoggingService,
  ) {}

  ngOnInit() {
    this.adObservable = this.membershipService.getUpsellAdWithPayment();
  }

  isInGroup(group: string): boolean {
    return this.userService.isInGroup(group);
  }

  get isPhysician(): boolean {
    return this.userService.isPhysician;
  }

  // get environment(): any {
  //   return environment;
  // }

  get orgManagerURL(): string {
    //return this.environment.orgManagerURL;
    return window["env"]["orgManagerURL"];
  }

  get eProfilesSearchURL(): string {
    return window["env"]["eProfilesSearchURL"];
  }

  get eProfilesAdminURL(): string {
    return window["env"]["eProfilesAdminURL"];
  }

  get eProfilesDNRURL(): string {
    return window["env"]["eProfilesDNRURL"];
  }

  get upgradeProfileURL(): string {
    return window["env"]["upgradeProfileURL"];
  }
}
