<section class = "userheader">
    <div class="doctor-title">
    <span *ngIf="isPhysician && !isStudent">Dr. </span> {{user.firstName}} {{user.lastName}}
    </div>
</section>
<section id="user-sub-header">
    <!-- Basic logic here is:
            If the user is a member, show "Member Since" and the year they became a member
            If the user is not a member and not a physician, display "Non-Physician"
            If the user is not a member and they are a physician (or medical student), display "Non-Member"
    -->
    <div class="left-container">
      <div *ngIf="user.isMember" class="member-since-shell">
          <span *ngIf="memberSince && memberSince.memberSinceYear" class="member-since">{{ getMemberSinceDisplay(memberSince.memberSinceYear) }}</span>
          <span class="user-sub-header-member-check">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="user-sub-header-member-check-image">
                  <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
              </svg> Member
          </span>
      </div>
      <div *ngIf="!isPhysician">
          <span>Non-Physician</span>
      </div>
      <div *ngIf="!user.isMember && isPhysician">
          <span>Non-Member</span>
      </div>
    </div>
    <div class="right-container" *ngIf = "(router.url !== '/membership') && this.shouldShowJoinRenewButton">
      <app-membership-join
        [defaultProduct] = "defaultProduct"
        [buttonText] = "buttonText"
        [isRenewFlag] = "isRenewFlag"
        [yearDisplay] = "membershipYearDisplay"
      >
      </app-membership-join>
    </div>
</section>




