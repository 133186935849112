<div id="incentives" [formGroup]="autoRenewFormService.incentiveForm" *ngIf="incentivesForDisplay !== undefined" data-id="incentive-form" >

  <div (click)="onClickIncentive()" *ngIf="canSelect()">
    <div>
      <app-radio-buttons-incentive
        [formGroup]="autoRenewFormService.incentiveForm"
        fieldName="incentiveOption"
        subfieldName="subcategoryOption"
        [incentiveOptions]="incentivesForDisplay"
        [preSelectedIncentive]="preSelectedIncentive"
        [showInput]="true">
      </app-radio-buttons-incentive>
    </div>

    <div class="no-gift">
      <input
        formControlName="noGiftOption"
        id="noGift"
        (click)="autoRenewFormService.toggleNoGiftOption()"
        type="checkbox">
        <label for="noGift">I do not wish to receive a gift.</label>
    </div>
  </div>

  <div *ngIf="!canSelect()">
    <app-radio-buttons-incentive
      [formGroup]="autoRenewFormService.incentiveForm"
      fieldName="incentiveOption"
      subfieldName="subcategoryOption"
      [incentiveOptions]="incentivesForDisplay"
      (incentiveClicked)="incentiveClickedForAutorenewModal($event)">
      
    </app-radio-buttons-incentive>
  </div>
</div>
