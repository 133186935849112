<div class="layout">
  <div class="content" *ngIf="!isFinishedLoading">
    <app-spinner></app-spinner>
  </div>


  <div *ngIf="showAlternateRatesIframe" class="alt-rate-iframe" id="outerdiv">
<!-- 
      <div class="alt-rate-iframe-close-toolbar" >
      <div>
        <a class="iframe-close-link" (click)="closeIFrame()">Close</a>
      </div>
      <div class="iframe-close-icon">
        <span class="icon icon-close-x" (click)="closeIFrame()"></span>
      </div>
      <div class="iframe-close-margin">
      </div>
    </div>

    <iframe [src]="alternateRatesUrl" height="500px" width="100%"></iframe> -->

    <a [href]="alternateRatesUrl" target="_blank">Open Join Renew</a>

    <app-contact-us-link></app-contact-us-link>
  </div>
  <div id="membership-content" class="content" *ngIf="isFinishedLoading && !showAlternateRatesIframe" data-id="membership-status-content">
    <section  *ngIf="isDeclinedCardExist" style="background-color: rgba(233, 150, 122, 0.411);padding: 10px; margin-bottom: 15px;">
      <app-declined-card-section></app-declined-card-section>
    </section>

    <section *ngIf="shouldShowMembershipTypesSection" class="membership-join">
      <app-card-header title="Membership Types">
        <app-membership-types
          [defaultProduct]="this.defaultProduct"
          [allProducts]="this.allProducts"
          [isRenewFlag]="this.isRenewFlag"
          [yearDisplay]="this.membershipYearDisplay"
          (showIframe)="showAlternateRatesIframe = $event"
          [alternateRatesUrl]="alternateRatesUrl">
        </app-membership-types>
      </app-card-header>
    </section>
    <section>
      <app-card-header *ngIf="this.isMember" title="My Membership">
        <div id="my-membership">
          <div>
            <div class="membership-expiration">
              Expires on December 31, {{endPeriodDate}}
            </div>
            <div class="autorenew-section">
              <div class="membership-autorenew-status-header">Auto-renew</div>
              <div *ngIf="!autorenewStatus" class="membership-autorenew-detail-text">
                Turn on automatic renewal to save time renewing your membership each year and never lose your benefits. Please read <a class="link-blue" (click)="openTocModal()">terms and conditions</a>.
              </div>
              <div *ngIf="autorenewStatus" class="membership-autorenew-detail-text">
                Your membership is set to renew automatically. Questions? <a class="link" href="https://www.ama-assn.org/form/contact-us" target="_blank">Contact Us</a>
              </div>
              <br>
              <div *ngIf="showAutorenewButton"
                [disabled]="!isAutoRenewEligible"
                [matTooltipDisabled]="isAutoRenewEligible"
                matTooltip="Auto-renew unavailable until your membership is fully processed."
                matTooltipClass="autorenew-tooltip"
                matTooltipPosition="right"
                class="button button-orange membership-autorenew-button"
                [ngClass]="{'button-orange': isAutoRenewEligible,'button-darkorange': !isAutoRenewEligible}"
                (click)="enableAutorenewButton()">
                {{enableAutoRenewBtnTxt}}
              </div>
            </div>
          </div>
          <div class="membership-card-container">
            <div class="membership-renew-now" *ngIf="this.shouldShowRenewForNextYearButton && isAutoRenewEligible">
              <app-membership-join
                [defaultProduct] = "this.defaultProduct"
                [buttonText] = "this.buttonText"
                [isRenewFlag] = "this.isRenewFlag"
                [yearDisplay] = "this.membershipYearDisplay">
              </app-membership-join>
            </div>
            <div class="membership-card-title" [ngClass]="this.getClassForMembershipCardTitle">
              AMA Membership Card
            </div>
            <span id= "membership-card-to-print">
            <div class="membership-card-header">
              <span class="printable-header">
                <!-- The printable-header class only displays on the printing window that opens for the membership-card
                  This svg is the banner + logo on top of the membership card.
                  The svg here is inlined because IE will not allow users to print background images via css.
                  In order for users to print the banner + logo for their membership card in IE, we cannot componentize this svg.
                -->
                <svg width="79px" height="31px" viewBox="0 0 79 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <!-- Generator: Sketch 61.1 (89650) - https://sketch.com -->
                  <title>logo</title>
                  <desc>Created with Sketch.</desc>
                  <g id="High-Fidelity-Comps---Sprint-2---Ungrouped" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Membership-Details---Updated-Membership-Card" transform="translate(-798.000000, -546.000000)" fill="#FFFFFF" fill-rule="nonzero">
                          <g id="logo" transform="translate(798.000000, 546.000000)">
                              <g id="g30" transform="translate(59.000000, 0.000000)">
                                  <path d="M11.152,17.3195652 L9.588,13.8152174 C8.5,14.5565217 8.092,15.0282609 8.092,15.0282609 L9.792,18.3304348 C9.792,18.2630435 10.472,17.723913 11.152,17.3195652 L11.152,17.3195652 Z" id="path32"></path>
                                  <path d="M6.664,12.1978261 L5.508,9.90652174 C6.052,9.3 7.276,8.35652174 7.276,8.35652174 L8.364,10.7826087 C8.296,10.7826087 7.412,11.3891304 6.664,12.1978261 L6.664,12.1978261 Z" id="path34"></path>
                                  <path d="M3.672,6.40217391 L0.34,0 L3.604,0 L5.576,4.5826087 L5.576,4.65 C5.644,4.7173913 4.624,5.39130435 3.672,6.40217391 Z" id="path36"></path>
                                  <path d="M13.396,25.1369565 L11.084,20.6891304 C11.084,20.6891304 11.56,20.2173913 12.24,19.8130435 L14.076,23.9913043 C14.008,23.9913043 13.668,24.5304348 13.396,25.1369565 L13.396,25.1369565 Z" id="path38"></path>
                                  <path d="M14.28,26.9565217 L16.32,30.8652174 L17,30.8652174 L14.892,25.9456522 C14.892,25.9456522 14.552,26.2826087 14.28,26.9565217 Z" id="path40"></path>
                              </g>
                              <path d="M76.6,23 C76.5333333,23.0882353 76.6,23 76.6,23 C76.0666667,23.5294118 75.3333333,23.9705882 74.3333333,24.3235294 L74.2,24.4117647 C74.2,24.4117647 73.8666667,24.5882353 73,26 C73.0666667,26 73.0666667,26 73.0666667,26 C75.3333333,25.8235294 77.2,25.2058824 77.9333333,24.4117647 C78,24.3235294 78,24.4117647 78,24.3235294 C77.8,23.5294118 77.4,23 76.6,23 Z" id="path42"></path>
                              <path d="M78,10.9680519 C77.0653595,12.6307331 73.6143791,13.8043904 69.5163399,14 C68.7254902,14 68.2941176,14 67.5751634,14 L67.503268,14 L67,12.3373188 C67,12.3373188 67.3594771,12.0439045 67.7189542,11.7504901 C68.2222222,11.7504901 68.5098039,11.7504901 69.0130719,11.7504901 C72.4640523,11.5548806 74.6928105,10.3812233 75.7712418,9.01195639 C75.7712418,8.91415161 77.4248366,9.4031755 78,10.9680519 L78,10.9680519 Z" id="path44"></path>
                              <path d="M63,12.03125 C62.1428571,11.75 61.2857143,11.46875 60.7142857,11.09375 L60.5714286,11 C60.5714286,11 60.0714286,12.03125 60,13.0625 L60.1428571,13.15625 C60.6428571,13.53125 61.0714286,13.71875 61.7857143,14 C62.1428571,13.34375 62.6428571,12.5 63,12.03125 Z" id="path46"></path>
                              <path d="M71,24.9902465 L70.4814815,23.9951232 C70.4074074,23.9951232 70.4074074,23.9951232 70.3333333,23.9951232 C68.6296296,23.8420274 67.1481481,23.5358356 66.2592593,23.0765479 C66.1851852,23 66.2592593,23.0765479 66.1851852,23 C65.8888889,23.7654794 66.037037,24.0716712 66.037037,24.1482191 C66.037037,24.1482191 65.962963,24.1482191 66.1111111,24.1482191 C67.1481481,24.6840547 68.9259259,25.0667944 71,24.9902465 L71,24.9902465 L71,24.9902465 Z" id="path48"></path>
                              <path d="M70,18.95 L70.4173913,20 C70.626087,20 70.8347826,20 71.0434783,20 C74.5217391,20 77.2347826,19.25 78,18.2 C77.373913,17.15 76.2608696,17 76.0521739,17 C75.1478261,17.825 73.2,18.5 70.5565217,18.5 C70.626087,18.575 70.4173913,18.65 70,18.95 L70,18.95 Z" id="path50"></path>
                              <path d="M68,18.4571429 C65.9591837,18.1142857 64.6326531,17.6857143 63.5102041,17.0857143 C63.4081633,17 63.5102041,17.0857143 63.4081633,17 C63,17.8571429 63,18.3714286 63,18.7142857 C63.1020408,18.8 63,18.7142857 63.1020408,18.8 C64.0204082,19.2285714 64.9387755,19.6571429 66.5714286,20 C67.3877551,18.9714286 67.5918367,18.8857143 68,18.4571429 L68,18.4571429 Z" id="path52"></path>
                              <path d="M63.1754734,19.6276261 C63.4618901,17.1699582 66.1112453,14.2654416 69.8346633,12.4780468 C73.7728939,10.6161772 77.4963119,10.7651268 78.641979,12.6269964 L78.7135832,12.775946 C79,11.9567233 79.0716042,11.0630259 78.9283958,10.3927529 C78.2123539,7.63718588 73.8444981,7.19033718 69.1902256,9.42458069 C64.535953,11.6588242 61.3853685,15.6804625 62.1014105,18.4360295 C62.2446189,19.1063026 62.674244,19.6276261 63.1754734,20 L63.1754734,19.6276261 L63.1754734,19.6276261 Z" id="path54"></path>
                              <path d="M76.7657188,19.3123721 L76.8271875,19.3777471 C77.0115937,18.7239968 77.0730624,18.1356215 76.8886563,17.6126212 C76.3354377,15.6513702 73.2005327,15.4552451 69.9426903,17.154996 C66.6848478,18.8547468 64.5334424,21.8619984 65.0866609,23.8886244 C65.2095984,24.3462497 65.516942,24.7384999 65.8857544,25 L65.8857544,24.8038749 C66.0701605,23.038749 67.8527536,20.7506228 70.4959088,19.3777471 C73.2005327,17.9394964 75.8436879,17.9394964 76.7657188,19.3123721 L76.7657188,19.3123721 Z" id="path56"></path>
                              <path d="M78.8230511,23.1447688 L78.8230511,23.1447688 C78.9709828,22.6823659 79.0449487,22.1428957 78.9709828,21.6034256 C78.6011536,19.7538137 76.6040757,19.4455451 74.3851002,20.9868883 C72.1661248,22.4511644 70.6868078,25.148515 71.056637,26.9981269 C71.1306029,27.4605299 71.2785346,27.7687985 71.574398,28 L71.574398,27.8458657 C71.7223297,26.2274553 72.9797491,24.2237091 74.6809636,22.9906345 C76.5301098,21.7575599 78.3052902,21.8346271 78.8230511,23.1447688 L78.8230511,23.1447688 Z" id="path58"></path>
                              <g id="g60" transform="translate(59.000000, 0.000000)">
                                  <g id="g62">
                                      <path d="M14.673913,1.73333333 C14.673913,1.73333333 14.6086957,1.6 14.5434783,1.4 C14.4782609,1.26666667 14.4130435,1.2 14.4130435,1.2 C14.2173913,0.866666667 13.3695652,0.866666667 12.5869565,0.8 C12.1956522,0.8 11.4130435,0.533333333 11.0869565,0.533333333 C10.6304348,0.533333333 10.4347826,0.533333333 10.173913,0.6 C9.91304348,0.666666667 9.13043478,1 8.86956522,1.13333333 L8.67391304,1.2 C3.06521739,3.33333333 -0.391304348,7.66666667 0.456521739,10.3333333 C0.652173913,10.9333333 1.10869565,11.4666667 1.76086957,11.8666667 L1.82608696,11.8666667 C2.15217391,9.46666667 5.2826087,6.2 9.7173913,4.46666667 L9.84782609,4.4 C10.3043478,4.26666667 10.2391304,4.4 10.6956522,4.4 C11.2826087,4.4 11.2826087,4.33333333 12.1956522,4 L14.1521739,3.26666667 C14.1521739,3.26666667 14.5434783,3.13333333 14.7391304,2.8 C14.8695652,2.53333333 14.7391304,2.13333333 14.673913,1.73333333 L14.673913,1.73333333 Z M10.826087,1.66666667 C10.3043478,1.86666667 10.0434783,1.46666667 10.0434783,1.46666667 L10.0434783,1.4 C10.4347826,1.06666667 10.9565217,1.06666667 11.0869565,1 L11.0869565,1 L11.0869565,1 C11.1521739,1.4 11.0217391,1.6 10.826087,1.66666667 Z" id="path64"></path>
                                  </g>
                              </g>
                              <path d="M8.17869416,8 L11.8900344,8 L20,25 L16.5635739,25 L14.8453608,21.0983607 L5.2233677,21.0983607 L3.43642612,25 L0,25 L8.17869416,8 Z M13.5395189,18.2418033 L10.0343643,10.647541 L6.52920962,18.2418033 L13.5395189,18.2418033 Z" id="path66"></path>
                              <polygon id="path68" points="25.0824373 12.1106557 25.0824373 12.1106557 25.0107527 25 22 25 22 8 26.5878136 8 32.0358423 20.6106557 37.5555556 8 42 8 42 25 38.9892473 25 38.9892473 12.1106557 38.9175627 12.1106557 33.3978495 25 30.6738351 25 25.0824373 12.1106557"></polygon>
                              <path d="M50.1786942,8 L53.8900344,8 L62,25 L58.5635739,25 L56.8453608,21.0983607 L47.2233677,21.0983607 L45.4364261,25 L42,25 L50.1786942,8 L50.1786942,8 Z M55.5395189,18.2418033 L52.0343643,10.647541 L48.5292096,18.2418033 L55.5395189,18.2418033 L55.5395189,18.2418033 Z" id="path70"></path>
                          </g>
                      </g>
                  </g>
              </svg>
            </span>
              <div class="membership-card__header-image"></div>
            </div>
            <div class="membership-card-body">
              <div class="membership-card-details-container">
                <div class="membership-card-details-text">
                  <div>{{fullName}}</div>
                  <div>ME# {{user.meNumber}}</div>
                  <div *ngIf="memberSince && memberSince.memberSinceYear">{{ getMemberSinceDisplay(memberSince.memberSinceYear) }}</div>
                </div>
                <div class="member-year-text">
                  <div class="member-label">Member</div>
                  <div class="current-year">{{ currentYear }}</div>
                </div>
              </div>
            </div>
          </span>
            <div class="print-link"><a class="link-blue" (click)="printCard()">Print</a></div>
          </div>
        </div>
      </app-card-header>
    </section>
    <app-card-header *ngIf="user.isMember && !autorenewStatus && isAutoRenewEligible && isEligibleForIncentives" title="Select a free gift when you turn on Auto-renew!">
      <app-incentives location="Membership Page" [paymentInformation]="paymentInformation" (nonSelectiveIncentiveClickedForAutorenewModal)="enableAutorenewButton($event)" class="incentive-section"></app-incentives>
    </app-card-header>

    <ama__template-modal__v1
      [visible]="isTocModalVisible"
      (on-close-requested)="closeTocModal()">
      <ng-template>
        <app-terms-and-conditions-modal>
        </app-terms-and-conditions-modal>
      </ng-template>
    </ama__template-modal__v1>

    <ama__template-modal__v1
      [visible]="autoRenewFormService.hasNormalizedAddress"
      (on-close-requested)="closeAddrModal()"
      class="modal__no-close-button verify-shipping-address-size">
      <ng-template>
        <app-confirm-shipping-address-modal
          [address]="autoRenewFormService.selectedShippingAddress"
          [normalizedAddress]="autoRenewFormService.normalizedShippingAddress"
          [enteredAddress]="autoRenewFormService.enteredShippingAddress"
          (confirmShippingAddress)="autoRenewFormService.confirmShippingAddress($event)"
          (cancelShippingAddress)="editShippingAddress()">
        </app-confirm-shipping-address-modal>
      </ng-template>
    </ama__template-modal__v1>
    <app-wallet #mywallet></app-wallet>
    <app-membership-transaction-history></app-membership-transaction-history>
    <app-contact-us-link></app-contact-us-link>
  </div>
</div>
