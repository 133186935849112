import { Input, Directive } from '@angular/core';
import { allSubscribables } from '../utils/subscriptions.utils';
import { EmailRecord } from './email-address';
import { SubscribableRecord, SubscriptionsData } from '../subscriptions/subscriptions';

import * as _ from 'lodash';

@Directive()
export class HasAssociatedSubscribablesComponent {
  @Input() subscriptionData: SubscriptionsData = {
    numberOfSubscriptions: 0,
    categories: [],
  };

  get allSubscribables() {
    return allSubscribables(this.subscriptionData);
  }

  hasSubscriptions(email: EmailRecord): boolean {
    return this.numberOfSubscriptions(email) > 0;
  }

  subscriptionsForEmail(email: EmailRecord): SubscribableRecord[] {
    return this.allSubscribables.filter((s) => {
      return _.includes(s.subscribedEmailAddressIds, email.id);
    });
  }

  numberOfSubscriptions(email: EmailRecord): number {
    return this.subscriptionsForEmail(email).length;
  }
}
