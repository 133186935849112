import { Component } from '@angular/core';
import { EmailAddressesService } from 'app/email-addresses/email-addresses.service';
import { UserService } from './../users/user.service';

@Component({
  selector: 'app-contact-preferences',
  templateUrl: './contact-preferences.component.html'
})
export class ContactPreferencesComponent {
  constructor(public emailAddressesService: EmailAddressesService,
    protected userService: UserService) {}

  get isAddressesListInModal(): boolean {
    return this.emailAddressesService.isChangePrimaryModal;
  }
  get isPhysician(): Boolean {
    return this.userService.isPhysician;
  }
 }
