import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StandardSurveyQuestion } from '../survey-type-definitions';

@Component({
  selector: 'app-multiple-answer',
  templateUrl: 'multiple-answer.component.html',
})
export class MultipleAnswerComponent implements OnInit {
  @Input() question: StandardSurveyQuestion;
  @Input() parentForm: FormGroup;
  questionFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.questionFormGroup = this.formBuilder.group({});
    for (const answer of this.question.options) {
      const answerControl = this.formBuilder.control(false);
      this.questionFormGroup.addControl(answer.id, answerControl);
    }
    this.parentForm.addControl(this.question.id, this.questionFormGroup);
  }

  getAnswerId(answer): string {
      return `${this.question.id}-${answer.id}`
  }

  get answersHalfPoint(): number {
      return Math.ceil(this.question.options.length / 2);
  }

  get numbersOfAnswers(): number {
      return this.question.options.length;
  }
}
