import { Injectable } from '@angular/core';
import { createRecord, allRecords } from '../utils/contact-records.utils';
import { ContactNumberRecord } from './contact-number';
import { ContactNumbersMemoryAPI } from './contact-numbers.memory-api';
import { Repo } from '../utils/repo';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { map, catchError } from 'rxjs/operators';

export interface ContactNumberAPIParams {
  type: string;
  areaCode: string;
  prefix: string;
  lineNumber: string;
  extension?: string;
  smsOptIn?: boolean;
}

interface ContactNumberAPIData extends ContactNumberAPIParams {
  id: string;
  addedAt: Date;
}

export type RequestBody = { faxNumberId: string } | { telephoneNumberId: string };

@Injectable()
export class ContactNumbersService {
  url: string;
  disableAdd: boolean;
  limit: number;
  name: string;
  contactNumbers: Repo<ContactNumberRecord>;

  constructor(
    protected httpClient: HttpClient,
    protected memoryAPI: ContactNumbersMemoryAPI,
  ) { };

  get allContactNumbers(): ContactNumberRecord[] {
    return this.memoryAPI.allContactNumbers;
  }

  getContactNumbers(): Observable<Repo<ContactNumberRecord>> {
    if (!!this.contactNumbers) {
      return of(this.checkNumberCount(this.contactNumbers));
    }

    return this.httpClient.get(`${this.url}/contact-preferences`).pipe(
      map((response: ApiResponse<any>) => {
        const data: Repo<ContactNumberRecord> = this.fromAPIResponse(response?.data);
        this.contactNumbers = data;
        this.memoryAPI.repo = data;
        return this.checkNumberCount(data);
      })
    );
  }

  addContactNumber(params: Partial<ContactNumberRecord>): Observable<Repo<ContactNumberRecord>> {
    // If a user selected mobile and then selected to opt-in to text messaging
    // and then changed to non-mobile, we should opt-out automatically
    if (params.type !== 'mobile') {
      params.smsOptIn = false;
    }

    const body = this.toAPIParams(params);

    return this.httpClient.post(this.url, body).pipe(
      map((response: ApiResponse<any>) => {
        if (!response?.data.id) {
          return this.memoryAPI.getContactNumbers();
        } else {
          const id = response?.data.id;
          this.contactNumbers = this.checkNumberCount(this.memoryAPI.addContactNumber(createRecord<ContactNumberRecord>(params, id)));
          return this.contactNumbers;
        }
      })
    );
  }

  markAsDefault(id: string): Observable<Repo<ContactNumberRecord>> {
    const body = this.getRequestBody(id);

    return this.httpClient.put(`${this.url}/primary`, body).pipe(
      map(() => {
        this.contactNumbers = this.memoryAPI.markAsDefault(id);
        return this.contactNumbers;
      })
    );
  }

  deleteContactNumber(id: string): Observable<Repo<ContactNumberRecord>> {
    const body = this.getRequestBody(id);

    return this.httpClient.request('delete', this.url, { body: body }).pipe(
      map(() => {
        this.contactNumbers = this.checkNumberCount(this.memoryAPI.deleteContactNumber(id));
        return this.contactNumbers;
      })
    );
  }

  updateContactNumber(id: string, params: Partial<ContactNumberRecord>): Observable<Repo<ContactNumberRecord>> {
    // If a user selected mobile and then selected to opt-in to text messaging
    // and then changed to non-mobile, we should opt-out automatically
    if (params.type !== 'mobile') {
      params.smsOptIn = false;
    }

    const data = this.toAPIRecord(params, id);
    const memoryData = this.toMemoryAPIParams(params);

    return this.httpClient.put(this.url, data).pipe(
      map(() => {
        this.contactNumbers = this.memoryAPI.updateContactNumber(id, memoryData);
        return this.contactNumbers;
      })
    );
  }

  getRequestBody(id: string): RequestBody {
    return { faxNumberId: id };
  }

  toAPIRecord(params: Partial<ContactNumberRecord>, id: string): any {
    return this.toAPIParams(params);
  }

  protected getAreaCode(number: string): string {
    return number.slice(0, 3);
  }

  protected getPrefix(number: string): string {
    return number.slice(3, 6);
  }

  protected getLineNumber(number: string): string {
    return number.slice(6, 10);
  }

  private toAPIParams(params: Partial<ContactNumberRecord>): ContactNumberAPIParams {
    const apiParams: ContactNumberAPIParams = {
      type: params.type,
      areaCode: this.getAreaCode(params.number),
      prefix: this.getPrefix(params.number),
      lineNumber: this.getLineNumber(params.number),
    };

    // if a user edits the record to type mobile, we want to remove the extension from the phone number.
    if (params.extension && params.type !== 'mobile') {
      apiParams.extension = params.extension;
    }

    if (params.smsOptIn) {
      apiParams.smsOptIn = params.smsOptIn;
    }

    return apiParams;
  }

  private toMemoryAPIParams(params: Partial<ContactNumberRecord>): Partial<ContactNumberRecord> {
    const memoryParams: Partial<ContactNumberRecord> = {
      type: params.type,
      number: params.number,
    };
    if (params.extension) {
      memoryParams.extension = params.extension;
    } else {
      memoryParams.extension = '';
    }

    if (params.smsOptIn) {
      memoryParams.smsOptIn = params.smsOptIn;
    } else {
      memoryParams.smsOptIn = false;
    }

    return memoryParams;
  }

  private fromAPIResponse(repo: Repo<ContactNumberAPIData>): Repo<ContactNumberRecord> {
    let def = null;
    if (repo.primary) {
      def = this.fromAPIData(repo.primary);
    }

    const others = repo.others.map((data) => {
      return this.fromAPIData(data);
    });

    return { primary: def, others };
  }

  private fromAPIData(data: ContactNumberAPIData): ContactNumberRecord {
    const numberParts = [data.areaCode, data.prefix, data.lineNumber];
    const number = numberParts.join('');
    const contactData: ContactNumberRecord = {
      number,
      type: data.type,
      addedAt: new Date(data.addedAt),
      id: data.id,
    };

    if (data.extension) {
      contactData.extension = data.extension;
    }

    if (data.smsOptIn != null) {
      contactData.smsOptIn = data.smsOptIn;
    }

    return contactData;
  }

  private checkNumberCount(numbers: Repo<ContactNumberRecord>): Repo<ContactNumberRecord> {
    this.disableAdd = allRecords(numbers).length >= this.limit;
    return numbers;
  }
}
