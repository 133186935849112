import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { PaymentInformation, MembershipService, Product, JoinRenewParams, UpdateCreditCardRequest, CreditCard } from '../membership.service';
import { UserService } from 'app/users/user.service';
import { PaymentFormService } from '../payment/payment-form.service';
import { MailingAddressesService } from 'app/mailing-addresses/mailing-addresses.service';
import { MailingAddressRecord, MailingAddressParams } from 'app/mailing-addresses/mailing-address';
import { WalletService, CCardInfoFromWallet, AddCreditCardToWalletRequest } from '../wallet/wallet.service';
import { Repo } from 'app/utils/repo';
import { defaultRepo, allRecords } from 'app/utils/contact-records.utils';
import { serverError } from 'app/utils/alerts.utils';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import * as _ from 'lodash'
import { map } from 'rxjs/operators';
import { AutoRenewFormService } from '../auto-renew-form.service';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { CreditCardsWalletListComponent } from './../wallet/ccwallet-list.component';
import { AnalyticsService } from 'app/analytics/analytics.service';
import { PaymentOptionModalComponent } from './payment-option-modal.component';
import { fieldMasks } from 'app/utils/form.utilities';
import { TermsAndConditionsModalComponent } from './terms-and-conditions-modal.component';
import { IncentiveService } from '../incentive.service';


@Component({
  selector: 'app-new-credit-card-modal',
  templateUrl: './new-credit-card-modal.component.html',
})

export class NewCreditCardModalComponent implements OnInit {
  @Input() product: Product;
  @Input() isRenewFlag: boolean;
  @Input() yearDisplay: string;
  public _creditCardsInfo: Repo<CCardInfoFromWallet> = defaultRepo;
  private entityId: number = 0;
  protected status: string = 'active';
  public showSpinner: boolean = true;
  private _isChangeLinkClicked: boolean = true;
  public showAlternateRates: boolean = false;
  public buttonsDisabled: boolean = false;

  public formTitle = 'Welcome to the AMA';
  public formBodyRenewal;
  public buttonText = 'Join';
  public secondaryButtonText = 'View Additional Rates';

  public formGroup: FormGroup;
  public ccWalletInfo?: Repo<CCardInfoFromWallet>;
  hasCardOnFile: boolean = false;

  get paymentInformation(): PaymentInformation {
    return this.membershipService.paymentInfo;
  }

  get defaultMailingAddress(): MailingAddressRecord {
    return this.mailingService.mailingAddresses.primary
  }

  public get creditCardsInfo() {
    return this._creditCardsInfo;
  }

  public get allCreditCards() {
    const creditCardsInfoSaved  = this.walletService.creditCardsInfoSaved;
    return allRecords(creditCardsInfoSaved);
  }

  constructor(
    private membershipService: MembershipService,
    private paymentFormService: PaymentFormService,
    private mailingService: MailingAddressesService,
    private alertService: AlertsService,
    private userService: UserService,
    private walletService: WalletService,
    public dialogRef: MatDialogRef<NewCreditCardModalComponent>,
    private autoRenewService: AutoRenewFormService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
    protected autorenewFormService: AutoRenewFormService,
    private incentiveService: IncentiveService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.product = data.product;
    this.isRenewFlag = data.isRenewFlag;
    this.yearDisplay = data.yearDisplay;
  }

  ngOnInit() {
    if (this.isRenewFlag) {
      this.formTitle = 'Membership Renewal';
      this.formBodyRenewal = ' back ';
      this.buttonText = 'Renew';
      this.secondaryButtonText = 'View Additional Rates';
    }
    this.retrieveList();

    this.formGroup = this.paymentFormService.paymentForm;

    if (!this.autorenewFormService.hasChangedCardOnSubmit) {
      const creditCard = this.paymentInformation.creditCard || {};
      this.formGroup.patchValue({
        nameOnCard: _.get(creditCard, 'nameOnCard', ''),
        expirationDate: _.isEmpty(creditCard) ? '' : `${this.cardExpirationMonth}-${this.cardExpirationYear}`,
      });
      this.paymentFormService.hasChangedCard = false;
    }
    this.hasCardOnFile = !!this.paymentInformation.creditCard || (!!this.ccWalletInfo && !!this.ccWalletInfo.primary);
    if (!this.hasCardOnFile) {
      this.paymentFormService.hasChangedCard = true;
    }
  }

  retrieveList(): void {
    this.walletService.getCreditCardListFromWallet(this.status).subscribe(
      (creditCardsInfo) => {
        this.creditCardsInfo = creditCardsInfo;
        this.walletService.creditCardsInfoSaved =  creditCardsInfo;
        this.entityId = this.walletService.entityId;
        // if a primary card exists, the new credit card fields don't show unless change link is clicked
        // this.isChangeLinkClicked = !creditCardsInfo.primary;
        if (this.allCreditCards.length === 1 &&
          _.isEmpty(this.allCreditCards[0].creditCard)) {
          this.creditCardsInfo = defaultRepo;
          this.walletService.creditCardsInfoSaved = defaultRepo;
        }
        this.membershipService.getAllProducts().subscribe((response) => {
          //Business requirements 4/21/2023 Do not show alternative rate for M3
          if (this.getProductCode() === '72') {
            this.showAlternateRates = false;
          } else if (response.length > 1) {
            this.showAlternateRates = true;
          } else if (response.length === 1 && response[0].autorenewEligibilityIndicator){
            this.showAlternateRates = true;
          }
          this.showSpinner = false;
        });
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
        this.showSpinner = false;
      }
    );
  }

  public set creditCardsInfo(repo: Repo<CCardInfoFromWallet>) {
    this._creditCardsInfo = repo;
  }

  public get isChangeLinkClicked() {
    return this._isChangeLinkClicked;
  }


  /**
   * Gets the price to be displayed on the page for the default product.
   */
  getPriceDisplayed(): string {
    let price: string;
    if (this.product.halfYearDiscount) {
      price = this.product.halfYearDiscount.toString();
    } else {
      price = this.product.productPrice;
    }

    if (String(price).indexOf('.') >= 0) {
      return parseFloat(price).toFixed(2).toString();
    } else {
      return parseFloat(price).toFixed(0).toString();
    }
  }
  getOriginalPrice(): string {
    let price: string = this.product.productPrice;
    if (String(price).indexOf('.') >= 0) {
      return parseFloat(price).toFixed(2).toString();
    } else {
      return parseFloat(price).toFixed(0).toString();
    }
  }
  getAmount(): number {
    if (this.product.halfYearDiscount) {
      return this.product.halfYearDiscount;
    } else {
      return parseFloat(this.product.productPrice);
    }
  }
  /**
   * If product with half year discount.
   */
  isHalfYearDiscount(): boolean {
    return !!this.product.halfYearDiscount
   }
   isProductAutorenewable(): boolean {
    return !!this.product.autorenewEligibilityIndicator
   }
   getProductCode() : string {
    return this.product.productCode;
  }
  /**
   * If product with half year discount.
   */
  getProductYear() : number {
      return this.product.membershipYear;
  }
  /**
   * Opens the autorenew terms and conditions modal and tracks it.
  */
  public openTocModal() {
    const dialogRef = this.dialog.open(TermsAndConditionsModalComponent, {
      width: this.modalService.getAppropriateWidthProperty(704),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {isConfirmModal: true}
    });
  }
  /**
   * AR-154: Adds the newly entered card to wallet if not a duplicate, otherwise only calls join/renew
   */
  public join() {

    if (this.isChangeLinkClicked && !this.paymentFormService.paymentForm.valid) {
      this.autoRenewService.showFormErrors(this.paymentFormService.paymentForm);
    } else {
      this.buttonsDisabled = true;
      console.log (`this.isChangeLinkClicked ${this.isChangeLinkClicked}  button disable ${this.buttonsDisabled} `);
      if (this.isChangeLinkClicked) {
        //Order Fulfillment method is adding card. Do not need to have additional call to add to wallet
        const cardInfo = this.checkIfDuplicateCard(this.paymentFormService.creditCardNumber.value, this.paymentFormService.creditCardExpirationDate.value);
        // returns null, not a duplicate
        if (!cardInfo) {
             const creditCard = this.buildCreditCardPassToService();
             this.callJoinRenew(creditCard, null);
        } else {
          console.log (`cardInfo else call to JOINRENEW ${JSON.stringify(cardInfo)}  `);
          this.callJoinRenew(cardInfo.creditCard, cardInfo.walletId);
        }
      } else {
        console.log (`this.isChangeLinkClicked ${this.isChangeLinkClicked}  button disable ${this.buttonsDisabled} `);
        this.callJoinRenew(this.creditCardsInfo.primary.creditCard, this.creditCardsInfo.primary.walletId);
      }
    }
  }

  private callJoinRenew(creditCard: CreditCard, walletId: number) {
    this.showSpinner = true;

    const params: JoinRenewParams = this.membershipService.buildJoinRenewParams(
      this.userService.profile,
      this.product,
      this.paymentInformation,
      this.defaultMailingAddress,
      creditCard,
      walletId,
      this.isRenewFlag,
      this.getAmount()
    );

    this.membershipService.joinRenew(params).subscribe((result) => {
      this.dialogRef.close('success');
      this.buttonsDisabled = false;
      // Reload Wallet fragment
      const walletListCompOneObj = new CreditCardsWalletListComponent(
        this.modalService, this.alertService, this.userService, this.walletService,
          this.membershipService, this.dialog );
      this.analyticsService.joinMembership(this.product);
      walletListCompOneObj.ngOnInit();

      this.showSpinner = false;
      console.log (`Before Advantage Update ${this.incentiveService.getGroupName()}`);
      this.membershipService.updateAdvantage(this.userService.profile,
        this.product,
        this.defaultMailingAddress,
        this.incentiveService.getGroupName()
      ).subscribe(() => {
        console.log ('In Advantage Update');
      });
      console.log ('After Advantage Update');
    },
    (error) => {
      this.dialogRef.close('failure');
      this.buttonsDisabled = false;
      // alert the user to the error

      this.showSpinner = false;
    });

  }

  /**
   * AR-154: checks to see if card exists in wallet already
   */
  private checkIfDuplicateCard(number: string, expiration: string): CCardInfoFromWallet {
    for (let i = 0; i < this.allCreditCards.length; i++) {
       if ((this.getLast4Digits(number) === this.allCreditCards[i].creditCard.lastFourDigits)
          && (this.switchExpirationDate(expiration) === this.allCreditCards[i].creditCard.expiration)) {
        return this.allCreditCards[i];
      }
    }
    return;
  }

  private getLast4Digits(number: string): string {
    return (number.substring(number.length - 4));
  }

  private switchExpirationDate(date: string): string {
    return date.split('-').reverse().join('-');
  }
  private buildCreditCardPassToService(): CreditCard {
    return {
      nameOnCard: this.paymentFormService.creditCardName.value,
      expiration: this.paymentFormService.switchExpirationDate( this.paymentFormService.creditCardExpirationDate.value),
      lastFourDigits: this.paymentFormService.creditCardNumber.value,
    }
  }
  private buildAddCreditCardToWalletRequest(): AddCreditCardToWalletRequest {
    let primaryInd = 'N';
    // if a primary card doesn't exist already, make this the primary card
    if (!this.creditCardsInfo.primary) {
      primaryInd = 'Y';
    }
    const req = {
      entityId: this.walletService.entityId,
      primaryInd: primaryInd,
      name: this.paymentFormService.creditCardName.value,
      number: this.paymentFormService.creditCardNumber.value,
      expiration: this.paymentFormService.switchExpirationDate( this.paymentFormService.creditCardExpirationDate.value),
      profileFirstName:  this.userService.profile.firstName,
      profileLastName:  this.userService.profile.lastName,
      profileLabelName:  this.userService.profile.labelName,
    };
     return req;
  }

  public showIframe() {
    this.analyticsService.additionalRatesClick()
    const url = window["env"]["joinRenewAlternateRates"];
    window.open(url, '_blank');
  }

  readonly dateMask = fieldMasks.expirationDate;
  readonly zipCodeMask = fieldMasks.zipCode;
  readonly creditCardNumberMask = fieldMasks.creditCardNumber;

  get cardExpirationMonth(): string {
    return this.paymentInformation.creditCard.expiration.split('-')[1];
  }

  get cardExpirationYear(): string {
    return this.paymentInformation.creditCard.expiration.split('-')[0];
  }
  close(): void {
    this.dialogRef.close();
  }
}
