import { Component } from '@angular/core';

import { FaxNumbersService } from './fax-numbers.service';
import { ContactNumbersHomeComponent } from '../contact-numbers/home.component';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';

@Component({
  selector: 'app-fax-numbers',
  templateUrl: './home.component.html'
})
export class FaxNumbersHomeComponent extends ContactNumbersHomeComponent {
  readonly title = 'Fax';
  name: ContactType = 'fax number';

  constructor(
    protected service: FaxNumbersService,
    protected navService: ContactPreferencesNavigationService,
  ) {
    super(service, navService);
  }
}
