import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from './user.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as cookieUtils from '../utils/cookie.utils';

@Injectable()
export class RetrieveAuthenticatedUserOrRedirect implements CanActivate {
  constructor(private service: UserService,
              private location: Location,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // If the route query params includes an action, store that and the Id to
    // Local Storage for action later, after the sign-on has been processed.
    // This can be extended for other actions, but only supports a single action
    // at a time.
    if (!!route.queryParams.action) {
      const expiration: Date = new Date();
      expiration.setMinutes(expiration.getMinutes() + 20);

      const actionBody = {
        action: route.queryParams.action,
        id: route.queryParams.id,
        expiration: expiration.getTime()};
      localStorage.setItem(route.queryParams.action + 'Action', JSON.stringify(actionBody));
    }
    
    return this.service.fetchProfile(false,true).pipe(
      map(() => {
        return true;
      }),
      catchError(() =>  {
        //check for email verification link (or deep link) then redirect to SSO(login)
        if((this.location.path().indexOf('action') >= 0) 
          || (this.location.path().indexOf('referrals') >= 0) 
          || (this.location.path().indexOf('membership-status') >= 0)
          || (this.location.path().indexOf('membership/walletView') >= 0)
          || (this.location.path().indexOf('newsletters') >= 0)
          || (this.location.path().indexOf('subscriptions/my-subscriptions') >= 0)
          || (this.location.path().indexOf('subscriptions/add-new-subscriptions') >= 0)){
          this.router.navigate(['login']);
        }else{
          this.checkUserAlreadyLoggedInFromOtherAmaApplication();
        }
        
        return of(false);
      }))
  }

  /**
   * This method is used to check if user is already logged in from other AMA application but callback is
   * not performed on AMC(Account) application so having unathenticated user issue. Since we need to 
   * show the landing page we could not redirect to the fsso login page for not logged in user(that will take
   * them into login form).
   * (2024 March)Discussing with IBM to have api so that we could perform this check without redirecting to fsso login
   * but for the time being we are checking PD-H-SESSION_ID cookie presence to see if the user
   * is already logged in or not. If its logged in we are redirecting to the login page to get callback method
   * into AMC app to register user session login.
   */
  checkUserAlreadyLoggedInFromOtherAmaApplication(){
    const cookieVal = cookieUtils.getCookie('PD-H-SESSION-ID');
    if (cookieVal) {
      this.router.navigate(['login']);
    }else{
      this.router.navigate(['home']);
    }
  }

}
