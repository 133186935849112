import { ContactType } from '../contact-preferences/contact-preferences-navigation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/* tslint:disable-next-line */
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// from emailregex.com

export class EmailFormComponent {
  name: ContactType = 'email';
  buttonsDisabled = false;
  emailForm: FormGroup;
  public emailSent = false;

  get email() {
    return this.emailForm.get('address');
  }
  get emailRetype() {
    return this.emailForm.get('address2');
  }

  /**
   * AR-15 AR-197 Add Change Primary Email Link and Modal
   * The change primary modal reuses the new and list email components
   * however, we will never edit an email on the change primary modal.
   *
   * This method is overridden by new.
   */
  get isChangePrimaryModal() {
    return false;
  }

  get isChangePrimaryModalClass() {
    if (this.isChangePrimaryModal) {
      return { 'change-primary-list': true};
    } if(this.emailSent){
      return { 'email-verify-modal': true};
    } else {
      return { 'add-email-modal': true};
    }
  }

  constructor(
    protected formBuilder: FormBuilder,
  ) {
    this.emailForm = this.formBuilder.group({
      address: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])],
      id: [''],
      address2: ['', Validators.compose([])],
    });
  }

  async validateForm(): Promise<void> {
    if (this.emailForm.valid) {
      this.onFormSubmit();
      // this.emailSent = true;
    } else {
      this.email.markAsTouched();
      this.emailRetype.markAsTouched();
    }
  }

  onFormSubmit() {}

  isInvalid(): boolean {
    return this.email.touched && this.email.errors && this.email.errors['pattern'] && !this.email.errors['required'];
  }

  isNotPresent1(): boolean {
    return this.email.touched && this.email.errors && this.email.errors['required'];
  }
  isNotPresent2(): boolean {
    return this.emailRetype.touched && this.emailRetype.errors && this.emailRetype.errors['required'];
  }

  /**
   * Jira AR-20
   * This method returns a boolean true if the retype email field could display the "your fields do not match" error
   * returns false if it should not.
   * note: this method DOES NOT determine whether or not there IS a fieldsmatch error. It determines whether or not we should DISPLAY the fieldsmatch error.
   */
  couldDisplay(): boolean {
    // do not display if both fields are empty
    if (this.isNotPresent1 && this.isNotPresent2 && !this.email.value && !this.emailRetype.value) {
      return false;
    }

    // do not display if you have not yet touched the retype field and it is empty, and the first email field has something.
    if (!this.emailRetype.value && this.email.value && !this.emailRetype.touched) {
      return false;
    }
    return true;
  }

  fieldsMatch(): boolean {
    if (this.email.value === '' && this.emailRetype.value === '') {
      return false;
    }
    return this.email.value === this.emailRetype.value;
  }

  public disableButton() {
    if (this.email.value === '' ) {
      return true
    }
    return false
  }

}
