import { Component, Inject } from '@angular/core';

import { FaxNumbersService } from './fax-numbers.service';
import { ContactNumbersListComponent } from '../contact-numbers/list.component';
import { FaxNumberAPIType, faxNumberTypes } from './fax-number-types';
import { ModalService } from '../ui-components/modals/modal.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { EditFaxNumberComponent } from './edit.component';
import { NewFaxNumberComponent } from './new.component';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-fax-numbers-list',
  templateUrl: './list.component.html'
})
export class FaxNumbersListComponent extends ContactNumbersListComponent {
  name: ContactType = 'fax number';

  constructor(
    protected service: FaxNumbersService,
    protected modalService: ModalService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected dialog: MatDialog,
    protected analyticsService : AnalyticsService
  ) {
    super(service, modalService, navService, alertService, dialog, analyticsService);
  }

  toDisplayName(numberType: FaxNumberAPIType): string {
    const matchingType = faxNumberTypes.find((num) => {
      return numberType === num.apiName;
    });
    return matchingType.displayName;
  }

  // Jira AR-22: Opens MatDialog form for adding a new FaxNumber
  openAddDialog(): void {
    const dialogRef = this.dialog.open(NewFaxNumberComponent, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {contactType: this.name}
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  // Jira AR-22: Opens MatDialog form for editting a current FaxNumber
  openEditDialog(number): void {
    const dialogRef = this.dialog.open(EditFaxNumberComponent, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {model: number }
    });
    this.onStartEdit(number);
    this.navService.setActiveSection(this.name, 'list');
    dialogRef.afterClosed().subscribe(result => {});
  }

  closeDialog(): void {}
}
