import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertMessages } from './alert-messages.config';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

export enum AppName {
  AMC = 'AMC',
}

export interface AppConfigData {
  dataKey: string,
  dataValue: string
}

export interface Response<T> {
  data: T,
  errors: string[]
}


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private url= '/api/configurations';
  constructor(private httpClient: HttpClient) { }

  public getPageConfigValuesByKeyAndAppName(filterStr: string, appName: string): Observable<AppConfigData[]>{
    const params = new HttpParams().set('key', filterStr).set('appName', appName);
    return this.httpClient.get<Response<AppConfigData[]>>(`${this.url}/filter`, { params: params })
      .pipe(map((response: Response<AppConfigData[]>) => {
        return response.data
      }));
  }

  public searchKey(data: AppConfigData[], key: string): string | undefined {
    const result =  data.find(obj => obj.dataKey === key);
    return result?.dataValue ?? undefined;
  }

}
