import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { allRecords, byId } from '../utils/contact-records.utils';
import { EmailRecord } from './email-address';
import { Repo } from '../utils/repo';

@Injectable()
export class EmailAddressesMemoryAPI {
  private _repo: Repo<EmailRecord> = { primary: { address: 'default@email.com', id: '0' }, others: [] };

  set repo(newRepo: Repo<EmailRecord>) {
    this._repo = newRepo;
  }

  get allEmails(): EmailRecord[] {
    return allRecords(this._repo);
  }

  getEmails(): Repo<EmailRecord> {
    return this._repo;
  }

  addEmail(params: Partial<EmailRecord>): Repo<EmailRecord> {
    const record = {
      address: params.address,
      id: params.id,
    };

    if (this.isUnique(record)) {
      this._repo.primary ? this._repo.others.push(record) : this._repo.primary = record;
    }

    return this._repo;
  }

  markAsDefault(id: string): Repo<EmailRecord> {
    const email = this.findById(id);
    this.deleteFromOther(email);

    const oldPrimary = this._repo.primary;
    this._repo.others.push(oldPrimary);

    this._repo.primary = email;

    return this._repo;
  }

  deleteEmail(id: string): Repo<EmailRecord> {
    const email = this.findById(id);
    this.deleteFromOther(email);
    return this._repo;
  }

  updateEmail(id: string, params: Partial<EmailRecord>): Repo<EmailRecord> {
    const email = this.findById(id);
    const newEmail = Object.assign(email, params);

    if (this._repo.primary.id === email.id) {
      this._repo.primary = newEmail;
    } else {
      this._repo.others = _.map(this._repo.others, (eml) => {
        return eml.id === id ? newEmail : eml;
      });
    }
    return this._repo;
  }

  private isUnique(record: EmailRecord): boolean {
    const match = _.find(this.allEmails, (email) => {
      return email.address === record.address;
    });
    return _.isUndefined(match);
  }

  private deleteFromOther(email: EmailRecord): void {
    this._repo.others = this._repo.others.filter((e) => e !== email);
  }

  private findById(id: string): EmailRecord {
    return this.allEmails.find(byId(id));
  }

  /**
   * Helper method to find an email record by it's email address instead of id
   * @param address Address to find email record by
   */
  findByAddress(address: string): EmailRecord {
      const match = _.find(this.allEmails, (email) => {
        return email.address === address;
    })
    return match;
  }
}
