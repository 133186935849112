import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export const fieldMasks = {
  expirationDate: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  zipCode: [/\d/, /\d/, /\d/, /\d/, /\d/],
  creditCardNumber: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
};

export const fieldPatterns = {
  expirationDate: /^(?:[0][1-9]|[1][0-2])-20\d{2}$/,
  creditCard: /^[0-9]{13,19}$/,
};

export const expirationDateInThePast: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const expirationDate = control.value;
  const [expMonth, expYear] = expirationDate.split('-').map((d) => parseInt(d, 10));
  const now = new Date(),
    currentMonth = now.getMonth() + 1,
    currentYear = now.getFullYear();

  const isInPast = expYear < currentYear ||
    (expYear === currentYear && expMonth < currentMonth);

  if (!expirationDate || !isInPast) {
    return null;
  } else {
    return { expirationDateInPast: true };
  }
};
