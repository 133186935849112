import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stateless-checkbox',
  templateUrl: './checkbox.component.html',
})
export class StatelessCheckboxComponent {
  @Input()
  public name: string;

  @Input()
  public isChecked: boolean;

  @Input()
  public displayName: string;

  @Output()
  public checked = new EventEmitter<void>();

  @Output()
  public unchecked = new EventEmitter<void>();

  public get value(): string {
    return (!!this.isChecked).toString();
  }

  public onClick(): void {
    if (this.isChecked) {
      this.unchecked.emit();
    } else {
      this.checked.emit();
    }
  }
}
