import { Repo } from './repo';

export function byId<T extends { id: string; }>(id: string): (record: T) => boolean {
  return function(record: T): boolean {
    return record.id === id;
  };
}

export function allRecords<T>(repo: Repo<T>): T[] {
  if (!repo) {
    return [];
  }
  if (!repo.primary) {
    return repo.others;
  }

  return (([repo.primary]).concat(repo.others)).filter((r) => r);
}

export function convertDates<T extends { addedAt?: Date }>(repo: Repo<T>): Repo<T> {
  if (repo.primary) {
    repo.primary.addedAt = new Date(repo.primary.addedAt);
  }

  repo.others.map((record) => {
    record.addedAt = new Date(record.addedAt);
    return record;
  });
  return repo;
}

export function createRecord<T extends { id?: string }>(params: Partial<T>, id: string): Partial<T> {
  params.id = id;
  return params;
}

export const defaultRepo = {
  primary: null,
  others: [],
};
