import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ReadyToPayChangeResponse } from '@google-pay/button-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  loading = true;

}
