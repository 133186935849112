export interface StateRecord {
  abbr: string;
  fullName: string;
}

export type StateOrTerritory =
  'AL' | 'AK' | 'AZ' | 'AR' | 'CA' |
  'CO' | 'CT' | 'DE' | 'FL' | 'GA' |
  'HI' | 'ID' | 'IL' | 'IN' | 'IA' |
  'KS' | 'KY' | 'LA' | 'ME' | 'MD' |
  'MA' | 'MI' | 'MN' | 'MS' | 'MO' |
  'MT' | 'NE' | 'NV' | 'NH' | 'NJ' |
  'NM' | 'NY' | 'NC' | 'ND' | 'OH' |
  'OK' | 'OR' | 'PA' | 'RI' | 'SC' |
  'SD' | 'TN' | 'TX' | 'UT' | 'VT' |
  'VA' | 'WA' | 'WV' | 'WI' | 'WY' |
  'AS' | 'DC' | 'FM' | 'GU' | 'MH' |
  'MP' | 'PW' | 'PR' | 'VI';

export const states: StateOrTerritory[] = [
  'AL', 'AK', 'AZ', 'AR', 'CA',
  'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA',
  'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO',
  'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH',
  'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT',
  'VA', 'WA', 'WV', 'WI', 'WY',
  'AS', 'DC', 'FM', 'GU', 'MH',
  'MP', 'PW', 'PR', 'VI'
];

export const stateKeyValuePairs: StateRecord[] = [
  { abbr: 'AL', fullName: 'Alabama' },
  { abbr: 'AK', fullName: 'Alaska' },
  { abbr: 'AZ', fullName: 'Arizona' },
  { abbr: 'AR', fullName: 'Arkansas' },
  { abbr: 'CA', fullName: 'California' },
  { abbr: 'CO', fullName: 'Colorado' },
  { abbr: 'CT', fullName: 'Connecticut' },
  { abbr: 'DE', fullName: 'Deleware' },
  { abbr: 'FL', fullName: 'Florida' },
  { abbr: 'GA', fullName: 'Georgia' },
  { abbr: 'HI', fullName: 'Hawaii' },
  { abbr: 'ID', fullName: 'Idaho' },
  { abbr: 'IL', fullName: 'Illinois' },
  { abbr: 'IN', fullName: 'Indiana' },
  { abbr: 'IA', fullName: 'Iowa' },
  { abbr: 'KS', fullName: 'Kansas' },
  { abbr: 'KY', fullName: 'Kentucky' },
  { abbr: 'LA', fullName: 'Louisiana' },
  { abbr: 'ME', fullName: 'Maine' },
  { abbr: 'MD', fullName: 'Maryland' },
  { abbr: 'MA', fullName: 'Massachusetts' },
  { abbr: 'MI', fullName: 'Michigan' },
  { abbr: 'MN', fullName: 'Minnesota' },
  { abbr: 'MS', fullName: 'Mississippi' },
  { abbr: 'MO', fullName: 'Missouri' },
  { abbr: 'MT', fullName: 'Montana' },
  { abbr: 'NE', fullName: 'Nebraska' },
  { abbr: 'NV', fullName: 'Nevada' },
  { abbr: 'NH', fullName: 'New Hampshire' },
  { abbr: 'NJ', fullName: 'New Jersey' },
  { abbr: 'NM', fullName: 'New Mexico' },
  { abbr: 'NY', fullName: 'New York' },
  { abbr: 'NC', fullName: 'North Carolina' },
  { abbr: 'ND', fullName: 'North Dakota' },
  { abbr: 'OH', fullName: 'Ohio' },
  { abbr: 'OK', fullName: 'Oklahoma' },
  { abbr: 'OR', fullName: 'Oregon' },
  { abbr: 'PA', fullName: 'Pennsylvania' },
  { abbr: 'RI', fullName: 'Rhode Island' },
  { abbr: 'SC', fullName: 'South Carolina' },
  { abbr: 'SD', fullName: 'South Dakata' },
  { abbr: 'TN', fullName: 'Tennesee' },
  { abbr: 'TX', fullName: 'Texas' },
  { abbr: 'UT', fullName: 'Utah' },
  { abbr: 'VT', fullName: 'Vermont' },
  { abbr: 'VA', fullName: 'Virginia' },
  { abbr: 'WA', fullName: 'Washington' },
  { abbr: 'WV', fullName: 'West Virginia' },
  { abbr: 'WI', fullName: 'Wisconsin' },
  { abbr: 'WY', fullName: 'Wyoming' },
  { abbr: 'AS', fullName: 'American Samoa' },
  { abbr: 'DC', fullName: 'District of Columbia' },
  { abbr: 'FM', fullName: 'Federated States of Micronesia' },
  { abbr: 'GU', fullName: 'Guam' },
  { abbr: 'MH', fullName: 'Marshall Islands' },
  { abbr: 'MP', fullName: 'Northern Mariana Islands' },
  { abbr: 'PW', fullName: 'Palau' },
  { abbr: 'PR', fullName: 'Puerto Rico' },
  { abbr: 'VI', fullName: 'Virgin Islands' },
];

export function getStateFullNamesList(): string[] {
  const result: string[] = [];
  stateKeyValuePairs.forEach(data => {result.push(data.fullName)});
  return result;
}

export function getStateNameByAbbr(abbr: string): string {
  let stateName: string = null;
  for (let i = 0; i < stateKeyValuePairs.length; i++) {
    if (stateKeyValuePairs[i].abbr === abbr) {
      stateName = stateKeyValuePairs[i].fullName;
      break;
    }
  }
  return stateName;
}

export function getStateAbbrByName(name: string): string {
  let stateAbbr: string = null;
  for (let i = 0; i < stateKeyValuePairs.length; i++) {
    if (stateKeyValuePairs[i].fullName === name) {
      stateAbbr = stateKeyValuePairs[i].abbr;
      break;
    }
  }
  return stateAbbr;
}
