import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModalService } from './modal.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  animations: [
    trigger('showModal', [
      state('true' , style({ transform: 'scale(1.0)' })),
      state('false', style({ transform: 'scale(0.0)' })),
      transition('* => *', animate('.3s ease-in-out'))
    ])
  ]
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: JQuery;
  isVisible = false;

  constructor(private service: ModalService, private el: ElementRef) {
     this.element = $(el.nativeElement);
  }

  ngOnInit(): void {
    this.element.appendTo('body');
    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.service.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.isVisible = true;
    this.element.show();
  }

  close(): void {
    this.isVisible = false;
    this.element.hide();
  }
}
