<span id="exitButton" (click)="closeDialog()"></span>
<form id="address-edit-modal" [formGroup]="addressForm" (ngSubmit)="validateForm()">
  <fieldset>
    <div class="form-title">Address</div>
    <div class="remove-link">
      <app-link *ngIf="canDelete()" (click)="onConfirmDelete(model)" [disabled]="buttonsDisabled">Remove</app-link>
      <div
        matTooltip="Please choose another primary before removing."
        matTooltipClass="remove-tooltip"
        class="remove-link disabled"
        *ngIf="!canDelete() && (formType === 'edit')">
        Remove
      </div>
    </div>

    <div class="mailing-addr-form">
      <div>
        <label>Address Type*</label>
        <select
          formControlName="type"
          [ngClass]="{'has-error': type.touched && !type.valid }">
          <option [value]="''" disabled></option>
          <option *ngFor="let type of addressTypes" [value]="type.apiName">
            {{type.displayName}}
          </option>
        </select>
        <label class="error-label" *ngIf="type.touched && type.errors && type.errors.required">
          This field is required.
        </label>
      </div>

      <div>
        <label>Street Address Line 1*</label>
        <input
          type="text"
          formControlName="lineOne"
          placeholder="Street Address"
          [ngClass]="{'has-error': lineOne.touched && !lineOne.valid }">
        <label class="error-label" *ngIf="isNotPresent(lineOne)">
          This field is required.
        </label>
      </div>

      <div>
        <label>Street Address Line 2</label>
        <input
          type="text"
          placeholder="Apartment, Suite, Unit, Building, Floor"
          formControlName="lineTwo">
      </div>

      <div>
        <label>Street Address Line 3</label>
        <input
          type="text"
          placeholder="Company Name, Care of, etc."
          formControlName="lineThree">
      </div>

      <div>
        <label>City*</label>
        <input
          type="text"
          formControlName="city"
          [ngClass]="{'has-error': city.touched && !city.valid }">
        <label class="error-label" *ngIf="isNotPresent(city)">
          This field is required.
        </label>
      </div>

      <div>
        <div>
          <label>State*</label>
          <select
            formControlName="stateTerritory"
            class="state-field"
            [ngClass]="{'has-error': state.touched && !state.valid }">
            <option *ngFor="let state of states" [value]="state">
              {{state}}
            </option>
          </select>
          <label class="error-label" *ngIf="isNotPresent(state)">
            This field is required.
          </label>
        </div>

        <div>
          <label>Zip Code*</label>
          <input
            type="text"
            formControlName="zipCode"
            [textMask]="{mask: zipCodeMask , guide: false}"
            class="zip-code-field"
            [ngClass]="{'has-error': zip.touched && !zip.valid }">
          <label class="error-label" *ngIf="isNotPresent(zip)">
            This field is required.
          </label>
          <label class="error-label" *ngIf="zipCodeIsInvalid()">
            Must be a valid US zip code.
          </label>
        </div>
      </div>

      <div>
        <div>*indicates a required field</div>
        <div *ngIf="!canDelete() && (formType === 'edit')">This is my primary address for receiving mail from AMA.</div>
      </div>

      <div class="card__button">
        <button>Save</button>
      </div>

      <p class="close-link" (click)="closeDialog()">Cancel</p>
      <div
        *ngIf="isEditMode"
        matTooltip="Please choose another primary address before removing."
        [matTooltipDisabled]="canDelete()"
        matTooltipClass="mobile-remove-tooltip"
        matTooltipPosition="above"
        (click)="onConfirmDelete(model)"
        class="button delete-contact-item-button"
        [ngClass]="{'disabled-contact-button': !canDelete()}">Remove</div>
    </div>
  </fieldset>
</form>
