import { Component } from '@angular/core';
import { PhoneNumbersService } from './phone-numbers.service';
import { ContactNumbersListComponent } from '../contact-numbers/list.component';
import { PhoneNumberAPIType, phoneNumberTypes } from './phone-number-types';
import { ModalService } from '../ui-components/modals/modal.service';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { EditPhoneNumberComponent } from './edit.component';
import { NewPhoneNumberComponent } from './new.component';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-phone-numbers-list',
  templateUrl: './list.component.html',
})
export class PhoneNumbersListComponent extends ContactNumbersListComponent {
  name: ContactType = 'phone number';

  constructor(
    protected service: PhoneNumbersService,
    public modalService: ModalService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected dialog: MatDialog,
    protected analyticsService : AnalyticsService,
  ) {
    super(service, modalService, navService, alertService, dialog, analyticsService);
  }

  toDisplayName(numberType: PhoneNumberAPIType): string {
    const matchingType = phoneNumberTypes.find((num) => {
      return numberType === num.apiName;
    });
    return matchingType.displayName;
  }

  // Jira AR-21: Open MatDialog form for adding a new Phone Number
  openAddDialog(): void {
    const dialogRef = this.dialog.open(NewPhoneNumberComponent, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  // Jira AR-21: Open MatDialog form for editting a current Phone Number
  openEditDialog(number): void {
    const dialogRef = this.dialog.open(EditPhoneNumberComponent, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {model: number }
    });
    this.onStartEdit(number);
    dialogRef.afterClosed().subscribe(result => {})
  }

  closeDialog(): void {
  }

  calculateListStyle(number) {
    if (number.smsOptIn) {
      return { 'detailed-item-with-sms': true}
    } else {
      return { 'detailed-item-without-sms': true}
    }
  }
}
