import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appInputAutofocus]',
})
export class InputAutofocusDirective implements OnInit {
  @Input() public appInputAutofocus: string;
  @Input() public delayAutofocus: number;

  constructor (private elementRef: ElementRef) {
  }

  public ngOnInit() {
    if (this.appInputAutofocus === 'prioritized' && this.elementRef.nativeElement.value === '') {
      this.elementRef.nativeElement.focus();
      if (this.delayAutofocus) {
        setTimeout(() => {
          this.elementRef.nativeElement.focus();
        }, this.delayAutofocus);
      }
    }
  }
}
