import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { map } from 'rxjs/operators';

export interface ReferralSearchParams {
  firstName: string;
  lastName: string;
  stateCode: string;
  email?: string;
}

export interface ReferralSearchResult {
  id?: string;
  firstName?: string;
  lastName?: string;
  labelName?: string;
  stateCode?: string;
  email?: string;
  medicalSchoolName?: string;
  personType?: string;
  meNumber?: string;
  dob?: string;
  entityId?: number;
  partyId?: number;
}

export interface ReferralInvitationParams {
  senderSsoId: string;
  senderFirstName: string;
  senderLastName: string;
  senderDateOfBirth?: string;
  senderMeNumber: string,
  senderSsoGroups?: string;
  receiverFirstName: string;
  receiverLastName: string;
  receiverMeNumber?: string;
  receiverDateOfBirth?: string;
  receiverEntityId?: number;
  receiverStateCode: string;
  receiverEmail: string;
  receiverPartyId?: number,
  matched?: boolean;
  medicalSchoolName?: string;
  personType?: string;
}

@Injectable()
export class ReferralService {
  constructor(
    protected httpClient: HttpClient
  ) {}

  public getSearchResults(searchParams: ReferralSearchParams): Observable<ReferralSearchResult[]> {
    let params = new HttpParams();
    params = params.append('firstName', searchParams.firstName);
    params = params.append('lastName', searchParams.lastName);
    params = params.append('stateCode', searchParams.stateCode);
    params = params.append('emailAddress', searchParams.email);

    return this.httpClient.get('/api/person-search', {params: params}).pipe(
      map((response: ApiResponse<ReferralSearchResult[]>) => {
        return response?.data as ReferralSearchResult[];
      }));
  }

  public sendInvitation(params: ReferralInvitationParams): Observable<string> {
    return this.httpClient.post('/api/referrals', params).pipe(
      map((response: ApiResponse<string>) => {
        return response?.data;
      }));
  }
}
