<!--todo: remove this file after fixing the "all your newsletters will be subscribed to" functionality
leaving this here for reference. Will remove in AR-15:Task AR-197 Add change primary email link & modal -->
<app-modal [id]="modalElementId">
  <div *ngIf="shouldShowDeleteAndUnsubscribeVersion">
    <h2 class="modal__header">
      Deleting {{ emailAddress }} will also unsubscribe you from:&nbsp;
    </h2> <!--This can be useful -->

    <ul class="modal__list">
      <li *ngFor="let title of subscriptionTitles">
        {{ title }}
      </li>
    </ul>

    <div class="centered">
      <app-cancel-button (click)="cancelButtonClicked()">
        Cancel
      </app-cancel-button>

      <app-submit-button (click)="confirmationButtonClicked()">
        Yes, Delete and unsubscribe me
      </app-submit-button>
    </div>
  </div>

  <div *ngIf="shouldShowDeleteOnlyVersion">
    <h2 class="modal__header">
      Are you sure you would like to delete {{ emailAddress }}?
    </h2>

    <div class="centered">
      <app-cancel-button (click)="cancelButtonClicked()">
        Cancel
      </app-cancel-button>

      <app-submit-button (click)="confirmationButtonClicked()">
        Yes, Delete it
      </app-submit-button>
    </div>
  </div>
</app-modal>
