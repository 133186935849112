import { Component } from '@angular/core';
import { AlertsService } from './alerts.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
})
export class AlertsComponent {

  get error(): string {
    return this.service.error;
  }

  get success(): string {
    return this.service.success;
  }

  constructor(private service: AlertsService) { }

}
