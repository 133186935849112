<app-card-list [isVisible]="isVisible()" [showSpinner]="showSpinner">
  <div class="message" *ngIf="noAddresses()">
    Update with your primary mailing address
  </div>

  <div *ngFor="let address of allAddresses">

    <app-transition isVisible="true">
      <div class="address-top-info">
        <div>
          <mat-radio-button class="primary-radio-button" [checked]="isDefault(address)" (click)="onMarkDefault($event, address)" name="addr_primary_rb"></mat-radio-button>
          <div class="address-user-name">{{ getUserName() }}</div>
          <div class="address-type">({{ toDisplayName(address.type) }})</div>
        </div>
        <app-link
          (click)="onStartEdit(address)"
          [disabled]="buttonsDisabled"
          class="list-component-edit-link"
          >
          Edit
        </app-link>
      </div>

      <div class="address">
        <span>{{ address.lineOne }}, </span>
        <span *ngIf="address.lineTwo">{{ address.lineTwo }}, </span>
        <span *ngIf="address.lineThree">{{ address.lineThree }}, </span>
        <span>{{ address.city }}, {{ address.stateTerritory }} {{ address.zipCode }}</span>
      </div>
    </app-transition>
  </div>
</app-card-list>
