<input type="checkbox"
       [name]="name"
       [id]="name"
       [value]="value"
       [checked]="isChecked"
       (click)="onClick()">
<label [for]="value"
       class="card__footer"
       (click)="onClick()">
  {{ displayName }}
</label>
