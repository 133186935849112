import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { fieldPatterns, expirationDateInThePast } from 'app/utils/form.utilities';
import { Injectable } from '@angular/core';
import { MembershipService, UpdateCreditCardRequest } from '../membership.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MailingAddressParams } from 'app/mailing-addresses/mailing-address';
import { AnalyticsService } from 'app/analytics/analytics.service';
import { UserService } from './../../users/user.service';

@Injectable()
export class PaymentFormService {
  paymentForm: FormGroup;
  hasChangedCard: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private membershipService: MembershipService,
    private analytics: AnalyticsService,
    private userService: UserService,
  ) {}

  createForm() {
    this.paymentForm = this.formBuilder.group({
      nameOnCard: ['', Validators.required],
      cardNumber: ['', Validators.required],
      expirationDate: ['', Validators.compose([
        Validators.required,
        Validators.pattern(fieldPatterns.expirationDate),
        expirationDateInThePast])
      ],
    });
    this.paymentForm.valueChanges.subscribe(() => {
      if (this.nameOnCardChanged() || this.cardNumberChanged() || this.expirationDateChanged()) {
        this.enforceCreditCardValidators();
      } else if (this.creditCardName.value === '' && this.creditCardNumber.value === '' && this.creditCardExpirationDate.value === '' ) {
        this.enforceCreditCardValidators();
      } else {
        this.clearCreditCardValidators();
      }
    });
  }

  submitWithBillingAddress(billingAddress: MailingAddressParams): Observable<any> {
    const hasCardOnFile = !!this.membershipService.paymentInfo.creditCard;
    return this.membershipService.updateCreditCardDetails(this.buildUpdateCreditCardRequest(billingAddress))
      .pipe(map((response) => {
        this.membershipService.paymentInfo.creditCard = {
          nameOnCard: response.name,
          lastFourDigits: response.number,
          type: response.type,
          expiration: response.expiration
        };
        if (hasCardOnFile) {
          this.analytics.creditCardChange('edit');
        } else {
          this.analytics.creditCardChange('add');
        }
      }
    ));
  }

  creditCardDetailsChanged(): boolean {
    return this.nameOnCardChanged() ||
      this.cardNumberChanged() ||
      this.expirationDateChanged();
  }

  private buildUpdateCreditCardRequest(billingAddress: MailingAddressParams): UpdateCreditCardRequest {
    return {
      name: this.creditCardName.value,
      number: this.creditCardNumber.value,
      expiration: this.switchExpirationDate(this.creditCardExpirationDate.value),
      billingAddress: billingAddress,
      profileFirstName:  this.userService.profile.firstName,
      profileLastName:  this.userService.profile.lastName,
      profileLabelName:  this.userService.profile.labelName,
    };
  }

  private nameOnCardChanged(): boolean {
    if (this.membershipService.paymentInfo && this.membershipService.paymentInfo.creditCard) {
      return this.creditCardName.value !== this.membershipService.paymentInfo.creditCard.nameOnCard;
    } else {
      return this.creditCardName.value !== '';
    }
  }

  private cardNumberChanged(): boolean {
    return this.creditCardNumber.value !== '';
  }

  private expirationDateChanged(): boolean {
    if (this.membershipService.paymentInfo && this.membershipService.paymentInfo.creditCard) {
      return this.switchExpirationDate(this.creditCardExpirationDate.value) !== this.membershipService.paymentInfo.creditCard.expiration;
    } else {
      return this.creditCardExpirationDate.value !== '';
    }
  }

  switchExpirationDate(date: string): string {
    return date.split('-').reverse().join('-');
  }

  private enforceCreditCardValidators() {
    this.creditCardNumber.setValidators(Validators.compose([
      Validators.required,
      Validators.pattern(fieldPatterns.creditCard)
    ]));
    this.creditCardNumber.updateValueAndValidity({emitEvent: false});
  }

  private clearCreditCardValidators() {
    this.creditCardNumber.clearValidators();
    this.creditCardNumber.updateValueAndValidity({emitEvent: false});
  }

  get creditCardName(): AbstractControl {
    return this.paymentForm.get('nameOnCard');
  }

  get creditCardNumber(): AbstractControl {
    return this.paymentForm.get('cardNumber');
  }

  get creditCardExpirationDate(): AbstractControl {
    return this.paymentForm.get('expirationDate');
  }
}
