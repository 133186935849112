import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { map } from 'rxjs/operators';

export interface ProfessionalDetailsAPIParams {
  id?: number,
  practiceSetting?: string;
  stateTerritory?: string;
  city?: string;
  hospitalId?: number;
  hospitalName?: string;
  practiceSettingName?: string;
  hospitalIdName?: string;
}

export interface ProfessionalDetailsFindRecord {
  list: ProfessionalDetailsAPIParams[];
}

export interface PracticeSettingsFindRecord {
  practiceSettings: PracticeSetting[];
}

export interface PracticeSetting {
  practiceSetting: string;
  uniqueId: string;
}

export interface HospitalsFindRecord {
  hospitals: Hospital[];
}

export interface Hospital {
  id: number;
  name: string;
  address: string;
  zipcode: string;
  state_territory: string;
}

@Injectable()
export class ProfessionalDetailsService {
  url= '/api/professional-details';
  disableAdd = false; // to set a limit of max 20 professional details per user.
  private practiceSettings;

  protected currentCity: string;
  protected currentState: string;
  private professionalDetailsList: ProfessionalDetailsAPIParams[];
  private citiesList: Map<string, string[]>;
  private hospitalList: Map<string, Hospital[]>;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  /**
   * A static method to remove duplicates in a string array.
   */
  static _removeDuplicates(arr: string[]): string[] {
    const unique = {};
    arr.forEach(function(i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  /**
   * A static method to check if an element exists in a list.
   * @param element
   * @param list
   */
  static _inList(element: string, list: string[]): boolean {
      if (list.some(x => x === element)) {
        return true;
        }
    return false;
   }

  constructor(public httpClient: HttpClient) {
    this.getPracticeSettings().subscribe();
    this.citiesList = new Map<string, string[]>();
    this.hospitalList = new Map<string, Hospital[]>();
   }

   /**
    * Checks if an inputted city exists in the cached city list for that particular state
    * If the state has not yet been cached, this method returns false.
    */
   inCachedCitiesList(city: string, state: string): boolean {
    if (this.citiesList.has(state)) {
      const cityList: string[] = this.citiesList.get(state);

      if (cityList.some(x => x === city)) {
        return true;
        }
    }
    return false;
   }

  findCities(state: string): Observable<string[]> {
    if (this.citiesList.has(state)) {
      return of(this.citiesList.get(state));
    }
    const params = new HttpParams().set('state', state);

    return this.httpClient.get(`${this.url}/cities`, {params: params }).pipe(
      map((response: ApiResponse<any>) => {
        this.citiesList.set(state, ProfessionalDetailsService._removeDuplicates(response?.data));
        return this.citiesList.get(state);
      }));
  }

  findHospitals(state: string, city: string): Observable<Hospital[]> {
    const key: string = this.generateHospitalKey(state, city);
    if (this.hospitalList.has(key) && !!this.hospitalList.get(key)) {
      return of(this.hospitalList.get(key));
    }

    const params = new HttpParams().set('city', city).set('state', state);

    return this.httpClient.get(`${this.url}/hospitals`, { params: params }).pipe(
      map((response: ApiResponse<any>) => {
          this.hospitalList.set(key, response?.data);
          return this.hospitalList.get(key);
      }));
  }

  getPracticeSettings(): Observable<PracticeSetting[]> {
    return !!this.practiceSettings ?
      of(this.practiceSettings) :
      this.httpClient.get(`${this.url}/practice-settings`).pipe(
      map((response: ApiResponse<PracticeSettingsFindRecord>) => {
        this.practiceSettings = response?.data;
        return this.practiceSettings;
      })
      );
  }

  getProfessionalDetails(): Observable<ProfessionalDetailsAPIParams[]> {
    if (!!this.professionalDetailsList) {
      return of(this.professionalDetailsList);
    }

    return this.httpClient.get(`${this.url}`).pipe(
      map((response: ApiResponse<ProfessionalDetailsAPIParams[]>) => {
        this.professionalDetailsList = response?.data || [];
        for (let i = 0; i < this.professionalDetailsList.length; i++) {
          // Add selection when Hospital Based and no pre-filled hospital selected.
          if (this.professionalDetailsList[i].practiceSettingName === 'Hospital Based'
            && this.professionalDetailsList[i].hospitalIdName === '') {
            this.professionalDetailsList[i].hospitalIdName = 'Hospital/Institution Not Listed';
          }
        }

        return this.professionalDetailsList;
      }));
  }

  addProfessionalDetail(params: Partial<ProfessionalDetailsAPIParams>): Observable<void> {
    const body = this.toAPIParams(params);
    return this.httpClient.post(`${this.url}`, body).pipe(
      map((response: ApiResponse<any>) => {
        params.id = Number.parseInt(response?.data.id);
        if (params.hospitalName === '') {
          params.hospitalName = null;
        }
        this.professionalDetailsList.push(params);
        return response?.data;
      })
    );
  }

  updateProfessionalDetails(params: Partial<ProfessionalDetailsAPIParams>): Observable<void> {
    const body = this.toAPIParams(params);
    return this.httpClient.put(`${this.url}/${body.id}`, body).pipe(
      map((response: ApiResponse<any>) => {
        for (let i = 0; i < this.professionalDetails.length; i++) {
          if (this.professionalDetails[i].id === params.id) {
            this.professionalDetails[i].city = params.city;
            this.professionalDetails[i].stateTerritory = params.stateTerritory;
            this.professionalDetails[i].hospitalId = params.hospitalId;
            this.professionalDetails[i].hospitalIdName = params.hospitalIdName;
            this.professionalDetails[i].practiceSetting = params.practiceSetting;
            this.professionalDetails[i].practiceSettingName = params.practiceSettingName;
            this.professionalDetails[i].hospitalName = params.hospitalName === '' ? null : params.hospitalName;
            break;
          }
        }
      })
    );
  }

  deleteProfessionalDetails(id: number): Observable<any> {
    return this.httpClient.request('delete', this.url, {body: { id: id }}).pipe(
      map((response) => {
        for (let i = 0; i < this.professionalDetails.length; i++) {
          if (this.professionalDetails[i].id === id) {
            this.professionalDetails.splice(i, 1);
            break;
          }
        }
      }));
  }

  private toAPIParams(params): ProfessionalDetailsAPIParams {
    let nullablePlaceOfEmployment: string = '';
    if (!!params.hospitalName) {
      nullablePlaceOfEmployment = params.hospitalName;
    }
    return {
      id: params.id,
      practiceSetting: params.practiceSettingName,
      stateTerritory: params.stateTerritory,
      city: params.city,
      hospitalName: nullablePlaceOfEmployment,
      hospitalId: this.getHospitalId(params.stateTerritory, params.city, params.hospitalIdName),
    };
  }

  getPracticeSettingArr(): string[] {
    if (!!this.practiceSettings) {
      return  this.practiceSettings.map(setting => setting.practiceSetting);
    }
    return [];
  }

  /**
   * This gets a list of hospital names for given city and state from the API. It also caches the city used,
   * the state used, and the list of hospitals returned as well as the list of hospital names returned
   * so the calls to the API are minimized.
   * @param city
   * @param state
   */
  getHospitalNames(state: string , city: string ): Observable<string[]> {
    return this.findHospitals(state, city).pipe(map((hospitals) => {
      const result: string[] = [];
      hospitals.forEach(h => {result.push(h.name)});
      return result;
    }))
  }

  /**
   * When we add a new professional detail, we need to add the hospital id
   * if the practice setting chosen is hospital-based.
   * since we cache the list of hospitals, we can find the hospital from the cached list and get its id.
   */
  getHospitalId(state: string, city: string, hospitalName: string): number {
    let id: number = null;
    const hospitals = this.hospitalList.get(this.generateHospitalKey(state, city));
    if (!!hospitals) {
      for (let i = 0; i < hospitals.length; i++) {
        if (hospitals[i].name === hospitalName) {
          id = hospitals[i].id;
          break;
        }
      }
    }
    return id;
  }

  getHospitalNameById(id: number, city: string, state: string): string {
    return this.getHospitalById(id, city, state).name;
  }

  getHospitalById(id: number, city: string, state: string): Hospital {
    let hospital: Hospital = null;
    this.findHospitals(state, city).subscribe((hospitals) => {
      for (let i = 0; i < hospitals.length; i++) {
        if (hospitals[i].id === id) {
          hospital = hospitals[i];
          break;
        }
      }
    })
    return hospital;
  }

  refreshProfessionalDetailsList(): void {
    this.getProfessionalDetails().subscribe(sub => {
      this.professionalDetailsList = sub;
    });
  }

  get professionalDetails(): ProfessionalDetailsAPIParams[] {
    return this.professionalDetailsList;
  }

  private generateHospitalKey(state: string, city: string): string {
    return state + '-' + city;
  }
}
