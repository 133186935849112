import { AlertComponent } from './alert.component';
import { AlertsService } from './alerts.service';
import { AlertsConfig } from '../../../config/alerts.config';
import { Component } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-success',
  templateUrl: './alert.component.html',
  animations: [
    trigger('visibilityChanged', [
      state('true' , style({ opacity: 1, height: '*', padding: '*' })),
      state('false', style({ opacity: 0, height: 0, padding: 0 })),
      transition('* => *', animate('.4s ease-in-out'))
    ])
  ]
})
export class SuccessComponent extends AlertComponent {
  className = 'alert-success';
  timeout: number;
  initialMessage = false;

  constructor(
    protected service: AlertsService,
    config: AlertsConfig
  ) {
    super(service);
    this.timeout = config.successTimeout;
  }

  get message() {
    return this.service.success;
  }

  show(): void {
    this.isVisible = true;
    this.initialMessage = true;
    setTimeout(() => this.hide(), this.timeout);
  }
}
