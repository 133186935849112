import { Component, EventEmitter, Output, Input } from '@angular/core';
import { MailingAddressParams } from 'app/mailing-addresses/mailing-address';
import { MembershipService } from '../membership.service';

@Component({
  selector: 'app-confirm-shipping-address-modal',
  templateUrl: './confirm-shipping-address-modal.component.html',
})
export class ConfirmShippingAddressModal {
  @Output() cancelShippingAddress = new EventEmitter<void>();
  @Output() confirmShippingAddress = new EventEmitter<MailingAddressParams>();
  @Input() address: MailingAddressParams;
  @Input() normalizedAddress: MailingAddressParams;
  @Input() enteredAddress: MailingAddressParams;
  submittingAddress: boolean = false;

  model = {
    selectedAddress: 'normalized',
  };

  constructor (
    private membershipService: MembershipService,
  ) {}

  acceptShippingAddress() {
    this.submittingAddress = true;
    this.membershipService.autorenewToggleState = true;
    this.confirmShippingAddress.emit(this.model.selectedAddress === 'normalized' ? this.normalizedAddress : this.enteredAddress);
  }

  editShippingAddress() {
    this.submittingAddress = false;
    this.cancelShippingAddress.emit();
  }
}
