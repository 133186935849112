import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class UserIsMENumberUserService implements CanActivate {
  constructor(
    private service: UserService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    if (this.service.isPhysician) {
      return of(true);
    } else {
      this.router.navigate( [''] );
    }
  }
}
