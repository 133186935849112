import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonsModule } from '../ui-components/buttons/buttons.module';
import { NewMailingAddressComponent } from './new.component';
import { EditMailingAddressComponent } from './edit.component';
import { MailingAddressesHomeComponent } from './home.component';
import { MailingAddressesListComponent } from './list.component';
import { MailingAddressesService } from './mailing-addresses.service';
import { MailingAddressesMemoryAPI } from './mailing-addresses.memory-api';
import { ModalsModule } from '../ui-components/modals/modals.module';
import { ModalService } from '../ui-components/modals/modal.service';
import { TransitionsModule } from '../ui-components/transitions/transitions.module';
import { ContactPreferencesNavigationService } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { MaxRecordsConfig } from '../../config/max-database-records.config';
import { SpinnersModule } from '../ui-components/spinners/spinners.module';
import { TextMaskModule } from 'angular2-text-mask';
import { EditUnsavedMailingAddressComponent } from './edit-unsaved.component';
import { VerifyExistingMailingAddressComponent } from './verification/verify-existing.component';
import { VerifyUnsavedMailingAddressComponent } from './verification/verify-unsaved.component';
import { CardsModule } from '../ui-components/cards/cards.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule,
    ModalsModule,
    TransitionsModule,
    SpinnersModule,
    TextMaskModule,
    CardsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatTooltipModule,
  ],
  declarations: [
    MailingAddressesHomeComponent,
    NewMailingAddressComponent,
    EditMailingAddressComponent,
    MailingAddressesListComponent,
    EditUnsavedMailingAddressComponent,
    VerifyExistingMailingAddressComponent,
    VerifyUnsavedMailingAddressComponent,
  ],
  exports: [
    MailingAddressesHomeComponent
  ],
  providers: [
    MailingAddressesService,
    MailingAddressesMemoryAPI,
    ModalService,
    ContactPreferencesNavigationService,
    AlertsService,
    MaxRecordsConfig,
  ]
})

export class MailingAddressesModule { }
