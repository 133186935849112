import {
  Component,
  Input,
} from '@angular/core';

import { ButtonComponent } from './button.component';

@Component({
  selector: 'app-form-submit-button',
  templateUrl: './form-submit-button.component.html',
})
export class FormSubmitButtonComponent extends ButtonComponent {
  @Input() value: string;
  @Input() disabled = false;
  @Input() buttonSize: string;
}
