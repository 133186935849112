import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmaCloseIconV1Module } from '../ama__close-icon__v1/ama__close-icon__v1.module';
import { AmaFadeAnimationV1Module } from '../ama__fade-animation__v1/ama__fade-animation__v1.module';
import { AmaTemplateModalV1Component } from './ama__template-modal__v1.component';

@NgModule({
  declarations: [
    AmaTemplateModalV1Component,
  ],
  exports: [
    AmaTemplateModalV1Component,
  ],
  imports: [
    CommonModule,
    AmaCloseIconV1Module,
    AmaFadeAnimationV1Module,
  ],
})
export class AmaTemplateModalV1Module {}
