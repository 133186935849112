<span id="exitButton" (click)="closeDialog()"></span>

<form id="address-edit-modal" [formGroup]="walletCardForm" (ngSubmit)="validateForm()">
    <div class="form-title">{{ title }}</div>

    <div class="remove-link">
      <app-link *ngIf="model && model.walletId && (!isDefault() || (!isAutoRenew() && isLastCC()))" (click)="openDeleteConfirmationDialog()" [disabled]="buttonsDisabled">
       Remove
      </app-link>
      <div
        matTooltip="Please choose another primary before removing."
        matTooltipClass="remove-tooltip"
        class="remove-link disabled"
        *ngIf="((isDefault() && isAutoRenew()) || (isDefault() && !isAutoRenew() && !isLastCC()))  && (formType === 'edit')">
        Remove
      </div>
    </div>
    <div class="mailing-addr-form">
          <div class="bold">Accepted credit cards:</div>
          <div class="visa-icon" style="margin-top: 0px;"></div>
          <div class="mc-icon" style="margin-top: 0px;"></div>
          <div class="amex-icon" style="margin-top: 0px;"></div>

          <ama__validated-text-input__v1
              [formGroup]="paymentFormService.paymentForm"
              [itemLabel]="{name: 'Name on Card*'}"
              itemMaxLength="50"
              fieldName="nameOnCard"
              itemPlaceholder="Name on Card">
          </ama__validated-text-input__v1>

          <ama__validated-text-input__v1
            [formGroup]="paymentFormService.paymentForm"
            [itemLabel]="{name: 'Card Number*'}"
            fieldName="cardNumber"
            itemMaxLength="16"
            [fieldMask]="creditCardNumberMask"
            itemPlaceholder="**** **** **** {{lastFourDigits}}"
            patternValidationMessage="Must be a valid credit card number">
          </ama__validated-text-input__v1>

          <ama__validated-text-input__v1
            [formGroup]="paymentFormService.paymentForm"
            [itemLabel]="{name: 'Exp Date*'}"
            [fieldMask]="dateMask"
            fieldName="expirationDate"
            itemPlaceholder="MM-YYYY"
            patternValidationMessage="Must be a valid expiration date in MM-YYYY format">
          </ama__validated-text-input__v1>

          <div style="font-size: 0.9em;" *ngIf="formType === 'add'" >
            <mat-checkbox class="phone_checkbox" formControlName="primaryInd"
            (change)="displayWarningForRemovingDigital($event)" [(ngModel)]="checkedPrimary">
              This is my primary form of payment
            </mat-checkbox>
          </div>

          <div>*indicates a required field</div>

          <div class="primary-card-header" *ngIf="model && model.walletId && isDefault()">
            This is my primary card to activate autorenewal.
          </div>


          <div class="card__button">
            <button [disabled]="buttonsDisabled">Save</button>
          </div>

          <p  class="close-link" (click)="onNoClick()">Cancel</p>

          <div
              *ngIf="model && model.walletId"
              matTooltip="Please choose another primary before removing."
              [matTooltipDisabled]="isDefault() && (formType === 'edit')"
              matTooltipClass="mobile-remove-tooltip"
              matTooltipPosition="above"
              (click)="openDeleteConfirmationDialog()"
              class="button delete-contact-item-button"
              [ngClass]="{'disabled-contact-button': isDefault()}">
           Remove
          </div>

     </div>

</form>
