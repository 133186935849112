<div class="payment-title">Payment Information</div>
<div *ngIf="hasCardOnFile && !autorenewFormService.hasChangedCardOnSubmit" class="show-card-info">
  <div *ngIf="!notJoinRenewCC" class="primary-card-info">
    <span>{{ paymentInformation.creditCard.type }} </span>
    <span>x{{ paymentInformation.creditCard.lastFourDigits }} </span>
    <span>EXP:{{ cardExpirationMonth }}/{{ cardExpirationYear }}</span>
  </div>

  <div *ngIf="notJoinRenewCC" class="primary-card-info-modal">
    <span class="card-type">{{ ccWalletInfo.primary.creditCard.type }} ending in {{ ccWalletInfo.primary.creditCard.lastFourDigits }}</span>
    <span class="card-expiration">Expires {{ joinRenewCardExpirationMonth }}/{{ joinRenewCardExpirationYear }}</span>
  </div>

  <app-link class="change-method-link" (click)="newCreditCard()">
    Change
  </app-link>
</div>
<div *ngIf="!hasCardOnFile || autorenewFormService.hasChangedCardOnSubmit" class="empty-card-info">
  <div>
    <ama__validated-text-input__v1
      [formGroup]="formGroup"
      [itemLabel]="{name: 'Name on Card*'}"
      itemMaxLength="50"
      fieldName="nameOnCard"
      itemPlaceholder="">
    </ama__validated-text-input__v1>
    <div class="cc-input-container">
      <ama__validated-text-input__v1 class="credit-card-field"
        [formGroup]="formGroup"
        [itemLabel]="{name: 'Card Number*'}"
        fieldName="cardNumber"
        itemMaxLength="16"
        [fieldMask]="creditCardNumberMask"
        itemPlaceholder=""
        patternValidationMessage="Must be a valid credit card number">
      </ama__validated-text-input__v1>
      <div class="cc-icons-modal">
        <span class="visa-icon"></span>
        <span class="mc-icon"></span>
        <span class="amex-icon"></span>
      </div>
    </div>
  </div>
  <div>
    <ama__validated-text-input__v1
      [formGroup]="formGroup"
      [itemLabel]="{name: 'Expiration Date*'}"
      [fieldMask]="dateMask"
      fieldName="expirationDate"
      itemPlaceholder="MM-YYYY"
      patternValidationMessage="Must be a valid expiration date in MM-YYYY format">
    </ama__validated-text-input__v1>
    <div class="cc-icons">
      <span class="visa-icon"></span>
      <span class="mc-icon"></span>
      <span class="amex-icon"></span>
    </div>
  </div>
</div>
<div *ngIf="!hasCardOnFile" class="required-desc">*indicates a required field</div>
