import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckboxComponent } from './checkbox.component';
import { StatelessCheckboxComponent } from './stateless-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CheckboxComponent,
    StatelessCheckboxComponent,
  ],
  exports: [
    CheckboxComponent,
    StatelessCheckboxComponent,
  ],
})
export class SelectorsModule { }
