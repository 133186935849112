<span id="exitButton" (click)="closeAndNotConfirmDialog()"></span>
<div id="walletcard-edit-modal" style="padding: 0 25px;">
  <div class="form-title"></div>

  <div class="bold wallet-card-remove-sub-header" >
    By updating your primary payment method to this credit card, <br>
    your digital payment method will be removed from your wallet <br>
    and will not be able to be added back. To confirm these  <br>
    changes, click save.
  </div>
  <div>
    <a  *ngIf="data.displaySave" class="wallet-card-remove-link" (click)="closeAndConfirmDialog()">
      Save</a>
  </div>
</div>

