import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from './user.service';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable()
export class RetrieveAuthenticatedUser implements CanActivate {
  constructor(private service: UserService,
              private location: Location,
              private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.service.fetchProfile(false,true).pipe(
      map(() => {
        if(this.location.path() === '/home'){
          this.router.navigate(['entry-point']);
        }
        return true;
      }),
      catchError(() => {
       return of(true);
      }),
      finalize(() => {
       return true;
      }))
    }
}
