import { Component, EventEmitter, OnInit, Output, } from '@angular/core';

import { allRecords, defaultRepo } from '../../utils/contact-records.utils';
import { IListComponent } from '../../IListComponent';
import { ModalService } from '../../ui-components/modals/modal.service';
import { AlertsService } from '../../ui-components/alerts/alerts.service';
import {  getMarkDefaultSuccessMessage, serverError } from '../../utils/alerts.utils';
import { Repo } from '../../utils/repo';
import { UserService } from '../../users/user.service';
import { WalletService,
              CCardInfoFromWallet,
               MakeCreditCardPrimaryRequest,
               PayOutstandingBalancedRequest} from './wallet.service';
import { UserProfileRecord } from '../../users/user-credentials';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { MembershipService } from '../membership.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WalletCartFormComponent } from './form.component';
import {ConfirmModalComponent} from './modals/confirm-modal.component';


@Component({
  selector: 'app-wallet-credit-cards-list',
  templateUrl: './ccwallet-list.component.html'
})
export class CreditCardsWalletListComponent implements OnInit, IListComponent<CCardInfoFromWallet> {
  @Output() onEdit = new EventEmitter<CCardInfoFromWallet>();
  @Output() openEdit = new EventEmitter<any>();

  public _creditCardsInfo: Repo<CCardInfoFromWallet> = defaultRepo;
  protected status: string = 'active';
  public dataLoaded = false;
  showSpinner: boolean;
  buttonsDisabled = false;
  outstandingBalance: number  = 0;

  constructor(
    protected modalService: ModalService,
    protected alertService: AlertsService,
    protected userService: UserService,
    protected walletService: WalletService,
     protected membershipService: MembershipService,
    protected dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.isLoading();
    this.retrieveList();
    this.membershipService.refreshWalletEvent
    .subscribe((data:string) => {
      console.log('Even received to refresh wallet -> ' + data);
      this.isLoading();
      this.retrieveList();
    });
  }

  public retrieveList(): void {
    this.walletService.getCreditCardListFromWallet(this.status).subscribe(
      (creditCardsInfo) => {
        this.creditCardsInfo = creditCardsInfo;
        this.walletService.creditCardsInfoSaved =  creditCardsInfo;
        this.outstandingBalance = this.walletService.outstandingBalance;
        if (this.allCreditCards.length === 1 &&
          _.isEmpty(this.allCreditCards[0].creditCard)) {
          this.creditCardsInfo = defaultRepo;
          this.walletService.creditCardsInfoSaved =  defaultRepo;
        }
        this.isFinishedLoading();
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
        this.isFinishedLoading();
      }
    );
  }
  public set creditCardsInfo(repo: Repo<CCardInfoFromWallet>) {
    this.dataLoaded = true;
    this._creditCardsInfo = repo;
  }

  public get creditCardsInfo() {
    return this._creditCardsInfo;
  }
  public get allCreditCards() {
   const creditCardsInfoSaved  = this.walletService.creditCardsInfoSaved;
   return allRecords(creditCardsInfoSaved);
  }

//   public outstandingBalance(): number {
//     return this.walletService.outstandingBalance;
//  }

  public noCreditCards(): boolean {
     return (this.allCreditCards.length === 0) && this.dataLoaded;
  }

  get user(): UserProfileRecord {
    return this.userService.profile;
  }

  isVisible(): boolean {
    return true;
  }

  public isLoading(): void {
    this.showSpinner = true;
    this.buttonsDisabled = true;
  }

  public isFinishedLoading(): void {
    this.showSpinner = false;
    this.buttonsDisabled = false;
  }

  async onStartEdit(cCardInfo: CCardInfoFromWallet) {
    const cloned = Object.assign({}, cCardInfo);
    this.onEdit.emit(cloned);
  }

  openEditDialog(cCardInfo): void {
    const dialogRef = this.dialog.open(WalletCartFormComponent, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {model: cCardInfo}
    });
    this.onStartEdit(cCardInfo);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'noupdate') {
        this.retrieveList();
      }
    });

  }
  openAddDialog(): void {
    const dialogRef = this.dialog.open(WalletCartFormComponent, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {model: {}}
    });
      dialogRef.afterClosed().subscribe(result => {
      if (result !== 'noupdate') {
        this.retrieveList();
      }
    });
  }

  onConfirmDelete(cCardInfo: CCardInfoFromWallet): void {
    this.modalService.open(String(cCardInfo.walletId));
  }

  onStartPaying(cCardInfo: CCardInfoFromWallet): void {
    this.modalService.open(`pay${String(cCardInfo.walletId)}`);
  }
  onPay(cCardInfo: CCardInfoFromWallet): void {
    console.log(`I am in onPay ${JSON.stringify(cCardInfo)}`);
     this.alertService.removeAllAlerts();
      this.isLoading();
      const request: PayOutstandingBalancedRequest = this.buildPayOutstandingBalancedRequest(cCardInfo)
      console.log (`outstandingpayment request ${JSON.stringify(request)}`);
       this.walletService.payOutstandingBalance(this.buildPayOutstandingBalancedRequest(cCardInfo))
         .pipe(

         ).subscribe(
           () => {
             this.alertService.displaySuccess(getMarkDefaultSuccessMessage('credit card'));
           },
           (err) => {
             this.alertService.displayError(err.message || serverError);
           }
         );
  }
  onMarkDefault(cCardInfo: CCardInfoFromWallet) {
    this.isLoading();
    this.walletService.markAsPrimary(this.buildMarkDefaultCreditCardRequest(cCardInfo)).pipe(
      finalize(() => {
        this.walletService.getCreditCardListFromWallet(this.status).subscribe(
          (creditCardsInfo) => {
            this.creditCardsInfo = creditCardsInfo;
            this.walletService.creditCardsInfoSaved =  creditCardsInfo;
          },
          (err) => {
            this.alertService.displayError(err.message || serverError);
          }
        );
        // Added this call to reload the primary card properly in the Autorenew On modal
        this.membershipService.getPaymentInformation().subscribe(() => {
          this.isFinishedLoading();
        });
      }),
    ).subscribe(
      () => {
        this.alertService.displaySuccess(getMarkDefaultSuccessMessage('credit card'));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  confirmChangeDefault(event:any , cCardInfo: CCardInfoFromWallet): void {
    if ( this.isDefault(cCardInfo) ) {
      return;
    }

    console.log("Entered Changing Primary Payment Method with cCardInfo -> " + cCardInfo);

    const autoRenew: boolean = this.membershipService.paymentInfo.autoRenew;

    if(!this.isDigital(this.walletService.creditCardsInfoSaved.primary)) {
      console.log("Changing Primary Payment Method to ->" + cCardInfo);
      this.onMarkDefault(cCardInfo);
      return;
    }

    // warning for switching off digital since it will end digital payment method
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {displaySave: true, model: {}}
    });
      dialogRef.afterClosed().subscribe(result => {
      if (result === 'no') {
      } else {
        this.onMarkDefault(cCardInfo);
      }
    });

  }

  isDefault(cCardInfo: CCardInfoFromWallet): boolean {
    return this.walletService.creditCardsInfoSaved.primary  === cCardInfo;
  }

  cardExpirationMonth(cCardInfo: CCardInfoFromWallet): string {
    if (cCardInfo.creditCard.expiration) {
       return cCardInfo.creditCard.expiration.split('-')[1];
    }
    return '';
  }
  cardExpirationYear(cCardInfo: CCardInfoFromWallet): string {
    if (cCardInfo.creditCard.expiration) {
      return cCardInfo.creditCard.expiration.split('-')[0];
    }
    return '';
  }

  private buildMarkDefaultCreditCardRequest(cCardInfo: CCardInfoFromWallet): MakeCreditCardPrimaryRequest {
    return {
      entityId: cCardInfo.entityId,
      updateId: cCardInfo.walletId,
    };
  }

  private buildPayOutstandingBalancedRequest(cCardInfo: CCardInfoFromWallet): PayOutstandingBalancedRequest {
    return {
      entityId: cCardInfo.entityId,
      paymentId: cCardInfo.paymentId,
      creditCardDetails: {
        name: cCardInfo.creditCard.nameOnCard,
        number: cCardInfo.creditCard.lastFourDigits,
        creditCardWalletId: cCardInfo.walletId,
        type: cCardInfo.creditCard.type,
        expiration: cCardInfo.creditCard.expiration ,
        chargeAmount: 0 //should be $0
      }
    };
  }

  public isDigital(cCardInfo: CCardInfoFromWallet): boolean {
    if(!cCardInfo.creditCard) return false;

    return ['Apple Pay', 'Google Pay'].includes(cCardInfo.creditCard.type);
  }

}
