<div class="layout">

    <div class="content">
        <div>
            <h2 class="login-header">Manage Account</h2>
        </div>
        <div class="mobile-view-hide">
            <div class="layout layout_two_up no-padding">
                <div class="layout_two_up-primary">
                    <div class="center">
                        <a routerLink="/login" class="button-login button--medium">
                            Log In
                        </a>
                    </div>
                    <div class="create-account center"><a routerLink="/signup">Create Free Account</a></div>
                </div>
                <div class="layout_two_up-secondary">
                    <div class="login-subheader">Login and get access to:</div>
                    <ul class="check-mark-list">
                        <li>View your profile</li>
                        <li>Check your membership status</li>
                        <li>Update your contact information</li>
                        <li>Subscribe to newsletters</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mobile-view-show">
            <div class="center">
                <div class="login-subheader">Login and get access to:</div>
            </div>
            <div class="content-section">
                <ul class="check-mark-list">
                    <li>View your profile</li>
                    <li>Check your membership status</li>
                    <li>Update your contact information</li>
                    <li>Subscribe to newsletters</li>
                </ul>
            </div>
            <br><br>
            <div class="center">
                <div>
                    <a routerLink="/login" class="button-login button--medium">
                        Log In
                    </a>
                </div>
                <div class="create-account"><a routerLink="/signup">Create Free Account</a></div>
            </div>
                
    
        </div>
        <br><br><br><br>
    </div>
</div>