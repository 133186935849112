import { Component } from '@angular/core';

import { PhoneNumbersService } from './phone-numbers.service';
import { PhoneNumberType, dropdownPhoneNumberTypes } from './phone-number-types';
import { NewContactNumberComponent } from '../contact-numbers/new.component';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-new-phone-number',
  templateUrl: '../contact-numbers/form.component.html',
})
export class NewPhoneNumberComponent extends NewContactNumberComponent {
  readonly title = 'Phone';
  readonly typesLabel = 'Phone Number Type*';
  readonly numberPlaceholder = 'Phone Number*';
  readonly extPlaceholder = 'Extension (optional)';
  numberTypes: PhoneNumberType[] = dropdownPhoneNumberTypes;
  name: ContactType = 'phone number';
  public isEditMode: boolean;
  public subscribed: boolean;

  constructor(
    protected service: PhoneNumbersService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected analyticsService : AnalyticsService,
    protected dialogRef: MatDialogRef<NewPhoneNumberComponent>

  ) {
    super(service, navService, alertService, formBuilder, analyticsService);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isPhone(phone?): boolean {
    return true;
  }

  isFax(fax?): boolean {
    return false;
  }

  isMobile(number): boolean {
    if (number._parent.value.type === 'mobile') {
      return true;
    }
    return false;
  }
}
