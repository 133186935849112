<div class="referral-search">
  <h4 class="center margin-top-bottom">No Match Found</h4>

  <p class="referral-search__text text-small">We were unable to confirm your colleague’s information</p>

  <div class="referral-search__text margin-top--small">
    <p class="no-margin text-small">You can still send your invitation now, or go back and try a different state or name spelling.</p>
  </div>

  <div class="margin-top-bottom--large centered">
    <button type="button" class="button-secondary" (click)="formService.closeReferralNoMatchModal()">Go Back</button>
    <button type="button" class="button" (click)="formService.submitNoMatchFound()">Send Email Invitation</button>
  </div>
</div>
