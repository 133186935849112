<div class="sticky-element mobile-nav">
  <p class="mobile-nav-header">{{currentRouteName$ | async}}</p>
  <div (click)="toggleVisibility()" class="hamburger-menu-icon"></div>
  <div class="hamburger-menu" *ngIf="isOpen">
    <p class="hamburger-menu-header">
      Menu
    </p>
    <p class="hamburger-link" (click)="navigateToPage('/personalinfo'); closeIframeIfOpen();" *ngIf="isPhysician">Profile</p>
    <p class="hamburger-link" (click)="navigateToPage('/membership')" *ngIf="isPhysician">Membership</p>
    <p class="hamburger-link" (click)="navigateToPage('/contact-preferences'); closeIframeIfOpen();">Contact Preferences</p>
    <p class="hamburger-link" (click)="navigateToPage('/newsletters'); closeIframeIfOpen();">Newsletters</p>
    <a class="link hamburger-link" target="_blank" rel="noopener noreferrer" *ngIf="!isPhysician" href="{{ upgradeProfileURL }}">Upgrade your account</a>
  </div>
</div>
