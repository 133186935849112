<span id="email-list" [ngClass]="isChangePrimaryModalClass">
  <span class="email-title" *ngIf="!isChangePrimaryModal">
    Email
  </span>

  <div *ngFor="let email of allEmails">
    <span class="detailedItems">
      <mat-radio-button
      *ngIf="!isChangePrimaryModal"
      class="primary-radio-button"
      [checked]="isSelected(email)"
      (click)="onMarkDefault(email)"
      name = "email_primary_rb"></mat-radio-button>

      <mat-radio-button
      *ngIf="isChangePrimaryModal"
      class="primary-radio-button"
      [checked]="isSelected(email) && !addNew"
      (click)="onMarkDefault(email)"
      name = "email_primary_rb"></mat-radio-button>

      <span *ngIf="isDefault(email)">
        <span>{{email.address}}</span>
        <app-link
        *ngIf="!isChangePrimaryModal"
        class="list-component-edit-link"
        [disabled]="buttonsDisabled"
        (click)="openEditDialog(email)">
        Edit
        </app-link>
        <div class="email-sent" *ngIf="!isChangePrimaryModal">Newsletters are sent to this email</div>
      </span>

      <span *ngIf="!isDefault(email)">
        <span class="emailaddr">{{email.address}}</span>
        <app-link
          *ngIf="!isChangePrimaryModal"
          class="list-component-edit-link"
          [disabled]="buttonsDisabled"
          (click)="openEditDialog(email)">
          Edit
        </app-link>
      </span>

    </span>
  </div>

  <div *ngIf="this.selectedEmail && this.selectedEmail.id && changePrimaryNotAlreadyClicked" class="email__add-new-section" (click)="isChangePrimaryModal ? addNewEmailForChangePrimaryModal() : openAddDialog()">
    <span class="email__add-button" id="add-button-icon"></span>
    <span>   Add New Email Address</span>
  </div>

  <div *ngIf="isChangePrimaryModal && !addNew">
    <input class="button change-primary-button" type="submit" value="{{buttonValue}}"
    [disabled]="buttonsDisabled"
    (click)="submitButtonClick()">
  </div>

</span>
