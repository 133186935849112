import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { NavigationStart, Router } from '@angular/router';

export type ActiveSection = 'list' | 'new' | 'edit' | 'verify-unsaved' | 'verify-existing' | 'edit-unsaved';
export type ContactType = 'email' | 'mailing address' | 'phone number' | 'fax number' |  'wallet credit number';

interface ContactSection {
  activeSection: ActiveSection;
  name: ContactType;
}

@Injectable()
export class ContactPreferencesNavigationService {
  sections: ContactSection[] = [
    {
      activeSection: 'list',
      name: 'email',
    },
    {
      activeSection: 'list',
      name: 'mailing address',
    },
    {
      activeSection: 'list',
      name: 'phone number',
    },
    {
      activeSection: 'list',
      name: 'fax number',
    },
    {
      activeSection: 'list',
      name: 'wallet credit number',
    }
  ];

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.resetAll();
      }
    });
  }

  getActiveSection(name: ContactType): ActiveSection {
    return this.findByName(name).activeSection;
  }

  setActiveSection(name: ContactType, section: ActiveSection): void {
    this.resetAll();
    this.findByName(name).activeSection = section;
  }

  resetAll() {
    _.each(this.sections, (s) => {
      s.activeSection = 'list';
    });
  }

  private findByName(name: string): ContactSection {
    return _.find(this.sections, (s) => {
      return s.name === name;
    });
  }
}

