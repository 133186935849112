<div class="form-item" [formGroup]="formGroup">
    <label *ngIf="itemLabel" [for]="fieldName" class="form-item_label show">{{itemLabel.name}}</label>
    <input [appInputAutofocus]="focus" [delayAutofocus]="delayAutofocus"
           [formControlName]="fieldName"
           [id]="fieldName"
           type="text"
           [textMask]="{mask: fieldMask, guide: false}"
           [maxlength]="itemMaxLength"
           [placeholder]="itemPlaceholder"
           [ngClass]="{'is-valid valid': isFieldValid(), 'has-error error': showValidationErrors() }">
    <span class="form-item_icon form-item_icon-to-front"></span>

    <label class="error-message" *ngIf="isFieldPresent()">
        Field is required.
    </label>
    <label class="error-message" *ngIf="patternValidationMessage && patternIsInvalid()">
        {{patternValidationMessage}}.
    </label>
    <label class="error-message" *ngIf="getField().hasError('expirationDateInPast') && this.showValidation()">
        Date cannot be in the past.
    </label>
</div>
