import { Component, OnInit } from '@angular/core';
import { UserService } from '../../users/user.service';
import { UserProfileRecord } from '../../users/user-credentials';
import { MembershipService, MemberSince, Product, PaymentInformation } from 'app/membership-status/membership.service';
import { PaymentFormService } from 'app/membership-status/payment/payment-form.service';
import { AutoRenewFormService } from 'app/membership-status/auto-renew-form.service';
import { IncentiveService } from 'app/membership-status/incentive.service';
import { forkJoin } from 'rxjs';
import { MailingAddressesService } from 'app/mailing-addresses/mailing-addresses.service';
import { Router } from '@angular/router';
import { MemberSinceConfig } from 'config/member-since.config';

@Component({
  selector: 'app-user-header',
  templateUrl: 'user-header.component.html',
})
export class UserHeaderComponent implements OnInit {
    membershipYear: number;
    defaultProduct: Product;
    buttonText: string;
    renewProductResponse: number;
    isRenewFlag: boolean = false;
    membershipYearDisplay: any;

    constructor(
        private userService: UserService,
        private membershipService: MembershipService,
        private paymentFormService: PaymentFormService,
        private autoRenewFormService: AutoRenewFormService,
        private incentiveService: IncentiveService,
        private mailingService: MailingAddressesService,
        public router: Router,
        private memberSinceConfig: MemberSinceConfig,
    ) {}

    private _memberSince: MemberSince;

    get user(): UserProfileRecord {
        return this.userService.profile;
    }

    get isStudent(): boolean {
        return this.userService.isInGroup('student');
    }
    get isPhysician(): boolean {
        return this.userService.isPhysician;
    }

    get currentYear(): number {
      return new Date(Date.now()).getFullYear();
    }

    get isMember(): boolean {
      return this.userService.isMember;
    }

    get memberStatus(): string {
      return this.isMember ? 'Member' : 'Non-Member';
    }

    get paymentInformation(): PaymentInformation {
      return this.membershipService.paymentInfo;
    }

    /**
     * Gets the first year a member became a member
     */
    get memberSince(): MemberSince {
        return this._memberSince;
    }

    /**
     * Gets whether the Join Today/Renew Now button should be shown
     * It should be shown if:
     * 1. The user is a Physician AND
     * 2. The user is not a member
     */
    get shouldShowJoinRenewButton(): boolean {
      return this.isPhysician && !this.isMember;
    }

    /**
     * Sets the first year a member became a member
     */
    set memberSince(memberSince: MemberSince) {
        this._memberSince = memberSince;
    }

  /**
   * Subscribe to getting the Member Since from the Membership Service
   */
    ngOnInit(): void {
      forkJoin(
        this.mailingService.getMailingAddresses(),
        this.membershipService.getPaymentInformation(),
        this.incentiveService.getAutoRenewIncentives()
      ).subscribe(([addresses, paymentInfo, incentives]) => {
        this.autoRenewFormService.primaryAddress = addresses.primary;
        this.autoRenewFormService.createAutoRenewCheckboxForm();
        this.autoRenewFormService.createPaymentForm();
        this.autoRenewFormService.createShippingAddressForm();
        this.isRenew()
      })

      this.membershipService.getMemberSince().subscribe(
          (memberSince) => this._memberSince = memberSince,
          (err) => this._memberSince = null
      );
  }

  public getDefaultProduct(membershipYear) {
    // Get default product from current year
    this.membershipService.getDefaultProduct(membershipYear)
      .subscribe((product) => {
        this.defaultProduct = product;
        if (!this.isRenewFlag) {
          if (membershipYear === this.currentYear && !this.isRenewFlag) {
            this.buttonText = 'Join Today'
          } else {
            this.buttonText = 'Join for ' + membershipYear;
          }
        }
    })
  }

  public isRenew() {
    this.membershipService.getMembershipYear().subscribe((year) => {
      const membershipYear = year;

    // Get default product from previous year
    this.membershipService.getDefaultProduct(this.currentYear - 1)
      .subscribe(
        (product) => {
          if (product?.returnCode === -2) {
            this.isRenewFlag = true;
            if (this.currentYear === membershipYear) {
              this.buttonText = 'Renew Now'
            } else {
              this.buttonText = 'Renew for ' + membershipYear
            }
            this.getDefaultProduct(membershipYear)
          } else {
            this.getDefaultProduct(membershipYear)
          }
        },
        () => {
          this.getDefaultProduct(membershipYear)
        }
      )
    })
  }

  public getMemberSinceDisplay(year: number): string {
    if (year < this.memberSinceConfig.loyalAmaMemberBeforeYear) {
      return 'Loyal AMA Member';
    } else {
      return 'Member since ' + year;
    }
  }
 }
