type DisplayType = 'Home' | 'Office' | 'Home Office' | 'Other';
export type FaxNumberAPIType = 'home' | 'office' | 'home-office' | 'other';

export interface FaxNumberType {
  displayName: DisplayType;
  apiName: FaxNumberAPIType;
}

export const faxNumberTypes: FaxNumberType[] = [
  { displayName: 'Home', apiName: 'home' },
  { displayName: 'Office', apiName: 'office' },
  { displayName: 'Home Office', apiName: 'home-office' },
  { displayName: 'Other', apiName: 'other' },
];

// Took away "other" as an option for future, but will still display in the list if preconfigured prior
export const dropdownFaxNumberTypes: FaxNumberType[] = [
  { displayName: 'Home', apiName: 'home' },
  { displayName: 'Office', apiName: 'office' },
  { displayName: 'Home Office', apiName: 'home-office' },
];
