import { Injectable } from '@angular/core';
import { MailingAddressAPIType, addressTypes } from './address-types';
import { StateOrTerritory, states } from '../utils/states';
import { MailingAddress, MailingAddressParams, MailingAddressRecord, VerificationResult } from './mailing-address';
import { MailingAddressesMemoryAPI } from './mailing-addresses.memory-api';
import * as _ from 'lodash';

import {
  allRecords,
  convertDates,
  createRecord,
} from '../utils/contact-records.utils';
import { MaxRecordsConfig } from '../../config/max-database-records.config';
import { Repo } from '../utils/repo';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { map } from 'rxjs/operators';
import { AnalyticsService } from 'app/analytics/analytics.service';

interface APIParams extends MailingAddressParams {
  addressId?: string;
}

@Injectable()
export class MailingAddressesService {
  limit: number;
  url = '/api/addresses';
  disableAdd = false;
  verificationResult: VerificationResult;
  originalAddress: Partial<MailingAddressRecord>;
  mailingAddresses: Repo<MailingAddressRecord>;

  constructor(
    private httpClient: HttpClient,
    private memoryAPI: MailingAddressesMemoryAPI,
    maxRecordsConfig: MaxRecordsConfig,
    private analyticsService: AnalyticsService
  ) {
    this.limit = maxRecordsConfig.mailingAddresses;
  }

  addMailingAddress(params: Partial<MailingAddressRecord>): Observable<Repo<MailingAddressRecord>> {
    if (!this.isStateOrTerritory(params.stateTerritory as StateOrTerritory)) {
      return throwError( { message: `${params.stateTerritory} is not a valid state`} );
    };

    if (!this.isAddressType(params.type as MailingAddressAPIType)) {
      return throwError( { message: `${params.type} is not a valid type`} );
    }

    return this.httpClient.post(this.url, this.createAPIParams(params)).pipe(
      map((response: ApiResponse<any>) => {
        const id = response?.data.id;
        this.mailingAddresses = this.checkAddressCount(this.memoryAPI.addMailingAddress(createRecord(params, id)));
        this.analyticsService.contactInfoUpdate('address', 'add', params.type, JSON.parse(JSON.stringify(params)));
        return this.mailingAddresses;
      })
    );
  }

  getMailingAddresses(): Observable<Repo<MailingAddressRecord>> {
    if (!!this.mailingAddresses) {
      return of(this.checkAddressCount(this.mailingAddresses));
    }

    return this.httpClient.get(`${this.url}/contact-preferences`).pipe(
      map((response: ApiResponse<any>) => {
        const data = convertDates<MailingAddressRecord>(response?.data);
        this.mailingAddresses = data;
        this.memoryAPI.repo = data;
        return this.checkAddressCount(data);
      })
    );
  }

  markAsDefault(id: string): Observable<Repo<MailingAddressRecord>> {
    const body = { addressId: id };
    return this.httpClient.put(`${this.url}/primary`, body).pipe(
      map(() => {
        this.mailingAddresses = this.memoryAPI.markAsDefault(id);
        this.analyticsService.contactInfoUpdate('address', 'make primary', this.mailingAddresses.primary.type,  JSON.parse(JSON.stringify(this.mailingAddresses.primary)));
        return this.mailingAddresses;
      })
    );
  }

  deleteMailingAddress(id: string): Observable<Repo<MailingAddressRecord>> {
    const body = { addressId: id };
    return this.httpClient.request('delete', this.url, { body: body }).pipe(
      map(() => {
        this.mailingAddresses = this.checkAddressCount(this.memoryAPI.deleteMailingAddress(id));
        return this.mailingAddresses;
      })
    );
  }

  updateMailingAddress(id: string, params: Partial<MailingAddressRecord>): Observable<Repo<MailingAddressRecord>> {
    if (!this.isStateOrTerritory(params.stateTerritory as StateOrTerritory)) {
      return throwError( { message: `${params.stateTerritory} is not a valid state`} );
    };

    if (!this.isAddressType(params.type as MailingAddressAPIType)) {
      return throwError( { message: `${params.type} is not a valid type`} );
    }

    const data = this.createAPIParams(params, id);
    const memoryData = this.createMemoryParams(params, id);
    return this.httpClient.put(this.url, data).pipe(
      map(() => {
        this.mailingAddresses = this.memoryAPI.updateMailingAddress(id, memoryData);
        this.analyticsService.contactInfoUpdate('address', 'edit', params.type, JSON.parse(JSON.stringify(params)));
        return this.mailingAddresses;
      })
    );
  }

  normalizeMailingAddress(address: MailingAddress, type: string): Observable<VerificationResult> {
    return this.httpClient.post(`${this.url}/normalize`, address).pipe(
      map((response: ApiResponse<any>) => {
        this.mailingAddresses = null;
        this.verificationResult = response?.data;
        this.verificationResult.editedType = type;
        return this.verificationResult;
      })
    );
  }

  normalizeShippingAddress(address: MailingAddressParams): Observable<VerificationResult> {
    const { type, ...requestParams } = address;
    return this.httpClient.post(`${this.url}/normalize`, requestParams).pipe(
      map((response: ApiResponse<any>) => {
        this.verificationResult = response?.data;
        this.verificationResult.editedType = type;
        return this.verificationResult;
      })
    );
  }

  private isStateOrTerritory(s: StateOrTerritory): s is StateOrTerritory {
    return _.includes(states, s);
  }

  private isAddressType(t: MailingAddressAPIType): t is MailingAddressAPIType {
    return (addressTypes.find((type) => type.apiName === t) !== undefined);
  }

  private createAPIParams(params: Partial<MailingAddressRecord>, id?: string): APIParams {
    const address: APIParams = {
      lineOne: params.lineOne,
      city: params.city,
      stateTerritory: params.stateTerritory,
      zipCode: params.zipCode,
      type: params.type,
    };
    if (params.lineTwo) {
      address.lineTwo = params.lineTwo;
    }

    if (params.lineThree) {
      address.lineThree = params.lineThree;
    }

    if (id) {
      address.addressId = id;
    }

    return address;
  }

  private createMemoryParams(params: Partial<MailingAddressRecord>, id?: string): Partial<MailingAddressRecord> {
    const address: Partial<MailingAddressRecord> = {
      lineOne: params.lineOne,
      city: params.city,
      stateTerritory: params.stateTerritory,
      zipCode: params.zipCode,
      type: params.type,
    };
    if (params.lineTwo) {
      address.lineTwo = params.lineTwo;
    } else {
      address.lineTwo = '';
    }

    if (params.lineThree) {
      address.lineThree = params.lineThree;
    } else {
      address.lineThree = '';
    }

    if (id) {
      address.id = id;
    }

    return address;
  }

  private checkAddressCount(addresses: Repo<MailingAddressRecord>): Repo<MailingAddressRecord> {
    this.disableAdd = allRecords(addresses).length >= this.limit;
    return addresses;
  }
}
