import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { ButtonComponent } from '../../ui-components/buttons/button.component';

@Component({
  selector: 'app-subscribable-button',
  templateUrl: './subscribable-button.component.html'
})
export class SubscribableButtonComponent extends ButtonComponent {
  @Input() subscribed: boolean;
  @Input() membersOnly: boolean;

  @Output() subscribe = new EventEmitter<void>()
  @Output() unsubscribe = new EventEmitter<void>()
}
