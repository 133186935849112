//import { AppConfigService } from './../../providers/app-config.service';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from '../../users/user.service';
import { UserProfileRecord } from '../../users/user-credentials';
//import { environment } from '../../../environments/environment';
import { MembershipService } from 'app/membership-status/membership.service';

@Component({ selector: 'app-header', templateUrl: './header.component.html' })
export class HeaderComponent {
  mainNavVisible = false;
  userNavVisible = false;
  mainNavActive = false;
  userNavActive = false;
  hideSignInHomePageNav = false;

  @ViewChild('mainNav') mainNav: ElementRef;
  @ViewChild('userNav') userNav: ElementRef;

  constructor(
    private userService: UserService,
    private membershipService : MembershipService,
    private location: Location
   // private config: AppConfigService
  ) {
      if(location.path() === '/home'){
        this.hideSignInHomePageNav = true;
      }
   // console.log(`this.config.getConfig() ${JSON.stringify(this.config.getConfig())}`);
  }

  get user(): UserProfileRecord {
    return this.userService.profile;
  }

  get member(): boolean {
    return this.userService.profile.isMember;
  }

  get autoRenew(): boolean {
    const paymentInfo = this.membershipService.paymentInfo
    if(paymentInfo && paymentInfo.autoRenew) {
      return true;
    }else {
      return false;
    }
  }

  toggleMainNav() {
    this.mainNavActive = !this.mainNavActive;
    this.mainNavVisible = !this.mainNavVisible;
    this.userNavVisible = false;
  }

  toggleUserNav() {
    this.userNavActive = !this.userNavActive;
    this.mainNavVisible = false;
    this.userNavVisible = !this.userNavVisible;
  }

  toggleNavOff() {
    this.mainNavActive = false;
    this.userNavActive = false;
    this.mainNavVisible = false;
    this.userNavVisible = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.mainNav.nativeElement.contains(event.target)) {
      this.toggleMainNav();
    } else if (this.userNav.nativeElement.contains(event.target)) {
      this.toggleUserNav();
    } else {
      this.toggleNavOff();
    }
  }

  // get environment(): any {
  //   return environment;
  // }

  get amaStoreUrl(): string {
    //return this.environment.amaStore;
    return window["env"]["amaStore"];
  }

  get amaWireUrl(): string {
    //return this.environment.amaWire;
    return window["env"]["amaWire"];
  }

  get jamaUrl(): string {
    //return this.environment.jama;
    return window["env"]["jama"];
  }

  get journalOfEthicsUrl(): string {
    //return this.environment.journalOfEthics;
    return window["env"]["journalOfEthics"];
  }

  get freidaUrl(): string {
    let freidaFromJsFile = window["env"]["freida"];
    return freidaFromJsFile;
  }

  get amaStoreAccountUrl(): string {
    //return this.environment.amaStoreAccount;
    return window["env"]["amaStoreAccount"];
  }

  get becomeMemberUrl(): string {
    //return this.environment.becomeMember;
    return window["env"]["becomeMember"];
  }

  get referralsUrl(): string {
    //return this.environment.referrals;
    return window["env"]["referrals"];
  }

}
