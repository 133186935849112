import { Injectable } from '@angular/core';

import {
  ContactNumbersService,
  ContactNumberAPIParams,
  RequestBody,
} from '../contact-numbers/contact-numbers.service';

import { PhoneNumbersMemoryAPI } from './phone-numbers.memory-api';
import { ContactNumberRecord } from '../contact-numbers/contact-number';
import { MaxRecordsConfig } from '../../config/max-database-records.config';
import { HttpClient } from '@angular/common/http';

interface PhoneNumberAPIRecord extends ContactNumberAPIParams {
  telephoneNumberId: string;
}

@Injectable()
export class PhoneNumbersService extends ContactNumbersService {
  url = '/api/telephone-numbers';
  name = 'phone number';

  constructor(
    protected httpClient: HttpClient,
    protected memoryAPI: PhoneNumbersMemoryAPI,
    maxRecordsConfig: MaxRecordsConfig,
  ) {
    super(httpClient, memoryAPI);
    this.limit = maxRecordsConfig.phoneNumbers;
  };

  getRequestBody(id: string): RequestBody {
    return { telephoneNumberId: id };
  }

  toAPIRecord(params: Partial<ContactNumberRecord>, id: string): PhoneNumberAPIRecord {
    const record = super.toAPIRecord(params, id) as PhoneNumberAPIRecord;
    record.telephoneNumberId = id;
    return record;
  }
}
