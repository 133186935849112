import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AmaTemplateModalV1Module } from '../../ui-components/ama-ng-framework/ama__template-modal__v1/ama__template-modal__v1.module';
import { AmaValidatedTextInputV1Module } from '../../ui-components/ama-ng-framework/ama__validated-text-input__v1/ama__validated-text-input__v1.module';
import { AmaValidatedDropdownInputV1Module } from '../../ui-components/ama-ng-framework/ama__validated-dropdown-input__v1/ama__validated-dropdown-input__v1.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertsModule } from '../../ui-components/alerts/alerts.module';
import { ButtonsModule } from '../../ui-components/buttons/buttons.module'
import { NavigationModule } from '../../ui-components/navigation/navigation.module'
import { SelectorsModule } from '../../ui-components/selectors/selectors.module';
import { SpinnersModule } from '../../ui-components/spinners/spinners.module';
import { CardsModule } from '../../ui-components/cards/cards.module';
import { TransitionsModule } from '../../ui-components/transitions/transitions.module';
import { ModalsModule } from '../../ui-components/modals/modals.module';
import { ContactUsLinkModule } from 'app/ui-components/links/contact-us-link/contact-us-link.module';
import { WalletComponent } from './wallet.component';
import { CreditCardsWalletListComponent } from './ccwallet-list.component';
import {WalletCartFormComponent} from './form.component';
import {ConfirmDeleteCardInWalletModalComponent} from './modals/confirm-delete-card-modal.component';
import {ConfirmModalComponent} from './modals/confirm-modal.component';
import { UserService } from 'app/users/user.service';
import { WalletService } from './wallet.service';
import { NumberToStringConverterPipe } from '../../utils/number-to-string-converted pipe';
import { DeclinedCardSectionComponent } from './declined-card-section/declined-card-section.component';
import { AlertMaxlimitCardsUpdateComponent } from './modals/alert-maxlimit-cards-update.component';


@NgModule({
  declarations: [
    WalletComponent,
    CreditCardsWalletListComponent,
    WalletCartFormComponent,
    ConfirmDeleteCardInWalletModalComponent,
    ConfirmModalComponent,
    NumberToStringConverterPipe,
    DeclinedCardSectionComponent,
    AlertMaxlimitCardsUpdateComponent,
   ],
  imports: [
    AlertsModule,
    AmaValidatedTextInputV1Module,
    AmaValidatedDropdownInputV1Module,
    AmaTemplateModalV1Module,
    ButtonsModule,
    CommonModule,
    FormsModule,
    NavigationModule,
    ReactiveFormsModule,
    SelectorsModule,
    SpinnersModule,
    TransitionsModule,
    CardsModule,
    ModalsModule,
    MatRadioModule,
    MatTooltipModule,
    MatCheckboxModule,
    ContactUsLinkModule,
  ],
  exports: [
    WalletComponent,
    CreditCardsWalletListComponent,
    ConfirmDeleteCardInWalletModalComponent,
    ConfirmModalComponent,
    AlertMaxlimitCardsUpdateComponent,
    NumberToStringConverterPipe,
    DeclinedCardSectionComponent
  ],
  providers: [
    UserService,
    WalletService,
    NumberToStringConverterPipe
  ]
})

export class WalletModule { }
