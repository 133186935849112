<app-modal [id]="'remove-' + email.id">
  <h2 class="modal__header">Removing {{ email.address }} will also unsubscribe you from: </h2>
  <ul class="modal__list">
    <li *ngFor="let subscription of subscriptions">
      {{ subscription.title }}
    </li>
  </ul>

  <div class="centered">
    <app-cancel-button (click)="!buttonsDisabled && closeModal(email.id)">Cancel</app-cancel-button>
    <app-submit-button
      [disabled]="buttonsDisabled"
      (click)="!buttonsDisabled && onRemove(email)">
      Yes, Remove and unsubscribe me
    </app-submit-button>
    <p class="modal__note">* This email will still exist in contact preferences.</p>
  </div>
</app-modal>
