import { Injectable } from '@angular/core';

import {
  ContactNumbersService,
  ContactNumberAPIParams,
  RequestBody,
} from '../contact-numbers/contact-numbers.service';

import { ContactNumberRecord } from '../contact-numbers/contact-number';
import { FaxNumbersMemoryAPI } from './fax-numbers.memory-api';
import { MaxRecordsConfig } from '../../config/max-database-records.config';
import { HttpClient } from '@angular/common/http';

interface FaxNumberAPIRecord extends ContactNumberAPIParams {
  faxNumberId: string;
}

@Injectable()
export class FaxNumbersService extends ContactNumbersService {
  url = '/api/fax-numbers';
  name = 'fax number';

  constructor(
    protected httpClient: HttpClient,
    protected memoryAPI: FaxNumbersMemoryAPI,
    maxRecordsConfig: MaxRecordsConfig,
  ) {
    super(httpClient, memoryAPI);
    this.limit = maxRecordsConfig.faxNumbers;
  };

  getRequestBody(id: string): RequestBody {
    return { faxNumberId: id };
  }

  toAPIRecord(params: Partial<ContactNumberRecord>, id: string): FaxNumberAPIRecord {
    const record = super.toAPIRecord(params, id) as FaxNumberAPIRecord;
    record.faxNumberId = id;
    return record;
  }
}
