import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html'
})
export class CardHeaderComponent {
  @Input() title: string;
  @Input() hasPrimary = false;
  @Input() primaryDescription: string = 'Indicates primary form of contact';
}
