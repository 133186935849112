import { Component } from '@angular/core';
import { DateService } from '../date.service';

@Component({
  selector: 'app-unauthenticated-layout',
  templateUrl: './unauthenticated-layout.component.html',
})
export class UnauthenticatedLayoutComponent {

  get currentYear() {
    return DateService.getCurrentYear();
  }
}
