import { WalletService } from 'app/membership-status/wallet/wallet.service';
import { Component, OnInit } from '@angular/core';
import { UserProfileRecord } from '../users/user-credentials';
import { UserService } from '../users/user.service';
import { MembershipService, PaymentInformation, MemberSince, Product } from './membership.service';
import { MailingAddressesService } from '../mailing-addresses/mailing-addresses.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { autoRenewErrorMessage, serverError, oneClickJoinRenewalSuccessMessage, oneClickJoinNewMemberSuccessMessage } from '../utils/alerts.utils';
import { Incentive, IncentiveService } from './incentive.service';
import { AutoRenewFormService } from './auto-renew-form.service';
import { forkJoin } from 'rxjs';
import { PaymentFormService } from './payment/payment-form.service';
import { TurnOffAutorenewModalComponent } from './modals/turn-off-autorenew-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TurnOnAutorenewModalComponent } from './modals/turn-on-autorenew-modal.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { TermsAndConditionsModalComponent } from './modals/terms-and-conditions-modal.component';
import { MemberSinceConfig } from 'config/member-since.config';
import { CreditCardsWalletListComponent } from './wallet/ccwallet-list.component';
import { AdminService, AppName } from 'app/services/admin.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-membership-status',
  templateUrl: './membership-status.component.html',
})
export class MembershipStatusComponent implements OnInit {
  enableAutoRenewBtnTxt: string;
  searchFilter: string = "autoR";
  isTocModalVisible = false;
  isFinishedLoading = false;
  isUpdatePaymentInfoPosting = false;
  incentiveOptions: Incentive[];
  incentivesForDisplay;
  analyticsAutoRenewEnabledStart = false;
  memberSince: MemberSince;
  defaultProduct: Product;
  allProducts: Product[];
  nextYearDefaultProduct: Product;
  renewProductResponse: number;
  buttonText: string;
  membershipYearDisplay: number;
  isRenewFlag: boolean = false;

  constructor(
    private alertsService: AlertsService,
    private adminService: AdminService,
    private mailingService: MailingAddressesService,
    private membershipService: MembershipService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    public autoRenewFormService: AutoRenewFormService,
    private paymentFormService: PaymentFormService,
    private incentiveService: IncentiveService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private memberSinceConfig: MemberSinceConfig,
    private walletService:  WalletService,
  ) {
    window.addEventListener('message', this.closeIframe, false);
  }

  get isAutoRenewEligible(): boolean {
    return this.membershipService.isAutoRenewEligable();
  }

  get isEligibleForIncentives(): boolean {
    return this.membershipService.paymentInfo.incentiveEligible;
  }

  get user(): UserProfileRecord {
    return this.userService.profile;
  }

  get paymentInformation(): PaymentInformation {
    return this.membershipService.paymentInfo;
  }

  get isPhysician(): boolean {
    return this.userService.isPhysician;
  }

  get isMember(): boolean {
    return this.userService.isMember;
  }

  get hasPendingOrder(): boolean {
    return this.userService.hasPendingOrder;
  }

  get pendingOrderMbrYear(): string {
    return this.userService.pendingOrderMbrYear;
  }

  get memberStatus(): string {
    return this.isMember ? 'Member' : 'Non-Member';
  }

  get fullName(): string {
    return `${this.user.labelName}`;
  }

  get cardExpirationMonth(): string {
    return this.paymentInformation.creditCard.expiration.split('-')[1];
  }

  get cardExpirationYear(): string {
    return this.paymentInformation.creditCard.expiration.split('-')[0];
  }

  get currentYear(): number {
    return new Date(Date.now()).getFullYear();
  }

  get membershipExpireYear(): number {
    if (this.paymentInformation.autoRnwYear) {
      return this.paymentInformation.autoRnwYear;
    } else {
      return this.currentYear + 1;
    }
   }
   get endPeriodDate(): string {
    if (this.paymentInformation.endPeriodDate) {
      return this.paymentInformation.endPeriodDate.slice(0, 4);
    } else {
      if (this.hasPendingOrder &&  this.pendingOrderMbrYear) {
        return this.pendingOrderMbrYear;
      } else {
        return this.currentYear.toString();
      }
    }
   }

  /**
   * Gets the auto renew status for the slide toggle
   */
  get autorenewStatus(): boolean {
    return this.membershipService.paymentInfo.autoRenew;
  }

  /**
   * Gets whether the Membership Types section should be shown
   * It should be shown when User is not a current Member
   */
  get shouldShowMembershipTypesSection(): boolean {
    return !this.isMember;
  }

  /**
   * Gets whether the Renew for Next Year button should be shown
   * It should be shown when:
   * 1. User is a Member AND
   * 2. Default Product for next year (this will happen once membership year is not current year) is not null
   */
  get shouldShowRenewForNextYearButton(): boolean {
    if (this.hasPendingOrder) {
      return false;
    } else{
      return this.isMember
        && !!this.defaultProduct
        && !!this.defaultProduct.productCode;
    }
  }

  get getClassForMembershipCardTitle(): string {
    if (this.shouldShowRenewForNextYearButton) {
      return 'membership-card-title-with-renew-button';
    } else {
      return 'membership-card-title-without-renew-button';
    }
  }

  /**
   * AR-25, AR-26: keeps track of the state of the toggle and keeps the correct state if user exits the auto-renew modal
  */
  get autorenewToggleState(): boolean {
    return this.membershipService.autorenewToggleState;
  }
  set autorenewToggleState(status: boolean) {
    this.membershipService.autorenewToggleState = status;
  }
  get showAutorenewButton(): boolean {
    return !this.membershipService.paymentInfo.autoRenew;
  }
  get showAlternateRatesIframe(): boolean {
    return this.membershipService.showAlternateRatesIframe;
  }
  set showAlternateRatesIframe(status: boolean) {
    this.membershipService.showAlternateRatesIframe = status;
  }

  private _alternateRatesUrl: SafeUrl;
  get alternateRatesUrl(): SafeUrl {
    return this._alternateRatesUrl;
  }

  ngOnInit() {
    this.fetchConfigDetails();
    // If the member previously signed up for a membership through the Join Renew iFrame,
    // that triggered a reload with a local storage item.
    // Here, we take the local storage item and use it to determine which confirmation alert to
    // show. Happens here to make sure the animation is shown after the loading screen
    // disappears.
    const showConfirmationAlert = localStorage.getItem('showConfirmationAlert');
    if (!!showConfirmationAlert) {
      if (showConfirmationAlert === 'renewal') {
        this.alertsService.displaySuccess(oneClickJoinRenewalSuccessMessage)
      } else if (showConfirmationAlert === 'new-member') {
        this.alertsService.displaySuccess(oneClickJoinNewMemberSuccessMessage);
      }
      localStorage.removeItem('showConfirmationAlert');
    }

    forkJoin(
      this.mailingService.getMailingAddresses(),
      this.membershipService.getPaymentInformation(),
      this.incentiveService.getAutoRenewIncentives(),
      this.membershipService.getMemberSince(),
    ).subscribe(([addresses, paymentInfo, incentives, memberSince]) => {
        this.isFinishedLoading = true;
        this.autoRenewFormService.primaryAddress = addresses.primary;
        this.autoRenewFormService.createAutoRenewCheckboxForm();
        this.autoRenewFormService.createShippingAddressForm();
        this.memberSince = memberSince;
        this.isRenew();
      }
    );
    this.membershipService.getAllProducts().subscribe((response) => {
      this.allProducts = response;
    });
    let joinRenewAlternateRates = window["env"]["joinRenewAlternateRates"]
    this._alternateRatesUrl = this.sanitizer.bypassSecurityTrustResourceUrl(joinRenewAlternateRates);
  }

  public disableAutorenew() {
    this.membershipService.disableAutorenew().subscribe(
      () => {
        this.paymentInformation.autoRenew = false;
        const walletListCompOneObj = new CreditCardsWalletListComponent(
          this.modalService, this.alertsService, this.userService, this.walletService, this.membershipService,this.dialog );
          walletListCompOneObj.ngOnInit();
      },
      () => {
        return this.alertsService.displayError(autoRenewErrorMessage);
      }
    );
  }

  public openTocModal() {
    const dialogRef = this.dialog.open(TermsAndConditionsModalComponent, {
      width: this.modalService.getAppropriateWidthProperty(704),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {isConfirmModal: true}
    });
  }

  public getDefaultProduct(membershipYear: number) {
    // Get default product from current year
    this.membershipService.getDefaultProduct(membershipYear)
      .subscribe((product) => {
        this.defaultProduct = product;
        if (!this.isRenewFlag) {
          if (membershipYear === this.currentYear) {
            this.buttonText = 'Join Today'
          } else {
            this.buttonText = 'Join for ' + membershipYear;
          }
        }
    })
  }

  public getNextYearDefaultProduct(membershipYear: number) {
    this.membershipService.getDefaultProduct(membershipYear + 1)
      .subscribe((product) => {
        this.nextYearDefaultProduct = product;
      })
  }

  public isRenew() {
    this.membershipService.getMembershipYear().subscribe((year) => {
      const membershipYear = year;
      this.membershipYearDisplay = membershipYear
      // Get default product from previous year
      this.membershipService.getDefaultProduct(this.currentYear - 1)
        .subscribe(
          (product) => {
            if (product?.returnCode === -2) {
              this.isRenewFlag = true;
              if (this.currentYear === membershipYear) {
                this.buttonText = 'Renew Now';
              } else {
                this.buttonText = 'Renew for ' + membershipYear;
              }
            }
          },
          (error) => {
            this.alertsService.displayError('Unable to retrieve default product. Please try again later.')
          },
          () => {
            this.getDefaultProduct(membershipYear);
            this.getNextYearDefaultProduct(membershipYear);
          }
        );
    });
  }

  /**
   * AR-25, AR-26: handles opening modal for auto-renew on or off and moves toggle value
   * 5/21/2024 toggle button has been replaced by 'Enable auto-renew, this function not needed
   */
  handleChange(checkbox?: MatSlideToggleChange, openTurnOn?: boolean) {
    if (checkbox && checkbox.checked) {
      this.autoRenewFormService.setupAddresses();
    }
    if (openTurnOn || checkbox.checked) {
      this.autorenewToggleState = true;
      this.dialog.open(TurnOnAutorenewModalComponent, {
        width: this.modalService.getAppropriateWidthProperty(800),
        maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
        height: this.modalService.getAppropriateHeightProperty(),
        maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
        autoFocus: false,
        data: {selectedIncentive: null}
      }).afterClosed().subscribe(data => {
        if (data !== 'turn-on') {
          this.autorenewToggleState = false;
        }

        // Refresh payment information since it may have changed (card, incentive eligibility, etc.)
        this.membershipService.getPaymentInformation().subscribe();
      });
    } else {
      this.autorenewToggleState = false;
      this.dialog.open(TurnOffAutorenewModalComponent, {
        width: this.modalService.getAppropriateWidthProperty(550),
        maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
        height: this.modalService.getAppropriateHeightProperty(),
        maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
          autoFocus: false,
      }).afterClosed().subscribe(data => {
        if (data === 'disable') {
          this.disableAutorenew();
        } else {
          this.autorenewToggleState = true;
        }
        this.userService.requestNewAdInformationRefresh$.next();
      });
    }
  }

  enableAutorenewButton(nonSelectiveIncentiveClickEvent?:any) {
    
    if (!this.isAutoRenewEligible){return;}

    let selectedIncentiveValue = null;
    if(!!nonSelectiveIncentiveClickEvent){
      //info of  pre selected incentive when selected from membership incentive section
      selectedIncentiveValue = nonSelectiveIncentiveClickEvent.value;
    }else{
      //no incentive pre selected when we use autorenew button
      selectedIncentiveValue = null;
    }
    
    this.autoRenewFormService.setupAddresses();
    this.autorenewToggleState = true;
    this.membershipService.paymentInfo.autoRenew = true;
    this.dialog.open(TurnOnAutorenewModalComponent, {
      width: this.modalService.getAppropriateWidthProperty(800),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {selectedIncentive: selectedIncentiveValue}
    }).afterClosed().subscribe(data => {
      if (data !== 'turn-on') {
        this.autorenewToggleState = false;
        this.membershipService.paymentInfo.autoRenew = false;
        // Refresh payment information since it may have changed (card, incentive eligibility, etc.)
        this.membershipService.getPaymentInformation().subscribe();
      }
    });
    
  }

  public editShippingAddress() {
    this.autoRenewFormService.hasNormalizedAddress = false;
    this.autoRenewFormService.clickedEditAddress = true;
    this.handleChange(null, true);
  }

  public closeTocModal() {
    this.isTocModalVisible = false;
  }

  public closeAddrModal() {
    this.autoRenewFormService.hasNormalizedAddress = false;
    this.membershipService.autorenewToggleState = false;
  }

  private closeIframe(event) {
    let joinRenewBaseUrl = window["env"]["joinRenewBaseUrl"]
    if (event.data === 'amcConfirmPayment' && event.origin === joinRenewBaseUrl) {
      // Store that there should be a confirmation alert in local storage
      localStorage.setItem('showConfirmationAlert', this.isRenewFlag ? 'renewal' : 'new-member');

      // Reload the page. Necessary because all services need to be refreshed, and this is the
      // surest way to accomplish that.
      window.location.reload();
    }
  }

  public getMemberSinceDisplay(year: number): string {
    if (year < this.memberSinceConfig.loyalAmaMemberBeforeYear) {
      return 'Loyal AMA Member';
    } else {
      return 'Member since ' + year;
    }
  }

  /**
   * AR-27 allow printing of the membership card
   * This method allows the user to print out the membership card, it finds it by looking for the id "membership-card-to-print"
   * It opens up a new window to print the card, and has some browser-specific CSS elements in the new window.
   * Once the user prints the card, or hits cancel, the new browser window closes.
   */
  printCard() {
    const printContent = document.getElementById('membership-card-to-print');
    const windowPrt = window.open( '', '', 'left=0, top=0, width = 900, height = 900, toolbar=0, scrollbars=0, status=0');

     windowPrt.document.write(`
     <html>
     <head>
       <title>Print tab</title>
       <style>
        body{
          -webkit-print-color-adjust: exact !important;
          color-adjust: exact !important;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active);
          printer-colors: exact;
        }

        .membership-card-header {
          margin-top: 140px;
          height: 0.46875in;
          width: 2.91in;
          border-radius: 15px 15px 0 0;
          background-color: #46166B;
        }

        .membership-card-body {
          height: 1.198in;
          width: 2.89in;
          border-radius: 0 0 15px 15px;
          border: 1px solid grey;
          background: linear-gradient(270deg, white 0%, #e8e8e8 100%);
        }

        .membership-card-details-container {
          padding-top: 8px;
          font-family: "myriad-pro", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
          height: calc(100% - 8px);
          width: 100%;
        }

        .membership-card-details-text {
          padding-left: 24px;
          padding-bottom: 10px;
          font-size: 14px;
        }

        .member-year-text {
          color: #46166B;
          text-align: right;
          position: relative;
          bottom: 5px;
          right: 10px;
        }

        .member-label {
          font-size: 14px;
          text-transform: uppercase;
        }

        .current-year {
          font-size: 36px;
          font-weight: 600;
          line-height: 36px;
        }

        .membership-card__header-image {
          display: none;
        }

        .printable-header {
          position: relative;
          top: 7px;
          left: 10px;
        }
       </style>
     </head>
     `);
    windowPrt.document.write(printContent.innerHTML);
    windowPrt.document.close();
    this.analyticsService.printCardOrReciept('membership card');
    windowPrt.focus();
    windowPrt.print();
    windowPrt.close();
  }

  get isDeclinedCardExist (): boolean {
      return this.walletService.isDeclinedCardExist;
  }
  closeIFrame() : void {
    this.showAlternateRatesIframe = false;

    $( ".outerdiv" ).hide();
    $( ".membership-content" ).show();

  }

  // Get the enable auto renew button text
  private fetchConfigDetails(): void {
    this.adminService.getPageConfigValuesByKeyAndAppName(this.searchFilter, AppName.AMC)
    .pipe(take(1))
    .subscribe(
        data =>  this.enableAutoRenewBtnTxt = this.adminService.searchKey(data, `${this.searchFilter}-btn-text`) || 'Enable Auto-renew',
        error => this.enableAutoRenewBtnTxt = 'Enable Auto-renew');
  }

}
