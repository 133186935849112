import { Component, Input } from '@angular/core';
import { UserCredentials } from '../../users/user-credentials';

@Component({
  selector: 'app-ama-header',
  templateUrl: './ama-header.component.html',
})
export class AmaHeaderComponent {
  @Input() user: UserCredentials;
}
