<app-modal [id]="'update-' + currentEmail?.id">
  <h2 class="modal__header">This update will transfer {{ nSubscriptionsLabel }} from {{ currentEmail.address }} to {{ selectedEmail.address }}: </h2>
  <ul class="modal__list">
    <li *ngFor="let subscription of subscriptions">
      {{ subscription.title }}
    </li>
  </ul>

  <div class="centered">
    <app-cancel-button
      [disabled]="buttonsDisabled"
      (click)="!buttonsDisabled && closeModal(currentEmail.id)">
      Cancel
    </app-cancel-button>
    <app-submit-button
      [disabled]="buttonsDisabled"
      (click)="!buttonsDisabled && onUpdate(email)">
      Yes, Update and transfer
    </app-submit-button>
  </div>
</app-modal>
