import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TextMaskModule } from 'angular2-text-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonsModule } from '../ui-components/buttons/buttons.module';
import { ProfessionalDetailsListComponent } from './professional-details-list/professional-details-list.component';
import { ProfessionalDetailsFormComponent } from './professional-details-form/form.component';
import { AutoCompleteComponent } from './auto-complete-dropdown/auto-complete.component';
import { ProfessionalDetailsService } from './professional-details-service';

// copied from faxModule
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    ButtonsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
  ],
  declarations: [
    ProfessionalDetailsListComponent,
    ProfessionalDetailsFormComponent,
    AutoCompleteComponent,
   ],
  exports: [
    ProfessionalDetailsListComponent,
  ],
  providers: [
    ProfessionalDetailsListComponent,
    ProfessionalDetailsService,
  ]
})
export class ProfessionalDetailsModule { }
