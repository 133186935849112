import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscribablesModule } from './subscribables/subscribables.module';
import { SubscriptionsComponent } from './subscriptions.component';
import { SubscriptionsService } from './subscriptions.service';
import { AlertsModule } from '../ui-components/alerts/alerts.module';
import { EmailAddressesService } from '../email-addresses/email-addresses.service';
import { EmailAddressesMemoryAPI } from '../email-addresses/email-addresses.memory-api';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { TabsModule } from '../ui-components/tabs/tabs.module';
import { UnsubscribeSurveyComponent } from './survey/unsubscribe-survey.component';
import { UnsubscribeThankYouComponent } from './survey/unsubscribe-thank-you.component';
import { NavigationModule } from '../ui-components/navigation/navigation.module';
import { SingleAnswerComponent } from './survey/single-answer.component';
import { MultipleAnswerComponent } from './survey/multiple-answer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '../ui-components/buttons/buttons.module';
import { TextAnswerComponent } from './survey/text-answer.component';
import { RouterModule } from '@angular/router';
import { ContactUsLinkModule } from 'app/ui-components/links/contact-us-link/contact-us-link.module';

@NgModule({
  imports: [
    CommonModule,
    SubscribablesModule,
    AlertsModule,
    TabsModule,
    RouterModule,
    NavigationModule,
    ReactiveFormsModule,
    ButtonsModule,
    ContactUsLinkModule
  ],
  declarations: [
    SubscriptionsComponent,
    UnsubscribeSurveyComponent,
    UnsubscribeThankYouComponent,
    SingleAnswerComponent,
    MultipleAnswerComponent,
    TextAnswerComponent
  ],
  providers: [
    SubscriptionsService,
    EmailAddressesService,
    EmailAddressesMemoryAPI,
    AlertsService,
  ],
})
export class SubscriptionsModule { }
