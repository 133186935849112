import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { ContactNumberRecord } from './contact-number';
import { Repo } from '../utils/repo';

import {
  byId,
  allRecords,
} from '../utils/contact-records.utils';

@Injectable()
export class ContactNumbersMemoryAPI {
  private _repo: Repo<ContactNumberRecord> = { primary: null, others: [] };

  set repo(newRepo: Repo<ContactNumberRecord>) {
    this._repo = newRepo;
  }

  get allContactNumbers(): ContactNumberRecord[] {
    return allRecords(this._repo);
  }

  addContactNumber(params: Partial<ContactNumberRecord>): Repo<ContactNumberRecord> {
    const record: ContactNumberRecord = {
      number: params.number,
      type: params.type,
      addedAt: params.addedAt,
      id: params.id,
    };

    if (params.extension) {
      record.extension = params.extension;
    }

    if(params.smsOptIn != null) {
      record.smsOptIn = params.smsOptIn;
    }

    if (this.isUnique(record)) {
      this._repo.primary ? this._repo.others.push(record) : this._repo.primary = record;
    }

    return this._repo;
  }

  getContactNumbers(): Repo<ContactNumberRecord> {
    return this._repo;
  };

  markAsDefault(id: string): Repo<ContactNumberRecord> {
    const number = this.getContactNumberById(id);
    this.deleteOtherRecord(number);

    if (this._repo.primary){
      this._repo.others.push(this._repo.primary);
    }
    this._repo.primary = number;

    return this._repo;
  }

  deleteContactNumber(id: string): Repo<ContactNumberRecord> {
    const number = this.getContactNumberById(id);

    if (this._repo.primary === number) {
      this.deleteDefaultRecord();
    } else {
      this.deleteOtherRecord(number);
    }

    return this._repo;
  }

  updateContactNumber(id: string, params: Partial<ContactNumberRecord>): Repo<ContactNumberRecord> {
    const number = this.getContactNumberById(id);
    const newNumber = Object.assign(number, params);

    if (this._repo.primary === number) {
      this._repo.primary = newNumber;
    } else {
      const idx = this._repo.others.findIndex(byId(id));
      this._repo.others[idx] = newNumber;
    }

    return this._repo;
  }

  private isUnique(record: ContactNumberRecord): boolean {
    const match = _.find(this.allContactNumbers, (num) => {
      return num.number === record.number && num.type === record.type;
    });
    return _.isUndefined(match);
  }

  private getContactNumberById(id: string): ContactNumberRecord {
    return this.allContactNumbers.find(byId<ContactNumberRecord>(id));
  }

  private deleteOtherRecord(number: ContactNumberRecord): void {
    this._repo.others = this._repo.others.filter((n) => n !== number);
  }

  private deleteDefaultRecord(): void {
    if (this._repo.others.length > 0) {
      const firstNumber = this._repo.others[0];
      this.deleteOtherRecord(firstNumber);
      this._repo.primary = firstNumber;
    } else {
      this._repo.primary = null;
    }
  }
}
