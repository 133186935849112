import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-transition',
  template: `
    <div [@visibilityChanged]="isVisible" >
      <ng-content></ng-content>
    </div>
  `,
  animations: [
    trigger('visibilityChanged', [
      state('true' , style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('* => *', animate('.4s ease-in-out'))
    ])
  ]
})
export class TransitionComponent {
  @Input() isVisible = true;
}
