import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { AmaTemplateModalV1Module } from '../ui-components/ama-ng-framework/ama__template-modal__v1/ama__template-modal__v1.module';
import { AmaValidatedTextInputV1Module } from '../ui-components/ama-ng-framework/ama__validated-text-input__v1/ama__validated-text-input__v1.module';
import { AmaValidatedDropdownInputV1Module } from '../ui-components/ama-ng-framework/ama__validated-dropdown-input__v1/ama__validated-dropdown-input__v1.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertsModule } from '../ui-components/alerts/alerts.module';
import { ButtonsModule } from '../ui-components/buttons/buttons.module'
import { NavigationModule } from '../ui-components/navigation/navigation.module'
import { SelectorsModule } from '../ui-components/selectors/selectors.module';
import { SpinnersModule } from '../ui-components/spinners/spinners.module'
import { ReferralFormService } from './referral-form.service';
import { ReferralsComponent } from './referrals.component';
import { ReferralSearchModalComponent } from './modals/referral-search-modal.component';
import { ReferralService } from './referral.service';
import { UserService } from 'app/users/user.service';
import { ReferralNoMatchModalComponent } from './modals/referral-no-match-modal.component';

@NgModule({
  declarations: [
    ReferralsComponent,
    ReferralSearchModalComponent,
    ReferralNoMatchModalComponent,
  ],
  imports: [
    AlertsModule,
    AmaValidatedTextInputV1Module,
    AmaValidatedDropdownInputV1Module,
    AmaTemplateModalV1Module,
    ButtonsModule,
    CommonModule,
    FormsModule,
    NavigationModule,
    ReactiveFormsModule,
    SelectorsModule,
    SpinnersModule,
  ],
  providers: [
    ReferralFormService,
    ReferralService,
    UserService,
  ]
})

export class ReferralModule { }
