import { ContactPreferencesNavigationService } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhoneNumbersService } from '../phone-numbers/phone-numbers.service';
import { FaxNumbersService } from 'app/fax-numbers/fax-numbers.service';
import { ContactNumberRecord } from './contact-number';

export class ContactNumberFormComponent {
  buttonsDisabled = false;
  contactNumberForm: FormGroup;

  readonly numberMask = [/[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  readonly extensionMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  model: Partial<ContactNumberRecord>;

  get type() {
    return this.contactNumberForm.get('type');
  }

  get number() {
    return this.contactNumberForm.get('number');
  }

  get ext() {
    return this.contactNumberForm.get('extension');
  }

  get id() {
    return this.contactNumberForm.get('id');
  }

  get smsOptIn() {
    return this.contactNumberForm.get('smsOptIn');
  }

  get formData() {
    const data: Partial<ContactNumberRecord> = {
      number: this.sanitizeInput(this.number.value),
      type: this.type.value,
    };
    if (this.ext.value !== '' && data.type !== 'mobile') {
      data.extension = this.ext.value;
    }
    if (this.id.value !== '') {
      data.id = this.id.value;
    }
    if (this.smsOptIn.value != null) {
      data.smsOptIn = this.smsOptIn.value;
    }
    return data;
  }

  constructor(
    protected service: PhoneNumbersService | FaxNumbersService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
  ) {
    this.contactNumberForm = this.formBuilder.group({
      type: ['', Validators.required],
      number: ['', Validators.compose([Validators.required, Validators.pattern(/\d{3}[-]?\d{3}[-]?\d{4}/)]) ],
      extension: [''],
      id: [''],
      smsOptIn: [false],
    });
  }

  validateForm(): void {
    if (this.contactNumberForm.valid && (this.contactNumberForm.value.type !== 'other')) {
      this.onFormSubmit();
    } else {
      this.type.markAsTouched();
      this.number.markAsTouched();
    }
  }

  onFormSubmit(): void { }

  numberIsInvalid(): boolean {
    return this.number.touched && this.number.errors && this.number.errors['pattern'] && !this.number.errors['required'];
  }

  isNotPresent(field): boolean {
    return field.touched && field.errors && field.errors['required'];
  }

  isTypeOther(field): boolean {
    return field.value === 'other';
  }

  protected disableButton() {
    this.buttonsDisabled = true;
  }

  protected enableButton() {
    this.buttonsDisabled = false;
  }

  protected sanitizeInput(input: string) {
    return input.replace(/\D+/g, '');
  }
}
