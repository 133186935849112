import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EmailRecord } from '../email-address';
import { ModalService } from '../../ui-components/modals/modal.service';
import { SubscribableRecord } from '../../subscriptions/subscriptions';

@Component({
  selector: 'app-update-email-modal',
  templateUrl: './update-email-modal.component.html',
})
export class UpdateEmailModalComponent {

  @Input() email: EmailRecord;
  @Input() newEmail: string;
  @Input() subscriptions: SubscribableRecord[];
  @Output() updateEmail = new EventEmitter<EmailRecord>();

  buttonsDisabled = false;

  get nSubscriptionsLabel(): string {
    const n = this.subscriptions.length;
    return `${n} subscription${n === 1 ? '' : 's' }`;
  }

  constructor(protected modalService: ModalService) { }

  onUpdate(email: EmailRecord): void {
    this.updateEmail.emit(email);
    this.disableButtons();
    this.closeModal(email.id);
  }

  closeModal(id: string): void {
    this.modalService.close(`update-${id}`);
  }

  protected disableButtons(): void {
    this.buttonsDisabled = true;
  }
}
