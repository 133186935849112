import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { AlertComponent } from './alert.component';

export interface IAlertsService {
  displayError(message: string): void;
}

@Injectable()
export class AlertsService implements IAlertsService {
  private alerts: AlertComponent[] = [];
  private _error: string;
  private _success: string;

  get error() {
    return this._error;
  }

  get success() {
    return this._success;
  }

  add(alert: AlertComponent): void {
    this.alerts.push(alert);
  }

  remove(id: string): void {
    const alertToRemove = this.findById(id);
    this.alerts = _.without(this.alerts, alertToRemove);
  }

  show(id: string): void {
    const alert = this.findById(id);
    if (alert) {
      alert.show();
    }
  }

  removeAllAlerts(): void {
    this.alerts.forEach((alert) => alert.hide());
  }

  hide(id: string): void {
    const alert = this.findById(id);
    if (alert) {
      alert.hide();
    }
  }

  displayError(message: string) {
    this.show('error');
    this._error = message;
  }

  displaySuccess(message: string) {
    this.show('success');
    this._success = message;
  }

  private findById(id: string): AlertComponent {
    return _.find(this.alerts, { id: id });
  }
}
