/**
 *  Calculations for modal height/width so that it scrolls on smaller screens
 */
export function calcModalHeight() {
  return window.innerHeight - 50;
}

export function calcModalWidth(width: number) {
  if ((window.innerWidth - 50) < width) {
    return (window.innerWidth - 50) + 'px';
  }
  return width + 'px';
}
