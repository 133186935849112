import { Injectable } from '@angular/core';
import { SubscribeData, SubscriptionsData, SubscribableRecord, UpdateEmailResidencyFlag } from './subscriptions';
import { UnsubscribeSurveyAnswer, UnsubscribeSurveyQuestion } from './survey-type-definitions';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { map, finalize } from 'rxjs/operators';
import { EmailAddressesService } from 'app/email-addresses/email-addresses.service';

export type questionType = 'single-answer' | 'multiple-answer' | 'text';

@Injectable()
export class SubscriptionsService {
  numberOfSubscriptions: number;
  showSpinner = false;
  subscriptions: SubscriptionsData;
  private subscribablesUrl = '/api/subscribables';
  private subscriptionUrl = '/api/subscriptions';
  private unsubscribeSurveyUrl = '/api/unsubscribe-survey';
  private unsubscribeSurveyResponsesUrl = '/api/unsubscribe-survey-responses';
  private updateResidencyUrl = '/api/subscriptions/update-residency';
  public primaryEmail;
  public allSubscribables;
  public subscriptionEmailIds = [];

  constructor(
    private httpClient: HttpClient,
    public emailAddressesService: EmailAddressesService,
  ) {}

  getSubscriptions(): Observable<SubscriptionsData> {
    if (!!this.subscriptions) {
      return of(this.subscriptions);
    }

    return this.httpClient.get(this.subscribablesUrl).pipe(
      map((response: ApiResponse<SubscriptionsData>) => {
        const data = response?.data;
        this.subscriptions = data;
        this.numberOfSubscriptions = data.numberOfSubscriptions;
        return data;
      })
    );
  }

  subscribe(data: SubscribeData): Observable<void> {
    return this.httpClient.put(this.subscriptionUrl, data).pipe(
      map(() => {
        this.subscriptions = null;
        this.numberOfSubscriptions += 1;
      })
    );
  }

  unsubscribe(subscribableId: string): Observable<void> {
    const data = {
      subscribableId,
      emailAddressIds: [],
    };
    return this.httpClient.put(this.subscriptionUrl, data).pipe(
      map(() => {
        this.subscriptions = null;
        this.numberOfSubscriptions -= 1;
      })
    );
  }

  getSubscriptionsForEmailId(emailId: string): any[] {
    this.getSubscriptions().pipe(
      finalize(() => {})
    ).subscribe(allNewsletters => {
      allNewsletters.categories.forEach(category => {
        category.subscribables.forEach(newsletter => {
          if (!!newsletter.subscribedEmailAddressIds) {
            if ((newsletter.subscribedEmailAddressIds[0] === emailId)
            || (newsletter.subscribedEmailAddressIds[1] === emailId)) {
              this.allSubscribables.append(newsletter);
            }
          }
        });
      });
    });
    return this.allSubscribables;
  }

  hasMultipleEmailsSubscribed(): boolean {
    this.emailAddressesService.getSubscriptionEmails().subscribe(subscriptionEmails => {
      this.primaryEmail = subscriptionEmails.primary.id

      this.getSubscriptions().pipe(
        finalize(() => {})
      ).subscribe(sub => {
        const subscriptions = sub.categories.map(s => s.subscribables).map(e => e.map(x => x.subscribedEmailAddressIds));
        const merged = [].concat.apply([], subscriptions);
        this.subscriptionEmailIds = [].concat.apply([], merged);

        if (!this.subscriptionEmailIds.every(e => e === this.primaryEmail)) {
          return true;
        } else {
          return false;
        }
      });

    });
    return false;
  }

  update(data: SubscribeData): Observable<any> {
    return this.httpClient.put(this.subscriptionUrl, data);
  }

  getSurveyQuestions(): Observable<UnsubscribeSurveyQuestion[]> {
    return this.httpClient.get(this.unsubscribeSurveyUrl).pipe(
      map((response: ApiResponse<any>) => {
        return response?.data.questions;
      })
    );
  }

  postSurveyAnswers(responses: UnsubscribeSurveyAnswer): Observable<any> {
    return this.httpClient.post(this.unsubscribeSurveyResponsesUrl, responses);
  }

  updateResidency(data: UpdateEmailResidencyFlag): Observable<any> {
    return this.httpClient.post(this.updateResidencyUrl, data);
  }
}
