import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '../../ui-components/buttons/buttons.module';
import { AvailableSubscriptionsComponent } from './available-subscriptions/available-subscriptions.component';
import { SubscribableButtonComponent } from './subscribable-button.component';
import { UpdateSubscribableButtonComponent } from './update-subscribable-button.component';
import { SelectorsModule } from '../../ui-components/selectors/selectors.module';
import { IconsModule } from '../../ui-components/icons/icons.module';
import { SubscriptionEmailsFormComponent } from './subscription-emails-form/subscription-emails-form.component';
import { TransitionsModule } from '../../ui-components/transitions/transitions.module';
import { SpinnersModule } from '../../ui-components/spinners/spinners.module';
import { FormsModule } from '@angular/forms';
import { UpdateSubscriptionEmailModalComponent } from './subscription-emails-form/modals/update-subscription-email-modal.component';
import { ModalsModule } from '../../ui-components/modals/modals.module';
import { RemoveSecondaryEmailModalComponent } from './subscription-emails-form/modals/remove-secondary-email-modal.component';
import { NewsletterCardComponent } from './newsletter-card/newsletter-card.component';
import { RouterModule } from '@angular/router';
import { ChangePrimaryEmailComponent } from 'app/email-addresses/app-change-primary-email/app-change-primary-email.component';
import { MembersBannerComponent } from 'app/ui-components/members-banner/members-banner.component';
import { PremiumNewsLetterModalModalComponent } from './newsletter-card/modals/premium-newsletter-modal.component';
import { UserService } from 'app/users/user.service';
import { MembershipService } from 'app/membership-status/membership.service';
import { AmaPipesModule } from 'app/ui-components/pipes/ama-pipes.module';
import { UnitedStatesResidentValidationModalComponent } from './newsletter-card/modals/united-states-resident-validation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule,
    SelectorsModule,
    IconsModule,
    TransitionsModule,
    SpinnersModule,
    ModalsModule,
    RouterModule,
    AmaPipesModule
  ],
  declarations: [
    AvailableSubscriptionsComponent,
    NewsletterCardComponent,
    SubscribableButtonComponent,
    UpdateSubscribableButtonComponent,
    SubscriptionEmailsFormComponent,
    UpdateSubscriptionEmailModalComponent,
    RemoveSecondaryEmailModalComponent,
    MembersBannerComponent,
    UnitedStatesResidentValidationModalComponent,
  ],
  providers: [
    UserService,
    MembershipService
  ],
  exports: [
    AvailableSubscriptionsComponent,
  ]
})
export class SubscribablesModule { }
