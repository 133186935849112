<span id="exitButton" (click)="onNoClick()"></span>
<form id="change-primary-email-form" (ngSubmit)="validateForm()" [formGroup]="emailForm">
  <span class="title">{{title}} Primary Email</span>

  <div class="email-section">
    <span class="confirm-text" *ngIf="service.isChangePrimaryModal && title==='Confirm'">All newsletters are now being sent to your primary email.</span>
    <div *ngIf="service.isChangePrimaryModal && (title === 'Confirm')" class="primary-email-spacing"> </div>

    <app-email-addresses-list (onNewClickedForChangePrimaryEmailModal)="addNewClick()"></app-email-addresses-list>

    <div *ngIf="addNew">
      <app-new-email-address class="add-new-email-change-primary"></app-new-email-address>
    </div>
  <div class="blue-cancel-link"  (click)="onNoClick()"> Cancel </div>
  </div>
</form>
