import { Component, OnInit } from '@angular/core';
import { DateService } from '../date.service';
import { SubscriptionsService } from 'app/subscriptions/subscriptions.service';
import { EmailAddressesService } from 'app/email-addresses/email-addresses.service';
import { SubscriptionEmailsFormComponent } from 'app/subscriptions/subscribables/subscription-emails-form/subscription-emails-form.component';
import { ChangePrimaryEmailComponent } from 'app/email-addresses/app-change-primary-email/app-change-primary-email.component';
import { calcModalWidth, calcModalHeight } from 'app/utils/modal.utils';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { finalize, switchMap, startWith } from 'rxjs/operators';
import { AdDetailsWithPayment, MembershipService } from 'app/membership-status/membership.service';
import { UserService } from 'app/users/user.service';
import { AutoRenewLoggingService } from 'app/auto-renew-logging/auto-renew-logging.service';

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
})
export class AuthenticatedLayoutComponent implements OnInit {

  public primaryEmail;
  public subscriptionEmailIds = [];
  public showAd: boolean = false;
  public adInfo: AdDetailsWithPayment;

  get currentYear() {
    return DateService.getCurrentYear()
  }

  constructor(
    public subscriptionsService: SubscriptionsService,
    public emailAddressesService: EmailAddressesService,
    private membershipService: MembershipService,
    private userService: UserService,
    private autoRenewLoggingService: AutoRenewLoggingService,
    private modalService: ModalService,
    protected dialog: MatDialog,
  ) {}

  /**
   * If a user is using 2 emails to subscribe to a newsletter
   * we pop the confirm primary email dialog (same as change primary email on Newsletters)
   */
  ngOnInit() {
    // Getting all of the newsletters
    this.emailAddressesService.getSubscriptionEmails().pipe(finalize(() => {}))
    .subscribe(subscriptionEmails => {

      // getting primary email
      this.primaryEmail = subscriptionEmails.primary.id
      this.subscriptionsService.getSubscriptions().pipe(finalize(() => {}))
      .subscribe(sub => {
        const subscriptions = sub.categories.map(s => s.subscribables).map(e => e.map(x => x.subscribedEmailAddressIds))
        const merged = [].concat.apply([], subscriptions)

        // getting all of the emails being used for subscriptions
        this.subscriptionEmailIds = [].concat.apply([], merged)

        // check if there exists an alternate email
        if (!this.subscriptionEmailIds.every(e => e === this.primaryEmail)) {

          // if there does, we need to move some newsletters to be unsubscribed with alternate, and subscribed with primary
          const newslettersToMove = [];
          this.subscriptionEmailIds.forEach(email => {
            if (email !== this.primaryEmail) {

              // getting the newsletters
              sub.categories.forEach(category => {
                category.subscribables.forEach(newsletter => {
                  if (!!newsletter.subscribedEmailAddressIds) {
                    if ((newsletter.subscribedEmailAddressIds[0] === email)
                    || (newsletter.subscribedEmailAddressIds[1] === email)) {

                      // adding the specific newsletter subscribed to by an alternate email to an arr
                      newslettersToMove.push(newsletter);
                    }
                  }
                });
              });
            }
          })

          // before popping the modal, we unsubscribe, then resubscribe using primary
          if (!!newslettersToMove) {
          newslettersToMove.forEach(newsletter => {
            const id = newsletter.id;
            this.subscriptionsService.unsubscribe(newsletter.id).pipe(finalize(() => {}))
            .subscribe(val => {
              this.subscriptionsService.subscribe({
                subscribableId: id,
                emailAddressIds: [this.primaryEmail] })
                .pipe(finalize(() => {})).subscribe();
            })
            });

          const dialogRef = this.dialog.open(ChangePrimaryEmailComponent, {
            width: calcModalWidth(560),
            maxHeight: calcModalHeight(),
            autoFocus: false,
            data: {isConfirmModal: true}
          });
          dialogRef.afterClosed().subscribe(result => {
            this.emailAddressesService.isChangePrimaryModal = false;
          });
        }
      }
      });
    });

    this.userService.requestNewAdInformationRefresh$.pipe(
      startWith(true), switchMap(() => {
        return this.membershipService.getUpsellAdWithPayment();
      })
      ).subscribe((adAndPayment: AdDetailsWithPayment) => {
        this.adInfo = adAndPayment;
        if (this.shouldShowAutoRenewAd(adAndPayment)) {
          this.autoRenewLoggingService.logViewedAd(
            [{ dataKey: 'First Name', dataValue: this.userService.profile.firstName },
            { dataKey: 'Last Name', dataValue: this.userService.profile.lastName}],
            this.membershipService.paymentInfo.entityId
          ).subscribe();
        }
      });
  }

  get isPhysician(): boolean {
    return this.userService.isPhysician;
  }

  get isMember(): boolean {
    return this.userService.isMember;
  }

  shouldShowAutoRenewAd(adAndPayment: AdDetailsWithPayment) {
    const showAutoRenewAd = this.isMember
                              && adAndPayment.isEligible
                              && !adAndPayment.isAutoRenew
                              && (!!adAndPayment.description || !!adAndPayment.imageUrl)
                              && this.membershipService.paymentInfo.incentiveEligible;
    this.showAd = showAutoRenewAd || !this.isPhysician;
    return showAutoRenewAd;
  }
}
