<div class="layout layout_one_up align-left form-item" [formGroup]="parentForm">
  <legend class="text--heavy">{{ question.text }}</legend>
  <div>
    <div *ngFor="let answer of question.options">
      <input [formControlName]="question.id"
             type="radio"
             [value]="answer.id"
             [id]="getAnswerId(answer)" >
      <label [for]="getAnswerId(answer)">{{ answer.text }}</label>
    </div>
  </div>
</div>
