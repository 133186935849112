import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaymentInformation } from '../membership.service';
import { fieldMasks } from 'app/utils/form.utilities';
import * as _ from 'lodash';
import { PaymentFormService } from './payment-form.service';
import { Repo } from 'app/utils/repo';
import { WalletService, CCardInfoFromWallet } from '../wallet/wallet.service';
import { AutoRenewFormService } from '../auto-renew-form.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public paymentInformation: PaymentInformation = {
    autoRenew: false,
    isEligible: false
  }
  @Input() public ccWalletInfo?: Repo<CCardInfoFromWallet>;
  @Input() notJoinRenewCC: boolean = false;
  @Output() newCC? = new EventEmitter<any>();
  hasCardOnFile: boolean = false;

  readonly dateMask = fieldMasks.expirationDate;
  readonly zipCodeMask = fieldMasks.zipCode;
  readonly creditCardNumberMask = fieldMasks.creditCardNumber;

  get lastFourDigits(): string {
    return _.get(this.paymentInformation, 'creditCard.lastFourDigits', '1234');
  }

  get cardExpirationMonth(): string {
    return this.paymentInformation.creditCard.expiration.split('-')[1];
  }

  get cardExpirationYear(): string {
    return this.paymentInformation.creditCard.expiration.split('-')[0];
  }

  get joinRenewCardExpirationMonth(): string {
    return this.ccWalletInfo.primary.creditCard.expiration.split('-')[1];
  }

  get joinRenewCardExpirationYear(): string {
    return this.ccWalletInfo.primary.creditCard.expiration.split('-')[0];
  }

  constructor(
    private walletService:  WalletService,
    private paymentFormService: PaymentFormService,
    public autorenewFormService: AutoRenewFormService,
  ) { }

  ngOnInit() {
    if (!this.autorenewFormService.hasChangedCardOnSubmit) {
      const creditCard = this.paymentInformation.creditCard || {};
      this.formGroup.patchValue({
        nameOnCard: _.get(creditCard, 'nameOnCard', ''),
        expirationDate: _.isEmpty(creditCard) ? '' : `${this.cardExpirationMonth}-${this.cardExpirationYear}`,
      });
      this.paymentFormService.hasChangedCard = false;
    }
    this.hasCardOnFile = !!this.paymentInformation.creditCard || (!!this.ccWalletInfo && !!this.ccWalletInfo.primary);
    if (!this.hasCardOnFile) {
      this.paymentFormService.hasChangedCard = true;
    }else if(this.hasCardOnFile && this.isCreditCardExpired()){
      this.newCreditCard();
    }else if(this.walletService.declinedCard()?.declinedCard){
      this.newCreditCard();
    }
  }

  isCreditCardExpired():boolean {
    if(!this.hasCardOnFile){
      return false;
    }
    const expirationDate = this.paymentInformation.creditCard?.expiration;
    const [expYear, expMonth] = expirationDate.split('-').map((d) => parseInt(d, 10));
    const now = new Date(),
      currentMonth = now.getMonth() + 1,
      currentYear = now.getFullYear();
  
    const isInPast = expYear < currentYear ||
      (expYear === currentYear && expMonth < currentMonth);
  
    if (!expirationDate || !isInPast) {
      return false;
    } else {
      return true;
    }
  };


  newCreditCard() {
    this.paymentFormService.hasChangedCard = true;
    this.paymentFormService.createForm();
    this.hasCardOnFile = false;
    this.newCC.emit(true);
  }

}
