import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextSurveyQuestion } from '../survey-type-definitions';

@Component ({
  selector: 'app-text-answer',
  templateUrl: 'text-answer.component.html'
})
export class TextAnswerComponent implements OnInit {
  @Input() question: TextSurveyQuestion;
  @Input() parentForm: FormGroup;

  constructor (
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    const textFormControl = this.formBuilder.control('');
    this.parentForm.addControl(this.question.id, textFormControl);
  }
}
