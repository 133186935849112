import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common'
import { AmaTemplateModalV1Module } from '../ui-components/ama-ng-framework/ama__template-modal__v1/ama__template-modal__v1.module';
import { AmaValidatedTextInputV1Module } from '../ui-components/ama-ng-framework/ama__validated-text-input__v1/ama__validated-text-input__v1.module';
// tslint:disable-next-line: max-line-length
import { AmaValidatedDropdownInputV1Module } from '../ui-components/ama-ng-framework/ama__validated-dropdown-input__v1/ama__validated-dropdown-input__v1.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MembershipService } from './membership.service';
import { MembershipStatusComponent } from './membership-status.component';
import { AlertsModule } from '../ui-components/alerts/alerts.module';
import { ButtonsModule } from '../ui-components/buttons/buttons.module'
import { TermsAndConditionsModalComponent } from './modals/terms-and-conditions-modal.component';
import { TurnOffAutorenewModalComponent } from './modals/turn-off-autorenew-modal.component';
import { NavigationModule } from '../ui-components/navigation/navigation.module'
import { SelectorsModule } from '../ui-components/selectors/selectors.module';
import { SpinnersModule } from '../ui-components/spinners/spinners.module'
import { IncentiveService } from './incentive.service';
import { IncentiveComponent } from './incentive.component';
import { AmcFormsModule } from 'app/ui-components/forms/amc-forms.module';
import { PaymentComponent } from './payment/payment.component';
import { MembershipAddressesComponent } from './membership-addresses/membership-addresses.component';
import { AutoRenewFormService } from './auto-renew-form.service';
import { ConfirmShippingAddressModal } from './modals/confirm-shipping-address-modal.component';
import { WalletModule } from './wallet/wallet.module';
import { RouterModule } from '@angular/router';
import { TurnOnAutorenewModalComponent } from './modals/turn-on-autorenew-modal.component';
import { CardsModule } from '../ui-components/cards/cards.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContactUsLinkModule } from 'app/ui-components/links/contact-us-link/contact-us-link.module';
import { MembershipJoinModule } from './membership-join/MembershipJoinModule';
import { MembershipTransactionHistoryComponent } from './membership-transaction-history/membership-transaction-history.component';
import { MembershipTransactionHistoryService } from './membership-transaction-history/membership-transaction-history.service';
import { MembershipTypesComponent } from './membership-types/membership-types.component';
import { MembershipReceiptConfig } from 'config/membership-receipt.config';
import { GooglePayButtonModule } from '@google-pay/button-angular';

@NgModule({
  declarations: [
    MembershipStatusComponent,
    TermsAndConditionsModalComponent,
    TurnOffAutorenewModalComponent,
    ConfirmShippingAddressModal,
    IncentiveComponent,
    MembershipAddressesComponent,
    TurnOnAutorenewModalComponent,
    PaymentComponent,
    MembershipTransactionHistoryComponent,
    MembershipTypesComponent
  ],
  imports: [
    AlertsModule,
    AmaValidatedTextInputV1Module,
    AmaValidatedDropdownInputV1Module,
    AmaTemplateModalV1Module,
    ButtonsModule,
    CommonModule,
    FormsModule,
    NavigationModule,
    ReactiveFormsModule,
    SelectorsModule,
    SpinnersModule,
    AmcFormsModule,
    WalletModule,
    RouterModule,
    CardsModule,
    MatSlideToggleModule,
    ContactUsLinkModule,
    MembershipJoinModule,
    MatTooltipModule,
    GooglePayButtonModule
  ],
  providers: [
    MembershipService,
    IncentiveService,
    AutoRenewFormService,
    MembershipTransactionHistoryService,
    MembershipReceiptConfig,
  ],
  exports: [
    PaymentComponent,
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
  ]
})

export class MembershipStatusModule { }
