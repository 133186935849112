import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../ui-components/modals/modal.service';
import { ReferralFormService } from '../referral-form.service';

@Component({
  selector: 'app-referral-search-modal',
  templateUrl: './referral-search-modal.component.html',
})
export class ReferralSearchModalComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    public formService: ReferralFormService,
  ) { }

  ngOnInit() {
    this.formService.setupMatchesForm();
  }
}
