import { Component } from '@angular/core';
import { WalletService} from './wallet.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html'
})
export class WalletComponent {
  
  constructor(
    private walletService:  WalletService,
  ) {}

    isCardExist () {
      if (this.walletService.creditCardsInfoSaved && this.walletService.creditCardsInfoSaved.primary) {
        return true;
      }
      return false
    }

}
