<section class="ribbon">
  <div class="layout layout_one_up">
    <nav class="ribbon_dropdown">
      <span class="ribbon_dropdown_trigger" tabindex="0" href="#">American Medical Association
        <span class="icon" data-grunticon-embed>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 8 5" style="enable-background:new 0 0 8 5;" xml:space="preserve">
            <path d="M4,5L0.3,1.6c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,1-0.4,1.4,0L4,2.4l2.3-2.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,0.9,0,1.3L4,5z"/>
            </svg>
        </span>
      </span>

      <ul class="ribbon_dropdown_nav">
        <li class="ribbon_dropdown_nav_item"><a href="#" class="link link-white">AMA Home</a></li>
        <li class="ribbon_dropdown_nav_item"><a href="#" class="link link-white">AMA Store</a></li>
        <li class="ribbon_dropdown_nav_item"><a href="#" class="link link-white">JAMA Network&trade;</a></li>
        <li class="ribbon_dropdown_nav_item"><a href="#" class="link link-white">Journal of Ethics</a></li>
      </ul>
    </nav>

    <nav class="ribbon_user-menu ribbon_user-menu-auth">
      <span class="ribbon_user-menu_trigger" tabindex="0" href="#">
        <span class="is-vishidden">User Menu</span>
        <span class="icon" data-grunticon-embed>
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><defs><style>.cls-1{fill:#46166b;}</style></defs><title>human_icon</title><path class="cls-1" d="M0,30S-1.12,17,15,17,30,30,30,30H0Z"/><circle class="cls-1" cx="15" cy="7" r="7"/></svg>
        </span>
      </span>

      <ul class="ribbon_user-menu_nav ribbon_user-menu_nav-auth">
        <li class="ribbon_user-menu_nav_item"><a href="#" class="link link-white">Become a Member</a></li>
        <li class="ribbon_user-menu_nav_item auth-user">
          <span class="ribbon_user-menu_trigger-auth" tabindex="0" href="#">
            {{ user.firstName }} {{ user.lastName }}
            <span>
              <span class="icon" data-grunticon-embed>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 8 5" style="enable-background:new 0 0 8 5;" xml:space="preserve">
                <path d="M4,5L0.3,1.6c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,1-0.4,1.4,0L4,2.4l2.3-2.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,0.9,0,1.3L4,5z"/>
              </svg>
              </span>
            </span>
          </span>

          <ul class="ribbon_user-menu_nav-child">
            <li class="ribbon_user-menu_nav-child_item"><a href="#" class="link link-white">Profile</a></li>
            <li class="ribbon_user-menu_nav-child_item"><a href="#" class="link link-white">Newsletter Preference</a></li>
            <li class="ribbon_user-menu_nav-child_item"><a href="#" class="link link-white">AMA Physician Verification Service</a></li>
            <li class="ribbon_user-menu_nav-child_item sign-out"><a href="#" class="link link-white">Sign Out</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</section>
