import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { SogiComponent } from './sogi/sogi.component';
import { ContactPreferencesComponent } from './contact-preferences/contact-preferences.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { MembershipStatusComponent } from './membership-status/membership-status.component';
import { UnsubscribeSurveyComponent } from './subscriptions/survey/unsubscribe-survey.component';
import { AvailableSubscriptionsComponent } from './subscriptions/subscribables/available-subscriptions/available-subscriptions.component';
import { RetrieveAuthenticatedUserOrRedirect } from './users/retrieve-authenticated-user-or-redirect.service';
import { AuthenticatedLayoutComponent } from './authenticated-layout/authenticated-layout.component';
import { RedirectToExternalSso } from './users/redirect-to-external-sso.service';
import { RedirectToExternalSignup } from './users/redirect-to-external-signup.service';
import { RetrieveAuthenticatedUser } from './users/retrieve-authenticated-user.service';
import { UnauthenticatedLayoutComponent } from './unauthenticated-layout/unauthenticated-layout.component';
import { UnsubscribeThankYouComponent } from './subscriptions/survey/unsubscribe-thank-you.component';
import { SogiAuthorizedService } from './users/sogi-authorized.service';
import { ReferralsComponent } from './referrals/referrals.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { EntryPointComponent } from './authenticated-layout/entry-point.component';
import { UserIsMENumberUserService } from './users/user-is-me-number-user.service';

const routes: Routes = [
  {
    // If no path is provided, redirect to the entry point.
    path: '',
    redirectTo: 'entry-point',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [ RetrieveAuthenticatedUserOrRedirect ],
    component: AuthenticatedLayoutComponent,
    children: [
      // Entry Point route to handle path-less entry.
      {
        path: 'entry-point',
        component: EntryPointComponent,
      },
      // todo replace the current path to newsletters with below when ads on the left nav are removed:
      /**{
       * path: 'newsletters',
       * component: AvailableSubscriptionsComponent,
       * },
       */
      {
        path: 'newsletters',
        component: SubscriptionsComponent,
        data: {
          sectionTitle: 'Newsletters'
        },
        children: [
          {
            path: '',
            component: AvailableSubscriptionsComponent,
            pathMatch: 'full',
          },
        ]
      },
      {
        path: 'contact-preferences',
        component: ContactPreferencesComponent,
        data: {
          sectionTitle: 'Contact Preferences'
        }
      },
      {
        path: 'membership',
        component: MembershipStatusComponent,
        canActivate: [UserIsMENumberUserService],
        data: {
          sectionTitle: 'Membership'
        }
      },
      // The next 2 are to allow those who may have previously bookmarked those pages to still access the membership page
      {
        path: 'membership/membership-status',
        redirectTo: '/membership',
        pathMatch: 'full',
      },
      {
        path: 'membership/walletView',
        redirectTo: '/membership',
        pathMatch: 'full',
      },
      // the next 2 are to allow those who may have previously bookmarked those pages to still access the newsletters page
      {
        path: 'subscriptions/my-subscriptions',
        redirectTo: '/newsletters',
        pathMatch: 'full',
      },
      {
        path: 'subscriptions/add-new-subscriptions',
        redirectTo: '/newsletters',
        pathMatch: 'full',
      },

      {
        path: 'personalinfo',
        canActivate: [UserIsMENumberUserService],
        component:  PersonalInformationComponent,
        data: {
          sectionTitle: 'Profile'
        }
      },
      {
        path: 'referrals',
        component: ReferralsComponent,
        data: {
          sectionTitle: 'Referrals'
        }
      },
    ]
  },
  {
    path: '',
    canActivate: [ RetrieveAuthenticatedUser ],
    component: UnauthenticatedLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'unsubscribe-survey',
        component: UnsubscribeSurveyComponent
      },
      {
        path: 'unsubscribe-survey/thank-you',
        component: UnsubscribeThankYouComponent
      }
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        canActivate: [ RedirectToExternalSso ],
        children: []
      },
      {
        path: 'signup',
        canActivate: [ RedirectToExternalSignup ],
        children: []
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/entry-point',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always'
  }),
  ]
})
export class AppRoutingModule { }
