import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class SogiAuthorizedService implements CanActivate {

  constructor(private service: UserService,
    private router: Router) { }

    canActivate(): Observable<boolean> {
      const groups = 'physician,resident,student,AMAStaff';
      if (this.service.isInGroup('physician') || this.service.isInGroup('resident') || this.service.isInGroup('student')) {
        return of(true);
      } else {
        this.router.navigate( [''] );
      }
  }
}
