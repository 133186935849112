<div class="sticky-mobile-header">
  <app-header></app-header>
  <app-mobile-navigation></app-mobile-navigation>
</div>
<div class="scrollable-mobile-content">
  <div class="content-columns">
    <div class="extra-column" *ngIf="showAd"></div>
    <div class="main-content-column">
      <app-user-header></app-user-header>
      <app-top-navigation></app-top-navigation>
      <router-outlet></router-outlet>
    </div>
    <div class="ad-column">
      <app-ads [shouldShowAutoRenewAd]="showAd && isPhysician" [adInfo]="adInfo"></app-ads>
    </div>
  </div>

  <footer>
    <div class="layout layout_one_up">
      <div>
        <div>
          Copyright 1995 - {{ currentYear }} American Medical Association. All rights reserved.
        </div>

        <ul class="clean-list">
          <li><a href="https://www.ama-assn.org/terms-use">Terms of Use</a></li>
          <li><a href="https://www.ama-assn.org/privacy-policy">Privacy Policy</a></li>
          <li><a href="https://www.ama-assn.org/code-conduct">Code of Conduct</a></li>
          <li><a href="https://www.ama-assn.org/accessibility-statement">Website Accessibility</a></li>
        </ul>
      </div>
    </div>
  </footer>
</div>
