import { Injectable } from '@angular/core';

import { allRecords, byId } from '../utils/contact-records.utils';

import { MailingAddressRecord } from './mailing-address';
import { Repo } from '../utils/repo';

@Injectable()
export class MailingAddressesMemoryAPI {
  private _repo: Repo<MailingAddressRecord> = { primary: null, others: [] };

  set repo(newRepo: Repo<MailingAddressRecord>) {
    this._repo = newRepo;
  }

  get allMailingAddresses(): MailingAddressRecord[] {
    return allRecords(this._repo);
  }

  getMailingAddresses(): Repo<MailingAddressRecord> {
    return this._repo;
  }

  addMailingAddress(params: Partial<MailingAddressRecord>): Repo<MailingAddressRecord> {
    const record: MailingAddressRecord = {
      lineOne: params.lineOne,
      city: params.city,
      stateTerritory: params.stateTerritory,
      zipCode: params.zipCode,
      type: params.type,
      id: params.id,
    };

    if (params.lineTwo) {
      record.lineTwo = params.lineTwo;
    }

    if (params.lineThree) {
      record.lineThree = params.lineThree;
    }

    this._repo.primary ? this._repo.others.push(record) : this._repo.primary = record;

    return this._repo;
  }

  markAsDefault(id: string): Repo<MailingAddressRecord> {
    const address = this.getAddressById(id);
    this.deleteOtherRecord(address);

    if (this._repo.primary) {
      this._repo.others.push(this._repo.primary);
    }

    this._repo.primary = address;

    return this._repo;
  }

  deleteMailingAddress(id: string): Repo<MailingAddressRecord> {
    const address = this.getAddressById(id);

    if (this._repo.primary === address) {
      this.deleteDefaultRecord();
    } else {
      this.deleteOtherRecord(address);
    }

    return this._repo;
  }

  updateMailingAddress(id: string, params: Partial<MailingAddressRecord>): Repo<MailingAddressRecord> {
    const address = this.getAddressById(id);
    const newAddress = Object.assign(address, params);

    if (this._repo.primary === address) {
      this._repo.primary = newAddress;
    } else {
      const idx = this._repo.others.findIndex(byId(id));
      this._repo.others[idx] = newAddress;
    }

    return this._repo;
  }

  private getAddressById(id: string): MailingAddressRecord {
    return this.allMailingAddresses.find(byId(id));
  }

  private deleteOtherRecord(address: MailingAddressRecord): void {
    this._repo.others = this._repo.others.filter((a) => a !== address);
  }

  private deleteDefaultRecord(): void {
    if (this._repo.others.length > 0) {
      const firstAddress = this._repo.others[0];
      this.deleteOtherRecord(firstAddress);
      this._repo.primary = firstAddress;
    } else {
      this._repo.primary = null;
    }
  }
}
