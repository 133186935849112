<span id="exitButton" (click)="closeDialog()"></span>
<div id="walletcard-edit-modal" style="padding: 0 25px;">
  <div class="form-title"> Remove Payment</div>

  <div class="bold wallet-card-remove-sub-header" >
    You are removing this card
  </div>
  <div>
    <div class= "wallet-card-remove-desc">
      <div class="wallet-card-remove-name-cc">{{nameOnCard}} </div>
      <div>{{nameType}} x{{lastFourDigits}}&nbsp;&nbsp;EXP: {{expiration}}</div>
    </div>

    <a  class="wallet-card-remove-link" (click)="closeAndDeleteDialog()">
      Yes, Remove it</a>
    <a  class="wallet-card-remove-link" (click)="closeDialog()">
      Cancel</a>
  </div>
</div>

