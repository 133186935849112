import { Component } from '@angular/core';
import { MailingAddressesService } from '../mailing-addresses.service';
import { ContactPreferencesNavigationService } from '../../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../../ui-components/alerts/alerts.service';
import { getUpdateSuccessMessage, serverError } from '../../utils/alerts.utils';
import { VerifyMailingAddressComponent } from './verify.component';
import { finalize } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MailingAddressRecord } from '../mailing-address';

@Component({
  selector: 'app-verify-existing-mailing-address',
  templateUrl: './verify.component.html'
})
export class VerifyExistingMailingAddressComponent extends VerifyMailingAddressComponent {

  constructor(
    protected service: MailingAddressesService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    private dialogRef: MatDialogRef<VerifyExistingMailingAddressComponent>,
    protected dialog: MatDialog,
  ) {
    super(service, navService, alertService);
  }

  openEditDialog(address: any): void {
    address.type = this.service.verificationResult.editedType;
    address.id = this.service.originalAddress.id;
    this.dialogRef.close(address);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onVerify(): void {
    this.dialogRef.close();
  }

  onFormSubmit() {
    const selectedAddress = this.service.verificationResult[this.model.selectedAddress];
    const addressType = this.service.verificationResult.editedType;
    const addressRecord = this.getAddressRecord(selectedAddress, addressType);
    const id = this.editingAddress.id;
    this.service.updateMailingAddress(id, addressRecord).pipe(
      finalize(() => {
        this.navService.setActiveSection(this.name, 'list');
      })
    ).subscribe(
      () => {
        this.alertService.displaySuccess(getUpdateSuccessMessage('mailing address'));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }
}
