import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface RadioOption {
  value: any;
  id: string;
  description?: string;
  text?: string;
}

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
})
export class RadioButtonsComponent {
  @Input() label: string;
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Input() buttonsClass: string;
  @Input() options: RadioOption[];
  @Input() tag: string;
}
