<span id="phone-numbers-list">
  <span class=phone-title>
    Phone
  </span>
  <div class="message" *ngIf="noContactNumbers()">
    Update with your primary phone number
  </div>
  <div *ngFor="let number of allContactNumbers">
    <span [ngClass]="calculateListStyle(number)">
      <mat-radio-button
      class="primary-radio-button"
      [checked]="isDefault(number)"
      (click)="onMarkDefault(number)"
      name = "phone_primary_rb"></mat-radio-button>

      <span *ngIf="isDefault(number)">{{ formatNumber(number) }}</span>
      <span *ngIf="!isDefault(number)" class="phonenumbers">{{ formatNumber(number) }}</span>
      <i class="contact-subtext" [ngClass]="{'hide-type': number.type === 'home-office' && modalService.isScreenMobile && number.extension}">({{toDisplayName(number.type)}}) </i>
      <app-link
          class="list-component-edit-link"
          [disabled]="buttonsDisabled"
          (click)="openEditDialog(number)">
          Edit
      </app-link>
    </span>
    <div *ngIf="number.type === 'home-office' && modalService.isScreenMobile && number.extension" class="mobile-home-office">({{toDisplayName(number.type)}})</div>
    <span *ngIf="number.smsOptIn" class="sms-opt-in">Opted in to SMS Messaging</span>
    <span *ngIf="!number.smsOptIn" class="sms-opt-in-blank"> </span>
  </div>
  <div class= phone__add-new-section (click)=openAddDialog()>
    <a class="phone__add-button"></a>
    <span>   Add New Phone Number</span>
  </div>
