import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TextMaskModule } from 'angular2-text-mask';
import { SpinnersModule } from '../ui-components/spinners/spinners.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { ButtonsModule } from '../ui-components/buttons/buttons.module';
import { ModalsModule } from '../ui-components/modals/modals.module';
import { SogiComponent } from './sogi.component';
import { SogiService } from './sogi.service';
import { ListSogiComponent } from './list.component';

// copied from faxModule
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    ButtonsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
  ],
  declarations: [
    SogiComponent,
    ListSogiComponent,
   ],
  exports: [
    ListSogiComponent,
  ],
  providers: [
    SogiService,
    ListSogiComponent,
  ]
})
export class SogiModule { }
