import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent {
  @Input() name: string;
  @Input() value: string;
  @Input() isChecked: boolean;
  @Input() displayName: string;
  @Output() checked = new EventEmitter<string>();
  @Output() unchecked = new EventEmitter<string>();

  onClick() {
    this.isChecked = !this.isChecked;
    if (this.isChecked) {
      this.checked.emit(this.value);
    } else {
      this.unchecked.emit(this.value);
    }
  }
}
