<div id="sogi-personal-details-section">
  <div class="sub-title"> Personal Details </div>
    <app-link
    class="list-component-edit-link"
    (click)="openEditDialog()">
    Edit
  </app-link>

  <div *ngIf="hasSOGIData">
    <div class="detail-element" *ngIf="!data.genderotherdesc && data.genderdesc">Gender Identity: <span>{{data.genderdesc}}</span></div>
    <div class="detail-element" *ngIf="data.genderotherdesc">Gender Identity: <span>Prefer to self-describe as {{data.genderotherdesc}}</span></div>

    <div class="detail-element" *ngIf="!data.sexualotherdesc && data.sexualdesc">Sexual Orientation: <span>{{data.sexualdesc}}</span></div>
    <div class="detail-element" *ngIf="data.sexualotherdesc">Sexual Orientation: <span>Prefer to self-describe as {{data.sexualotherdesc}}</span></div>

    <div class="detail-element" *ngIf="data.transgenderInd==='N'">Do you identify as transgender?: <span>No</span></div>
    <div class="detail-element" *ngIf="data.transgenderInd==='Y'">Do you identify as transgender?: <span>Yes</span></div>
    <div class="detail-element" *ngIf="data.transgenderInd==='P'">Do you identify as transgender?: <span>Prefer not to say</span></div>

  </div >
  <div class="blank-state" *ngIf="!hasSOGIData">
    Complete your profile with demographic details like gender identity and sexual orientation.
  </div>

</div>
