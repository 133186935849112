import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LinkComponent } from './link.component';
import { LinkDividerComponent } from './link-divider.component';

import { FormSubmitButtonComponent } from './form-submit-button.component';
import { CancelButtonComponent } from './cancel-button.component';
import { SubmitButtonComponent } from './submit-button.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    LinkComponent,
    LinkDividerComponent,
    FormSubmitButtonComponent,
    CancelButtonComponent,
    SubmitButtonComponent,
  ],
  exports: [
    LinkComponent,
    LinkDividerComponent,
    FormSubmitButtonComponent,
    CancelButtonComponent,
    SubmitButtonComponent,
  ],
})
export class ButtonsModule { }
