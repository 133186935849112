import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/users/user.service';
import { Router } from '@angular/router';

/**
 * This component exists to handle redirects from the root URL.
 * This is primarily meant to handle when a user is entering the application for the first time.
 */
@Component({
  template: ''
})
export class EntryPointComponent {
  constructor(
    private userService: UserService,
    private router: Router
    ) {
      // If we have a "subscribeToNewsletterAction" item in Local Storage
      // (which would have been added by the retrieve-authenticated-user-or-redirect.service.ts
      // route guard), redirect to the Newsletters page instead of the user's default
      // page.
      // First we read in and see if the local storage item should be expired.
      let shouldNavigateToNewsletter: boolean = false;
      let shouldNavigateToContacts: boolean = false;
      const newsletterAction = localStorage.getItem('subscribeToNewsletterAction');
      const emailAction = localStorage.getItem('emailUpdateAction');

      if (!!newsletterAction) {
        const now = new Date().getTime();
        const expiration = JSON.parse(newsletterAction).expiration;
        if (expiration > now) {
          shouldNavigateToNewsletter = true;
        } else {
          localStorage.removeItem('subscribeToNewsletterAction');
        }
      }

      if (!!emailAction ) {
        const now = new Date().getTime();
        const expiration = JSON.parse(emailAction).expiration;
        if (expiration > now) {
          shouldNavigateToContacts = true;
        } else {
          localStorage.removeItem('emailUpdateAction');
        }
      }

      if (shouldNavigateToNewsletter) {
        this.router.navigate(['newsletters'], { queryParams: null });
      } else if (shouldNavigateToContacts){
        this.router.navigate(['contact-preferences'], {queryParams: null});
      } else if (this.userService.isPhysician) {
        // MSOP-1255: If the user is a physician, resident, or student (i.e. has an ME number),
        // we now redirect them to the Membership page, *regardless* of whether they are a member
        // or not.
        // This is a modification from AR-220.
        this.router.navigate(['membership']);
      } else {
        this.router.navigate(['contact-preferences']);
      }
  }
}
