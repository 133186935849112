<div class="layout layout_one_up_center_container">
  <div class="section__header">
    <h3 class="margin-top-bottom">You have been unsubscribed from {{ subscribableTitle }}</h3>
  </div>
  <div class="layout_one_up_center centered text--small">
    <div class="layout_container">
      <form [formGroup]="surveyForm">
        <div *ngFor="let question of surveyQuestions">

          <div *ngIf="isSingleAnswerQuestion(question)">
            <app-single-answer
              [parentForm]="surveyForm"
              [question]="question"
            ></app-single-answer>
          </div>

          <div *ngIf="isMultipleAnswerQuestion(question)">
            <app-multiple-answer
              [parentForm]="surveyForm"
              [question]="question">
            </app-multiple-answer>
          </div>

          <div *ngIf="isTextQuestion(question)">
            <app-text-answer
              [parentForm]="surveyForm"
              [question]="question">
            </app-text-answer>
          </div>
        </div>

        <button type="button" (click)="onUnsubscribe()">Submit</button>
      </form>
    </div>
  </div>
</div>
