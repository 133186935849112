import * as _ from 'lodash';
import { ModalComponent } from './modal.component';
import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, startWith, map, refCount, publishReplay } from 'rxjs/operators';
import { calcModalWidth } from 'app/utils/modal.utils';

export const MOBILE_WIDTH_LIMIT = 768;

@Injectable()
export class ModalService {
  private modals: ModalComponent[] = [];
  public readonly isScreenMobile$ = fromEvent(window, 'resize').pipe(
    debounceTime(1000),
    startWith(),
    map(() => this.isScreenWidthMobileEnabled()),
    publishReplay(),
    refCount(),
    startWith(this.isScreenWidthMobileEnabled())
  );

  private _isScreenMobile = false;

  constructor() {
    this.isScreenMobile$.subscribe(isMobile => {
      this._isScreenMobile = isMobile;
    });
  }

  get isScreenMobile() {
    return this._isScreenMobile;
  }

  getAppropriateWidthProperty(initialWidth?: number): string {
    return this.isScreenMobile ? '100vw' : calcModalWidth(initialWidth || 550);
  }

  getAppropriateMaxWidthProperty() {
    return this.isScreenMobile ? '100vw' : '80vw';
  }

  getAppropriateHeightProperty(): string {
    return this.isScreenMobile ? '100vh' : 'auto';
  }

  getAppropriateMaxHeightProperty() {
    return this.isScreenMobile ? '100vh' : '80vh';
  }

  private isScreenWidthMobileEnabled() {
    const newWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return newWidth <= MOBILE_WIDTH_LIMIT;
  }

  add(modal: ModalComponent): void {
    this.modals.push(modal);
  }

  remove(id: string): void {
    const modalToRemove = this.findById(id);
    this.modals = _.without(this.modals, modalToRemove);
  }

  open(id: string): void {
    const modal = this.findById(id);
    if (modal) {
      modal.open();
    }
  }

  close(id: string): void {
    const modal = this.findById(id);
    if (modal) {
      modal.close();
    }
  }

  private findById(id: string): ModalComponent {
    return _.find(this.modals, { id: id });
  }
}
