import { Component, Input, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { EmailRecord } from './email-address';
import { EmailAddressesService } from './email-addresses.service';
import { IFormComponent } from '../IFormComponent';
import { ContactPreferencesNavigationService, ContactType } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { getUpdateSuccessMessage, serverError, getDeleteSuccessMessage } from '../utils/alerts.utils';
import { allSubscribables } from '../utils/subscriptions.utils';
import { ModalService } from '../ui-components/modals/modal.service';
import { FormBuilder } from '@angular/forms';
import { EmailFormComponent } from './form.component';
import { SubscribableRecord, SubscriptionsData } from '../subscriptions/subscriptions';
import { finalize } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultRepo } from 'app/utils/contact-records.utils';
import { Repo } from 'app/utils/repo';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-edit-email-address',
  templateUrl: './form.component.html',
})
export class EditEmailAddressComponent extends EmailFormComponent implements IFormComponent, OnInit {
  isEditMode = true;
  subscribed: boolean;

  @Input() model: EmailRecord;

  @Input() subscriptionData: SubscriptionsData = {
    numberOfSubscriptions: 0,
    categories: [],
  };

  private _emails: Repo<EmailRecord> = defaultRepo;
  name: ContactType = 'email';
  newEmail: string;
  buttonsDisabled = false;
  private dataLoaded: boolean;

  get allSubscribables() {
    return allSubscribables(this.subscriptionData);
  }

  constructor(
    private service: EmailAddressesService,
    private navService: ContactPreferencesNavigationService,
    private alertService: AlertsService,
    private modalService: ModalService,
    protected formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<EditEmailAddressComponent>,
    protected analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
) {
    super(formBuilder);
  }

  set emails(repo: Repo<EmailRecord>) {
    this.dataLoaded = true;
    this._emails = repo;
  }
  get emails(): Repo<EmailRecord> {
    return this._emails;
  }

  /**
   * This checks if the input email addr is the 'primary' email addr.
   */
  isDefault(id: string): boolean {
    if (!id || !this.emails.primary) {
      return false; // check if exist
    }
    return id === this.emails.primary.id;
  }

  ngOnInit() {
    this.model = this.data.model;

    this.emailForm.patchValue(this.model);
    this.emailForm.patchValue({address2: this.model.address});
    this.service.getEmails().subscribe(
      (emails) => {
        this.emails = emails as Repo<EmailRecord>;
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFormSubmit() {
    if (this.hasSubscriptions(this.model)) {
      const modalId = `update-${this.model.id}`;
      this.newEmail = this.emailForm.value.address;
      this.modalService.open(modalId);
    } else {
      this.onUpdate();
    }
  }

  onUpdate(event?) {
    this.disableButton();
    this.service.updateEmail(this.model.id, this.emailForm.value).pipe(
      finalize(() => {
        //this.onNoClick();
        this.navService.setActiveSection(this.name, 'list');
      })
    ).subscribe(
      () => {
        this.emailSent = true;
        this.alertService.displaySuccess("We've sent a verification email to your email address");
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  hasSubscriptions(email: EmailRecord): boolean {
    return this.numberOfSubscriptions(email) > 0;
  }

  subscriptionsForEmail(email: EmailRecord): SubscribableRecord[] {
    return this.allSubscribables.filter((s) => {
      return _.includes(s.subscribedEmailAddressIds, email.id);
    });
  }

  numberOfSubscriptions(email: EmailRecord): number {
    return this.subscriptionsForEmail(email).length;
  }

  onDelete(email: EmailRecord) {
    const emailRecord = email;
    if (!this.isDefault(email.id)) {
      this.service.deleteEmail(email.id).pipe(
        finalize(() => {
        //  this.isFinishedLoading();
        })
      ).subscribe(
        (emails) => {
          this.analyticsService.contactInfoUpdate('email', 'remove', '', emailRecord.address);
          this.emails = emails as Repo<EmailRecord>;
          this.alertService.displaySuccess(getDeleteSuccessMessage('email address'));
          this.onNoClick();
        },
        (err) => {
          this.alertService.displayError(err.message || serverError);
        }
      );
    }
  }

}
