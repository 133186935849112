<div class="newsletter-card" [ngClass]="{'member-border': requiresMembership}">

  <div class="newsletter-image">
    <app-members-banner *ngIf="requiresMembership"></app-members-banner>
    <img [src]="imageUrl">
  </div>


  <div class="newsletter-title"> {{ title }} </div>
  <div class="newsletter-description">{{ htmlDescription }}</div>

  <app-subscribable-button
    (subscribe)="subscribeCheckboxChecked()"
    (unsubscribe)="subscribeCheckboxUnchecked()"
    [subscribed]="isSubscribeCheckboxChecked"
    [membersOnly]="!isMember && requiresMembership"
  >
  </app-subscribable-button>

</div>
