import { Component, OnInit } from '@angular/core';
import { NavigationComponent } from '../navigation.component';
import { UserService } from '../../../users/user.service';
import { MembershipService } from 'app/membership-status/membership.service';
import { AutoRenewLoggingService } from 'app/auto-renew-logging/auto-renew-logging.service';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
})
export class MobileNavigationComponent extends NavigationComponent {
  isOpen = false;
  currentRouteName$ = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(this.route.snapshot),
      map(() => this.route.snapshot.firstChild ? this.route.snapshot.firstChild.data.sectionTitle : ''),
    );

  constructor(
    protected userService: UserService,
    protected membershipService: MembershipService,
    protected autoRenewLoggingService: AutoRenewLoggingService,
    private router: Router,
    public route: ActivatedRoute
  ) {
    super(userService, membershipService, autoRenewLoggingService);
  }

  toggleVisibility() {
    this.isOpen = !this.isOpen;
  }

  navigateToPage(route: string) {
    this.router.navigateByUrl(route);
    this.toggleVisibility();
  }

   /**
   * AR-23: If the Join/Renew iFrame is visible and a user navigates to a different tab,
   * make the iFrame not visible again if a user navigates back to the Membership page
   */
  private closeIframeIfOpen() {
    if (this.membershipService.showAlternateRatesIframe) {
      this.membershipService.showAlternateRatesIframe = false;
    }
  }
}
