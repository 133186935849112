<div class="layout">

  <div class="content">

    <app-card-header title="Contact Information"  hasPrimary="true">
      <app-phone-numbers></app-phone-numbers>
      <app-email-addresses *ngIf="!isAddressesListInModal"></app-email-addresses>
      <app-fax-numbers></app-fax-numbers>
    </app-card-header>

    <app-mailing-addresses></app-mailing-addresses>
    <app-name-change-link *ngIf="isPhysician"></app-name-change-link>
    <app-contact-us-link></app-contact-us-link>
  </div>
</div>
