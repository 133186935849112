
  <app-spinner *ngIf="showSpinner"></app-spinner>

      <app-subscription-emails-form
        [subscriptionEmails]="subscriptionEmails"
        [emailRepo]="emailRepo"
        [subscriptionData]="subscriptionData"
        (subscriptionEmailsChanged)="onSubscriptionEmailsChanged($event)">
      </app-subscription-emails-form>

        <div id="newsletter-card-section">
          <div *ngFor="let newsletter of sortNewslettersByAlpha(subscribedNewsletters)">
            <app-newsletter-card
              [subscribable]="newsletter"
              [subscriptionEmailAddresses]="subscriptionEmailAddresses"
              (updateSubscriptionData)="updateSubscriptionData()">
            </app-newsletter-card>
          </div>

          <div *ngFor="let newsletter of sortNewslettersByAlpha(unsubscribedNewsletters)">
            <app-newsletter-card
              [subscribable]="newsletter"
              [subscriptionEmailAddresses]="subscriptionEmailAddresses"
              (updateSubscriptionData)="updateSubscriptionData()"
              (subscribedNewsletterTitle)="scrollToNewsletter($event)">
            </app-newsletter-card>
          </div>
         </div>

