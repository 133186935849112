export type SexualOrientation =
'Straight/Heterosexual' | 'Gay or lesbian' | 'Bisexual' | 'Queer' | 'Prefer to self-describe as...' | 'Prefer not to say';

export type GenderIndentity =
'Female' | 'Male' | 'Non-binary/third gender' | 'Genderqueer' | 'Prefer to self-describe as...' | 'Prefer not to say';

export type YesNoMaybe =
'Yes' | 'No' | 'Prefer not to say';

export const SexualOrientationChoices: SexualOrientation[] = [
  'Straight/Heterosexual' , 'Gay or lesbian' , 'Bisexual' , 'Queer' , 'Prefer to self-describe as...' , 'Prefer not to say'
  ];

export const GenderIdentityChoices: GenderIndentity[] = [
  'Female' , 'Male' , 'Non-binary/third gender' , 'Genderqueer' , 'Prefer to self-describe as...' , 'Prefer not to say'
 ];

export const TransgenderChoices: YesNoMaybe[] = [
  'Yes' , 'No' , 'Prefer not to say'
];
