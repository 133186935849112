<span id="fax-numbers-list">
  <span class=fax-title>
    Fax
  </span>
  <div class="message" *ngIf="noContactNumbers()">
    Update with your primary fax number
  </div>
  <div *ngFor="let number of allContactNumbers" >

  <span class="detailedItems">
    <mat-radio-button
      class="primary-radio-button"
      [checked]="isDefault(number)"
      (click)="onMarkDefault(number)"
      name = "fax_primary_rb"></mat-radio-button>
    <span *ngIf="isDefault(number)">{{ formatNumber(number, this.name) }}</span>
    <span *ngIf="!isDefault(number)" class="faxnumbers">{{ formatNumber(number, this.name) }}</span>
    <i class="contact-subtext">({{toDisplayName(number.type)}}) </i>
    <app-link
    class="list-component-edit-link"
        [disabled]="buttonsDisabled"
        (click)="openEditDialog(number)">
        Edit
    </app-link>

  </span>

  </div>
  <div class= "fax__add-new-section" (click)="openAddDialog()">
    <a class="fax__add-button" id="add-button-icon"> </a>
    <span>   Add New Fax</span>
  </div>
</span>
