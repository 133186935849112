import { EventEmitter, OnInit, Output, Directive } from '@angular/core';

import { ContactNumberRecord } from './contact-number';
import { PhoneNumbersService } from '../phone-numbers/phone-numbers.service';
import { FaxNumbersService } from '../fax-numbers/fax-numbers.service';
import { IListComponent } from '../IListComponent';
import { ModalService } from '../ui-components/modals/modal.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { getDeleteSuccessMessage, getMarkDefaultSuccessMessage, serverError } from '../utils/alerts.utils';
import { allRecords, defaultRepo } from '../utils/contact-records.utils';
import { Repo } from '../utils/repo';

import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsService } from 'app/analytics/analytics.service';

const FORMAT = '$1-$2-$3';

@Directive()
export class ContactNumbersListComponent implements OnInit, IListComponent<ContactNumberRecord> {
  @Output() onEdit = new EventEmitter<ContactNumberRecord>();

  private _contactNumbers: Repo<ContactNumberRecord> = defaultRepo;
  name: ContactType;
  private dataLoaded;
  showSpinner = false;
  buttonsDisabled = false;

  set contactNumbers(repo: Repo<ContactNumberRecord>) {
    this.dataLoaded = true;
    this._contactNumbers = repo;
  }

  get contactNumbers() {
    return this._contactNumbers;
  }

  get allContactNumbers(): ContactNumberRecord[] {
    return allRecords(this.contactNumbers);
  }

  constructor(
    protected service: PhoneNumbersService | FaxNumbersService,
    protected modalService: ModalService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected dialog: MatDialog,
    protected analyticsService : AnalyticsService

  ) { }

  ngOnInit() {
    this.isLoading();
    this.service.getContactNumbers().subscribe(
      (numbers) => {
        this.contactNumbers = numbers;
        this.isFinishedLoading();
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
        this.isFinishedLoading();
      }
    );
  }

  isDefault(number: ContactNumberRecord): boolean {
    return number === this.contactNumbers.primary;
  }

  noContactNumbers(): boolean {
    return (this.allContactNumbers.length === 0) && this.dataLoaded;
  }

  onMarkDefault(number: ContactNumberRecord) {
    if ( this.isDefault(number)) {
      return;
    }
    this.isLoading();
    this.service.markAsDefault(number.id).pipe(
        finalize(() => {
          this.isFinishedLoading();
        })
      ).subscribe(
      () => {
         if (this.name.includes('fax number')){
          this.analyticsService.contactInfoUpdate('fax', 'make primary', number.type, number.number );
         }else{
          this.analyticsService.contactInfoUpdate('phone', 'make primary', number.type, number.number );
        }
        this.alertService.displaySuccess(getMarkDefaultSuccessMessage(this.name));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  async onStartEdit(number: ContactNumberRecord) {
    const cloned = Object.assign({}, number);
    this.onEdit.emit(cloned);
  }

  onDelete(number: ContactNumberRecord) {
    this.isLoading();
    this.service.deleteContactNumber(number.id).pipe(
        finalize(() => {
          this.isFinishedLoading();
        })
      ).subscribe(
      () => {
        this.alertService.displaySuccess(getDeleteSuccessMessage(this.name));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  formatNumber(number: ContactNumberRecord, contactType?: ContactType): string {
    const formattedNum = number.number.replace(/(\d{3})(\d{3})(\d{4})/, FORMAT);

    if (number.extension && contactType !== 'fax number') {
      return `${formattedNum}, ${this.formatExtension(number.extension)}`;
    }
    return formattedNum;
  }

  isVisible(): boolean {
    return this.navService.getActiveSection(this.name) === 'list';
  }

  private formatExtension(ext: string): string {
    return `ext. ${ext}`;
  }

  protected toDisplayName(numberType: any): string {
    return numberType;
  }

  protected isLoading() {
    this.showSpinner = true;
    this.buttonsDisabled = true;
  }

  protected isFinishedLoading() {
    this.showSpinner = false;
    this.buttonsDisabled = false;
  }
}
