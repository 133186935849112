
<span id="exitButton" class="pro-details-exit" (click)="onNoClick()"></span>

<form [formGroup]="professionalDetailsForm" (ngSubmit)="validateForm()">

  <fieldset id="professional-details-form">
    <div class="title"> Professional Details </div>

    <span class="form-section">
      <app-link
        class = "remove-link"
        *ngIf="isEditForm"
        (click)="onDelete(this.id); onNoClick()">
        Remove
      </app-link>

      <div class="subtitle">Practice Setting*</div>
      <select
        formControlName="practiceSettingName"
        id="practiceSettingName" class="dropdown-select"
        (change)="onPracticeSettingChange($event.target.value)"
        [ngClass]="{'has-error': practiceSettingName.touched && !practiceSettingName.valid }">
        <option [value]="''" disabled></option>
        <option *ngFor="let practiceSettingChoice of practiceSettingChoices" [value]="practiceSettingChoice">
          {{ practiceSettingChoice}}
        </option>
      </select>
      <label class="error-label" *ngIf="practiceSettingName.touched && practiceSettingName.errors && practiceSettingName.errors.required">
        This field is required.
      </label>

      <div class="subtitle">State*</div>
      <select
        formControlName="stateTerritory"
        id="stateTerritory" class="dropdown-select"
        (change) = "onStateChange($event.target.value)"
        [ngClass]="{'has-error': stateTerritory.touched && !stateTerritory.valid }">
        <option [value]="''" disabled></option>
        <option *ngFor="let stateChoice of stateChoices" [value]="stateChoice">
          {{ stateChoice }}
        </option>
      </select>
      <label class="error-label" *ngIf="stateTerritory.touched && stateTerritory.errors && stateTerritory.errors.required">
        This field is required.
      </label>

      <div class="subtitle">City*</div>
      <app-auto-complete
        autoCompleteFieldName="city"
        [parentFormGroup]="professionalDetailsForm"
        id="city"
        [disable] = "!filledState"
        (selectedOption)="onCityChange($event)"
        (change)="onCityChange($event)"
        [elements]="cityChoices"
        [ngClass]="{'has-error': city.touched && !city.valid }">
      </app-auto-complete>
      <label class="error-label" *ngIf="city.touched && city.errors && city.errors.required">
        This field is required.
      </label>

      <div *ngIf="showHospitalField">
        <div class="subtitle">Hospital/Institution*</div>
        <app-auto-complete
          autoCompleteFieldName="hospitalIdName"
          [parentFormGroup]="professionalDetailsForm"
          id="hospitalIdName"
          [disable]="!filledCity"
          (selectedOption)="onHospitalChange($event)"
          (change) ="hospitalTyped($event)"
          [elements]="hospitalChoices"
          [ngClass]="{'has-error': hospitalIdName.touched && !hospitalIdName.valid ||
          (hospitalIdName.touched && !inHospitalList(hospitalIdName.value))}"
          [isHospitalBasedList]="true">
        </app-auto-complete>
        <label class="error-label" *ngIf="hospitalIdName.touched && hospitalIdName.errors && hospitalIdName.errors.required ||
        (hospitalIdName.touched && !inHospitalList(hospitalIdName.value))">
          A selection is required.
        </label>
      </div>

      <div *ngIf="showEmploymentField">
        <div class="subtitle">Place of Employment*</div>
        <input
          formControlName="hospitalName"
          type="text" class="dropdown-select"
          [ngClass]="{'has-error': hospitalName.touched && !hospitalName.valid }">
        <label class="error-label" *ngIf="hospitalName.touched && hospitalName.errors && hospitalName.errors.required">
          This field is required.
        </label>
      </div>

      <div class="indicate-required">
        *indicates a required field
      </div>

      <div>
        <input class="button savebutton" type="submit" value="Save">
      </div>
      <div class="blue-cancel-link"  (click)="onNoClick()"> Cancel </div>
      <div *ngIf="isEditForm" class="mobile-remove" (click)="onDelete(id); onNoClick()">Remove</div>
    </span>
  </fieldset>
</form>
