import { Component } from '@angular/core';

import { PhoneNumbersService } from './phone-numbers.service';
import { ContactNumbersHomeComponent } from '../contact-numbers/home.component';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';

@Component({
  selector: 'app-phone-numbers',
  templateUrl: './home.component.html'
})
export class PhoneNumbersHomeComponent extends ContactNumbersHomeComponent {
  readonly title: String = 'Phone';
  name: ContactType = 'phone number';

  constructor(
    protected service: PhoneNumbersService,
    protected navService: ContactPreferencesNavigationService,
  ) {
    super(service, navService);
  }
}
