<span id="exitButton" (click)="onNoClick()" *ngIf="!isChangePrimaryModal"></span>
<form id=emailForm (ngSubmit)="validateForm()" [formGroup]="emailForm">
  <fieldset id="email-addr-dialog" [ngClass]="isChangePrimaryModalClass">
    <div class="email-title" *ngIf="!isChangePrimaryModal && !emailSent">
      Email
    </div>
    <div class="email-title" *ngIf="!isChangePrimaryModal && emailSent">
      Verify your Email Address
    </div>
    <div class="email-remove-link" *ngIf="model.id && !emailSent">
      <!-- I can remove a non primary email-->
      <app-link *ngIf="!isDefault(email.parent.value.id)"
      (click)="onDelete(email.parent.value); onNoClick()">
      Remove
      </app-link>

      <!--I cannot remove a primary email-->
      <div
        *ngIf="isDefault(email.parent.value.id) && !emailSent"
        matTooltip="Please choose another primary before removing."
        matTooltipClass="remove-tooltip"
        class="email-remove-link disabled">
        Remove
      </div>
    </div>
 
    <div class="form-section">
      <div *ngIf="!emailSent">
        <div class="email-field">
          <div class= "email-subtitle enter-email">
            <mat-radio-button
            *ngIf="isChangePrimaryModal"
            class="primary-radio-button new-email-radio"
            [checked]="true"
            ></mat-radio-button>
            <span>Enter Email*</span>
          </div>

          <input
            class="email-input" id="change-primary-modal-input"
            formControlName="address"
            type="email"
            [ngClass]="{'has-error error': email.touched && !email.valid }"
          >

          <label class="error" *ngIf="isNotPresent1()">
            This field is required.
          </label>
          <label class="error" *ngIf="isInvalid()">
            Must be a valid email address.
          </label>

        </div>

        <div [ngClass]="{'indicate-required': true, 'is-in-change-primary': isChangePrimaryModal}">
          *indicates a required field
        </div>

        <div *ngIf="model.id && isDefault(email.parent.value.id)" class="email-primary">
          This is my primary email address for receiving communications and newsletter subscriptions from AMA.
        </div>

        <div *ngIf="model.id">
          <app-update-email-modal
            [email]="model"
            [subscriptions]="subscriptionsForEmail(model)"
            [newEmail]="newEmail"
            (updateEmail)="onUpdate($event)">
          </app-update-email-modal>
        </div>

        <div>
          <input class="button savebutton" type="submit" value="Send Verification Email"
          [disabled]="disableButton()"
          [class.button--unsubscribe]="subscribed">
        </div>
      </div>
      <div *ngIf="emailSent" class="sent-email-dialog" style="padding-top: 25px;padding-bottom: 25px;">
        <b>A verification email has been sent to {{emailForm.controls['address'].value}}.</b>
        
        <div style="padding-top:20px;">
          <b>Please follow the link in your email to confirm your new email address.</b>
        </div>

        <div style="padding-top:20px;">
          <b><em>** Please allow up to 15 minutes to receive the email link depending on your service provider</em></b>
        </div>
      </div>

      <p *ngIf="!isChangePrimaryModal" class="close-link" (click)="onNoClick()">Cancel</p>
      <div
        *ngIf="isEditMode"
        matTooltip="Please choose another primary email before removing."
        [matTooltipDisabled]="!isDefault(email.parent.value.id)"
        matTooltipClass="mobile-remove-tooltip"
        matTooltipPosition="above"
        (click)="onDelete(email.parent.value);"
        class="button delete-contact-item-button"
        [ngClass]="{'disabled-contact-button': isDefault(email.parent.value.id)}">Remove</div>
      </div>
      

  </fieldset>
</form>
