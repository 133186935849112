import { Injectable } from '@angular/core';
import { ReferralService, ReferralSearchParams, ReferralSearchResult, ReferralInvitationParams } from './referral.service';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { states } from '../utils/states';
import { UserService } from 'app/users/user.service';
import * as _ from 'lodash';
import { EMAIL_PATTERN } from 'app/email-addresses/form.component';
import { serverError } from 'app/utils/alerts.utils';

@Injectable()
export class ReferralFormService {
  private _form;
  private _matchesForm;
  referralMatches: ReferralSearchResult[];
  isReferralSearchModalVisible = false;
  isReferralNoMatchModalVisible = false;
  stateOptions = _.map(states, (s) => {
    return { key: s.toString(), value: s.toString() }
  });

  get form(): FormGroup {
    return this._form;
  }

  get matchesForm(): FormGroup {
    return this._matchesForm;
  }

  get firstName(): AbstractControl {
    return this.form.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.form.get('lastName');
  }

  get state(): AbstractControl {
    return this.form.get('stateCode');
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get selectedMatch() {
    return this.matchesForm.get('matches');
  }

  get userName() {
    if (this.userService.isInGroup('physician') || this.userService.isInGroup('resident')) {
      return `Dr. ${this.userService.profile.lastName}`;
    } else {
        return `${this.userService.profile.firstName} `;
    }
  }

  get searchParams(): ReferralSearchParams {
    return {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      stateCode: this.state.value,
      email: this.email.value,
    }
  }

  constructor(
    private referralService: ReferralService,
    private alertService: AlertsService,
    private userService: UserService,
  ) {}

  setupBlankForm() {
    this._form = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      stateCode: new FormControl(null, Validators.required),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])),
    });
  }

  submitForm() {
    if (!this.form.valid) {
      for (const i in this.form.controls) {
        if (this.form.controls.hasOwnProperty(i)) {
          this.form.controls[i].markAsTouched();
          this.form.controls[i].markAsDirty();
        }
      }
    } else {
      this.referralService.getSearchResults(this.searchParams).subscribe(
        (results) => {
          if (_.isEmpty(results)) {
            this.isReferralNoMatchModalVisible = true;
            this.isReferralSearchModalVisible = false;
          } else {
            this.referralMatches = results;
            this.isReferralSearchModalVisible = true;
            this.isReferralNoMatchModalVisible = false;
          }
        },
        (err) => {
          this.alertService.displayError(serverError);
        }
      );
    }
  }

  setupMatchesForm() {
    this._matchesForm = new FormGroup({
      matches: new FormControl('', Validators.required),
    })
  }

  submitMatchesForm() {
    if (!this.matchesForm.valid) {
      this.matchesForm.markAsTouched();
    } else {
      const matchedRecord = this.findMatchedRecord(this.selectedMatch.value) || {};
      this.referralService.sendInvitation(this.buildInvitationParams(matchedRecord)).subscribe(
        () => {
          this.closeReferralSearchModal();
          this.setupBlankForm();
          this.alertService.displaySuccess('Your invitation email has been sent.');
        },
        (err) => {
          this.closeReferralSearchModal();
          this.alertService.displayError(serverError);
        }
      );
    }
  }

  submitNoMatchFound() {
    this.referralService.sendInvitation(this.buildInvitationParams({})).subscribe(
      () => {
        this.closeReferralNoMatchModal();
        this.setupBlankForm();
        this.alertService.displaySuccess('Your invitation email has been sent.');
      },
      (err) => {
        this.closeReferralNoMatchModal();
        this.alertService.displayError(serverError);
      }
    );
  }

  closeReferralSearchModal() {
    this.isReferralSearchModalVisible = false;
  }

  closeReferralNoMatchModal() {
    this.isReferralNoMatchModalVisible = false;
  }

  private buildInvitationParams(selectedMatch: ReferralSearchResult): ReferralInvitationParams {
    return {
      senderSsoId: this.userService.profile.uniqueId,
      senderFirstName: this.userService.profile.firstName,
      senderLastName: this.userService.profile.lastName,
      senderMeNumber: this.userService.profile.meNumber,
      senderSsoGroups: this.userService.profile.groups,
      receiverFirstName: selectedMatch.firstName ? selectedMatch.firstName : this.firstName.value,
      receiverLastName: selectedMatch.lastName ? selectedMatch.lastName : this.lastName.value,
      receiverMeNumber: selectedMatch.meNumber,
      receiverDateOfBirth: selectedMatch.dob,
      receiverEntityId: selectedMatch.entityId,
      receiverStateCode: selectedMatch.stateCode || this.state.value,
      receiverEmail: this.email.value,
      receiverPartyId: selectedMatch.partyId,
      matched: selectedMatch.id ? true : false,
      medicalSchoolName: selectedMatch.medicalSchoolName,
      personType: selectedMatch.personType,
    }
  }

  private findMatchedRecord(id: string) {
    return this.referralMatches.find((record: ReferralSearchResult) => {
      return record.id === id;
    });
  }
}
