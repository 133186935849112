<div id="sogi-edit-dialog-modal">

  <div>
    <div class="title"> Personal Details </div>
    <form class="sogi-form" [formGroup]="sogiForm"  (submit)="onSubmit()">
        <div class="disclaimer"> {{sogiDisclaimer}}  </div>

        <div class="subtitle"> Gender Identity </div>
        <div class="question"> What is your gender? </div>

        <div class=double-fields>
          <select formControlName="genderidentity" id="genderidentity" class="dropdown-select" (change)="onGenderChange($event.target.value)">
              <option [value]="''" disabled>Choose One</option>
              <option *ngFor="let genderidentitychoice of GenderIdentityChoices" [value]="genderidentitychoice.apiName">
                {{ genderidentitychoice.displayName }}
              </option>
          </select>

          <span *ngIf="isSelectedGenderOther">
            <input class='self-describe' formControlName="genderotherdesc" placeholder="Prefer to self-describe as* " (input)="onGIDescChange($event.target.value)"/>
            <label class="error" *ngIf="isSelectedGenderOtherDesc === false ">
                This field is required.
              </label>
          </span>
      </div>

        <div class="subtitle"> Sexual Orientation </div>
        <div class="question"> Which of the following best describes how you think of yourself? </div>

        <div class="double-fields">
          <select formControlName="sexualorientation" id="sexualorientation" class="dropdown-select" (change)="onSexualOrientChange($event.target.value)">
            <option [value]="''" disabled>Choose One</option>
            <option *ngFor="let sexualorientationchoice of SexualOrientationChoices" [value]="sexualorientationchoice.apiName">
              {{ sexualorientationchoice.displayName }}
            </option>
          </select>

          <span *ngIf="isSexualOrientOther">
            <input class='self-describe' formControlName="sexualorientationotherdesc" placeholder="Prefer to self-describe as* " (input)="onSODescChange($event.target.value)"/>
            <label class="error" *ngIf="isSexualOrientOtherDesc === false ">
                This field is required.
            </label>
          </span>
        </div>

        <div class="description">
          Transgender is an umbrella term that refers to people whose gender identity, expression or behavior is different from those typically associated with their assigned sex at birth. Other identities considered to fall under this umbrella can include non-binary, gender fluid and genderqueer—as well as many more.
        </div>
        <div class="question"> Do you identify as transgender? </div>

        <select formControlName="transgenderind" id="transgenderind" class="dropdown-select" >
          <option [value]="''" disabled>Choose One</option>
          <option *ngFor="let transgenderchoice of TransgenderChoices" [value]="transgenderchoice.apiName">
            {{ transgenderchoice.displayName }}
          </option>
        </select>

        <div class="save-section">
          <app-form-submit-button value="Save"></app-form-submit-button>
        </div>

        <div class="blue-cancel-link"  (click)="onNoClick()"> Cancel </div>
    </form>
  </div>
</div>
