<div id="premium-newsletter-modal">
  <div class="card__spinner" *ngIf="showSpinner">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!showSpinner">
    <span id="exitButton" class="premium-exit-button" (click)="onNoClick()"></span>
    <div class="center block">
      <div class="message-container">
        <div>This premium newsletter is available to AMA members.</div>
        <div>{{ message }}</div>
      </div>
      <div  class="join-button">
        <button (click)="onNoClick()" class="button cancel-button">Cancel</button>
        <a *ngIf="!isPhysician" (click)="onNoClick()" target="_blank" href="{{ upgradeProfileURL }}" class="button confirm-button">{{ title }}</a>
        <button *ngIf="isPhysician" (click)="openJoinRenewModal()" class="button confirm-button">{{ buttonText }}</button>
      </div>
    </div>
  </div>
</div>
