import { Injectable } from '@angular/core';
import { SogiAddRecord} from '../sogi/sogi-add';
import { SogiFindRecord } from '../sogi/sogi-find';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { AnalyticsService } from 'app/analytics/analytics.service';

export interface SogiAddAPIParams {
    uniqueId?: string;
    entityId?: number;
    genderIdentityDesc?: string;
    sexualOrientationDesc?: string;
    transgenderInd?: string;
    genderIdentityOtherDesc?: string;
    sexualOrientationOtherDesc?: string;
  }

const addurl = '/api/sogi-add';
const findurl = '/api/sogi-info';

@Injectable()
export class SogiService {

  constructor(public httpClient: HttpClient,
    private analyticsService: AnalyticsService) { }

  findSogiInfo(): Observable<SogiFindRecord> {
    return this.httpClient.get(findurl).pipe(
      map((response: ApiResponse<any>) => {
        return {
          entityId: response?.data.entityId,
          genderdesc: response?.data.genderIdentityDesc,
          sexualdesc: response?.data.sexualOrientationDesc,
          transgenderInd: response?.data.transgenderInd,
          genderotherdesc: response?.data.genderIdentityOtherDesc,
          sexualotherdesc: response?.data.sexualOrientationOtherDesc,
          sogidisclaimer: response?.data.sogiDisclaimer,
        };
      }));
  }

  updateSogiInfo(params: Partial<SogiAddRecord>): Observable<void> {
    const body = this.toAPIParams(params);
    this.analyticsService.personalInfoUpdate();
    return this.httpClient.put(addurl, body).pipe(
      map((response: ApiResponse<any>) => response?.data)
    );
  }

  private toAPIParams(params: Partial<SogiAddRecord>): SogiAddAPIParams {
    return {
      uniqueId: params.uniqueId,
      entityId: params.entityId,
      genderIdentityDesc: params.genderdesc,
      sexualOrientationDesc: params.sexualdesc,
      transgenderInd: params.transgenderind,
      genderIdentityOtherDesc: params.genderotherdesc,
      sexualOrientationOtherDesc: params.sexualorientationotherdesc,
    };
  }
}
