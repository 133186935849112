import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-maxlimit-cards-update',
  templateUrl: './alert-maxlimit-cards-update.component.html'
})
export class AlertMaxlimitCardsUpdateComponent implements OnInit {

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<AlertMaxlimitCardsUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
