import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
})
export class TermsAndConditionsModalComponent {
  constructor(private dialogRef: MatDialogRef<TermsAndConditionsModalComponent>) {}

  closeModal() {
    this.dialogRef.close();
  }
}
