import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CCardInfoFromWallet } from '../wallet.service';

@Component({
  selector: 'app-confirm-delete-card-modal',
  templateUrl: './confirm-delete-card-modal.component.html',
})
export class ConfirmDeleteCardInWalletModalComponent implements OnInit {

  model: Partial<CCardInfoFromWallet>;

  constructor(
      protected dialog: MatDialog,
      protected dialogRef: MatDialogRef<ConfirmDeleteCardInWalletModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
      this.model = this.data.model;
    }
    public get lastFourDigits(): string {
      return this.model.creditCard.lastFourDigits;
    }
    public get nameOnCard(): string {
      return this.model.creditCard.nameOnCard;
    }
    public get nameType(): string {
      return this.model.creditCard.type;
    }
    public get expiration(): string {
      return this.model.creditCard.expiration;
    }
    closeAndDeleteDialog () {
      this.dialogRef.close(`delete`);
    }

    closeDialog() {
      this.dialogRef.close(`nodelete`);
    }

}
