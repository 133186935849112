import { ButtonComponent } from './button.component';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cancel-button',
  template:
      `<button [class.link--disabled]="disabled" class="button--cancel">
                       <ng-content></ng-content>
                    </button>`
})
export class CancelButtonComponent extends ButtonComponent { }
