import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, throwError, of } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { serverError } from './utils/alerts.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  homeUrl = '/home';

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * by pass interceptor for canactive route guard  in /home page and root page so that
     * we can check the authenticated and unauthenticated user without http interceptor 
     * Preventing route guard at home page to redirecting it by interceptor to home page(which may cause infinite loop)
     */
    if(req.headers.has('skip-interceptor')){
      const headers = req.headers.delete('skip-interceptor');
      return next.handle(req.clone({headers}));
    }else{
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if (this.isUnauthorized(error.status)) {
              this.router.navigate([this.homeUrl]);
              return of(new HttpResponse({
                status: error.status,
                statusText: error.statusText,
                headers: error.headers,
                url: error.url,
              }));
            } else if (error.error && error.error.errors && error.error.errors[0] && this.isInUse(error.error.errors[0].code)) {
              return throwError(error);
            } else {
              return throwError(serverError);
            }
        }));
    }
  };

  private isUnauthorized(status: number): boolean {
    return status === 401;
  }

  private isInUse(code: string): boolean {
    return code === 'in-use';
  }
}
