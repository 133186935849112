<app-card-list [isVisible]="isVisible()" [showSpinner]="showSpinner">
   <div class="no-cards-in-wallet-msg"  *ngIf="noCreditCards()">
    You don't have any credit cards yet!
  </div>

  <div *ngFor="let cCardInfo of allCreditCards" class="card">
    <app-transition isVisible="true">
      <div class="address-top-info">
        <div>
          <mat-radio-button class="primary-radio-button" [checked]="isDefault(cCardInfo)" (click)="confirmChangeDefault($event, cCardInfo)" name="addr_primary_rb"></mat-radio-button>
          <div class="card-type-view">{{cCardInfo.creditCard.type}}</div>
          <div *ngIf="!isDigital(cCardInfo)" class="card-nbr-exp-view">x{{cCardInfo.creditCard.lastFourDigits}} &nbsp;&nbsp;
          EXP:
          <span *ngIf="cCardInfo.expirationDateFlag !== 'valid' else warningNotShow;" style="color:rgb(214, 82, 38);">
            {{cardExpirationMonth(cCardInfo)}}/{{cardExpirationYear(cCardInfo)}}
          </span>
          <ng-template #warningNotShow>
            {{cardExpirationMonth(cCardInfo)}}/{{cardExpirationYear(cCardInfo)}}
          </ng-template>

          <span *ngIf="cCardInfo.declinedCard " class="wallet-list-declined" >
            <span *ngIf="cCardInfo.expirationDateFlag === 'expired' else warningDeclined;">
              Expired
            </span>
            <ng-template #warningDeclined>
              Unable to process. Please update.
            </ng-template>
           </span>
          </div>
        </div>
        <app-link
          *ngIf="!isDigital(cCardInfo)"
          (click)="openEditDialog(cCardInfo)"
          [disabled]="buttonsDisabled"
          class="list-component-edit-link"
          >
          Edit
        </app-link>
      </div>

    </app-transition>

  </div>
  <div class= "addButton" (click)="openAddDialog()">
    <a class="addr__add-button"></a>
    <span>Add Payment</span>
  </div>
</app-card-list>
