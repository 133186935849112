<span class="membership_modal_close" (click)="close()"></span>
<div class="generic-form-modal-title">{{ formTitle }}</div>
<div id="join-now-modal" class="generic-form-modal-body">
  <div class="card__spinner" *ngIf="showSpinner">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!showSpinner">
    <div class="text-margin-25 form-message">
      <span class="text-font-smaller" *ngIf="isProductAutorenewable(), else nonrenewal_msg">
        <p class="align-left">For your convenience, we've set your account to auto renew.
          Select "View Additional Rates" below to see more dues and payment options.
        </p>
        <p class="align-left">Your dues (
          <span *ngIf="isHalfYearDiscount()" class="crossed-off-price">
            ${{ getOriginalPrice() }}&nbsp;
          </span>
          <span [ngClass]="isHalfYearDiscount() ? 'color-price': 'product-price-same-column'" >
            ${{ getPriceDisplayed() }}
          </span>
        ) will be charged to your preferred payment method:</p>
      </span>
      <ng-template #nonrenewal_msg>
        Your dues (
          <span *ngIf="isHalfYearDiscount()" class="crossed-off-price">
            ${{ getOriginalPrice() }}&nbsp;
          </span>
          <span [ngClass]="isHalfYearDiscount() ? 'color-price': 'product-price-same-column'" >
            ${{ getPriceDisplayed() }}
          </span>
        ) will be charged to your card.
      </ng-template>
    </div>

    <div class="payment">
      <div class="payment-title">Payment Information</div>
      <div class="empty-card-info">
        <div>
          <ama__validated-text-input__v1
            [formGroup]="formGroup"
            [itemLabel]="{name: 'Name on Card*'}"
            itemMaxLength="50"
            fieldName="nameOnCard"
            itemPlaceholder="">
          </ama__validated-text-input__v1>
          <div class="cc-input-container">
            <ama__validated-text-input__v1 class="credit-card-field"
                                           [formGroup]="formGroup"
                                           [itemLabel]="{name: 'Card Number*'}"
                                           fieldName="cardNumber"
                                           itemMaxLength="16"
                                           [fieldMask]="creditCardNumberMask"
                                           itemPlaceholder=""
                                           patternValidationMessage="Must be a valid credit card number">
            </ama__validated-text-input__v1>
            <div class="cc-icons-modal">
              <span class="visa-icon"></span>
              <span class="mc-icon"></span>
              <span class="amex-icon"></span>
            </div>
          </div>
        </div>
        <div>
          <ama__validated-text-input__v1
            [formGroup]="formGroup"
            [itemLabel]="{name: 'Expiration Date*'}"
            [fieldMask]="dateMask"
            fieldName="expirationDate"
            itemPlaceholder="MM-YYYY"
            patternValidationMessage="Must be a valid expiration date in MM-YYYY format">
          </ama__validated-text-input__v1>
          <div class="cc-icons">
            <span class="visa-icon"></span>
            <span class="mc-icon"></span>
            <span class="amex-icon"></span>
          </div>
        </div>
      </div>
      <div class="required-desc">*indicates a required field</div>
    </div>

    <div class="button-container">
      <button type="button" (click)="join()" class="button" [disabled]="buttonsDisabled" >{{ buttonText }}</button>
      <div (click)="this.dialogRef.close()" class="close-link"> Cancel </div>
      <button *ngIf="showAlternateRates" (click)="showIframe()" class="button-secondary">{{ secondaryButtonText }}</button>
    </div>
    <div class="product-footer">
      <div class="terms" *ngIf="isProductAutorenewable()">
        <div class="footer-msg" *ngIf="isHalfYearDiscount()">
          *The discounted dues are valid only for the {{getProductYear()}} membership year.
           Automatic renewal of your {{getProductYear() + 1}} membership will be at the full dues rate.
        </div>
        <div class="footer-terms">
          Please read the <a class="link" (click)="openTocModal()">terms and conditions</a> of automatic renewal authorization.
        </div>
      </div>
    </div>
  </div>
</div>
