import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { map } from 'rxjs/operators';
import { MembershipReceiptConfig } from 'config/membership-receipt.config';

/**
 * Membership Transaction History model.
 * This is returned from the API for use in the UI.
 */
export interface MembershipTransactionHistory {
  /**
   * Payment Id.
   */
  paymentId?: number,

  /**
   * Payment Date.
   */
  paymentDate?: Date,

  /**
   * Transaction Type.
   */
  transactionType?: string,

  /**
   * Payment Amount. May need to be buffered with zeroes after the decimal.
   */
  paymentAmount?: number,

  /**
   * Payment Method. Describes how the payment was made. Human-readable, but may need
   * to be proper-cased.
   */
  paymentMethod?: string,

  /**
   * Check Number.
   * If the Payment Method is CHECK, this will be populated.
   */
  checkNumber?: string,

  /**
   * Credit Card Number.
   * If the Payment Method is CC, this will be populated.
   */
  creditCardNumber?: string,

  /**
   * Credit Card Type.
   * If the Payment Method is CC, this will be populated.
   */
  creditCardType?: string,

  /**
   * The Membership Year this transaction history item applies to.
   */
  membershipYear?: number,
}

/**
 * Interface for the Membership Transaction History Service.
 */
export interface IMembershipTransactionHistoryService {
  /**
   * Gets the Membership Transaction History for the member with the passed ME Number.
   * @param meNumber ME Number to get Membership Transaction History for.
   */
  getMembershipTransactionHistory(): Observable<MembershipTransactionHistory[]>;

  /**
   * Prints the receipt associated with the selected Payment Id and ME Number.
   * @param paymentId Payment Id of the transaction to print a receipt for.
   * @param meNumber ME Number of the member to print a receipt for.
   * @param memberName Member Name to place on the receipt.
   */
  getReceipt(paymentId: number, memberName: string): Observable<any>;
}

/**
 * Implementation of the Membership Transaction History Service.
 * Wraps call to API and handles caching of data.
 */
@Injectable()
export class MembershipTransactionHistoryService implements IMembershipTransactionHistoryService {
  private _membershipTransactionHistory: MembershipTransactionHistory[];

  /**
   * Instantiates a new MembershipTransactionHistoryService using the supplied services.
   * @param httpClient HTTP Client for use.
   */
  constructor(protected httpClient: HttpClient,
    private config: MembershipReceiptConfig) {}

  /**
   * Gets the Membership Transaction History for the member with the passed ME Number.
   * @param meNumber ME Number to get Membership Transaction History for.
   */
  getMembershipTransactionHistory(): Observable<MembershipTransactionHistory[]> {
    if (this._membershipTransactionHistory) {
      return of(this._membershipTransactionHistory);
    }

    return this.httpClient.get('api/membership/transaction-history').pipe(
      map((response: ApiResponse<MembershipTransactionHistory[]>) => {
        this._membershipTransactionHistory = response?.data;
        return response?.data;
      })
    );
  }

  /**
   * Prints the receipt associated with the selected Payment Id and ME Number.
   * @param paymentId Payment Id of the transaction to print a receipt for.
   * @param meNumber ME Number of the member to print a receipt for.
   * @param memberName Member Name to place on the receipt.
   */
  getReceipt(paymentId: number, memberName: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Accept', this.config.memberhsipReceiptFileType);
    const params = new HttpParams().set('paymentId', paymentId.toString()).set('memberName', memberName);
    return this.httpClient.get('api/membership/transaction-receipt', {params: params, responseType: 'blob', headers: headers})
      .pipe(map((response) => {
        return {
          filename: this.config.membershipReceiptFileName,
          data: new Blob([response], {type: this.config.memberhsipReceiptFileType})
        }
      }));
  }
}
