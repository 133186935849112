import { Component, OnInit, Inject } from '@angular/core';
import { SexualOrientationChoices } from '../../app/utils/sogichoices';
import { GenderIdentityChoices } from '../../app/utils/sogichoices';
import {TransgenderChoices} from '../../app/utils/sogichoices';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { SogiService } from '../sogi/sogi.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { SogiAddRecord } from '../sogi/sogi-add';
import { serverError } from 'app/utils/alerts.utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const defaultSogiFindRecord = {
  genderdesc: null,
  sexualdesc: null,
};

@Component({
  selector: 'app-sogi',
  templateUrl: './sogi.component.html'
})
export class SogiComponent implements OnInit {
  readonly SexualOrientationChoices = mapToOptions(SexualOrientationChoices);
  readonly GenderIdentityChoices = mapToOptions(GenderIdentityChoices);
  readonly TransgenderChoices = mapToOptions(TransgenderChoices);
  sogiForm: FormGroup;
  buttonsDisabled = false;
  sogiDisclaimer: string;
  preferNotToSay = 'Prefer Not To Say';
  isSelectedGenderOther: boolean;
  isSelectedGenderOtherDesc: boolean;

  isSexualOrientOther: boolean;
  isSexualOrientOtherDesc: boolean;

  constructor(
    protected formBuilder: FormBuilder,
    public sogiService: SogiService,
    protected alertService: AlertsService,
    protected dialogRef: MatDialogRef<SogiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onGenderChange(newValue) {
    if (newValue === 'Prefer to self-describe as...') {
      this.isSelectedGenderOther = true;
    } else {
      this.isSelectedGenderOther = false;
    }

    this.validateGISelectedOther();
    if (!!this.sogiForm.get('isSelectedGenderOther')) {
      this.sogiForm.get('isSelectedGenderOther').updateValueAndValidity();
    }
  }

  onSexualOrientChange(newValue) {
    if (newValue === 'Prefer to self-describe as...') {
      this.isSexualOrientOther = true;
    } else {
      this.isSexualOrientOther = false;
    }

    this.validateSOSelectedOther();
    if (!!this.sogiForm.get('sexualorientationotherdesc')) {
     this.sogiForm.get('sexualorientationotherdesc').updateValueAndValidity();
    }
  }

  onSODescChange(newValue) {
    this.isSexualOrientOtherDesc = true;
  }

  onGIDescChange(newValue) {
    this.isSelectedGenderOtherDesc = true;
  }

  ngOnInit() {
    this.sogiForm = new FormGroup({
      genderidentity: new FormControl('', []),
      genderotherdesc: new FormControl('', Validators.compose([this.validateGISelectedOther()])),
      sexualorientation: new FormControl('', []),
      sexualorientationotherdesc: new FormControl('', Validators.compose([this.validateSOSelectedOther()])),
      transgenderind: new FormControl('', [])
    });

    // upon re-opening this modal, this res changes.
    try {
      let res = this.data.sogiRecord;

      this.sogiDisclaimer = res.sogidisclaimer;

      if (res.genderdesc != null) {
        this.sogiForm.patchValue({ genderidentity: res.genderdesc });
      }

      if (res.sexualdesc != null) {
        this.sogiForm.patchValue({ sexualorientation: res.sexualdesc });
      }

      if (res.transgenderInd != null) {
        this.sogiForm.patchValue({ transgenderind: this.YesNoMaybe(res.transgenderInd) });
      }

      if (res.genderotherdesc != null) {
        this.sogiForm.patchValue({ genderotherdesc: res.genderotherdesc });
        this.isSelectedGenderOther = true;
      }

      if (res.sexualotherdesc != null) {
        this.sogiForm.patchValue({ sexualorientationotherdesc: res.sexualotherdesc });
        this.isSexualOrientOther = true;
      }
    } catch (error) {
      this.alertService.displayError(serverError);
    }

  }

  YesNoMaybe(indicator: string): string {
    if (indicator === 'Y') {
      return 'Yes';
    }
    if (indicator === 'N') {
      return 'No';
    }
    if (indicator === 'P') {
      return 'Prefer not to say';
    } else { return null; }
  }

  protected disableButton() {
    this.buttonsDisabled = true;
  }

  get formData() {
    // The description values are sent only when Prefer to describe is selected. Hence the below if blocks.
    let genderOtherDesc: string;
    if (this.isSelectedGenderOther) {
      genderOtherDesc = this.sogiForm.get('genderotherdesc').value;
    }

    let sexualOrientDesc: string;
    if (this.isSexualOrientOther) {
      sexualOrientDesc = this.sogiForm.get('sexualorientationotherdesc').value;
    }

    const data: Partial<SogiAddRecord> = {
      genderdesc: this.sogiForm.get('genderidentity').value,
      sexualdesc: this.sogiForm.get('sexualorientation').value,
      transgenderind: this.sogiForm.get('transgenderind').value,
      genderotherdesc: genderOtherDesc,
      sexualorientationotherdesc: sexualOrientDesc,
    };
    return data;
  }

  validateSOSelectedOther(): ValidatorFn {
    return () => {
      if (this.isSexualOrientOther) {
        if (this.sogiForm.get('sexualorientationotherdesc').value === '') {
          return { isSexualOrientOtherDesc: false };
        } else {
          return null;
        }
      }
    };
  }

  validateGISelectedOther(): ValidatorFn {
    return () => {
      if (this.isSelectedGenderOther) {
        if (this.sogiForm.get('genderotherdesc').value === '') {
          return { isSelectedGenderOtherDesc: false };
        } else {
          return null;
        }
      }
    };
  }

  onNoClick() {
    this.dialogRef.close()
  }

  onSubmit() {
    if (this.sogiForm.valid) {
      this.onNoClick();
      this.sogiService.updateSogiInfo(this.formData).subscribe(
        () => {
          this.alertService.displaySuccess('Your personal information successfully saved');
        },
        (err) => {
          if (err.status === 412) {
            this.alertService.displayError(serverError);
          }
        }
      );
    } else {
      if (this.sogiForm.get('sexualorientationotherdesc').value === '') {
        this.isSexualOrientOtherDesc = false;
      }

      if (this.sogiForm.get('genderotherdesc').value === '') {
        this.isSelectedGenderOtherDesc = false;
      }

      this.alertService.displayError('Missing data in required fields');
      this.sogiForm.setErrors({ 'invalid': false });
    }
  }
}

export function mapToOptions<T>(options: T[]) {
  return options.map((option: T) => {
    return { 'displayName': option.toString(), 'apiName': option.toString() };
  });
}
