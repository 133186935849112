import { Component, Inject, Output, EventEmitter } from '@angular/core';

import { PhoneNumbersService } from './phone-numbers.service';
import { PhoneNumberType, dropdownPhoneNumberTypes } from './phone-number-types';
import { EditContactNumberComponent } from '../contact-numbers/edit.component';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { FormBuilder } from '@angular/forms';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { getDeleteSuccessMessage, serverError } from 'app/utils/alerts.utils';
import { UserService } from 'app/users/user.service';
import { ContactNumberRecord } from 'app/contact-numbers/contact-number';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-edit-phone-number',
  templateUrl: '../contact-numbers/form.component.html',
})
export class EditPhoneNumberComponent extends EditContactNumberComponent {
  @Output() deleteNumber = new EventEmitter<ContactNumberRecord>();

  readonly title: String  = 'Phone';
  readonly typesLabel: String = 'Phone Number Type*';
  readonly numberPlaceholder: String = 'Phone Number*';
  readonly extPlaceholder: String = 'Extension (optional)';
  numberTypes: PhoneNumberType[] = dropdownPhoneNumberTypes;
  name: ContactType = 'phone number';
  readonly isEditMode = true;

  constructor(
    protected service: PhoneNumbersService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected modalService: ModalService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<EditPhoneNumberComponent>,
    protected userService: UserService,
    protected analyticsService: AnalyticsService,
    // this data is injected from the list.component.ts
    @Inject(MAT_DIALOG_DATA) public data: any, // TODO Fix type
  ) {
    super(service, navService, alertService, formBuilder, userService, data, analyticsService);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmDelete(number) {
    if (this.canDelete(this.id.value)) {
      this.deleteNumber.emit(number);
      this.enableButton();
      this.onDelete(this.data.model);
      this.modalService.open(number.id); 
      this.onNoClick();
    }
  }

  onDelete(number) {
    this.service.deleteContactNumber(number.id).pipe(
        finalize(() => {
        })
      ).subscribe(
      () => {
      this.analyticsService.contactInfoUpdate('phone', 'remove', number.type, number.number );
        this.alertService.displaySuccess(getDeleteSuccessMessage(this.name));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }
}
