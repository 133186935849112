
<span id="exitButton" (click)="onNoClick()"></span>

<div class="generic-form-modal-title">
  {{title}}
</div>

<div class="generic-form-modal-body">
  <form [formGroup]="contactNumberForm" (ngSubmit)="validateForm()">
    <fieldset id="contact-numbers-dialog">
      <div class="phone-removeLink" *ngIf="!createNew && isPhone(title)">
        <!-- If I am a physician, I can remove a non primary phone number-->
        <app-link *ngIf="canDelete(id.value)"
        (click)="onConfirmDelete(number); onNoClick()">
        Remove
        </app-link>

        <!--If I am a physician, I cannot remove a primary phone number-->
        <div
          *ngIf="!canDelete(id.value)"
          matTooltip="Please choose another primary before removing."
          matTooltipClass="remove-tooltip"
          class="phone-removeLink disabled">
          Remove
        </div>
      </div>


      <div class="fax-removeLink" *ngIf="!createNew && isFax(title)">
        <app-link
          (click)="onConfirmDelete(number); onNoClick() ">
          Remove
        </app-link>
      </div>

      <div>
        <div class= "fax-subtitle">{{typesLabel}}</div>
          <select
            formControlName="type"
            class="watermark"
            [ngClass]="{ 'has-error error': (type.touched && !type.valid) || type.value === 'other' }">
            <option [value]="''" disabled></option>
            <option *ngFor="let type of numberTypes" [value]="type.apiName">
              {{ type.displayName }}
            </option>
          </select>

          <label class="error"  *ngIf="isNotPresent(type)">
            This field is required.
          </label>

          <label class="error"  *ngIf="isTypeOther(type)">
           "Other" is not supported. Please update type.
          </label>
        </div>

        <div>
          <div class="fax-subtitle">{{numberPlaceholder}}</div>
          <input
            type="tel"
            formControlName="number"
            [textMask]="{mask: numberMask, guide: false}"
            class="numInput"
            [ngClass]="{'has-error error': number.touched && !number.valid }">
          <span class="form-item_icon"></span>

          <label class="error" *ngIf="numberIsInvalid()">
            Must be a valid US {{ name }}.
          </label>

          <label class="error" *ngIf="isNotPresent(number)">
            This field is required.
          </label>
        </div>

        <!-- The extension field should only show for phone numbers that aren't mobile and no fax numbers -->
        <div *ngIf="!isFax(title) && !isMobile(number) && type.value !== ''">
          <div class="fax-subtitle"> Extension</div>
          <input
            class="extInput"
            type="tel"
            formControlName="extension"
            [textMask]="{mask: extensionMask, guide: false}">
        </div>

        <div class="indicate-required">
          *indicates a required field
        </div>
        <div *ngIf="isDefault(id.value) && isPhone(title)" class="phone-isPrimary">
            This is my primary phone number for receiving telephone communications from AMA.
        </div>
        <div *ngIf="isDefault(id.value) && isFax(title)" class="fax-subtitle">
          This is my primary fax number for receiving fax communications from AMA.
      </div>

      <div class="phone_opt-in-section" *ngIf="isPhone(title) && isMobile(number)">
        <mat-checkbox class="phone_checkbox" formControlName="smsOptIn"> Opt-in to Text Communications</mat-checkbox>
        <div class="phone_rates-apply"> Yes, I’d like to receive text messages about renewals, specials offers, and breaking health care news. Msg&Data rates may apply. Up to 4 msgs/month. Text <span style="font-weight: bold; color:black">STOP</span> to cancel and <span style="font-weight: bold;color:black">HELP</span> for help.
           View our <a class="link" href="https://www.ama-assn.org/general-information/general-information/privacy-policy" target="_blank">privacy policy</a>
           and <a class="link" href="https://www.ama-assn.org/general-information/general-information/terms-use" target="_blank">Mobile Terms & Conditions</a>
        </div>
      </div>
      <div>
        <input class="button savebutton" type="submit" value="Save"
          [disabled]="buttonsDisabled"
          [class.button--unsubscribe]="subscribed">
      </div>
    </fieldset>
  </form>
  <p class="close-link" (click)="onNoClick()">Cancel</p>
  <div
    *ngIf="isEditMode"
    matTooltip="Please choose another primary phone number before removing."
    [matTooltipDisabled]="canDelete(id.value)"
    matTooltipClass="mobile-remove-tooltip"
    matTooltipPosition="above"
    (click)="onConfirmDelete(number)"
    class="button delete-contact-item-button"
    [ngClass]="{'disabled-contact-button': !canDelete(id.value)}">Remove</div>
</div>
