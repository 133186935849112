import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-auth',
  template: '',
})
export class UserAuthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href = '/authentication/login';
  }

}
