import { Component, Input, EventEmitter, Output } from '@angular/core';
import { RadioButtonsComponent } from 'app/ui-components/forms/radio-buttons.component';
import { AbstractControl } from '@angular/forms';

export interface SubcategoryRadioOptions {
  value: any;
  id: string;
  text?: string;
}

export interface IncentiveRadioOptions {
  value: any;
  id: string;
  description?: string;
  text?: string;
  imageUrl: string;
  subcategories: SubcategoryRadioOptions[];
}

@Component({
  selector: 'app-radio-buttons-incentive',
  templateUrl: './radio-buttons-incentive.component.html',
})
export class RadioButtonsIncentiveComponent extends RadioButtonsComponent {
  @Input() incentiveOptions: IncentiveRadioOptions[];
  @Input() subfieldName: string;
  @Input() isDisabled: boolean;
  @Input() showInput: boolean = false;
  @Input() preSelectedIncentive: number;
  @Output() incentiveClicked = new EventEmitter<any>();

  ngOnInit() {
    if(this.preSelectedIncentive){
      this.incentiveFormControl.setValue(this.preSelectedIncentive);
    }
  }

  updateSubcategory() {
    this.subcategoryFormControl.setValue('');
    this.subcategoryFormControl.markAsUntouched();
  }

  incentiveSelected(option){
    if(this.incentiveFormControl.value != option.value ){
      this.incentiveFormControl.setValue(option.value);
      this.updateSubcategory();
    }
    /**
     * we are using incentive click event emit so that when non selective incentive is used at membership page,
     * it could be used to open auto renew modal
     */
    this.incentiveClicked.emit(option);
  }

  displayIncentiveSubcategories(id): boolean {
    if (this.hasIncentiveSubcategory(id)) {
      const selectedIncentiveId = this.incentiveFormControl.value;
      return selectedIncentiveId === id;
    } else {
      return false;
    }
  }

  get showLabel(): boolean {
    return this.incentiveOptions.length > 1;
  }

  hasIncentiveSubcategory(id: string): boolean {
    const incentive = this.incentiveOptions.find(option => {
      return option.value === id;
    });

    if (incentive) {
      return incentive.subcategories.length > 0;
    }

    return false;
  }

  invalidIncentive() {
    return this.incentiveFormControl.hasError('required') &&
      this.shouldShowValidation(this.incentiveFormControl);
  }

  invalidSubcategory() {
    return this.subcategoryFormControl.hasError('required') &&
      this.shouldShowValidation(this.subcategoryFormControl);
  }

  shouldShowValidation(formControl: AbstractControl) {
    if (formControl.validator) {
      return formControl.touched;
    } else {
      return formControl.value !== '' && formControl.value !== undefined;
    }
  }

  get subcategoryDropdownClass() {
    const isValid = this.subcategoryFormControl.valid && this.shouldShowValidation(this.subcategoryFormControl);
    const isTouched = this.subcategoryFormControl.touched;
    return {
      'is-valid valid': isValid,
      'has-error error': !isValid && isTouched,
    };
  }

  get incentiveFormControl(): AbstractControl {
    return this.formGroup.get('incentiveOption');
  }

  get subcategoryFormControl(): AbstractControl {
    return this.formGroup.get('subcategoryOption');
  }
}
