import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html'
})
export class CardListComponent {
  @Input() isVisible: boolean;
  @Input() showSpinner = false;
}
