<span class="membership_modal_close" (click)="close()"></span>
<div class="generic-form-modal-title">{{ formTitle }}</div>
<div id="join-now-modal" class="generic-form-modal-body">
  <div class="card__spinner" *ngIf="showSpinner">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!showSpinner">
    <div class="form-message">
      <span class="text-font-smaller" *ngIf="isProductAutorenewable(), else nonrenewal_msg">
        <p class="align-left">For your convenience, we've set your account to auto renew.
          Select "View Additional Rates" below to see more dues and payment options.
        </p>
        <p class="align-left">Your dues (
          <span *ngIf="isHalfYearDiscount()" class="crossed-off-price">
            ${{ getOriginalPrice() }}&nbsp;
          </span>
          <span [ngClass]="isHalfYearDiscount() ? 'color-price': 'product-price-same-column'" >
            ${{ getPriceDisplayed() }}
          </span>
          ) will be charged to your preferred payment method:</p>
      </span>
      <ng-template #nonrenewal_msg>
        Your dues (
          <span *ngIf="isHalfYearDiscount()" class="crossed-off-price">
            ${{ getOriginalPrice() }}&nbsp;
          </span>
          <span [ngClass]="isHalfYearDiscount() ? 'color-price': 'product-price-same-column'" >
            ${{ getPriceDisplayed() }}
          </span>
        ) will be charged to your card.
      </ng-template>
    </div>


    <div class="form-message" style="margin-top:20px" >Payment Options</div>

    <div class="button-container" style="margin-top:20px" >


      <div  *ngIf="appleAvailable" class="appleButtonDiv" >
        <div id="ckoApplePay" style="-apple-pay-button-style: black;" class="apple-pay-button apple-pay-button-black-with-text apple-pay-button-text-buy" lang=en></div>
        <span id="applePrice" style= "display: none;">{{amount}}</span>
        <span id="appleOrderToSubmit" style= "display: none;">{{getDataToSubmit()}}</span>
        <span id="appleResponse" style= "display: none;" (domChange)="onDomChange($event)"></span>
      </div>

      <br *ngIf="appleAvailable">
      <br *ngIf="appleAvailable">

      <div id="googleDiv" *ngIf="!walletService.disableGoogle && showGoogleButton && googleAvailable" >

        <google-pay-button
          [environment]="googleEnvironment.environment"
          [buttonType]="buttonType"
          [buttonColor]="buttonColor"
          [buttonLocale]="buttonLocale"
          [existingPaymentMethodRequired]="existingPaymentMethodRequired"
          [paymentRequest]="{
              apiVersion: paymentRequest.apiVersion,
              apiVersionMinor: paymentRequest.apiVersionMinor,
              allowedPaymentMethods: paymentRequest.allowedPaymentMethods,
              merchantInfo: paymentRequest.merchantInfo,
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: amount,
                currencyCode: 'USD',
                countryCode: 'US'
              },
              callbackIntents: ['PAYMENT_AUTHORIZATION']
            }"
          (loadpaymentdata)="onLoadPaymentData($event)"
          (readytopaychange)="onReadyToPayChange($event)"
          (error)="onError($event)"
          [paymentAuthorizedCallback]="onPaymentDataAuthorized"
          style="width: 100%;"
        ></google-pay-button>

        <br>
        <br>
      </div>

      <button type="button" (click)="newCreditCard()" class="button" style="font-size: 0.7em !important;height: 38px">Buy with Credit Card</button>

    </div>

    <div class="button-container">
      <div (click)="this.dialogRef.close()" class="close-link"> Cancel </div>
      <button *ngIf="showAlternateRates" (click)="showIframe()" class="button-secondary" style="margin-top: 10px;">{{ secondaryButtonText }}</button>
    </div>
    <div class="product-footer">
      <div class="terms" *ngIf="isProductAutorenewable()">
        <div  class="footer-msg" *ngIf="isHalfYearDiscount()">
          *The discounted dues are valid only for the {{getProductYear()}} membership year.
           Automatic renewal of your {{getProductYear() + 1}} membership will be at the full dues rate.
        </div>
        <div class="footer-terms">
          Please read the <a class="link" (click)="openTocModal()">terms and conditions</a> of automatic renewal authorization.
        </div>
      </div>
    </div>
  </div>
</div>
