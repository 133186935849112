import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StandardSurveyQuestion } from '../survey-type-definitions';

export interface AnswerOption {
  id: string;
  text: string;
}

@Component({
  selector: 'app-single-answer',
  templateUrl: 'single-answer.component.html',
})
export class SingleAnswerComponent implements OnInit {
  @Input() question: StandardSurveyQuestion;
  @Input() parentForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    const questionFormControl = this.formBuilder.control('');
    this.parentForm.addControl(this.question.id, questionFormControl);
  }

  getAnswerId(answer): string {
    return `${this.question.id}-${answer.id}`
  }
}
