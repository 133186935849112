import { SubscribableRecord, SubscriptionsData } from '../subscriptions/subscriptions';

export function allSubscribables(subscriptionData: SubscriptionsData): SubscribableRecord[] {
  const subscribables: SubscribableRecord[] = [];
  for (const category of subscriptionData.categories) {
    for (const subscribable of category.subscribables) {
      subscribables.push(subscribable);
    }
  }

  return subscribables;
}
