import * as _ from 'lodash';
import * as stringUtils from 'app/utils/string.utils';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { EmailRecord } from 'app/email-addresses/email-address';
import { SubscriptionsService } from 'app/subscriptions/subscriptions.service';
import { SubscribableRecord } from 'app/subscriptions/subscriptions';
import { UserService } from 'app/users/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { calcModalWidth, calcModalHeight } from 'app/utils/modal.utils';
import { PremiumNewsLetterModalModalComponent } from './modals/premium-newsletter-modal.component';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { map } from 'rxjs/operators';
import { MailingAddressesService } from 'app/mailing-addresses/mailing-addresses.service';
import { UnitedStatesResidentValidationModalComponent } from './modals/united-states-resident-validation-modal.component';
import { MembershipService } from 'app/membership-status/membership.service';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { AnalyticsService } from 'app/analytics/analytics.service';
import {isUndefined} from 'lodash';

function isEqualIgnoringOrder<T>(a1: T[], a2: T[]): boolean {
  return _.isEqual([...a1].sort(), [...a2].sort());
}

@Component({
  selector: 'app-newsletter-card',
  templateUrl: './newsletter-card.component.html',
})
export class NewsletterCardComponent implements OnInit {
  @Input()
  public subscribable: SubscribableRecord;

  @Input()
  public subscriptionEmailAddresses: EmailRecord[];

  @Output()
  public updateSubscriptionData = new EventEmitter<void>();

  @Output()
  public subscribedNewsletterTitle? = new EventEmitter<string>();

  public isChangeButtonDisabled: boolean;
  public isSpinnerVisible: boolean;
  public isSubscribeCheckboxVisible: boolean;
  public isEmailAddressSelectionVisible: boolean;
  public isUpdateButtonDisabled: boolean;
  public isServiceErrorMessageVisible: boolean;
  public showUnsubscribeSurvey: boolean;

  private _temporaryHeading: string;
  private _emailAddressIdSelections: string[];

  constructor(
    private subscriptionsService: SubscriptionsService,
    private userService: UserService,
    protected dialog: MatDialog,
    private modalService: ModalService,
    private mailingAddressService: MailingAddressesService,
    private membershipService: MembershipService,
    private alertService: AlertsService,
    private analyticsService: AnalyticsService
    ) {
    this.enterStableState();
  }

  public ngOnInit() {
    /* If this subscribable was marked as shouldSubscribeOnLogin (in the subscribable.component.ts),
    * and we are not subscribed already, mark shouldSubscribeOnLogin as false and launch the
    * subscribe using default email address routine (which is the only one used as of
    * 18-March-2020). This will properly handle all cases for whether a user is a member or not.
    *
    *  The subscribeDefaultEmailAddress modal can open a matDialogModal if the user is not a member.
    *  In order to handle the below error, We add a setTimeout to force the opening of the model to happen after the ngoninit
    *  { ExpressionChangedAfterItHasBeenCheckedError:
    *   Expression has changed after it was checked. Previous value: 'id: undefined'. Current value: 'id: mat-dialog-0' ... }
    *  further explanation can be found here https://github.com/angular/components/issues/5268
    */
    if (this.subscribable.shouldSubscribeOnLogin && !this.isSubscribed()) {
      this.subscribable.shouldSubscribeOnLogin = false;
      setTimeout(() => this.subscribeDefaultEmailAddress());
    }
  }

  public get heading(): string {
    if (this._temporaryHeading) {
      return this._temporaryHeading;
    } else {
      return this.isSubscribed()
        ? 'Subscribed with ' + this.renderedAddressListForEmailAddressIds(...this.subscribable.subscribedEmailAddressIds)
        : 'Not subscribed';
    }
  }

  public get title(): string {
    return this.subscribable.title;
  }

  public get htmlDescription(): string {
    return this.subscribable.htmlDescription || '';
  }

  public get isChangeButtonVisible(): boolean {
    return this.subscriptionEmailAddresses.length > 1;
  }

  public get isSubscribeCheckboxChecked(): boolean {
    return this.isSubscribed();
  }

  public get subscribeCheckboxLabel(): string {
    return this.isSubscribed()
      ? 'Subscribed'
      : 'Click to Subscribe';
  }

  /**
   * Gets the Image URL from the subscribable.
   */
  public get imageUrl(): string {
    return this.subscribable.imageUrl;
  }

  /**
   * Gets whether this subscribable requires a membership to subscribe to.
   */
  public get requiresMembership(): boolean {
    return this.subscribable.requiresMembership;
  }

  public get isMember(): boolean {
    return this.userService.isInGroup('member');
  }

  /**
   * Gets whether this user has an address. Since we only allow entry and display of US addresses,
   * if the user has a mailing address, we can assume they are in the United States.
   */
  public get isInUnitedStates(): boolean {
    if (!!this.mailingAddressService.mailingAddresses) {
    return !!this.mailingAddressService.mailingAddresses.primary;
    } else {
      return false;
    }
  }

  public changeButtonClicked(): void {
    this.enterEditingState();
  }

  public cancelLinkClicked(): void {
    this.enterStableState();
  }

  public subscribeCheckboxChecked(): void {
    this.subscribeDefaultEmailAddress();
  }

  public subscribeCheckboxUnchecked(): void {
    this.unsubscribeAllEmailAddresses();
  }

  public updateButtonClicked(): void {
    if (isEqualIgnoringOrder(this._emailAddressIdSelections, this.subscribable.subscribedEmailAddressIds)) {
      this.enterStableState();
    } else {
      this.updateSubscriptions(...this._emailAddressIdSelections);
    }
  }

  public emailAddressCheckboxChecked(emailAddress: EmailRecord): void {
    this._emailAddressIdSelections = [...this._emailAddressIdSelections, emailAddress.id];
  }

  public emailAddressCheckboxUnchecked(emailAddress: EmailRecord): void {
    this._emailAddressIdSelections = this._emailAddressIdSelections.filter(id => id !== emailAddress.id);
  }

  public address(emailAddress: EmailRecord): string {
    return emailAddress.address;
  }

  public id(emailAddress: EmailRecord): string {
    return emailAddress.id;
  }

  public isEmailAddressSubscribed(emailAddress: EmailRecord): boolean {
    return this.subscribable.subscribedEmailAddressIds.includes(emailAddress.id);
  }

  public openJoinDialog(): void {
    this.dialog.open(PremiumNewsLetterModalModalComponent, {
      width: this.modalService.isScreenMobile ? '300px' : calcModalWidth(580),
      maxHeight: this.modalService.isScreenMobile ? '400px' : calcModalHeight(),
      panelClass: 'height-fit-content',
      autoFocus: false,
    });
  }

  private subscribeDefaultEmailAddress() {
    const emailAddress = this.subscriptionEmailAddresses[0];
    if (!this.isMember && this.requiresMembership) {
      this.openJoinDialog();
    } else {
      // If the user does not have an address on file, and this
      // newsletter requires US residency, open the dialog and ask
      // them to confirm residency. If they confirm, subcribe them
      if (!this.isInUnitedStates && this.subscribable.requiresUSResidency) {
        this.dialog.open(UnitedStatesResidentValidationModalComponent, {
          width: this.modalService.isScreenMobile ? '300px' : calcModalWidth(580),
          maxHeight: this.modalService.isScreenMobile ? '400px' : calcModalHeight(),
          panelClass: 'height-fit-content',
          autoFocus: false,
          data: {
            newsletterTitle: this.subscribable.title
          }
        }).afterClosed().subscribe((data) => {
          if (data === 'accepted') {
            this.subscriptionsService.updateResidency({
              emailAddress: emailAddress.address,
              entityId: this.membershipService.paymentInfo.entityId,
            }).subscribe(() => {
              this.updateSubscriptions(this.subscriptionEmailAddresses[0].id);
            });
          }
        });
      // If the user does have an address on file and this newsletter requires
      // US residency, update their residency and subscribe.
      } else if (this.isInUnitedStates && this.subscribable.requiresUSResidency) {
        this.subscriptionsService.updateResidency({
          emailAddress: emailAddress.address,
          entityId: this.membershipService.paymentInfo.entityId,
        }).subscribe(() => {
          this.updateSubscriptions(this.subscriptionEmailAddresses[0].id);
        });
      // If this newsletter does not require US residency, subscribe them.
      } else {
        this.updateSubscriptions(this.subscriptionEmailAddresses[0].id);
      }
    }
  }

  private unsubscribeAllEmailAddresses() {
    this.updateSubscriptions();
  }

  private isSubscribed(): boolean {
    return !!this.subscribable.subscribedEmailAddressIds.length;
  }

  private enterStableState(): void {
    this._emailAddressIdSelections = undefined;
    this._temporaryHeading = undefined;
    this.isChangeButtonDisabled = false;
    this.isSpinnerVisible = false;
    this.isSubscribeCheckboxVisible = true;
    this.isEmailAddressSelectionVisible = false;
    this.isUpdateButtonDisabled = true;
    this.isServiceErrorMessageVisible = false;
  }

  private enterSubscribingState(ids: string[]): void {
    this._emailAddressIdSelections = undefined;
    this._temporaryHeading = `Subscribing with ${this.renderedAddressListForEmailAddressIds(...ids)}...`;
    this.isChangeButtonDisabled = true;
    this.isSpinnerVisible = true;
    this.isSubscribeCheckboxVisible = false;
    this.isEmailAddressSelectionVisible = false;
    this.isUpdateButtonDisabled = true;
    this.isServiceErrorMessageVisible = false;
    this.showUnsubscribeSurvey = false;
  }

  private enterUnsubscribingState(): void {
    this._emailAddressIdSelections = undefined;
    this._temporaryHeading = 'Unsubscribing...';
    this.isChangeButtonDisabled = true;
    this.isSpinnerVisible = true;
    this.isSubscribeCheckboxVisible = false;
    this.isEmailAddressSelectionVisible = false;
    this.isUpdateButtonDisabled = true;
    this.isServiceErrorMessageVisible = false;
    this.showUnsubscribeSurvey = false;
  }

  private enterEditingState(): void {
    this._emailAddressIdSelections = [...this.subscribable.subscribedEmailAddressIds];
    this._temporaryHeading = undefined;
    this.isChangeButtonDisabled = true;
    this.isSpinnerVisible = false;
    this.isSubscribeCheckboxVisible = false;
    this.isEmailAddressSelectionVisible = true;
    this.isUpdateButtonDisabled = false;
    this.isServiceErrorMessageVisible = false;
    this.showUnsubscribeSurvey = false;
  }

  private enterServiceErrorState(): void {
    this._emailAddressIdSelections = undefined;
    this._temporaryHeading = undefined;
    this.isChangeButtonDisabled = false;
    this.isSpinnerVisible = false;
    this.isSubscribeCheckboxVisible = true;
    this.isEmailAddressSelectionVisible = false;
    this.isUpdateButtonDisabled = true;
    this.isServiceErrorMessageVisible = true;
  }

  private updateSubscriptions(...ids: string[]) {
    const sortedIds = this.subscriptionEmailAddresses
      .map(emailAddress => emailAddress.id)
      .filter(id => ids.includes(id));

    if (ids.length === 0) {
      this.enterUnsubscribingState();
      this.alertService.displaySuccess(`You've successfully unsubscribed from ${this.subscribable.title}.`);
    } else {
      this.enterSubscribingState(sortedIds);
      this.alertService.displaySuccess(`You've successfully subscribed to ${this.subscribable.title}.`);
    }

    this.subscriptionsService.update({
      subscribableId: this.subscribable.id,
      emailAddressIds: ids
    }).subscribe(
      () => {
        this.subscribable.subscribedEmailAddressIds = sortedIds;
        this.enterStableState();
        this.updateSubscriptionData.emit();
        if (ids.length === 0) {
          this.analyticsService.emailUnSubscribe(this.subscribable.title)
          this.showUnsubscribeSurvey = true;
        } else {
          if(this.subscribable.shouldSubscribeOnLogin){
            this.analyticsService.emailSubscribe(this.subscribable.title, 'deep link');
          }else{
            this.analyticsService.emailSubscribe(this.subscribable.title, 'button');
          }

          this.subscribedNewsletterTitle.emit(this.subscribable.title);
        }
      },
      (err) => {
        this.enterServiceErrorState();
      }
    );
  }

  private renderedAddressListForEmailAddressIds(...ids: string[]): string {
    const tempAddresses = ids.map(id => this.subscriptionEmailAddresses.find(email => email.id === id))
    const filterOutInexistentEmails = tempAddresses.filter(tempAddress => !isUndefined(tempAddress))
    const addresses = filterOutInexistentEmails.map(email => email.address);
    return stringUtils.renderList(addresses);
  }
}
