<div class="layout layout_one_up_center_container">
  <div class="layout_one_up_center centered margin-top-bottom">
    <div class="layout_container">
      <div class="margin-top-bottom centered">
        <h3>Thank you for your feedback!</h3>
      </div>
      <div class="margin-top-bottom">

        <a href="/authentication/login" class="button type-smaller">
          Manage Account Preferences
        </a>
      </div>
    </div>
  </div>
</div>
