<div>
  <div *ngIf="!submittingAddress">
    <span id="exitButton" (click)="editShippingAddress()"></span>
    <form id="verify-modal" (ngSubmit)="acceptShippingAddress()">
      <fieldset>
        <div class="form-title">Address</div>
        <div class="suggested-address-form">
          <div class="form-item-radio">
            <div class="radio-tile"
                [class.radio-tile--selected]="model.selectedAddress === 'normalized'">
              <input
                [(ngModel)]="model.selectedAddress"
                type="radio"
                name="your_address"
                value="normalized"
                id="suggested_address"
                checked="checked"
                required>
              <label for="suggested_address" class="radio-tile__button"></label>

              <div class="radio-tile__content">
                <p class="radio-tile__title">Suggested Address</p>
                <address>
                  {{ normalizedAddress?.lineOne }}<br>
                  <span *ngIf="normalizedAddress?.lineTwo">
                    {{ normalizedAddress?.lineTwo }}<br>
                  </span>
                  <span *ngIf="normalizedAddress?.lineThree">
                    {{ normalizedAddress?.lineThree }}<br>
                  </span>
                  {{ normalizedAddress?.city }},
                  {{ normalizedAddress?.stateTerritory }}
                  {{ normalizedAddress?.zipCode}}
                </address>
              </div>
            </div>

            <div class="radio-tile"
                [class.radio-tile--selected]="model.selectedAddress === 'original'">
              <input
                [(ngModel)]="model.selectedAddress"
                type="radio"
                name="your_address"
                value="original"
                id="original_address">
              <label for="original_address" class="radio-tile__button"></label>

              <div class="radio-tile__content">
                <div>
                    <p class="radio-tile__title">Original Address</p>
                    <app-link (click)="editShippingAddress()">Edit</app-link>
                </div>

                <address>
                  {{ enteredAddress?.lineOne }}<br>
                  <span *ngIf="enteredAddress?.lineTwo">
                    {{ enteredAddress?.lineTwo }}<br>
                  </span>
                  <span *ngIf="enteredAddress?.lineThree">
                    {{ enteredAddress?.lineThree }}<br>
                  </span>
                  {{ enteredAddress?.city }},
                  {{ enteredAddress?.stateTerritory }}
                  {{ enteredAddress?.zipCode}}
                </address>
              </div>
            </div>
          </div>
          <div class="card__button">
            <button (click)="acceptShippingAddress()">Save</button>
            <p class="close-link" (click)="editShippingAddress()">Cancel</p>
          </div>
        </div>
      </fieldset>
    </form>
  </div>

  <div *ngIf="submittingAddress">
    <app-spinner></app-spinner>
  </div>
</div>
