import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpHandler, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { map, catchError } from 'rxjs/operators';

import * as _ from 'lodash';

import { Repo } from '../../utils/repo';
import { CreditCard } from '../membership.service';
import { defaultRepo } from '../../utils/contact-records.utils';
import { AnalyticsService } from 'app/analytics/analytics.service';

export interface CCardInfoFromWallet {
  walletId?: number;
  entityId: number;
  creditCard?: CreditCard;
  beginDate: string;
  endDate: string;
  primaryInd: string;
  ccDisplayRestrict?: boolean;
  declinedCard?: boolean;
  amoutDue?: number;
  paymentId?: number;
  expirationDateFlag?: string;
}

export interface MakeCreditCardPrimaryRequest {
  entityId: number,
  updateId: number;
}

export interface AddCreditCardToWalletRequest {
  entityId: number,
  replaceOldId?: number;
  primaryInd?: string;
  name: string;
  number: string;
  expiration: string;
  profileFirstName?: string;
  profileLastName?: string;
  profileLabelName?: string;
  paymentId?: number;
}
export interface EndDateCreditCardRequest {
  entityId: number,
  endDateId: number;
}
export interface PayOutstandingBalancedRequest {
  entityId: number,
  paymentId: number,
  creditCardDetails: {
    name: string,
    number: string,
    creditCardWalletId: number,
    type: string,
    expiration: string,
    chargeAmount: number,
  }
}

export interface GoogleEnvironment {
  environment: string;
  merchantId: string;
  merchantName: string;
  gatewayMerchantId: string;
  active?: string;
}

export enum CCTypes {
  AMEX = 'AMEX',
  VISA = 'VISA',
  MC = 'MC'
}

@Injectable()
export class WalletService {
  url = '/api/wallet';
  disableAdd = false;
  private currentList: Partial<Repo<CCardInfoFromWallet>> = {};
  private _creditCardsInfo: Repo<CCardInfoFromWallet> = defaultRepo;
  private cachedMeNumber:string;
  private cachedStatus:string;
  private cachedEntityId: number;
  public disableGoogle: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService
  ) { }

  private saveCCList(data: Partial<Repo<CCardInfoFromWallet>>) {
    this.currentList = {};
    Object.assign(this.currentList, data);
    //make sure the wallet id is cached
    console.log(this.entityId);
  }
  public set creditCardsInfoSaved(repo: Repo<CCardInfoFromWallet>) {
    this._creditCardsInfo = repo;
  }
  public get creditCardsInfoSaved() {
    return this._creditCardsInfo;
  }

  public get entityId(): number {
      if (this.currentList.primary) {
        this.cachedEntityId = this.currentList.primary.entityId;
        return this.currentList.primary.entityId;
      }
      const otherCards: CCardInfoFromWallet[] = this.currentList.others
      if (otherCards && otherCards.length > 0) {
        this.cachedEntityId = otherCards[0].entityId;
        return otherCards[0].entityId;
      }

      return this.cachedEntityId;
  }

  get isDeclinedCardExist(): boolean {
    if (!this.currentList) {
      return false;
    }
    if (this.currentList.primary && this.currentList.primary.declinedCard ) {
      return true;
    }
    let otherCards: CCardInfoFromWallet[] = this.currentList.others
    if (otherCards && otherCards.length > 0) {
      otherCards = otherCards.filter(k => k.declinedCard);
    }
    if (otherCards && otherCards.length > 0) {
       return true;
    }
    return false;
  }

  get hasNoCreditCard(): boolean {
    if (!this.currentList || (this.currentList.others.length == 0 && !this.currentList.primary ) ) {
      return true;
    }
    if (!this.currentList.primary && (!this.currentList.others || this.currentList.others.length === 0 ))  {
      return true;
    }

    return false;
  }

  get hasOnlyOneCreditCard(): boolean {
    if (this.currentList && (this.currentList.others.length == 1 && !this.currentList.primary ) ) {
      return true;
    }
    if (this.currentList.primary && (!this.currentList.others || this.currentList.others.length === 0 ))  {
      return true;
    }

    return false;
  }

  public declinedCard(): CCardInfoFromWallet {
    if (!this.currentList) {
      return null;
    }
    let declindedCard: CCardInfoFromWallet;
    if (this.currentList.primary && this.currentList.primary.declinedCard ) {
      declindedCard = this.currentList.primary;
      return declindedCard;
    }
    let otherCards: CCardInfoFromWallet[] = this.currentList.others
    if (otherCards && otherCards.length > 0) {
      otherCards = otherCards.filter(k => k.declinedCard);
    }
    if (otherCards && otherCards.length > 0) {
      declindedCard = otherCards[0];
      return declindedCard;
    }
  }

  public get outstandingBalance(): number {
    if (this.currentList.primary) {
      return this.currentList.primary.amoutDue;
    }
    const otherCards: CCardInfoFromWallet[] = this.currentList.others
    if (otherCards && otherCards.length > 0) {
      return otherCards[0].amoutDue;
    }
    return 0;
}

  getCreditCardListFromWallet(status: string): Observable<Repo<CCardInfoFromWallet>> {

    this.cachedStatus = status;
    const params = new HttpParams().set('status', status);

    return this.httpClient.get(`${this.url}/ccards-list`, { params: params }).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        this.saveCCList(response?.data);
        return data;
      })
    );
  }

  markAsPrimary(params: MakeCreditCardPrimaryRequest): Observable<any> {

    const ret: Observable<any> = this.httpClient.put(`${this.url}/primary-credit-card`, params).pipe(
      map(() => {
          this.analyticsService.creditCardChange("make primary");
      })
    );
    return ret;
  }

  addCardToWallet(params: AddCreditCardToWalletRequest): Observable<any> {
    return this.httpClient.put(`${this.url}/add-edit-credit-card-info`, params)
      .pipe(
        map((response: ApiResponse<any>) => {
            return response;
        })
      )
  }

  endDateCardToWallet(params: EndDateCreditCardRequest): Observable<any> {
    return this.httpClient.put(`${this.url}/enddate-credit-card-info`, params)
      .pipe(
        map((response: ApiResponse<any>) => {
          this.analyticsService.creditCardChange("remove");

          this.getCreditCardListFromWallet(this.cachedStatus);

          return response;
        })
      )
  }

  payOutstandingBalance(params: PayOutstandingBalancedRequest): Observable<any> {
    return this.httpClient.put(`${this.url}/pay-balance`, params)
      .pipe(
        map((response: ApiResponse<any>) => {
              return response;
        })
      )
  }


  public getGoogleEnvironment(): Observable<GoogleEnvironment> {
    const params = new HttpParams();

    return this.httpClient.get(`${this.url}/googleEnvironment`, { params: params }).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        return data;
      })
    );
  }


  public debundleGoogle(params: any): Observable<any> {
    return this.httpClient.put(`${this.url}/debundle/google`, params).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        return data;
      })
    );
  }


  public debundleApple(params: any): Observable<any> {
    return this.httpClient.put(`${this.url}/debundle/apple`, params).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        return data;
      })
    );
  }

  public submitGooglePaymentData(params: any): Observable<any> {
    return this.httpClient.post(`${this.url}/google/authorize `, params).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        return data;
      })
    );
  }

  public validateApple(params: any): Observable<any> {
    return this.httpClient.post(`${this.url}/apple/validate`, params).pipe(
      map((response: ApiResponse<any>) => {
        const data = response?.data;
        return data;
      })
    );
  }
}
