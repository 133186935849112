<div id="professional-details-section">
  <div class="sub-title"> Professional Details </div>

  <div *ngIf="hasProfData">
    <div  class="detail-group"*ngFor="let element of professionalDetails" >
      <app-link
        class="list-component-edit-link"
        (click)="openEditDialog(element)">
        Edit
      </app-link>
      <div class="detail-element">
        <span class="practice-setting">Practice Setting: </span>
        <span> {{element.practiceSettingName}}</span>
      </div>

      <div class="detail-element"> {{element.hospitalIdName}} </div>
      <div class="detail-element"> {{element.hospitalName}} </div>
      <div class="detail-element"> <span>{{element.city}}</span>, <span>{{getStateName(element.stateTerritory)}}</span></div>

    </div>
  </div>

  <div class="blank-state" *ngIf="!hasProfData">
    Complete your profile with your current employment details, including your location and practice setting.
  </div>

  <div *ngIf="canAdd" class="add-new-section" (click)="openAddDialog()">
    <a class="add-button"></a>
    <span class="add-button-text">   Add Position</span>
  </div>

</div>
