import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { ContactPreferencesComponent } from './contact-preferences/contact-preferences.component';
import { UserModule } from './users/user.module';
import { EmailAddressesModule } from './email-addresses/email-addresses.module';
import { MailingAddressesModule } from './mailing-addresses/mailing-addresses.module';
import { PhoneNumbersModule } from './phone-numbers/phone-numbers.module';
import { FaxNumbersModule } from './fax-numbers/fax-numbers.module';
import { AlertsModule } from './ui-components/alerts/alerts.module';
import { ButtonsModule } from './ui-components/buttons/buttons.module'
import { HeaderModule } from './ui-components/header/header.module';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';
import { AmaHeaderComponent } from './ui-components/ama-header/ama-header.component';
import { AuthInterceptor } from './auth-interceptor.interceptor';
import { NavigationModule } from './ui-components/navigation/navigation.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RetrieveAuthenticatedUser } from './users/retrieve-authenticated-user.service';
import { RetrieveAuthenticatedUserOrRedirect } from './users/retrieve-authenticated-user-or-redirect.service';
import { AuthenticatedLayoutComponent } from './authenticated-layout/authenticated-layout.component';
import { RedirectToExternalSso } from './users/redirect-to-external-sso.service';
import { RedirectToExternalSignup } from './users/redirect-to-external-signup.service';
import { UnauthenticatedLayoutComponent } from './unauthenticated-layout/unauthenticated-layout.component';
import { UserAuthComponent } from './user-auth/user-auth.component';
import { AmaTemplateModalV1Module } from './ui-components/ama-ng-framework/ama__template-modal__v1/ama__template-modal__v1.module';
import { AmaValidatedTextInputV1Module } from './ui-components/ama-ng-framework/ama__validated-text-input__v1/ama__validated-text-input__v1.module';
import { AmaValidatedDropdownInputV1Module } from './ui-components/ama-ng-framework/ama__validated-dropdown-input__v1/ama__validated-dropdown-input__v1.module';
import { MembershipService } from './membership-status/membership.service';
import { SelectorsModule } from './ui-components/selectors/selectors.module';
import { SpinnersModule } from './ui-components/spinners/spinners.module';
import { MembershipStatusModule } from './membership-status/membership-status.module';
import { SogiComponent } from './sogi/sogi.component';
import { SogiService} from './sogi/sogi.service';
import { SogiAuthorizedService } from './users/sogi-authorized.service';
import { AnalyticsModule } from './analytics/analytics.module';
import { ReferralModule } from './referrals/referral.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AmcFormsModule } from './ui-components/forms/amc-forms.module';
import { AutoRenewLoggingModule } from './auto-renew-logging/auto-renew-logging.module';
import { UserHeaderComponent } from './ui-components/user-header/user-header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CardsModule } from './ui-components/cards/cards.module';
import { ContactUsLinkModule } from './ui-components/links/contact-us-link/contact-us-link.module';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { SogiModule } from './sogi/sogi.module';
import { EntryPointComponent } from './authenticated-layout/entry-point.component';
import { UserIsMENumberUserService } from './users/user-is-me-number-user.service';
import { MembershipJoinComponent } from './membership-status/membership-join/membership-join.component';
import { JoinNowModalComponent } from './membership-status/modals/join-now-modal.component';
import { PaymentOptionModalComponent } from './membership-status/modals/payment-option-modal.component';
import { NewCreditCardModalComponent } from './membership-status/modals/new-credit-card-modal.component';
import { PaymentComponent } from './membership-status/payment/payment.component';
import { PaymentFormService } from './membership-status/payment/payment-form.service';
import { MembershipJoinModule } from './membership-status/membership-join/MembershipJoinModule';
import { ProfessionalDetailsModule } from './professional-details/professional-details.module';
import { RouterModule } from '@angular/router';
import { AdsComponent } from './ads/ads.component';
import { MemberSinceConfig } from 'config/member-since.config';
import { NameChangeLinkComponent } from './ui-components/links/name-change-link/name-change-link.component';

import { GooglePayButtonModule } from '@google-pay/button-angular';
import { DirectivesModule } from 'app/directives/directives.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactPreferencesComponent,
    AmaHeaderComponent,
    AuthenticatedLayoutComponent,
    UnauthenticatedLayoutComponent,
    UserAuthComponent,
    UserHeaderComponent,
    PersonalInformationComponent,
    EntryPointComponent,
    JoinNowModalComponent,
    PaymentOptionModalComponent,
    NewCreditCardModalComponent,
    AdsComponent,
    NameChangeLinkComponent,
  ],
  imports: [
    AlertsModule,
    AnalyticsModule,
    AppRoutingModule,
    AmaValidatedTextInputV1Module,
    AmaValidatedDropdownInputV1Module,
    AmaTemplateModalV1Module,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonsModule,
    SogiModule,
    ProfessionalDetailsModule,
    EmailAddressesModule,
    FaxNumbersModule,
    FormsModule,
    HeaderModule,
    HttpClientModule,
    MailingAddressesModule,
    MembershipStatusModule,
    NavigationModule,
    PhoneNumbersModule,
    ReactiveFormsModule,
    ReferralModule,
    SubscriptionsModule,
    SelectorsModule,
    SpinnersModule,
    UserModule,
    AmcFormsModule,
    AutoRenewLoggingModule,
    MatDialogModule,
    CardsModule,
    ContactUsLinkModule,
    MembershipJoinModule,
    RouterModule,
    GooglePayButtonModule,
    DirectivesModule
  ],
  providers: [
    UserModule,
    MembershipService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    RetrieveAuthenticatedUser,
    RetrieveAuthenticatedUserOrRedirect,
    RedirectToExternalSso,
    RedirectToExternalSignup,
    SogiService,
    SogiAuthorizedService,
    UserIsMENumberUserService,
    PaymentFormService,
    MemberSinceConfig,
  ],
  exports: [
    UserHeaderComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
