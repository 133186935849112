import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MailingAddressParams } from 'app/mailing-addresses/mailing-address';
import { UserService } from 'app/users/user.service';

export type DeliveryMethod = 'physical' | 'digital' | 'none';

export interface Incentive {
  id: number;
  name: string;
  displayOrder: number;
  description: string;
  type: DeliveryMethod;
  fulfillmentDetails?: string;
  imageUrl?: string;
  autoRenewRequired: boolean;
  effortCode?: string;
  subcategories?: IncentiveSubcategory[];
  subcategoryLabel?: string;
}

export const NO_INCENTIVE_OPTION: Incentive = {
  id: 0,
  name: 'No Gift',
  description: '',
  displayOrder: 99,
  type: 'none',
  imageUrl: '',
  autoRenewRequired: false,
  subcategoryLabel: '',
  subcategories: [],
}

export interface IncentiveSubcategory {
  id: number;
  name: string;
}

export interface AutoRenewUpsellParams {
  entityId: number,
  orderId: number,
  automaticRenewal: boolean,
  incentiveId: number,
  subcategoryId: number,
  emailAddress?: string,
  emailConfirmationData?: any;
};

export interface OrderIncentivesRequest {
  entityId: number;
  mbrYear: number;
  inctvId: number;
  inctvSubcatId?: number,
  shippingAddress?: MailingAddressParams,
}

@Injectable()
export class IncentiveService {
  private autorenewIncentivesUrl = '/api/membership/autorenew-incentives';
  private enableAutoRenewUrl = '/api/incentives/enable-auto-renew';
  autoRenewIncentives = [];

  constructor(private http: HttpClient, private userService: UserService) { }

  enableAutoRenew(params: AutoRenewUpsellParams): Observable<any> {
    return this.http.post(this.enableAutoRenewUrl, params).pipe(
      map((response: ApiResponse<any>) => {
        return response?.data;
      })
    );
  }

  getAutoRenewIncentives(): Observable<Incentive[]> {

    return this.http.get(this.autorenewIncentivesUrl + "?personType=" + this.getGroupName()).pipe(
      map((response: ApiResponse<Incentive[]>) => {
        this.autoRenewIncentives = response?.data || [];
        return response?.data;
      })
    )
  }

  getGroupName(): string {
    const groups = this.userService.profile.groups.split(',');
    let group = "";
    if (groups.length > 1) {
      group = groups.find(g => g.toLowerCase() === 'student');

      if(group) return 'S';

      group = groups.find(g => g.toLowerCase() === 'resident');

      if(group) return 'R';

      group = groups.find(g => g.toLowerCase() === 'physician');

      if(group) return 'P';
    }

    return group;
  }

  orderIncentives(request: OrderIncentivesRequest): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put('/api/membership/order-incentives', request, { headers: headers }).pipe(
      map((response: ApiResponse<any>) => {
        return response?.data;
      })
    );
  }
}
