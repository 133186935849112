import {
  Component,
} from '@angular/core';

import { SubscribablesComponent } from '../subscribables.component';
import { SubscriptionsService } from '../../subscriptions.service';
import { AlertsService } from '../../../ui-components/alerts/alerts.service';
import { EmailAddressesService } from '../../../email-addresses/email-addresses.service';
import { UserService } from 'app/users/user.service';

@Component({
  selector: 'app-available-subscriptions',
  templateUrl: 'available-subscriptions.component.html'
})
export class AvailableSubscriptionsComponent extends SubscribablesComponent {
  categoriesFlags = {};

  constructor(
    protected service: SubscriptionsService,
    protected alertService: AlertsService,
    protected emailService: EmailAddressesService,
    protected userService: UserService,
  ) {
    super(service, alertService, emailService, userService);
  }

  changeFlag(categoryTitle: string) {
    this.categoriesFlags[categoryTitle] = !this.categoriesFlags[categoryTitle];
  }
}
