import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { ButtonsModule } from '../ui-components/buttons/buttons.module';
import { EmailAddressesService } from './email-addresses.service';
import { EditEmailAddressComponent } from './edit.component';
import { EmailAddressesHomeComponent } from './home.component';
import { NewEmailAddressComponent } from './new.component';
import { EmailAddressesMemoryAPI } from './email-addresses.memory-api';
import { ModalsModule } from '../ui-components/modals/modals.module';
import { DeleteEmailModalComponent } from './modals/delete-email-modal.component';
import { EmailAddressesListComponent } from './list.component';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { TransitionsModule } from '../ui-components/transitions/transitions.module';
import { ContactPreferencesNavigationService } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { MaxRecordsConfig } from '../../config/max-database-records.config';
import { SpinnersModule } from '../ui-components/spinners/spinners.module';
import { UpdateEmailModalComponent } from './modals/update-email-modal.component';
import { CardsModule } from '../ui-components/cards/cards.module';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChangePrimaryEmailComponent } from './app-change-primary-email/app-change-primary-email.component';
import { PremiumNewsLetterModalModalComponent } from 'app/subscriptions/subscribables/newsletter-card/modals/premium-newsletter-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonsModule,
    ModalsModule,
    TransitionsModule,
    SpinnersModule,
    CardsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDialogModule,
    MatTooltipModule,
    MatRippleModule
  ],
  declarations: [
    EmailAddressesHomeComponent,
    NewEmailAddressComponent,
    EditEmailAddressComponent,
    DeleteEmailModalComponent,
    EmailAddressesListComponent,
    UpdateEmailModalComponent,
    ChangePrimaryEmailComponent,
    PremiumNewsLetterModalModalComponent
  ],
  exports: [
    EmailAddressesHomeComponent,
    NewEmailAddressComponent,
    EditEmailAddressComponent,
    DeleteEmailModalComponent,
    EmailAddressesListComponent,
    ChangePrimaryEmailComponent,
  ],
  providers: [
    EmailAddressesService,
    EmailAddressesMemoryAPI,
    SubscriptionsService,
    ContactPreferencesNavigationService,
    AlertsService,
    MaxRecordsConfig,
    NewEmailAddressComponent,
    EmailAddressesListComponent,
    {
    provide: MatDialogRef,
    useValue: undefined
},
  ]
})
export class EmailAddressesModule { }
