import { NgModule } from '@angular/core';
import { AmaCloseIconV1Component } from './ama__close-icon__v1.component';

@NgModule({
  declarations: [
    AmaCloseIconV1Component,
  ],
  exports: [
    AmaCloseIconV1Component,
  ],
  imports: [],
})
export class AmaCloseIconV1Module {}
