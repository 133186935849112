<section id="credit-cards">
  <app-card-header title="Wallet" [hasPrimary]=isCardExist() primaryDescription="Designate a primary card to activate Auto-renew">
    <app-wallet-credit-cards-list>
    </app-wallet-credit-cards-list>
    <div class="wallet-card-footer">
      Card information is stored and encrypted in our secure database.
    </div>
  </app-card-header>
</section>

