import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfessionalDetailsService, ProfessionalDetailsAPIParams } from '../professional-details-service';
import { getStateFullNamesList, getStateAbbrByName, getStateNameByAbbr } from '../../utils/states';
import * as _ from 'lodash';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-professional-details-form',
  templateUrl: './form.component.html',
})

export class ProfessionalDetailsFormComponent implements OnInit {
  filteredStates: Observable<string[]>;
  control = new FormControl();
  professionalDetailsForm: FormGroup;
  fields: AbstractControl[];

  readonly PracticeSettingChoices = mapToOptions(this.practiceSettingChoices);

  protected filledPracticeSetting: boolean;
  public filledState: boolean;
  public filledCity: boolean;
  protected selectedOtherHospital: boolean;
  public showHospitalField: boolean = false;
  public showEmploymentField: boolean = false;

  private _currentCities: string[];
  private _currentHospitals: string[];

  protected id: string;

  constructor(
    protected formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<ProfessionalDetailsFormComponent>,
    protected service: ProfessionalDetailsService,
    protected analyticsService : AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (!!data.professionalDetailEntity) {
      this.id = data.professionalDetailEntity.id;
    }
  }

  get isEditForm(): boolean {
    return this.data.isEditForm;
  }

  get isFilledPracticeSetting(): boolean {
    return this.isFilledPracticeSetting;
  }

  get isFilledState(): boolean {
    return this.isFilledState;
  }

  get isFilledCity(): boolean {
    return this.isFilledCity;
  }

  get cityChoices(): string[] {
    return this._currentCities;
  }

  get stateChoices(): string[] {
    return getStateFullNamesList();
  }

  get practiceSettingChoices(): string[] {
    return this.service.getPracticeSettingArr();
  }

  get hospitalChoices(): string[] {
    return this._currentHospitals;
  }

  get practiceSettingName() {
    return this.professionalDetailsForm.get('practiceSettingName');
  }

  get stateTerritory() {
    return this.professionalDetailsForm.get('stateTerritory');
  }

  get city() {
    return this.professionalDetailsForm.get('city');
  }

  get hospitalIdName() {
    return this.professionalDetailsForm.get('hospitalIdName');
  }

  get hospitalName() {
    return this.professionalDetailsForm.get('hospitalName');
  }

  get practiceSettingIsHospitalBased(): boolean {
    return this.practiceSettingName.value.toLowerCase().includes('hospital');
  }

  private _stateAbbr = '';
  get stateAbbr() {
    return this._stateAbbr;
  }
  set stateAbbr(val: string) {
    this._stateAbbr = val;
  }

  get formData() {
    const data: Partial<any> = {
      practiceSettingName: this.practiceSettingName.value,
      stateTerritory: this.stateAbbr,
      city: this.city.value,
    };

    if (this.hospitalIdName.value !== '') {
      data.hospitalIdName = this.hospitalIdName.value;
    }

    if (this.hospitalName.value !== '') {
      data.hospitalName = this.hospitalName.value;
    }

    return data;
  }

  onNoClick(data?: string): void {
    this.dialogRef.close(data);
  }

  onDelete(id: number) {
    this.service.deleteProfessionalDetails(id).subscribe(
      (response) => {
        this.analyticsService.professionalInfoUpdate('remove', this.practiceSettingName.value);
        this.onNoClick('delete success');
      },
      (error) => {
        this.onNoClick('delete failure');
      }
    );
  }

  ngOnInit() {
    this.professionalDetailsForm = this.formBuilder.group({
      practiceSettingName: ['', Validators.required],
      stateTerritory: ['', Validators.required],
      city: ['', Validators.required],
      hospitalIdName: [''],
      hospitalName: [''],
    });
    this.fields = [this.practiceSettingName, this.stateTerritory, this.city, this.hospitalIdName, this.hospitalName];

    if (!this.isEditForm) {
      this.filledPracticeSetting = false;
      this.filledState = false;
      this.filledCity = false;
      this.stateTerritory.disable();
    } else {
      this.filledPracticeSetting = true;
      this.filledState = true;
      this.filledCity = true;
      this.stateTerritory.enable();
      this.id = this.data.professionalDetailEntity.id;
      this.professionalDetailsForm.patchValue(this.data.professionalDetailEntity);
      this.stateAbbr = this.stateTerritory.value;
      this.stateTerritory.patchValue(getStateNameByAbbr(this.stateTerritory.value));

      // Need to load current data options
      this.service.findCities(this.stateAbbr).subscribe((cities) => {
        this._currentCities = cities;
      });
      const arr: string[] = [];
      this.service.findHospitals(this.stateAbbr, this.city.value).subscribe((hospitals) => {
        if (!!hospitals) {
          hospitals.forEach(h => {
            arr.push(h.name);
          })
          arr.push('Hospital/Institution Not Listed');
          this._currentHospitals = arr;
        }
      });

      if (!!this.data.professionalDetailEntity.hospitalIdName && this.data.professionalDetailEntity.hospitalIdName !== '') {
        this.showHospitalField = true;
      }

      if (!!this.data.professionalDetailEntity.hospitalName && this.data.professionalDetailEntity.hospitalName !== '') {
        this.showEmploymentField = true;
      }
    }
  }

  public onPracticeSettingChange(data: string) {
    this.filledPracticeSetting = true;
    this.filledCity = false;
    this.filledState = false;
    this.stateTerritory.enable();
    this.hospitalName.disable();
    if (this.practiceSettingIsHospitalBased) {
      this.showHospitalField = true;
      this.showEmploymentField = false;
      this.hospitalIdName.setValidators(Validators.required);
      this.hospitalName.setValidators(null);
    } else {
      this.showHospitalField = false;
      this.showEmploymentField = true;
      this.hospitalIdName.setValidators(null);
      this.hospitalName.setValidators(Validators.required);
    }
    this.stateTerritory.patchValue('');
    this.city.patchValue('');
    this.hospitalIdName.patchValue('');
    this.hospitalName.patchValue('');
    this.hospitalIdName.updateValueAndValidity();
    this.hospitalName.updateValueAndValidity();
  }

  public onStateChange(data) {
    this.filledState = true;
    this.filledCity = false;
    this.city.patchValue('');
    this.hospitalIdName.patchValue('');
    this.hospitalName.patchValue('');
    this.hospitalName.disable();

    this.stateAbbr = getStateAbbrByName(data);
    this.service.findCities(this.stateAbbr).subscribe((cities) => {
      this._currentCities = cities;
    });
  }

  /**
   * Upon selecting a city via typing in the auto complete form, this method will be called
   * If the chosen practice setting is 'hospital based', the employment/hospital institution fields will display dynamically.
   *
   * @param data
   */
  public onCityChange(data) {
    this.filledCity = true;
    this.hospitalIdName.patchValue('');
    this.hospitalName.patchValue('');
    this.hospitalName.enable();

    if (data !== '') {

      if (this.practiceSettingIsHospitalBased) {
        if (!this.service.inCachedCitiesList(data, this.stateAbbr)) {
          this.showEmploymentField = true;
          this.showHospitalField = false;
          this.hospitalName.enable();
          this.hospitalIdName.setValidators(null);
          this.hospitalName.setValidators(Validators.required);

        } else if (this.service.inCachedCitiesList(data, this.stateAbbr)) {
          this.showEmploymentField = false;
          this.showHospitalField = true;
          this.hospitalName.disable();
          this.hospitalIdName.setValidators(Validators.required);
          this.hospitalName.setValidators(null);
        }
      }

      const arr: string[] = [];
      this.service.findHospitals(this.stateAbbr, this.city.value).subscribe((hospitals) => {
        if (!!hospitals) {
          hospitals.forEach(h => {
            arr.push(h.name);
          })
          arr.push('Hospital/Institution Not Listed');
          this._currentHospitals = arr;
        }
      })
    }
  }

  public inHospitalList(element): boolean{
    if (!!element && !!this._currentHospitals){
    return ProfessionalDetailsService._inList(element, this._currentHospitals);
    } else { return false; }
  }

  public hospitalTyped(data) {
    if (ProfessionalDetailsService._inList(data.target.value, this._currentHospitals)) {
      this.showEmploymentField = false;
      this.onHospitalChange(data.target.value);
    }
  }

  public onHospitalChange(data) {
    if (data === 'Hospital/Institution Not Listed') {
      this.showEmploymentField = true;
      this.hospitalName.enable();
      this.hospitalIdName.setValidators(Validators.required);
      this.hospitalName.setValidators(Validators.required);
    } else {
      this.showEmploymentField = false;
      this.hospitalIdName.setValidators(Validators.required);
      this.hospitalName.setValidators(null);
    }
    this.hospitalName.patchValue('');
    this.hospitalIdName.updateValueAndValidity();
    this.hospitalName.updateValueAndValidity();
  }

  public validateForm() {
    if (this.professionalDetailsForm.valid) {
      this.onFormSubmit();
    } else {
      _.each(this.fields, (field) => {
        field.markAsTouched();
      })
    }
  }

  private onFormSubmit() {
    const data = this.formData;
    data.id = this.id;
    if (this.isEditForm) {
      this.service.updateProfessionalDetails(data).subscribe();
      this.analyticsService.professionalInfoUpdate('edit', data.practiceSettingName);
    } else {
      this.service.addProfessionalDetail(data).subscribe();
      this.analyticsService.professionalInfoUpdate('add', data.practiceSettingName);
    }
    this.dialogRef.close();
  }
}

export function mapToOptions<T>(options: T[]) {
  return options.map((option: T) => {
    return { 'displayName': option.toString(), 'apiName': option.toString() };
  });
}
