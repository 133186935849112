<div class="layout ads" *ngIf="!isPhysician">
  <div class="upgrade-ad">
    <p>Are you a Physician, Resident, or Medical Student?</p>
    <a href="{{ upgradeProfileURL }}"
      class="link">Upgrade your account</a>
  </div>
</div>

<div *ngIf="adInfo">
  <div class="layout ads" *ngIf="shouldShowAutoRenewAd">
    <div class="autorenew-upsell-ad">
      <div class="description">{{ adInfo.description }}</div>
      <img src="{{ adInfo.imageUrl }}">
      <button (click)="clickAutoRenewAd()">Turn On Auto-renew</button>
    </div>
  </div>
</div>
