import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

import { MailingAddressesService } from './mailing-addresses.service';
import { MailingAddressRecord } from './mailing-address';
import { allRecords, defaultRepo } from '../utils/contact-records.utils';
import { IListComponent } from '../IListComponent';
import { MailingAddressAPIType, addressTypes } from './address-types';
import { ModalService } from '../ui-components/modals/modal.service';
import { ContactPreferencesNavigationService, ActiveSection, ContactType } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { getDeleteSuccessMessage, getMarkDefaultSuccessMessage, serverError } from '../utils/alerts.utils';
import { Repo } from '../utils/repo';
import { UserService } from '../users/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-mailing-addresses-list',
  templateUrl: './list.component.html'
})
export class MailingAddressesListComponent implements OnInit {

  @Output() openEdit = new EventEmitter<any>();

  name: ContactType = 'mailing address';

  private _addresses: Repo<MailingAddressRecord> = defaultRepo;
  private dataLoaded = false;
  showSpinner: boolean;
  buttonsDisabled = false;

  set addresses(repo: Repo<MailingAddressRecord>) {
    this.dataLoaded = true;
    this._addresses = repo;
  }

  get addresses() {
    return this._addresses;
  }

  get allAddresses() {
    return allRecords(this.addresses);
  }

  constructor(
    private service: MailingAddressesService,
    private modalService: ModalService,
    private navService: ContactPreferencesNavigationService,
    private alertService: AlertsService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.isLoading();
    this.service.getMailingAddresses().subscribe(
      (addresses) => {
        this.addresses = addresses;
        this.isFinishedLoading();
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
        this.isFinishedLoading();
      }
    );
  }

  noAddresses(): boolean {
    return (this.allAddresses.length === 0) && this.dataLoaded;
  }

  // List always shows no on the home.component because edit/verify pop up in modals
  isVisible(): boolean {
    return true;
  }

  async onStartEdit(address: MailingAddressRecord): Promise<void> {
    this.openEdit.emit({type: 'edit', data: address});
  }

  isDefault(address: MailingAddressRecord): boolean {
    return this.addresses.primary === address;
  }

  canDelete(address?: MailingAddressRecord): boolean {
    const isPhysician = this.userService.isPhysician;
    return !isPhysician || (isPhysician && !this.isDefault(address));
  }

  onMarkDefault(event: any, address: MailingAddressRecord, ) {
    if ( this.isDefault(address) ) {
      return;
    }
    event.preventDefault();

    this.isLoading();
    this.service.markAsDefault(address.id).pipe(
      finalize(() => {
         this.isFinishedLoading();
      })
    ).subscribe(
      () => {
        this.alertService.displaySuccess(getMarkDefaultSuccessMessage('mailing address'));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  getUserName() {
    if (!this.userService.profile) {
      return '';
    }
    return this.userService.profile.firstName + ' ' + this.userService.profile.lastName;
  }

  toDisplayName(addressType: MailingAddressAPIType): string {
    const matchingType = addressTypes.find((addr) => {
      return addressType === addr.apiName;
    });

    return matchingType.displayName;
  }

  private isLoading(): void {
    this.showSpinner = true;
    this.buttonsDisabled = true;
  }

  private isFinishedLoading(): void {
    this.showSpinner = false;
    this.buttonsDisabled = false;
  }
}
