import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CCardInfoFromWallet } from '../wallet.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit {

  model: Partial<CCardInfoFromWallet>;

  constructor(
      protected dialog: MatDialog,
      protected dialogRef: MatDialogRef<ConfirmModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
      this.model = this.data.model;
    }

    closeAndConfirmDialog () {
      this.dialogRef.close(`yes`);
    }

    closeAndNotConfirmDialog() {
      this.dialogRef.close(`no`);
    }

}
