import { ModalService } from '../ui-components/modals/modal.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IListComponent } from '../IListComponent';
import { EmailHolder, EmailRecord } from './email-address';
import { allRecords } from '../utils/contact-records.utils';
import { EmailAddressesService } from './email-addresses.service';
import { ContactPreferencesNavigationService } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { getDeleteSuccessMessage, getMarkDefaultSuccessMessage, serverError, primaryEmailInUseMessage } from '../utils/alerts.utils';
import { HasAssociatedSubscribablesComponent } from './has-associated-subscribables.component';
import { Repo } from '../utils/repo';
import { finalize } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewEmailAddressComponent } from './new.component';
import { EditEmailAddressComponent } from './edit.component';
import { ChangePrimaryEmailComponent } from './app-change-primary-email/app-change-primary-email.component';

@Component({
  selector: 'app-email-addresses-list',
  templateUrl: './list.component.html',
})
export class EmailAddressesListComponent extends HasAssociatedSubscribablesComponent implements OnInit, IListComponent<EmailRecord> {

  @Output() onEdit = new EventEmitter<EmailRecord>();
  @Output() onNewClickedForChangePrimaryEmailModal = new EventEmitter();

  private _emails: Repo<EmailRecord> | undefined;
  private dataLoaded = false;
  showSpinner = false;
  buttonsDisabled = false;
  submitClicked= false;
  selectedEmail: EmailRecord;
  changePrimaryNotAlreadyClicked = true;

  constructor(
    private service: EmailAddressesService,
    private modalService: ModalService,
    private navService: ContactPreferencesNavigationService,
    private alertService: AlertsService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ChangePrimaryEmailComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading();
     const emailAction = localStorage.getItem('emailUpdateAction');
     if( !!emailAction ){
       this.performEmailChange(emailAction);
     }else {
      this.performEmailFetching();
     }
  }
  
  performEmailChange(emailAction: string){
    const id = JSON.parse(emailAction).id;
    let holder: EmailHolder;
    this.service.changeEmail(id).subscribe(
      (response) => {
          if(response.errors.length > 0){
              if(response.errors[0].code == 'duplicate-email') {
                this.alertService.displaySuccess('Email already exists in the account')
              }else if(response.errors[0].code == 'maximum-number-of-emails-reached'){
                this.alertService.displaySuccess('Maximum number of emails you can add to your account reached');
              }else if(response.errors[0].code == 'email-address-not-found'){
                this.alertService.displaySuccess("Email address not found to update");
              }
              localStorage.removeItem('emailUpdateAction');
          }else{
            holder = response.data;
            localStorage.removeItem('emailUpdateAction');
            this.alertService.displaySuccess('Success! Your email address has been verified.');
          }
          this.performEmailFetching();
      },error => {
         console.log("ERROR in processing.");
      });
  }

  performEmailFetching(){
    this.service.getEmails().pipe(
      finalize(() => {
        this.service.popupClosed = false;
        this.isFinishedLoading();
      })
    ).subscribe(
      (emails) => {
        this.emails = emails as Repo<EmailRecord>;
        this.selectedEmail = emails.primary;
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  set emails(repo: Repo<EmailRecord>) {
    this.dataLoaded = true;
    this._emails = repo;
  }

  get emails() {
    return this._emails;
  }

  get allEmails(): EmailRecord[] {
    if (!this.emails) {
      return [];
    }

    return allRecords(this.emails);
  }

  /**
   * This checks if we are on the change primary email modal
   */
  get isChangePrimaryModal(): boolean {
    return this.service.isChangePrimaryModal;
  }

  /**
   * This returns a css class for when we are on the change primary email modal
   */
  get isChangePrimaryModalClass() {
    if (this.isChangePrimaryModal) {
      return { 'change-primary-list': true};
    } else {
      return '';
    }
  }

  get addNew() {
    return this.service.addNew;
  }

  get buttonValue() {
    return this.service.buttonValue;
  }

  addNewEmailForChangePrimaryModal() {
    this.changePrimaryNotAlreadyClicked = false;
    this.onNewClickedForChangePrimaryEmailModal.emit();
  }

  /**
   * this is the button on the change primary email modal.
   * It will actually set the selected email as default/primary.
   */
  submitButtonClick() {
    if (this.selectedEmail.id === this.emails.primary.id) {
      console.log('do not re-mark');
    } else {
      this.markPrimary(this.selectedEmail);
    }
    this.dialogRef.close();
  }

  /**
   * Jira AR-20: Open MatDalog form for editting a current email address
   */
  openEditDialog(email): void {
    const dialogRef = this.dialog.open(EditEmailAddressComponent, {
      width: this.modalService.getAppropriateWidthProperty(704),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {model: email}
    });
    this.onStartEdit(email);
    dialogRef.afterClosed().subscribe(result => {this.service.popupClosed = true; this.performEmailFetching(); });
  }

  /**
   * Jira AR-20: Open MatDalog form for adding a new email address
   */
  openAddDialog(): void {
    const dialogRef = this.dialog.open(NewEmailAddressComponent, {
      width: this.modalService.getAppropriateWidthProperty(704),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {this.service.popupClosed = true; this.performEmailFetching();});
  }

  isVisible(): boolean {
    return this.navService.getActiveSection('email') === 'list';
  }

  isDefault(email: EmailRecord): boolean {
    return this.emails.primary.id === email.id;
  }

  isSelected(email: EmailRecord): boolean {
    return email.id === this.selectedEmail.id;
  }

  onMarkDefault(email: EmailRecord) {
    if ( this.isSelected(email)) {
      return;
    }
    this.selectedEmail = email;
    // If this is a change primary modal we
    // do not want to actually mark as default until the submit button is clicked.
    // thus we just keep a record of which email is selected instead
    // todo check when we pop the modal on login that buttonValue is being set to "confirm".
    if (this.isChangePrimaryModal) {
      this.service.addNew = false;

      if (email) {
        this.changePrimaryNotAlreadyClicked = true;
      }
    } else {
      this.markPrimary(email);
    }
  }

  markPrimary(email: EmailRecord) {
    this.isLoading();
    this.service.markAsDefault(email.id).pipe(
      finalize(() => {
        this.isFinishedLoading();
      })
    ).subscribe(
      (emails) => {
        this.emails = emails as Repo<EmailRecord>;
        this.alertService.displaySuccess(getMarkDefaultSuccessMessage('email address'));
      },
      (err) => {
        this.selectedEmail = this.emails.primary;
        if (err.status === 422) {
          this.alertService.displayError(primaryEmailInUseMessage);
        } else {
          this.alertService.displayError(err.message || serverError);
        }
      }
    );
  }

  async onStartEdit(email: EmailRecord) {
    const cloned = Object.assign({}, email);
    this.onEdit.emit(cloned);
  }

  /**
   * edit.component.ts calls this method.
   */
  onDelete() {
  }

  private isLoading(): void {
    this.showSpinner = true;
    this.buttonsDisabled = true;
  }

  private isFinishedLoading(): void {
    this.showSpinner = false;
    this.buttonsDisabled = false;
  }
}
