import { Injectable } from '@angular/core';
import { Product } from 'app/membership-status/membership.service';

function noop() {}

@Injectable()
export class AnalyticsService {
  public constructor() {}

  publish(eventParams: object) {
    try {
      (window['_trackAnalytics'] || noop)(eventParams);
    } catch (err) {
      console.log('error publishing analytics event', err);
    }
  }

   autoRenewSelected(option: string) {
    this.publish({
      'site_events': { auto_renew_change: true },
      'new_auto_renew_state': option
   });
  }
  creditCardChange (option: string) {
    this.publish({
      'site_events': { credit_card_update: true },
      'action_taken': option
    });
  }

public joinMembership(product: Product, paymentType?: string)  : void {
    let rev: number = +product.productPrice;
    let type: string;
   
    if(product.halfYearDiscount){
      rev = rev - product.halfYearDiscount;
    }
   
    if(paymentType){
      type = paymentType;
    }else {
      type = "creditCard";
    }
   
    const params =  {
          'site_events' : {'amc_membership_purchase' : true},
          'revenue' : rev,
          'transaction_id' : product.invoiceId ? product.invoiceId.toString() + '-' + Math.floor(100000 + Math.random() * 900000) : Math.floor(100000 + Math.random() * 900000),
          'product_name' : product.productName,
          'product_id' : product.productCode,
          'product_price' : product.productPrice,
          'product_quantity': '1',
          'halfYearDiscount': product.halfYearDiscount,
          'halfYearDuesDiscountCode': product.halfYearDuesDiscountCode,
          'installmentIndicator': product.installmentIndicator,
          'paymentType' : type,
          'auto_renew': product.autorenewEligibilityIndicator
    }
    this.publish(params);
  }

  public additionalRatesClick () :void {
    const params =  {
      'site_events' : {'additional_rates_click' : true}
    }
    this.publish(params);
  }

  public emailSubscribe(title: String, subMethod: String): void {
    this.publish({
      'site_events' : { 'email_subscribe': true },
      'newsletter_title': title,
      'subscribe_method': subMethod
    });
  }

  public emailUnSubscribe(title: String): void {
    this.publish({
      'site_events' : { 'email_unsubscribe': true },
      'newsletter_title': title
    });
  }

  public contactInfoUpdate(category: string, action: String, type: String, data: String): void {
      this.publish({
        'site_events': { 'contact_info_update': true },
        'contact_info_category': category,       
        'action_taken': action,                   
        'contact_info_type': type,               
        'user_data': {[category]: data}

      });
  }
  
  public professionalInfoUpdate(action: String, practice: String): void {
    this.publish({
      'site_events': { 'professional_info_update': true }, 
      'action_taken': action, 
      'practice_setting': practice
    });
  }

  public personalInfoUpdate(): void {
    this.publish({
      'site_events': { 'personal_info_update': true }
    });
  }

  public printCardOrReciept(option: String): void{
    this.publish({
      'site_events': { 'print_amc': true }, 
      'item_printed': option
    });
  }

}
