import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ApiResponse } from 'app/utils/api-data.utilities';

export const upsellTrackingCategory = 'amc-upsell';
export const viewedAdEvent = 'Viewed Upsell Ad';
export const clickedAdEvent = 'Clicked Upsell Ad';
export const enabledAutoRenewEvent = 'Enabled Auto Renew';

export interface UserTrackingData {
  dataKey: string,
  dataValue: string,
}

export interface UserTrackingEvent {
  trackingEvent: string
}

export interface UserTrackingFlow {
  trackingId?: number,
  trackingCategory: string,
  flowId?: string,
  entityId?: number,
  meNumber?: string,
  ssoId?: string,
  partyId?: number,
  events?: UserTrackingEvent[],
  data?: UserTrackingData[],
}

export interface IAutoRenewLoggingService {
  logViewedAd(trackingData: UserTrackingData[], entityId: number): Observable<any>;
  logClickedAd(trackingData: UserTrackingData[]): Observable<any>;
  logEnabledAutoRenew(trackingData: UserTrackingData[]): Observable<any>;
}

@Injectable()
export class AutoRenewLoggingService implements IAutoRenewLoggingService {
  hasViewedAd = false;
  userTrackingFlow: UserTrackingFlow;

  constructor(
    private httpClient: HttpClient,
  ) {}

  logViewedAd(trackingData: UserTrackingData[], entityId: number): Observable<any> {
    return this.startTrackingFlow(viewedAdEvent, trackingData, entityId);
  }

  logClickedAd(trackingData: UserTrackingData[]): Observable<any> {
    return this.updateTrackingFlow(clickedAdEvent, trackingData);
  }

  logEnabledAutoRenew(trackingData: UserTrackingData[]): Observable<any> {
    return this.updateTrackingFlow(enabledAutoRenewEvent, trackingData);
  }

  private startTrackingFlow(event: string, trackingData: UserTrackingData[], entityId: number): Observable<any> {
    if (!this.hasViewedAd) {
      this.hasViewedAd = true;
      const flow: UserTrackingFlow = {
        trackingCategory: upsellTrackingCategory,
        entityId: entityId,
        events: [{ trackingEvent: event }],
        data: trackingData,
      }

      return this.httpClient.post<ApiResponse<UserTrackingFlow>>('/api/tracking/start', flow).pipe(
        map((response: ApiResponse<UserTrackingFlow>) => {
          this.userTrackingFlow = response?.data;
          return of('');
        }),
        catchError(() => {
          return of('');
        })
      );
    } else {
      return of('');
    }
  }

  private updateTrackingFlow(event: string, trackingData: UserTrackingData[]): Observable<any> {
    if (this.hasViewedAd) {
      const flow: UserTrackingFlow = {
        flowId: this.userTrackingFlow.flowId,
        trackingCategory: upsellTrackingCategory,
        events: [{ trackingEvent: event }],
        data: trackingData,
      }

      return this.httpClient.post<ApiResponse<UserTrackingFlow>>('/api/tracking/update', flow).pipe(
        map((response: ApiResponse<UserTrackingFlow>) => {
          this.userTrackingFlow = response?.data;
          return of('');
        }),
        catchError(() => {
          return of('');
        })
      );
    } else {
      return of('');
    }
  }
}
