import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import { ButtonComponent } from '../../ui-components/buttons/button.component';

const ACTIVE = 'link--active';
const DISABLED = 'link--disabled';

@Component({
  selector: 'app-update-subscribable-button',
  template:
      `<input class="button {{disabledClass}}" type="submit" value="Update">`
})
export class UpdateSubscribableButtonComponent extends ButtonComponent implements OnChanges {
  @Input() subscribed: boolean;
  disabledClass = ACTIVE;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled'] && changes['disabled'].currentValue) {
      this.isDisabled();
    } else {
      this.isActive();
    }
  }

  private isDisabled() {
    this.disabledClass = DISABLED;
  }

  private isActive() {
    this.disabledClass = ACTIVE;
  }
}
