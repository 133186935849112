import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorComponent } from './error.component';
import { IconsModule } from '../icons/icons.module';
import { AlertsComponent } from './alerts.component';
import { SuccessComponent } from './success.component';
import { TransitionsModule } from '../transitions/transitions.module';
import { AlertsConfig } from '../../../config/alerts.config';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    TransitionsModule,
  ],
  declarations: [
    ErrorComponent,
    SuccessComponent,
    AlertsComponent,
  ],
  exports: [
    AlertsComponent,
  ],
  providers: [
    AlertsConfig,
  ]
})
export class AlertsModule { }

