import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../../ui-components/modals/modal.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/users/user.service';
//import { environment } from 'environments/environment';
import { calcModalWidth, calcModalHeight } from 'app/utils/modal.utils';
import { JoinNowModalComponent } from 'app/membership-status/modals/join-now-modal.component';
import { Product, MembershipService } from 'app/membership-status/membership.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { WalletService } from 'app/membership-status/wallet/wallet.service';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';

@Component({
  selector: 'app-premium-newsletter-modal',
  templateUrl: './premium-newsletter-modal.component.html'
})
export class PremiumNewsLetterModalModalComponent implements OnInit {
  public title: string;
  public message: string = 'Become a member to access this content!';
  public showSpinner: boolean = true;
  membershipYear: number;
  defaultProduct: Product;
  buttonText: string;
  renewProductResponse: number;
  isRenewFlag: boolean = false;
  membershipYearDisplay: number;

  public isMobile$ = this.modalService.isScreenMobile$.pipe(
    map(isMobile => isMobile)
  );

  constructor(
    protected modalService: ModalService,
    protected userService: UserService,
    protected dialogRef: MatDialogRef<PremiumNewsLetterModalModalComponent>,
    protected dialog: MatDialog,
    private membershipService: MembershipService,
    private router: Router,
    private walletService: WalletService,
    private alertService: AlertsService,
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }

  get isPhysician() {
    return this.userService.isPhysician;
  }

  // get environment(): any {
  //   return environment;
  // }

  get upgradeProfileURL(): string {
    return window["env"]["upgradeProfileURL"];
  }
  ngOnInit() {
    if (!this.isPhysician) {
      this.title = 'Upgrade Your Account'
      this.message = 'To join the AMA, first upgrade your account.'
      this.showSpinner = false;
    } else {
      this.isRenew();
    }
  }

  /**
   * AR-154: Functions used to calculate information used in the join/renew modal
   */
  get currentYear(): number {
    return new Date(Date.now()).getFullYear();
  }

  public getDefaultProduct(membershipYear) {
    // Get default product from current year
    this.membershipService.getDefaultProduct(membershipYear)
      .subscribe((product) => {
        this.defaultProduct = product;
        this.showSpinner = false;
        if (!this.isRenewFlag) {
          if (membershipYear === this.currentYear && !this.isRenewFlag) {
            this.buttonText = 'Join Today';
          } else {
            this.buttonText = 'Join for ' + membershipYear;
          }
        }
    })
  }

  public isRenew() {
    this.membershipService.getMembershipYear().subscribe((year) => {
      const membershipYear = year;
      this.membershipYearDisplay = membershipYear;
    // Get default product from previous year
    this.membershipService.getDefaultProduct(this.currentYear - 1)
      .subscribe(
        (product) => {
          if (product.returnCode === -2) {
            this.isRenewFlag = true;
            if (this.currentYear === membershipYear) {
              this.buttonText = 'Renew Now';
            } else {
              this.buttonText = 'Renew for ' + membershipYear;
            }
            this.getDefaultProduct(membershipYear);
          } else {
            this.getDefaultProduct(membershipYear);
          }
          this.title = this.buttonText;
        },
        () => {
          this.getDefaultProduct(membershipYear);
        }
      )
    })
  }

  /**
   * AR-154: If a user clicks to Join from the modal on the Newsletter page, open the Join/Renew modal
   */
  openJoinRenewModal() {
    this.dialogRef.close();
    this.dialog.open(JoinNowModalComponent, {
      width: this.modalService.getAppropriateWidthProperty(502),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {
        product: this.defaultProduct,
        yearDisplay: this.membershipYearDisplay,
        isRenewFlag: this.isRenewFlag
      }
    }).afterClosed().subscribe(data => {
      if (data === 'showIframe') {
        this.membershipService.showAlternateRatesIframe = true;
        this.router.navigateByUrl('/membership');
      } else if (data === 'success' && this.isRenewFlag) {
        this.refreshUserDetails();
        this.alertService.displaySuccess('Thanks for renewing your AMA membership! We’re processing your application now.');
      } else if (data === 'success' && !this.isRenewFlag) {
        this.refreshUserDetails();
        this.alertService.displaySuccess('Thanks for joining the AMA! We’re processing your application now.');
      } else if (data === 'failure') {
        this.alertService.displayError('Sorry, we couldn\'t process your membership. Please check your payment information and try again.');
      }
    });
  }

  private refreshUserDetails() {
    const meNumber: string = this.userService.meNumber;
    forkJoin(
      this.userService.fetchProfile(true),
      this.membershipService.getUpsellAdWithPayment(true),
      this.membershipService.getMemberSince(true),
      this.membershipService.getDefaultProduct(this.membershipService.membershipYear, true),
      this.membershipService.getDefaultProduct(this.membershipService.membershipYear + 1, true),
      this.membershipService.getDefaultProduct(this.membershipService.membershipYear - 1, true),
      this.membershipService.getAllProducts(true),
      this.walletService.getCreditCardListFromWallet('active')
    ).subscribe();
  }
}
