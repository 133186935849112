import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable()
export class RedirectToExternalSso implements CanActivate {
  constructor(private service: UserService,
              private router: Router) {}

  canActivate(): Promise<boolean> {
    return new Promise(_ => {
      window.location.href = '/authentication/login';
    });
  }
}
