import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { IncentiveService, Incentive, IncentiveSubcategory } from './incentive.service';
import { PaymentInformation, MembershipService } from './membership.service';
import * as _ from 'lodash';
import { AutoRenewFormService } from './auto-renew-form.service';

@Component({
  selector: 'app-incentives',
  templateUrl: './incentive.component.html',
})
export class IncentiveComponent implements OnInit {
  @Input() public paymentInformation: PaymentInformation
  // AR-31: Location 'Membership Page' doesn't have functionality to choose a gift but 'Auto-Renew Modal' does
  @Input() location: string = 'Membership Page';
  //to catch click event on incentive to open modal
  @Input() preSelectedIncentive:number;
  @Output() nonSelectiveIncentiveClickedForAutorenewModal = new EventEmitter<any>();

  incentivesForDisplay;
  taskSubcategory: IncentiveSubcategory  = {id: 0, name: ''};
  hasClickedIncentive = false;

  constructor(
    private incentiveService: IncentiveService,
    public autoRenewFormService: AutoRenewFormService,
  ) {}

  ngOnInit() {
    let incentives: any[] = [];
    // Only load incentives if they are eligible to get them.
    if (this.paymentInformation.incentiveEligible) {
      incentives = this.incentiveService.autoRenewIncentives;
    }
    if (incentives.length > 0) {
      this.incentivesForDisplay = this.getIncentivesForDisplay(incentives);
      this.taskSubcategory.id = 21;
    }
    if (!this.autoRenewFormService.hasSubmittedAutorenew) {
      this.autoRenewFormService.createIncentiveForm(incentives);
    }
  }

  onClickIncentive() {
    this.hasClickedIncentive = true;
  }

  private getIncentivesForDisplay(incentiveOptions: Incentive[]) {
    if (incentiveOptions) {
      return incentiveOptions
        .sort((n1, n2) => n1.displayOrder - n2.displayOrder)
        .map((incentive) => {
          return {
            value: incentive.id,
            id: incentive.name,
            text: incentive.name,
            description: incentive.description,
            imageUrl: incentive.imageUrl,
            subcategoryLabel: incentive.subcategoryLabel,
            subcategories: this.getSubcategoriesForDisplay(incentive.subcategories),
          };
        });
    }
  }

  private getSubcategoriesForDisplay(categories: IncentiveSubcategory[]) {
    return categories
      .sort((n1, n2) => n1.id - n2.id)
      .map((category) => {
      return {
        value: category.id,
        id: category.name,
        text: category.name,
      };
    });
  }

  /**
   * AR-31: Decides if radio buttons are visible to choose a gift or not
   */
  canSelect() {
    if (this.location === 'Auto-Renew Modal') {
      return true;
    }
    return false;
  }

  incentiveClickedForAutorenewModal(option:any) {
    this.nonSelectiveIncentiveClickedForAutorenewModal.emit(option);
  }
}
