<section class="ribbon">
  <div class="layout layout_one_up">
    <nav class="ribbon_dropdown ribbon_dropdown_border" #mainNav>
      <span class="ribbon_dropdown_trigger"
            [class.is-active]="mainNavActive"
            tabindex="0">
        American Medical Association
        <span class="icon" data-grunticon-embed>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 8 5" style="enable-background:new 0 0 8 5;" xml:space="preserve">
            <path d="M4,5L0.3,1.6c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,1-0.4,1.4,0L4,2.4l2.3-2.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,0.9,0,1.3L4,5z"/>
            </svg>
        </span>
      </span>

      <ul class="ribbon_dropdown_nav"
          [style.display]="mainNavVisible ? 'block' : 'none'">
          <li class="ribbon_dropdown_nav_item">
            <a href="{{ amaWireUrl }}" class="link link-white">AMA Home</a>
          </li>

          <li class="ribbon_dropdown_nav_item">
          <a href="{{ amaStoreUrl }}" class="link link-white">AMA Store</a>
        </li>

        <li class="ribbon_dropdown_nav_item">
          <a href="{{ jamaUrl }}" class="link link-white">JAMA Network&trade;</a>
        </li>

        <li class="ribbon_dropdown_nav_item">
          <a href="{{ journalOfEthicsUrl }}" class="link link-white">Journal of Ethics</a>
        </li>

        <li class="ribbon_dropdown_nav_item">
          <a href="{{ freidaUrl }}" class="link link-white">FREIDA</a>
        </li>
      </ul>
    </nav>

    <div *ngIf="user" class="ribbon_user-menu" >
      <a *ngIf="!member" href="{{ becomeMemberUrl }}" class="link link-white item-hide-on-small link-text_small">Become a Member</a>
      <a *ngIf="member && !autoRenew" href="{{ becomeMemberUrl }}" class="link link-white item-hide-on-small link-text_small">Renew Your Membership</a>
    
      <nav class="ribbon_dropdown" #userNav>
        <span class="item-hide-on-large ribbon_user-menu_trigger"
              [class.is-active]="userNavActive"
              tabindex="0">
          <span class="icon icon-right" data-grunticon-embed>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><defs><style>.cls-1{fill:#46166b;}</style></defs>
              <title>human_icon</title>
              <path class="cls-1" d="M0,30S-1.12,17,15,17,30,30,30,30H0Z"/><circle class="cls-1" cx="15" cy="7" r="7"/>
            </svg>
          </span>
        </span>

        <div class="item-hide-on-small">
          <span class="ribbon_user-menu_trigger-auth"
                [class.is-active]="userNavActive"
                tabindex="0">
            {{ user.firstName }} {{ user.lastName }}
            <span class="icon" data-grunticon-embed>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 8 5" style="enable-background:new 0 0 8 5;" xml:space="preserve">
                <path d="M4,5L0.3,1.6c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,1-0.4,1.4,0L4,2.4l2.3-2.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,0.9,0,1.3L4,5z"/>
              </svg>
            </span>
          </span>
        </div>

          <ul class="ribbon_dropdown_nav margin-top ribbon_dropdown_nav-right" [style.display]="userNavVisible ? 'block' : 'none'">
            <li class="ribbon_dropdown_nav_item">
              <a href="/contact-preferences" class="link link-white link-text-small">Profile</a>
            </li>

            <li class="ribbon_dropdown_nav_item">
              <a href="{{ amaStoreAccountUrl }}" class="link link-white link-text-small">AMA Store Account</a>
            </li>

            <li class="ribbon_dropdown_nav_item item-hide-on-large">
              <a href="{{ becomeMemberUrl }}" class="link link-white">Become a Member</a>
            </li>

            <li class="ribbon_dropdown_nav_item">
              <a href="{{ referralsUrl }}" class="link link-white">Invite a Colleague</a>
            </li>

            <li class="ribbon_dropdown_nav_item">
              <a href="/authentication/logout" class="link link-white">Sign Out</a>
            </li>
          </ul>
      </nav>
    </div>

    <div *ngIf="!user" class="ribbon_user-menu">
      <nav class="item-hide-on-small">
        <ul class="clean-list">
          <li class="ribbon_user-menu_nav_item" [ngClass]="{ 'border-right': !hideSignInHomePageNav }">
            <a href="{{ becomeMemberUrl }}" class="link link-white">Become a Member</a>
          </li>
          <li *ngIf="!hideSignInHomePageNav" class="ribbon_user-menu_nav_item padding-left">
            <a href="/login" class="link link-white">Sign In</a>
          </li>
        </ul>
      </nav>

      <nav class="item-hide-on-large ribbon_dropdown" #userNav>
        <span class="ribbon_user-menu_trigger"
              [class.is-active]="userNavActive"
              tabindex="0">
          <span class="icon icon-right" data-grunticon-embed>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><defs><style>.cls-1{fill:#46166b;}</style></defs>
              <title>human_icon</title>
              <path class="cls-1" d="M0,30S-1.12,17,15,17,30,30,30,30H0Z"/><circle class="cls-1" cx="15" cy="7" r="7"/>
            </svg>
          </span>
        </span>

        <ul  class="ribbon_dropdown_nav margin-top ribbon_dropdown_nav-right"
            [style.display]="userNavVisible ? 'block' : 'none'">
          <li class="ribbon_dropdown_nav_item">
            <a href="{{ becomeMemberUrl }}" class="link link-white link-text_small">Become a Member</a>
          </li>
          <li class="ribbon_dropdown_nav_item">
            <a href="/login" class="link link-white link-text_small">Sign In</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</section>

