export function getAddSuccessMessage(contactType: string): string {
  return `Your ${contactType} was successfully added!`;
}

export function getUpdateSuccessMessage(contactType: string): string {
  return `Your ${contactType} was successfully updated!`;
}

export function getDeleteSuccessMessage(contactType: string): string {
  return `Your ${contactType} was successfully deleted!`;
}

export function getMarkDefaultSuccessMessage(contactType: string): string {
  return `Your ${contactType} was successfully marked as primary!`;
}

export function getUnsubscribeFeedbackSuccessMessage(): string {
  return 'Thank you for your feedback!';
}

export const serverError = 'We’re having trouble connecting to our server. Try reloading or checking your internet connection.';

export const updateSubscriptionEmailSuccess = 'Your subscription preferences were successfully updated!';

export const autoRenewErrorMessage = 'We were unable to fulfill your request, please try again later.';

export const creditCardErrorMessage = 'We were unable to authorize the credit card. Please check the card and try again.';

// tslint:disable-next-line: max-line-length
export const creditCardAndAutoRenewErrorMessage = 'We were unable to authorize the credit card and enable automatic renewal. Please check the card and try again.';

export const addressErrorMessage = 'We were unable to save your address. Please check the information and try again.';

export const primaryEmailInUseMessage = 'Sorry, that primary email is already in use. Try another.';

export const oneClickJoinRenewalSuccessMessage = 'Thanks for renewing your AMA membership! We’re processing your application now.';
export const oneClickJoinNewMemberSuccessMessage = 'Thanks for joining the AMA! We’re processing your application now.';
export const oneClickJoinErrorMessage = 'Sorry, we couldn\'t process your membership. Please confirm or change your payment information and try again.';
