<section id="membership-types">
  <div class="content" *ngIf="showSpinner">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!showSpinner" class="pricing-display">
    <div *ngIf="shouldShowDiscount()">
       <div class="half-year-ad color-price">50% OFF-LIMITED TIME</div>
    </div>
    <span>&nbsp;Your Price&nbsp;</span>
    <div class="price-box">
      <div>
        <span>{{ getGroupName() | titlecase }}</span>
        <span>
          <span>
            {{ checkIfNeedSpaces(defaultProduct.productName) | titlecase }}:
              <span *ngIf="shouldShowDiscount()" class="product-price-same-column crossed-off-price">
                &nbsp;${{ getOriginalPrice() }}
              </span>
              <span [ngClass]="shouldShowDiscount() ? 'color-price': 'product-price-same-column'" >
                &nbsp;${{ getPriceDisplayed() }}
              </span>
          </span>
        </span>
      </div>
      <div>
        <app-membership-join
          [defaultProduct] = "defaultProduct"
          [buttonText] = "buttonText"
          [isRenewFlag] = "isRenewFlag"
          [yearDisplay] = "membershipYearDisplay"
        >
        </app-membership-join>
      </div>
    </div>
    <div class="alternate-rates" *ngIf="shouldShowAlternateRatesOption">
      <div>{{ buildAlternateQuestion() }}</div>
      <app-link (click)="showAlternateRates()">&nbsp;View Additional Rates</app-link>
    </div>
  </div>
</section>
