import { filter } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { JoinNowModalComponent } from '../modals/join-now-modal.component';
import { PaymentOptionModalComponent } from '../modals/payment-option-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Product, MembershipService } from '../membership.service';
import { Router } from '@angular/router';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { forkJoin } from 'rxjs';
import { UserService } from 'app/users/user.service';
import { CCTypes, WalletService } from '../wallet/wallet.service';
import { oneClickJoinRenewalSuccessMessage, oneClickJoinNewMemberSuccessMessage, oneClickJoinErrorMessage } from 'app/utils/alerts.utils';
import { allRecords } from 'app/utils/contact-records.utils';
import { NewCreditCardModalComponent } from '../modals/new-credit-card-modal.component';

@Component({
  selector: 'app-membership-join',
  templateUrl: './membership-join.component.html',
})
export class MembershipJoinComponent {
  @Input() defaultProduct: Product;
  @Input() buttonText: string;
  @Input() yearDisplay: string;
  @Input() isRenewFlag: boolean;

  public membershipYear
  public meNumber
  public autoRenew
  public currentYear = new Date().getFullYear();
  public allProducts: Product[];
  public hideButton: boolean = false

  constructor(
    protected dialog: MatDialog,
    private membershipService: MembershipService,
    private router: Router,
    private modalService: ModalService,
    private alertService: AlertsService,
    private userService: UserService,
    private walletService: WalletService,
  ) { }

  private get allCreditCards() {
    const creditCardsInfoSaved  = this.walletService.creditCardsInfoSaved;
    return allRecords(creditCardsInfoSaved);
  }

  private hasNoCreditCard() {
     if (this.allCreditCards.length === 0) {return true;}
     let ccPrimary = this.allCreditCards.filter ((cc) =>
          cc.primaryInd === 'Y' && (cc.creditCard.type.includes(CCTypes.AMEX) ||
          cc.creditCard.type.includes(CCTypes.MC) ||
          cc.creditCard.type.includes(CCTypes.VISA))
     );
     return ccPrimary.length ? false : true;
  }
  isProductAutorenewable(): boolean {
    return !!this.defaultProduct.autorenewEligibilityIndicator
  }
  public openJoinDialog(): void {
    if ( this.hasNoCreditCard() ) {
      this.dialog.open(PaymentOptionModalComponent, {
        width: this.modalService.getAppropriateWidthProperty(502),
        maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
        height: this.modalService.getAppropriateHeightProperty(),
        maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
        autoFocus: false,
        data: {
          product: this.defaultProduct,
          yearDisplay: this.yearDisplay,
          isRenewFlag: this.isRenewFlag
        }
      }).afterClosed().subscribe(data => {
        if (data === 'showIframe') {
          this.membershipService.showAlternateRatesIframe = true;
          this.router.navigateByUrl('/membership');
        } else if (data === 'success' && this.isRenewFlag) {
          this.refreshUserDetails();
          this.alertService.displaySuccess(oneClickJoinRenewalSuccessMessage);
        } else if (data === 'success' && !this.isRenewFlag) {
          this.refreshUserDetails();
          this.alertService.displaySuccess(oneClickJoinNewMemberSuccessMessage);
        } else if (data === 'failure') {
          this.alertService.displayError(oneClickJoinErrorMessage);
        }
      });
    } else {
      this.dialog.open(JoinNowModalComponent, {
        width: this.modalService.getAppropriateWidthProperty(502),
        maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
        height: this.modalService.getAppropriateHeightProperty(),
        maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
        autoFocus: false,
        data: {
          product: this.defaultProduct,
          yearDisplay: this.yearDisplay,
          isRenewFlag: this.isRenewFlag
        }
      }).afterClosed().subscribe(data => {
        if (data === 'showIframe') {
          this.membershipService.showAlternateRatesIframe = true;
          this.router.navigateByUrl('/membership');
        } else if (data === 'success' && this.isRenewFlag) {
          this.refreshUserDetails();
          this.alertService.displaySuccess(oneClickJoinRenewalSuccessMessage);
        } else if (data === 'success' && !this.isRenewFlag) {
          this.refreshUserDetails();
          this.alertService.displaySuccess(oneClickJoinNewMemberSuccessMessage);
        } else if (data === 'failure') {
          this.alertService.displayError(oneClickJoinErrorMessage);
        }
      });
    }
  }
  public openCCJoinDialog(): void {
      if ( this.hasNoCreditCard() ) {
        this.newCreditCard();
      } else {
      this.dialog.open(JoinNowModalComponent, {
        width: this.modalService.getAppropriateWidthProperty(502),
        maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
        height: this.modalService.getAppropriateHeightProperty(),
        maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
        autoFocus: false,
        data: {
          product: this.defaultProduct,
          yearDisplay: this.yearDisplay,
          isRenewFlag: this.isRenewFlag
        }
      }).afterClosed().subscribe(data => {
        if (data === 'showIframe') {
          this.membershipService.showAlternateRatesIframe = true;
          this.router.navigateByUrl('/membership');
        } else if (data === 'success' && this.isRenewFlag) {
          this.refreshUserDetails();
          this.alertService.displaySuccess(oneClickJoinRenewalSuccessMessage);
        } else if (data === 'success' && !this.isRenewFlag) {
          this.refreshUserDetails();
          this.alertService.displaySuccess(oneClickJoinNewMemberSuccessMessage);
        } else if (data === 'failure') {
          this.alertService.displayError(oneClickJoinErrorMessage);
        }
      });
    }
  }
  public newCreditCard()  {

    this.dialog.open(NewCreditCardModalComponent, {
      width: this.modalService.getAppropriateWidthProperty(502),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {
        product: this.defaultProduct,
        yearDisplay: this.yearDisplay,
        isRenewFlag: this.isRenewFlag
      }
    }).afterClosed().subscribe(data => {
      if (data === 'showIframe') {
        this.membershipService.showAlternateRatesIframe = true;
        this.router.navigateByUrl('/membership');
      } else if (data === 'success' && this.isRenewFlag) {
        this.refreshUserDetails();
        this.alertService.displaySuccess(oneClickJoinRenewalSuccessMessage);
      } else if (data === 'success' && !this.isRenewFlag) {
        this.refreshUserDetails();
          this.alertService.displaySuccess(oneClickJoinNewMemberSuccessMessage);
      } else if (data === 'failure') {
        this.alertService.displayError(oneClickJoinErrorMessage);
      }
    });
  }
  public joinNow() {
    if (!this.isProductAutorenewable()) {
      this.openJoinDialog();
    } else {
      this.openCCJoinDialog();
    }
  }

  private refreshUserDetails() {
    this.membershipService.refreshWallet('Refresh Wallet');
    forkJoin(
      this.userService.fetchProfile(true),
      this.membershipService.getUpsellAdWithPayment(true),
      this.membershipService.getMemberSince(true),
      this.membershipService.getDefaultProduct(this.membershipService.membershipYear, true),
      this.membershipService.getDefaultProduct(this.membershipService.membershipYear + 1, true),
      this.membershipService.getDefaultProduct(this.membershipService.membershipYear - 1, true),
      this.membershipService.getAllProducts(true),
      this.walletService.getCreditCardListFromWallet('active')
    ).subscribe();
  }
}
