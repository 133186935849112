<div class="modal" [@showModal]="isVisible">
  <div class="modal__body">
    <span class="modal__close">
      <app-icon-close (click)="close()">
      </app-icon-close>
    </span>
    <ng-content></ng-content>
  </div>
</div>
<div class="modal__background" [@showModal]="isVisible"></div>
