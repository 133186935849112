import { ButtonComponent } from './button.component';
import { Component } from '@angular/core';

@Component({
  selector: 'app-submit-button',
  template:
        `<button [class.link--disabled]="disabled" autofocus>
                              <ng-content></ng-content>
                          </button>`
})
export class SubmitButtonComponent extends ButtonComponent { }
