import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../membership.service';
import { UserService } from 'app/users/user.service';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-membership-types',
  templateUrl: './membership-types.component.html',
})
export class MembershipTypesComponent implements OnChanges {
  @Input() defaultProduct: Product;
  @Input() allProducts: Product[];
  @Input() yearDisplay: string;
  @Input() isRenewFlag: boolean;
  @Input() alternateRatesUrl: string;
  @Output() showIframe = new EventEmitter<any>();
  showSpinner: boolean = true;
  buttonText: string;

  get shouldShowAlternateRatesOption(): boolean {
    if (!this.allProducts) {return false;}
    //Business requirements 4/21/2023 Do not show alternative rate for M3
    if (!!this.defaultProduct && this.defaultProduct.productCode === '72') {return false;}
    if (this.allProducts.length > 1) {return true;}
    if (this.defaultProduct && this.defaultProduct.autorenewEligibilityIndicator  ) {return true;}
    return false
  }

  constructor(
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {}

  // updates as Input value changes
  ngOnChanges() {
    if (this.defaultProduct) {
      this.showSpinner = false;
    }
    this.buttonText = this.isRenewFlag ? 'Renew' : 'Join';
  }

  /**
   * AR-23: Splits the group string and returns the group name of the user that is not 'member'
   */
  getGroupName(): string {
    const groups = this.userService.profile.groups.split(',');
    if (groups.length > 1) {
      if (groups[0] !== 'member') {
        return groups[0];
      }
      return groups[1];
    }
    return this.userService.profile.groups;
  }

  /**
   * AR:23: Returns a product name with spaces around the pipe if it doesn't already have it for consistency
   */
  checkIfNeedSpaces(label: string): string{
    if (String(label).includes('|') && !String(label).includes(' | ')) {
      return String(label).split('|').join(' | ');
    }
    return label;
  }
  /**
   * Gets whether the striked through original discount should show.
   */
  shouldShowDiscount(): boolean {
    return (!!this.defaultProduct.halfYearDiscount)
  }
  /**
   * Gets the original price of the product before discounts.
   */
  getOriginalPrice(): string {
    let price: string = this.defaultProduct.productPrice;
    if (String(price).indexOf('.') >= 0) {
      return parseFloat(price).toFixed(2).toString();
    } else {
      return parseFloat(price).toFixed(0).toString();
    }
  }
  /**
   * Gets the price to be displayed on the page for the default product.
   */
  getPriceDisplayed(): string {
    let price: string;
    if (this.defaultProduct.halfYearDiscount) {
      price = this.defaultProduct.halfYearDiscount.toString();
    } else {
      price = this.defaultProduct.productPrice;
    }
    if (String(price).indexOf('.') >= 0) {
      return parseFloat(price).toFixed(2).toString();
    } else {
      return parseFloat(price).toFixed(0).toString();
    }
  }

  /**
   * AR-23: Returns a built out question for alternate rates
   */
  buildAlternateQuestion(): string {
    const names = [];
    // For students and residents, we are not displaying a question, just a link.
    for (let i = 0; i < this.allProducts.length; i++) {
      if (this.allProducts[i].returnCode === 0
        && this.allProducts[i].productName !== this.defaultProduct.productName) {
        if (this.allProducts[i].productName.toLowerCase().includes('regular practice')) {
          names.push('Regular Practice');
        } else if (this.allProducts[i].productName.toLowerCase().includes('military')) {
          names.push('Military');
        } else if (this.allProducts[i].productName.toLowerCase().includes('retired')
                    && !this.allProducts[i].productName.toLowerCase().includes('semi-retired')) {
          names.push('Retired');
        } else if (this.allProducts[i].productName.toLowerCase().includes('semi-retired')) {
          names.push('Semi-Retired');
        } else if (this.allProducts[i].productName.toLowerCase().includes('student')) {
          break;
        } else if (this.allProducts[i].productName.toLowerCase().includes('resident')) {
          break;
        }
      }
    }
    if (names.length === 1) {
      return names.toString().concat('?');
    } else if (names.length === 2) {
      // adds an 'or' and a '?' to the last name
      return names.filter(Boolean).join(' or ').concat('?');
    } else {
      // adds a comma between each name and adds ', or' and a '?' to the last name
      return names.filter(Boolean).join(', ').replace(/, ([^,]*)$/, ', or $1?');
    }
  }

  private showAlternateRates() {
    this.analyticsService.additionalRatesClick()
    const url = window["env"]["joinRenewAlternateRates"];
    window.open(url, '_blank');
  }
}
