import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as $ from 'jquery';

window['$'] = $;

enableProdMode();

document.write(environment.optimizelyScript)
window['start'] = function(creds: { firstName, lastName, uniqueId }): void {
  window['creds'] = creds;
  document.getElementsByTagName('html')[0].className = 'flexbox';
  platformBrowserDynamic().bootstrapModule(AppModule);
};
