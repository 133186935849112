<div [formGroup]="formGroup">
  <div [ngClass]="{'incentives-list-view-only': !showInput}">
    <div *ngFor="let option of incentiveOptions" [class]="this.incentiveFormControl.value === option.value ?   'selected-incentive':'unselected-incentive'" (click)="incentiveSelected(option)">
      <div>
        <input [formControlName]="fieldName"
              type="radio"
              [attr.disabled]="isDisabled ? '' : null"
              (change)="updateSubcategory()"
              [value]="option.value"
              [id]="option.id"
              *ngIf="showInput"
          />
        <label *ngIf="showLabel" [ngClass]="{'form-item-radio-incentive-label': showInput}" [for]="option.id">{{ option.text || option.value }}</label>
      </div>
      <div [ngClass]="{'form-item-radio-incentive-image': true, 'form-item-radio-incentive-image-view-only': !showInput}">
        <img *ngIf="option.imageUrl" src="{{ option.imageUrl }}"/>
      </div>

      <div *ngIf="displayIncentiveSubcategories(option.value) && showInput">
        <div *ngIf="option.subcategoryLabel">
          <div class="size-label">Size</div>
          <label for="subcategory"></label>
          <select
            id="subcategory"
            [formControlName]="subfieldName"
            [ngClass]="subcategoryDropdownClass">
            <option *ngFor="let subOption of option.subcategories" [value]="subOption.value">{{ subOption.text || subOption.value }}</option>
          </select>
          <app-form-error fitInLine="true" *ngIf="invalidSubcategory()">
            Field is required.
          </app-form-error>
        </div>
      </div>
    </div>
  </div>
  
  <app-form-error fitInLine="true" *ngIf="invalidIncentive() && showInput">
    Please choose an option.
  </app-form-error>
</div>