<span id="exitButton" (click)="closeDialog()"></span>

<div style="padding: 10px 10px;">
  <div>
      You have reached the limit of allowed credit card updates for today.<br/>
      You may try again tomorrow or contact the AMA United Service Center at 800-262-3211,
      Monday through Friday from 8:00 AM until 5:00 PM CST.
  </div>
  <div>
    <a  class="wallet-card-remove-link" (click)="closeDialog()">
      Close
    </a>
  </div>
  <app-contact-us-link></app-contact-us-link>
</div>
