import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { OtherAddressRecord } from 'app/mailing-addresses/mailing-address';
import { addressTypes } from 'app/mailing-addresses/address-types';
import { states } from 'app/utils/states';
import * as _ from 'lodash';
import { fieldMasks } from 'app/utils/form.utilities';
import { AutoRenewFormService } from '../auto-renew-form.service';

@Component({
  selector: 'app-membership-addresses',
  templateUrl: './membership-addresses.component.html',
})
export class MembershipAddressesComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public addresses: OtherAddressRecord[] = [];
  @Input() public addressName: string = 'Billing';
  @Input() public type: string = 'billing';
  @Input() public defaultId: string = '';
  hasPrimaryAddress: boolean = false;

  readonly zipCodeMask = fieldMasks.zipCode;
  addressTypes = _.map(addressTypes, (t) => {
    return { key: t.apiName, value: t.displayName }
  });
  stateOptions = _.map(states, (s) => {
    return { key: s.toString(), value: s.toString() }
  });

  constructor(
    public autorenewFormService: AutoRenewFormService,
  ) { }

  ngOnInit() {
    // Ensure there is a primary address and sets the default address to be the primary unless changed
    this.hasPrimaryAddress = !!this.addresses[0];
    this.addressOptionsInput.setValue(this.defaultId);
    if (this.autorenewFormService.clickedEditAddress) {
      this.autorenewFormService.hasChangedAddressOnSubmit = true;
      this.addressOptionsInput.reset();
    }
  }

  get addressOptionsInput(): AbstractControl {
    const optionsName = this.addressOptions;
    return this.formGroup.get(optionsName);
  }

  get addressOptions(): string {
    return `${this.type}AddressOptions`;
  }

  get addressType(): string {
    return `${this.type}AddressType`;
  }

  get lineOne(): string {
    return `${this.type}LineOne`;
  }

  get lineTwo(): string {
    return `${this.type}LineTwo`;
  }

  get lineThree(): string {
    return `${this.type}LineThree`;
  }

  get city(): string {
    return `${this.type}City`;
  }

  get stateTerritory(): string {
    return `${this.type}StateTerritory`;
  }

  get zipCode(): string {
    return `${this.type}ZipCode`;
  }

  newAddress() {
    this.addressOptionsInput.reset();
    this.hasPrimaryAddress = false;
  }
}
