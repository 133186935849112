// todo remove this file. will remove at AR-197

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EmailRecord } from '../email-address';
import { ModalService } from '../../ui-components/modals/modal.service';
import { SubscribableRecord } from '../../subscriptions/subscriptions';

@Component({
  selector: 'app-delete-email-modal',
  templateUrl: './delete-email-modal.component.html',
})
export class DeleteEmailModalComponent {
  @Input()
  public email: EmailRecord;

  @Input()
  public subscriptions: SubscribableRecord[];

  @Output()
  public deleteEmail = new EventEmitter<EmailRecord>();

  public get modalElementId(): string {
    return this.email.id;
  }

  public get shouldShowDeleteAndUnsubscribeVersion(): boolean {
    return this.subscriptions.length > 0;
  }

  public get shouldShowDeleteOnlyVersion(): boolean {
    return this.subscriptions.length === 0;
  }

  public get emailAddress(): string {
    return this.email.address;
  }

  public get subscriptionTitles(): string[] {
    return this.subscriptions.map(s => s.title);
  }

  constructor(public modalService: ModalService) { }

  public cancelButtonClicked(): void {
    this.modalService.close(this.modalElementId);
  }

  public confirmationButtonClicked(): void {
    this.deleteEmail.emit(this.email);
    this.modalService.close(this.modalElementId);
  }
}
