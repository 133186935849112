import { OnInit, Component } from '@angular/core';
import { MembershipTransactionHistoryService, MembershipTransactionHistory } from './membership-transaction-history.service';
import { UserService } from 'app/users/user.service';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { MembershipReceiptConfig } from 'config/membership-receipt.config';
import { AnalyticsService } from 'app/analytics/analytics.service';

/**
 * Membership Transaction History Component.
 */
@Component({
  selector: 'app-membership-transaction-history',
  templateUrl: './membership-transaction-history.component.html',
})
export class MembershipTransactionHistoryComponent implements OnInit {
  private _membershipTransactionHistory: MembershipTransactionHistory[];
  private _isFinishedLoading: boolean = false;

  /**
   * Instantiates a new MembershipTransactionHistoryComponent using the supplied services.
   * @param membershipTransactionHistoryService Membership Transaction History Service to use.
   * @param userService User Service to use.
   */
  constructor(
    private membershipTransactionHistoryService: MembershipTransactionHistoryService,
    private userService: UserService,
    private alertService: AlertsService,
    private analyticsService: AnalyticsService,
    private config: MembershipReceiptConfig) {
    }

  /**
   * Initialization code to run when the component is initialized.
   *
   * This ngOnInit ensures that data is loaded to be displayed.
   */
  ngOnInit(): void {
    this.membershipTransactionHistoryService.getMembershipTransactionHistory()
      .subscribe(
        (response) => {
          this._membershipTransactionHistory = response;
          this._isFinishedLoading = true;
        }
      );
  }

  /**
   * Gets the Membership Transaction History for display.
   */
  get getMembershipTransactionHistory(): MembershipTransactionHistory[] {
    return this._membershipTransactionHistory;
  }

  /**
   * Gets whether there is Membership Transaction History. Used to control display of spinner and blank state.
   */
  get hasMembershipTransactionHistory(): boolean {
    return !!this._membershipTransactionHistory && this._membershipTransactionHistory.length > 0;
  }

  /**
   * Gets whether the component has finished loading its data. Used to control display of spinner.
   */
  get isFinishedLoading(): boolean {
    return this._isFinishedLoading;
  }

  /**
   * Sends a print request to the API with the Payment Id of the row where Print() was clicked.
   * @param paymentId Payment Id of the row where Print() was clicked.
   */
  print(paymentId: number): void {
    this.membershipTransactionHistoryService.getReceipt(paymentId, this.userService.profile.labelName).subscribe(response => {
      const nav = (window.navigator as any)
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(response?.data, this.config.membershipReceiptFileName);
      } else {
        const url = window.URL.createObjectURL(response?.data);
        const pwa = window.open(url, '_blank');

        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
          this.alertService.displayError('Unable to download receipt. Please disable your pop-up blocker and try again.');
        }

        pwa.print();
        this.analyticsService.printCardOrReciept('membership receipt');

      }
    }, (error) => {
      this.alertService.displayError('Unable to download receipt. Please try again later.');
    });
  }

  public isDigital(item: MembershipTransactionHistory): boolean {
    return ['Apple Pay', 'Google Pay'].includes(item.creditCardType);
  }
}
