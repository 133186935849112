import { AlertComponent } from './alert.component';
import { AlertsService } from './alerts.service';
import { Component } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-error',
  templateUrl: './alert.component.html',
  animations: [
    trigger('visibilityChanged', [
      state('true' , style({ opacity: 1, height: '*', padding: '*' })),
      state('false', style({ opacity: 0, height: 0, padding: 0 })),
      transition('* => *', animate('.4s ease-in-out'))
    ])
  ]
})
export class ErrorComponent extends AlertComponent {
  className = 'alert-error';

  constructor(protected service: AlertsService) {
    super(service);
  }

  get message() {
    return this.service.error;
  }
}
