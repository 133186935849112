<form class="auto-complete-form" [formGroup]="parentFormGroup">
  <input class="plain-input"
         type="text"
         [matAutocomplete]="auto"
         [formControlName]="autoCompleteFieldName">
  <mat-autocomplete #auto="matAutocomplete"
    (optionSelected)="optionSelected($event.option.value)">
    <mat-option *ngFor="let element of filteredElements | async" [value]="element">
      {{element}}
    </mat-option>
  </mat-autocomplete>
</form>
