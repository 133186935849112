import { IHomeComponent } from '../IHomeComponent';
import { ContactNumberRecord } from './contact-number';
import { ContactNumbersService } from './contact-numbers.service';
import {
  ActiveSection,
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';

export class ContactNumbersHomeComponent implements IHomeComponent {

  editingContactNumber: Partial<ContactNumberRecord>;
  name: ContactType;

  get disableAdd(): boolean {
    return this.service.disableAdd;
  }

  get activeSection(): string {
    return this.navService.getActiveSection(this.name);
  }

  constructor(
    protected service: ContactNumbersService,
    protected navService: ContactPreferencesNavigationService,
  ) {}

  setActiveSection(newSection: ActiveSection): void {
    this.navService.setActiveSection(this.name, newSection);
  }

  onEdit(number: Partial<ContactNumberRecord>) {
    this.editingContactNumber = number;
    this.setActiveSection('edit');
  }

  isVisible(sectionName: ActiveSection): boolean {
    return this.activeSection === sectionName;
  }
}
