import { NgModule } from '@angular/core';
import { MembershipJoinComponent } from './membership-join.component';
@NgModule({
  declarations: [
    MembershipJoinComponent
  ],
  exports: [
    MembershipJoinComponent
  ]
})
export class MembershipJoinModule {
}
