import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AutoRenewLoggingService } from './auto-renew-logging.service';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    AutoRenewLoggingService,
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
  ]
})

export class AutoRenewLoggingModule { }
