import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * AR-35: An autocomplete drop down input
 * Will be utilized for dynamic data drop downs in the Professional Details form
 */
@Component({
  selector: 'app-auto-complete',
  templateUrl: 'auto-complete.html',
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input() disable: boolean = true;
  @Input() autoCompleteFieldName: string;
  @Input() parentFormGroup: FormGroup;
  @Input() protected elements: string[]= ['default'];
  @Input() isHospitalBasedList: boolean = false;
  @Output() selectedOption = new EventEmitter<string>();

  filteredElements: Observable<string[]>;
  itemName: string = 'Street';

  ngOnInit() {
    this.filteredElements = this.parentFormGroup.get(this.autoCompleteFieldName).valueChanges.pipe(
      map(value => this._filter(value))
    );
  }

  ngOnChanges() {
    this.disable ? this.parentFormGroup.get(this.autoCompleteFieldName).disable() : this.parentFormGroup.get(this.autoCompleteFieldName).enable();
  }

  public optionSelected(data) {
    this.selectedOption.emit(data);
  }

  private _filter(value: string): string[] {
    if (!!value && value !== '') {
      const filterValue = this._normalizeValue(value);
      const rval = this.elements.filter(element => this._normalizeValue(element).includes(filterValue));
      if (this.isHospitalBasedList && (rval.length === 0)) {
        return ['Hospital/Institution Not Listed'];
      }
      return rval;
    }
    return null;
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
