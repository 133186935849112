import {
  Component,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import { ButtonComponent } from './button.component';

@Component({
  selector: 'app-link',
  template:
    `<a class="link" [class.link--disabled]="disabled">
                 <ng-content></ng-content>
               </a>`
})
export class LinkComponent extends ButtonComponent implements OnChanges {
  disabled = false;
  text: string;

  ngOnChanges(changes: SimpleChanges) {
    this.disabled = !!(changes['disabled'] && changes['disabled'].currentValue);
  }
}
