import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getOrElse, Maybe } from '../maybe';

export const SELECTOR = 'ama__close-icon__v1';
const ON_CLICK_ATTRIBUTE = 'on-click';
const COLOR_ATTRIBUTE = 'color';
const DEFAULT_COLOR = 'black';

@Component({
  selector: SELECTOR,
  styleUrls: [ './ama__close-icon__v1.component.scss' ],
  templateUrl: './ama__close-icon__v1.component.html',
})
export class AmaCloseIconV1Component {
  @Input(COLOR_ATTRIBUTE)
  private _color: Maybe<string>;

  @Output(ON_CLICK_ATTRIBUTE)
  private _onClickEventEmitter = new EventEmitter<void>();

  public get fill(): string {
    return getOrElse(this._color, DEFAULT_COLOR);
  }

  public onIconClick(_: MouseEvent): void {
    this._onClickEventEmitter.emit();
  }
}
