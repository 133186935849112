import { Injectable, Inject  } from '@angular/core';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import { MembershipService } from 'app/membership-status/membership.service';
import { UserCredentials, UserProfileRecord } from './user-credentials';
import * as cookieUtils from '../utils/cookie.utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface UserProfileRecordResponse {
  data: UserProfileRecord
}

export interface IUserService {
  profile: UserProfileRecord;
  isInGroup(group: string): boolean;
}

@Injectable()
export class UserService implements IUserService {
  creds: UserCredentials;
  profile: UserProfileRecord;
  public requestNewAdInformationRefresh$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private membershipService: MembershipService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  get uniqueId(): string {
    return this.profile.uniqueId;
  }

  get meNumber(): string {
    return this.profile.meNumber;
  }

  get isMember(): boolean {
    if (!this.profile) {
      return false;
    }
    return !!this.profile.isMember;
  }

  get hasPendingOrder(): boolean {
    if (!this.profile) {
      return false;
    }
    return !!this.profile.hasPendingOrder;
  }

  get pendingOrderMbrYear(): string {
    return this.profile.pendingOrderMbrYear;
  }

  get isPhysician(): boolean {
    if (!this.profile) {
      return false;
    }
    return this.profile.meNumber !== undefined;
  }

  isInGroup(group: string): boolean {
    if (!this.profile) {
      return false;
    }
    return _.includes(this.profile.groups.split(','), group);
  }

  private getLifeStageInfo() : String {
    if(!this.profile) {
      return 'other'
    }
    if(this.profile.groups.includes('physician')){
      return 'physician';
    }else if(this.profile.groups.includes("resident")){
      return 'resident';
    }else if(this.profile.groups.includes('student')) {
      return 'student';
    }
    return 'other';
  }

  fetchProfile(forceRefresh: boolean = false, skipInterceptor: boolean = false): Observable<UserProfileRecord> {
    if (this.profile && !forceRefresh) {
      return of(this.profile);
    } else {
      const cn = cookieUtils.getCookie('amcRedirectMBR');
      if (this.document.location.href.indexOf('membership-status') > 0) {
        cookieUtils.setCookie('amcRedirectMBR', 'Y');
      } else {
        const cookieVal = cookieUtils.getCookie('amcRedirectMBR');
        if (cookieVal && cookieVal === 'Y') {
          cookieUtils.deleteCookie('amcRedirectMBR');
        }
      }

      const cnReferrals = cookieUtils.getCookie('amcRedirectReferrals');
      if (this.document.location.href.indexOf('referrals') > 0) {
        cookieUtils.setCookie('amcRedirectReferrals', 'Y');
      } else {
        const cookieVal = cookieUtils.getCookie('amcRedirectReferrals');
        if (cookieVal && cookieVal === 'Y') {
          cookieUtils.deleteCookie('amcRedirectReferrals');
        }
      }

      const cnNewsletters = cookieUtils.getCookie('amcRedirectNewsletters');
      /**
       * if newsletters and action present in url, entry-point component will handle redirect using localstorage item subscribeToNewsletterAction
       * if newsletters and not action present in url, logic using cookie  will handle redirect
       */
      if ((this.document.location.href.indexOf('newsletters') > 0 && this.document.location.href.indexOf('action') < 0) 
        || (this.document.location.href.indexOf('subscriptions/my-subscriptions') > 0)
        || (this.document.location.href.indexOf('subscriptions/add-new-subscriptions') > 0)) {
        cookieUtils.setCookie('amcRedirectNewsletters', 'Y');
      } else {
        const cookieVal = cookieUtils.getCookie('amcRedirectNewsletters');
        if (cookieVal && cookieVal === 'Y') {
          cookieUtils.deleteCookie('amcRedirectNewsletters');
        }
      }

      let headers = new HttpHeaders();
      if(skipInterceptor){
        headers = headers.set('skip-interceptor', 'true');
      }
      return this.http.get<UserProfileRecordResponse>('/api/account/',{ headers }).pipe(
        map((profile) => {
          this.profile = profile.data;

          (window['gaDataLayer'] || []).push({
            'event': 'dataLayerReady',
            'life_stage': this.getLifeStageInfo(),
            'member_status': this.isMember ? 'member' : 'non-member',
            'tam_id': this.uniqueId || 'unknown'
          });

          return this.profile;
        })
      );
    }
  }
}
