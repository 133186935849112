<div>{{ addressName }} Address</div>
<div [formGroup]="formGroup">
  <div *ngIf="hasPrimaryAddress && !autorenewFormService.hasChangedAddressOnSubmit" class="show-address-info">
    <div *ngFor="let address of addresses">
      <span>{{ address.lineOne }}</span>
      <span *ngIf="address.lineTwo">, {{ address.lineTwo }}</span>
      <span *ngIf="address.lineThree">, {{ address.lineThree }}</span>
      <span>, {{ address.city }}</span>
      <span>, {{ address.stateTerritory }}</span>
      <span> {{ address.zipCode }}</span>
    </div>
    <app-link class="change-method-link" (click)="newAddress()">
      Change
    </app-link>
  </div>
</div>
<div *ngIf="!hasPrimaryAddress || autorenewFormService.hasChangedAddressOnSubmit" class="empty-address-info">
  <div>
    <ama__validated-dropdown-input__v1
      [formGroup]="formGroup"
      [label]="{name: 'Address Type*'}"
      [fieldName]='addressType'
      placeholder=""
      [options]="addressTypes">
    </ama__validated-dropdown-input__v1>
    <ama__validated-text-input__v1
      [formGroup]="formGroup"
      [itemLabel]="{name: 'Address Line 1*'}"
      itemMaxLength="40"
      [fieldName]="lineOne"
      itemPlaceholder="Street Address">
    </ama__validated-text-input__v1>
  </div>
  <div>
    <ama__validated-text-input__v1
      [formGroup]="formGroup"
      [itemLabel]="{name: 'Address Line 2'}"
      itemMaxLength="40"
      [fieldName]="lineTwo"
      itemPlaceholder="Apartment, Ste, Unit, Building, Floor">
    </ama__validated-text-input__v1>
    <ama__validated-text-input__v1
      [formGroup]="formGroup"
      [itemLabel]="{name: 'Address Line 3'}"
      itemMaxLength="40"
      [fieldName]="lineThree"
      itemPlaceholder="Company Name, Care Of, etc.">
    </ama__validated-text-input__v1>
  </div>
  <div>
    <ama__validated-text-input__v1
      [formGroup]="formGroup"
      [itemLabel]="{name: 'City*'}"
      itemMaxLength="28"
      [fieldName]="city"
      itemPlaceholder="">
    </ama__validated-text-input__v1>
    <div>
      <ama__validated-dropdown-input__v1
        [formGroup]="formGroup"
        [label]="{name: 'State*'}"
        [fieldName]="stateTerritory"
        itemMaxLength="2"
        placeholder=""
        [options]="stateOptions">
      </ama__validated-dropdown-input__v1>
      <ama__validated-text-input__v1
        [formGroup]="formGroup"
        [itemLabel]="{name: 'Zip Code*'}"
        [fieldMask]="zipCodeMask"
        itemMaxLength="10"
        [fieldName]="zipCode"
        itemPlaceholder="">
      </ama__validated-text-input__v1>
    </div>
  </div>
</div>
<div *ngIf="!hasPrimaryAddress" class="required-desc">*indicates a required field</div>
