import { Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ModalService } from 'app/ui-components/modals/modal.service';
import { WalletCartFormComponent } from './../form.component';
import { WalletService, CCardInfoFromWallet } from '../wallet.service';
import { UserService } from 'app/users/user.service';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { serverError } from '../../../utils/alerts.utils';

@Component({
  selector: 'app-declined-card-section',
  templateUrl: './declined-card-section.component.html'
})
export class DeclinedCardSectionComponent implements OnInit {
  declinedCard: CCardInfoFromWallet;
  showSpinner: boolean;
  buttonsDisabled = false;

  constructor(
    private walletService:  WalletService,
    protected modalService: ModalService,
    protected userService: UserService,
    protected alertService: AlertsService,
    protected dialog: MatDialog,
  ) {}

  ngOnInit() {
  }

  get isDeclinedCardExist (): boolean {
    if (this.walletService.declinedCard()) {
      this.declinedCard = this.walletService.declinedCard()
      return true;
    }
    return false
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(WalletCartFormComponent, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {model: this.declinedCard}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'noupdate') {
        this.retrieveList();
      }
    });
  }
  public isLoading(): void {
    this.showSpinner = true;
    this.buttonsDisabled = true;
  }
  public isFinishedLoading(): void {
    this.showSpinner = false;
    this.buttonsDisabled = false;
  }
  retrieveList(): void {
    const status: string = 'active';
    this.isLoading();
    this.walletService.getCreditCardListFromWallet(status).subscribe(
      (creditCardsInfo) => {
        this.walletService.creditCardsInfoSaved =  creditCardsInfo;
        this.isFinishedLoading();
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
        this.isFinishedLoading();
      }
    );
  }

  public isDeclinedCardDigital(): boolean {
    if(!this.declinedCard.creditCard) return false;
    return ['Apple Pay', 'Google Pay'].includes(this.declinedCard.creditCard.type);
  }

}
