import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AmaValidatedDropdownInputV1Component } from './ama__validated-dropdown-input__v1.component';

@NgModule({
  declarations: [AmaValidatedDropdownInputV1Component],
  exports: [AmaValidatedDropdownInputV1Component],
  imports: [ReactiveFormsModule, CommonModule],
})
export class AmaValidatedDropdownInputV1Module {}
