<div class="layout">

  <div class="content profile-information-page">

    <app-card-header title="Profile Information">
      <app-professional-details></app-professional-details>
      <app-list-sogi></app-list-sogi>
    </app-card-header>
    <app-name-change-link></app-name-change-link>
    <app-contact-us-link></app-contact-us-link>
  </div>

</div>
