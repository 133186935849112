import { Component } from '@angular/core';
import { UserService } from '../../../users/user.service';
import { MembershipService } from 'app/membership-status/membership.service';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html'
})
export class TopNavigationComponent {

  get isPhysician(): Boolean {
    return this.userService.isPhysician;
  }

  constructor(
    protected userService: UserService,
    private membershipService: MembershipService,
  ) {}

  /**
   * AR-23: If the Join/Renew iFrame is visible and a user navigates to a different tab,
   * make the iFrame not visible again if a user navigates back to the Membership page
   */
  public closeIframeIfOpen() {
    if (this.membershipService.showAlternateRatesIframe) {
      this.membershipService.showAlternateRatesIframe = false;
    }
  }
}
