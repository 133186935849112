import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfessionalDetailsFormComponent } from '../professional-details-form/form.component';
import { ProfessionalDetailsService } from '../professional-details-service';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { getStateNameByAbbr } from 'app/utils/states';

@Component({
  selector: 'app-professional-details',
  templateUrl: './professional-details-list.component.html',
})
export class ProfessionalDetailsListComponent implements OnInit {
  private maxProfessionalDetails: number = 10;

  constructor(
    protected dialog: MatDialog,
    protected service: ProfessionalDetailsService,
    private modalService: ModalService,
  ) { }

  get hasProfData(): boolean {
    return !!this.service.professionalDetails && this.service.professionalDetails.length > 0;
  }

  get professionalDetails() {
    return this.service.professionalDetails;
  }

  get canAdd(): boolean {
    return !!this.service.professionalDetails && this.service.professionalDetails.length < this.maxProfessionalDetails;
  }

  ngOnInit() {
    this.service.getProfessionalDetails().subscribe((response) => {
    });
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(ProfessionalDetailsFormComponent, {
      width: this.modalService.getAppropriateWidthProperty(550),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: { isEditForm: false}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.service.refreshProfessionalDetailsList();
    });
  }

  openEditDialog(professionalDetail): void {
    const dialogRef = this.dialog.open(ProfessionalDetailsFormComponent, {
      width: this.modalService.getAppropriateWidthProperty(550),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {professionalDetailEntity: professionalDetail, isEditForm: true}
    });
    dialogRef.afterClosed().subscribe(result => { 
      this.service.refreshProfessionalDetailsList(); });
  }

  protected getStateName(name: string) {
    return getStateNameByAbbr(name);
  }
}
