import { MailingAddress, MailingAddressRecord } from './mailing-address';
import { MailingAddressesService } from './mailing-addresses.service';
import { StateOrTerritory, states } from '../utils/states';
import { AddressType, addressTypes } from './address-types';
import { IFormComponent } from '../IFormComponent';
import {
  ContactPreferencesNavigationService,
  ContactType,
  ActiveSection
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';

import * as $ from 'jquery';
import * as _ from 'lodash';

import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';

export class MailingAddressesFormComponent implements IFormComponent {

  model: Partial<MailingAddressRecord>;

  states: StateOrTerritory[] = states;
  addressTypes: AddressType[] = addressTypes;
  name: ContactType = 'mailing address';
  buttonsDisabled = false;
  zipCodeMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/];
  verificationSection: ActiveSection;
  addressForm: FormGroup;
  fields: AbstractControl[];

  get type() {
    return this.addressForm.get('type');
  }

  get lineOne() {
    return this.addressForm.get('lineOne');
  }

  get lineTwo() {
    return this.addressForm.get('lineTwo');
  }

  get lineThree() {
    return this.addressForm.get('lineThree');
  }

  get city() {
    return this.addressForm.get('city');
  }

  get state() {
    return this.addressForm.get('stateTerritory');
  }

  get zip() {
    return this.addressForm.get('zipCode');
  }

  get id() {
    return this.addressForm.get('id');
  }

  get formData() {
    const data: Partial<MailingAddressRecord> = {
      type: this.type.value,
      lineOne: this.lineOne.value,
      city: this.city.value,
      stateTerritory: this.state.value,
      zipCode: this.zip.value,
    };

    if (this.lineTwo.value !== '') {
      data.lineTwo = this.lineTwo.value;
    }

    if (this.lineThree.value !== '') {
      data.lineThree = this.lineThree.value;
    }

    if (this.id.value !== '') {
      data.id = this.id.value;
    }

    return data;
  }

  constructor(
    protected service: MailingAddressesService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<MailingAddressesFormComponent>,
  ) {
    this.addressForm = this.formBuilder.group({
      lineOne: ['', Validators.required],
      lineTwo: [''],
      lineThree: [''],
      city: ['', Validators.required],
      stateTerritory: ['', Validators.required],
      zipCode: ['', Validators.compose([Validators.required, Validators.pattern(/\d{5}[-\d{4}]?/)])],
      type: ['', Validators.required],
      id: [''],
    });
    this.fields = [this.type, this.lineOne, this.lineTwo, this.lineThree, this.city, this.state, this.zip];
  }

  validateForm() {
    if (this.addressForm.valid) {
      this.onFormSubmit();
    } else {
      _.each(this.fields, (field) => {
        field.markAsTouched();
      })
    }
  }

  onFormSubmit() {
    this.disableButton();
    const address = this.getAddressToNormalize();
    const editedType = this.formData.type;
    this.service.normalizeMailingAddress(address, editedType).subscribe(
      (result) => {
        this.service.verificationResult = result;
        this.service.originalAddress = this.model;
        this.navService.setActiveSection(this.name, this.verificationSection);
        this.onVerify();
      },
      (err) => {
        console.log('skipping normalize step');
        this.skipNormalizeStep();
      }
    );
  }

  onVerify(): void {
    this.dialogRef.close(this.verificationSection);
  }

  isNotPresent(field): boolean {
    return field.touched && field.errors && field.errors['required'];
  }

  zipCodeIsInvalid(): boolean {
    return this.zip.touched && this.zip.errors && this.zip.errors['pattern'] && !this.zip.errors['required'];
  }

  protected skipNormalizeStep() { }

  private disableButton() {
    this.buttonsDisabled = true;
  }

  private getAddressToNormalize(): MailingAddress {
    const address: MailingAddress = {
      lineOne: this.formData.lineOne,
      city: this.formData.city,
      stateTerritory: this.formData.stateTerritory,
      zipCode: this.formData.zipCode,
    };

    if (this.formData.lineTwo) {
      address.lineTwo = this.formData.lineTwo;
    }

    if (this.formData.lineThree) {
      address.lineThree = this.formData.lineThree;
    }

    return address;
  }
}
