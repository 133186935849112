import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-turn-off-autorenew-modal',
  templateUrl: './turn-off-autorenew-modal.component.html',
})
export class TurnOffAutorenewModalComponent {

  constructor(
    public dialogRef: MatDialogRef<TurnOffAutorenewModalComponent>,
) {}

  disableAutorenew() {
    this.dialogRef.close('disable');
  }

  closeAutorenewModal() {
    this.dialogRef.close();
  }

  get currentYear(): number {
    return new Date(Date.now()).getFullYear();
  }
}
