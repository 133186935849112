import { Component, OnInit, Inject } from '@angular/core';
import { EmailFormComponent } from '../form.component';
import { IFormComponent } from 'app/IFormComponent';
import { FormBuilder } from '@angular/forms';
import { EmailAddressesService } from '../email-addresses.service';
import { EmailAddressesListComponent } from '../list.component';
import { NewEmailAddressComponent } from '../new.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-primary-email',
  templateUrl: './change-primary-email.component.html'
})

/**
   * AR-15 AR-197 Add Change Primary Email Link and Modal
   * The change primary modal reuses the new and list email components
   * however, we will never edit an email on the change primary modal
   */
export class ChangePrimaryEmailComponent extends EmailFormComponent implements OnInit, IFormComponent {

  /**
   * When a user logs in, and has 2 emails being used for subscriptions,
   * we will display this modal with the title being "Change Primary Email"
   *
   * When a user clicks on the change primary link in the newsletter's tab,
   * we will display this modal with the title being "Confirm Primary Email"
  */
  protected isConfirmModal: boolean= false;

  constructor(
    protected formBuilder: FormBuilder,
    public service: EmailAddressesService,
    protected dialogRef: MatDialogRef<ChangePrimaryEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(formBuilder);
    this.dialogRef.afterClosed().subscribe(() => {
      this.onClosed();
    });
  }

  get title() {
    if (!!this.data.isConfirmModal) {
      this.service.buttonValue = 'Confirm';
      return 'Confirm';
    } else {
      this.service.buttonValue = 'Save';
      return 'Change';
    }
  }

  get addNew() {
    return this.service.addNew;
  }

  setAddNew(newVal: boolean) {
    this.service.addNew = newVal;
  }

  addNewClick() {
    this.service.addNew = !this.service.addNew;
  }

  // If the user clicks the radio buttons to change the primary, set addNew to false
  onNoClick() {
    this.dialogRef.close();
  }

  onClosed() {
    this.setAddNew(false);
    this.isConfirmModal = false;
    this.service.isChangePrimaryModal = false;
  }

  /**
   * The isChangePrimaryModal boolean var is used to
   * manipulate the new and list email components
   */
  ngOnInit() {
    this.service.isChangePrimaryModal = true;
  }
}
