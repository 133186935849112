import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, forkJoin, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'app/utils/api-data.utilities';
import { MailingAddressParams, MailingAddressRecord } from 'app/mailing-addresses/mailing-address';
import * as _ from 'lodash';
import { UserProfileRecord } from 'app/users/user-credentials';
import { AnalyticsService } from 'app/analytics/analytics.service';

const EMPTY_AD_DETAILS = {
  id: 0,
  description: '',
  imageUrl: '',
  isEligible: false,
}

export interface CreditCard {
  type?: string;
  nameOnCard: string;
  expiration: string;
  lastFourDigits: string;
}

export interface PaymentInformation {
  entityId?: number;
  autoRenew: boolean;
  isEligible: boolean;
  billingAddress?: MailingAddressParams;
  shippingAddress?: MailingAddressParams;
  creditCard?: CreditCard;
  autoRnwYear?: number;
  ccDisplayRestrict?: boolean;
  incentiveEligible?: boolean;
  endPeriodDate?: string;
}

export interface UpdateCreditCardRequest {
  name: string;
  expiration: string;
  number: string;
  billingAddress: MailingAddressParams,
  profileFirstName?: string;
  profileLastName?: string;
  profileLabelName?: string;
}

export interface UpdateAutoRenewRequest {
  enabled: boolean;
}

export interface GetMembershipHistoryRequest {
  meNumber: string;
}

export interface AdDetails {
  id: number,
  name?: string,
  description: string,
  imageUrl: string,
}

export interface AdDetailsWithPayment extends AdDetails {
  isEligible: boolean,
  isAutoRenew?: boolean;
}

/**
 * Object that holds the year a member became a member
 */
export interface MemberSince {
  memberSinceYear: number
}

export interface IMembershipService {
  paymentInfo: PaymentInformation;
  getUpsellAdWithPayment(): Observable<AdDetailsWithPayment>;
}

export interface Product {
  autorenewEligibilityIndicator: Boolean,
  effortCode: string,
  freeYears: number,
  halfYearDiscount: number,
  halfYearDuesDiscountCode: string,
  installmentIndicator: Boolean,
  invoiceId: number,
  membershipYear: number,
  multiyearIndicator: Boolean,
  premiumCode: string,
  productCode: string,
  productDescription: string,
  productName: string,
  productPrice: string,
  returnCode: number,
  returnMessage: string
}

export interface JoinRenewParams {
  meNumber: string,
  purchaserFirstName: string,
  purchaserLastName: string,
  purchaserAddress1: string,
  purchaserAddress2?: string,
  purchaserCity: string,
  purchaserState: string,
  purchaserZip: string,
  orderFulfillmentDate: string,
  emailAddress: string,
  membershipYear: number,
  productCode: string,
  productPrice: string,
  halfYearDiscountPrice: number,
  halfYearDuesDiscountCode: string,
  multiyearIndicator: Boolean,
  autorenewEligibilityIndicator?: Boolean,
  invoiceId: number,
  effortCode: string,
  renewalFlag?: boolean,
  creditCardDetails: {
    name: string,
    number: string,
    creditCardWalletId: number,
    type: string,
    expiration: string ,
    billingAddress: {
      lineOne: string,
      lineTwo: string,
      lineThree: string,
      city: string,
      stateTerritory: string,
      zipCode: string,
      type: string
    },
    profileFirstName: string,
    profileLastName: string,
    profileLabelName: string,
    chargeAmount: number
  }
}

export interface MemberToAdvantage {
  membershipYear: number;
  productCode: string;
  firstName: string;
  lastName: string;
  customerType: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  stateCode: string;
  postalCode: string;
}

@Injectable()
export class MembershipService implements IMembershipService {
  paymentInfo: PaymentInformation;
  adDetails: AdDetailsWithPayment = EMPTY_AD_DETAILS;
  memberSince: MemberSince;
  membershipYear: number;
  allProducts: Product[];
  defaultProducts: Map<number, Product>;

  @Output() refreshWalletEvent = new EventEmitter<string>();

  refreshWallet(msg: string) {
    this.refreshWalletEvent.emit(msg);
  }

  constructor(
    protected httpClient: HttpClient,
    private analyticsService : AnalyticsService) {

    this.defaultProducts = new Map<number, Product>();

  }

  private _showAlternateRatesIframe: boolean = false;
  get showAlternateRatesIframe(): boolean {
    return this._showAlternateRatesIframe;
  }
  set showAlternateRatesIframe(status: boolean) {
    this._showAlternateRatesIframe = status;
  }

  private _autorenewToggleState = false;
  get autorenewToggleState(): boolean {
    return this._autorenewToggleState;
  }
  set autorenewToggleState(status: boolean) {
    this._autorenewToggleState = status;
  }

  public isAutoRenewEligable(): boolean {
    return this.paymentInfo.isEligible;
  }

  public isAutoRenewEligableWithAd(): boolean {
    return this.paymentInfo.isEligible && (this.adDetails.id !== EMPTY_AD_DETAILS.id);
  }

  public getPaymentInformation(): Observable<PaymentInformation> {
    // need to refresh payment information all the time because changes can be done in wallet
    // if (this.paymentInfo) {
    //   return of(this.paymentInfo);
    // }

    return this.httpClient.get('/api/membership/payment-information').pipe(
      map((response: ApiResponse<PaymentInformation>) => {
        this.paymentInfo = response?.data;
        this.autorenewToggleState = response?.data.autoRenew;
        return this.paymentInfo;
      })
    );
  }

  public enableAutorenew(): Observable<any> {
    this.analyticsService.autoRenewSelected('enabled');
    return this.updateAutorenewPreferences({ enabled: true });
  }

  public disableAutorenew(): Observable<any> {
    this.analyticsService.autoRenewSelected('disabled');
    return this.updateAutorenewPreferences({ enabled: false });
  }

  public updateCreditCardDetails(request: UpdateCreditCardRequest): Observable<any> {
    return this.httpClient.put('/api/membership/credit-card', request).pipe(
      map((response: ApiResponse<any>) => {
        return response?.data;
      })
    );
  }

  public updateBillingAddress(request: MailingAddressParams): Observable<MailingAddressParams> {
    return this.httpClient.put('/api/membership/billing-address', request).pipe(
      map((response: ApiResponse<MailingAddressParams>) => {
        return response?.data;
      })
    );
  }

  public getUpsellAdDetails(): Observable<AdDetails> {
    return this.httpClient.get<ApiResponse<AdDetails>>('/api/membership/upsell-ad').pipe(
      map((response: ApiResponse<AdDetails>) => {
        return response?.data;
      })
    );
  }

  public getUpsellAdWithPayment(forceRefresh: boolean = false): Observable<AdDetailsWithPayment> {
    if (this.adDetails.id !== EMPTY_AD_DETAILS.id && !forceRefresh) {
      return of(this.adDetails);
    }

    return forkJoin(
      this.getUpsellAdDetails(),
      this.getPaymentInformation()
    ).pipe(
      map(([adDetails, paymentInfo]) => {
        this.adDetails = {
          ...adDetails,
          isEligible: paymentInfo.isEligible && !_.isEmpty(adDetails) && adDetails.description !== '',
          isAutoRenew: paymentInfo.autoRenew && !_.isEmpty(adDetails) && adDetails.description !== ''
        }
        return this.adDetails;
      })
    );
  }

  /**
   * Returns the year a member became a member.
   */
  public getMemberSince(forceRefresh: boolean = false): Observable<MemberSince> {
    if (this.memberSince && !forceRefresh) {
      return of(this.memberSince);
    }

    return this.httpClient.get<ApiResponse<MemberSince>>('api/membership/history').pipe(
      map((response: ApiResponse<MemberSince>) => {
        this.memberSince = response?.data;
        return this.memberSince;
      })
    );
  }

  public getMembershipYear(): Observable<number> {
    return this.httpClient.get<ApiResponse<number>>('api/membership/current-year').pipe(
      map((response: ApiResponse<number>) => {
        this.membershipYear = response?.data;

        //remove before merge !!!!!!!!!!!!!!!!
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        //this.membershipYear = 2023;
        return this.membershipYear
      })
    )
  }

  public getDefaultProduct(membershipYear: number, forceRefresh: boolean = false): Observable<Product> {
    if (this.defaultProducts.has(membershipYear) && !forceRefresh) {
      return of(this.defaultProducts.get(membershipYear));
    }

    const params = new HttpParams()
      .set('membershipYear', membershipYear?.toString());

    return this.httpClient.get<ApiResponse<Product>>('api/membership/product/default', { params: params }).pipe(
      map((response: ApiResponse<Product>) => {
        this.defaultProducts.set(membershipYear, response?.data);
        return this.defaultProducts.get(membershipYear);
      })
    );
  }

  public getAllProducts(forceRefresh: boolean = false): Observable<Product[]> {
    if (this.allProducts && !forceRefresh) {
      return of(this.allProducts);
    }

    return this.httpClient.get<ApiResponse<Product[]>>('api/membership/product').pipe(
      map((response: ApiResponse<Product[]>) => {
        this.allProducts = response?.data;
        return this.allProducts;
      })
    );
  }

  public joinRenew(params: JoinRenewParams): Observable<string> {
    return this.httpClient.post('/api/membership/joinrenew', params).pipe(map((response) => response.toString()));
  }

  public buildJoinRenewParams(
    user: UserProfileRecord,
    product: Product,
    payment: PaymentInformation,
    address: MailingAddressRecord,
    card: CreditCard,
    walletId: number,
    renewalFlag?: boolean,
    chargeAmount?: number): JoinRenewParams {

    // This generates the Date needed for the Order Fulfillment Date.
    // This is similar to the method used by the Join/Renew App as it also uses UTC for order fulfillment date.
    const date = new Date();
    const year = date.getUTCFullYear().toString();
    let month = (date.getUTCMonth() + 1).toString();
    let day = date.getUTCDate().toString();
    // Pad our month and day, as those can come back as single digits
    if (month.length === 1) {
      month = '0' + month;
    }

    if (day.length === 1) {
      day = '0' + day;
    }

    // Assemble our date in a MMddyyyy format.
    // For example, 8-April-2020 becomes: 04082020.
    const dateString = month + day + year;

    return {
      meNumber: user.meNumber,
      purchaserFirstName: user.firstName,
      purchaserLastName: user.lastName,
      purchaserAddress1: address.lineOne,
      purchaserAddress2: address.lineTwo,
      purchaserCity: address.city,
      purchaserState: address.stateTerritory,
      purchaserZip: address.zipCode,
      orderFulfillmentDate: dateString,
      emailAddress: user.email,
      membershipYear: product.membershipYear,
      productCode: product.productCode,
      productPrice: product.productPrice,
      halfYearDiscountPrice: product.halfYearDiscount,
      halfYearDuesDiscountCode: product.halfYearDuesDiscountCode,
      multiyearIndicator: product.multiyearIndicator,
      autorenewEligibilityIndicator: product.autorenewEligibilityIndicator,
      invoiceId: product.invoiceId,
      effortCode: product.effortCode,
      renewalFlag: renewalFlag,
      creditCardDetails: {
        name: card.nameOnCard,
        number: card.lastFourDigits,
        creditCardWalletId: walletId,
        type: card.type,
        expiration: card.expiration,
        billingAddress: {
          lineOne: payment.billingAddress.lineOne ? payment.billingAddress.lineOne : address.lineOne,
          lineTwo: payment.billingAddress.lineTwo ? payment.billingAddress.lineTwo : address.lineTwo,
          lineThree: payment.billingAddress.lineThree ? payment.billingAddress.lineThree : address.lineThree,
          city: payment.billingAddress.city ? payment.billingAddress.city : address.city,
          stateTerritory: payment.billingAddress.stateTerritory ? payment.billingAddress.stateTerritory : address.stateTerritory,
          zipCode: payment.billingAddress.zipCode ? payment.billingAddress.zipCode : address.zipCode,
          type: payment.billingAddress.type ? payment.billingAddress.type : address.type
        },
        profileFirstName: user.firstName,
        profileLastName: user.lastName,
        profileLabelName: user.labelName,
        chargeAmount: chargeAmount
      }
    }
  }

  private updateAutorenewPreferences(params: UpdateAutoRenewRequest): Observable<any> {
    const ret: Observable<any> =  this.httpClient.put('/api/membership/auto-renew', params);
    this.adDetails.isAutoRenew = params.enabled;
    return ret;
  }

    /**
   * Submit membership information to Advantage
   */
    public updateAdvantage(user, product, address, personType): Observable<ApiResponse<any>> {
      const data = this.buildDataToAdvantage(user, product, address, personType);
      return this.httpClient.post<ApiResponse<any>>(
        "/api/update-advantage",
        data
      );
    }
    /**
     * Builds data needed to pass to Advantage API.
     */
    buildDataToAdvantage(user: UserProfileRecord,
       product: Product,
       address: MailingAddressRecord, personType: string): MemberToAdvantage {
      const advData = {
        firstName: user.firstName,
        lastName: user.lastName,
        membershipYear: product.membershipYear,
        productCode: product.productCode,
        customerType: personType,
        addressLine1: address.lineOne,
        addressLine2: address.lineTwo,
        addressLine3: '',
        city: address.city,
        stateCode: address.stateTerritory,
        postalCode: address.zipCode,
      };
      return advData;
    }

}
