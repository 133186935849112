import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TextMaskModule } from 'angular2-text-mask';

import { EditFaxNumberComponent } from './edit.component';
import { NewFaxNumberComponent } from './new.component';
import { FaxNumbersHomeComponent } from './home.component';
import { FaxNumbersListComponent } from './list.component';
import { FaxNumbersService } from './fax-numbers.service';
import { FaxNumbersMemoryAPI } from './fax-numbers.memory-api';
import { ButtonsModule } from '../ui-components/buttons/buttons.module';
import { ModalsModule } from '../ui-components/modals/modals.module';
import { ContactNumbersModule } from '../contact-numbers/contact-numbers.module';
import { TransitionsModule } from '../ui-components/transitions/transitions.module';
import { MaxRecordsConfig } from '../../config/max-database-records.config';
import { SpinnersModule } from '../ui-components/spinners/spinners.module';
import { CardsModule } from '../ui-components/cards/cards.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    ButtonsModule,
    ModalsModule,
    ContactNumbersModule,
    TransitionsModule,
    SpinnersModule,
    CardsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRippleModule
  ],
  declarations: [
    EditFaxNumberComponent,
    NewFaxNumberComponent,
    FaxNumbersHomeComponent,
    FaxNumbersListComponent,
   ],
  exports: [
    FaxNumbersHomeComponent,
    EditFaxNumberComponent,
  ],
  providers: [
    FaxNumbersService,
    FaxNumbersMemoryAPI,
    MaxRecordsConfig,
  ]
})
export class FaxNumbersModule { }
