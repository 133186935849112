import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Incentive, IncentiveSubcategory, IncentiveService, OrderIncentivesRequest } from './incentive.service';
import { MailingAddressesService } from 'app/mailing-addresses/mailing-addresses.service';
import { MailingAddress, OtherAddressRecord, MailingAddressParams } from 'app/mailing-addresses/mailing-address';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { creditCardErrorMessage, creditCardAndAutoRenewErrorMessage } from 'app/utils/alerts.utils';
import * as _ from 'lodash';
import { MembershipService } from './membership.service';
import { AnalyticsService } from 'app/analytics/analytics.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PaymentFormService } from './payment/payment-form.service';
import { UserTrackingData, AutoRenewLoggingService, IAutoRenewLoggingService } from 'app/auto-renew-logging/auto-renew-logging.service';
import { UserService } from 'app/users/user.service';
import { CreditCardsWalletListComponent } from './wallet/ccwallet-list.component';
import { WalletService } from './wallet/wallet.service';
import { ModalService } from 'app/ui-components/modals/modal.service';

@Injectable()
export class AutoRenewFormService {
  autoRenewCheckboxForm: FormGroup;
  incentiveForm: FormGroup;
  billingAddressForm: FormGroup;
  shippingAddressForm: FormGroup;

  primaryAddress: OtherAddressRecord;
  incentiveOptions: Incentive[];

  defaultBillingId = 'bilto';
  billingAddresses: OtherAddressRecord[] = [];
  selectedBillingAddress: MailingAddressParams = null;

  defaultShippingId = 'shipto';
  shippingAddresses: OtherAddressRecord[] = [];
  selectedShippingAddress: MailingAddressParams = null;
  public normalizedShippingAddress: MailingAddressParams = null;
  public enteredShippingAddress: MailingAddressParams = null;
  hasNormalizedAddress = false;
  readonly idForNewBillingAddress = 'newBilTo';

  clickedEditAddress: boolean = false;
  hasSubmittedAutorenew: boolean = false;
  hasChangedCardOnSubmit: boolean = false;
  hasChangedAddressOnSubmit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private addressService: MailingAddressesService,
    private incentiveService: IncentiveService,
    private alertService: AlertsService,
    private membershipService: MembershipService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private paymentFormService: PaymentFormService,
    private autoRenewLoggingService: AutoRenewLoggingService,

    private modalService: ModalService,
    private walletService: WalletService,
    private dialog: MatDialog,
  ) {}

  isAutoRenewBoxChecked(): boolean {
    return this.autoRenewCheckboxForm.get('autoRenew').value;
  }

  createAutoRenewCheckboxForm() {
    this.autoRenewCheckboxForm = this.formBuilder.group({
      autoRenew: [this.membershipService.paymentInfo.autoRenew],
    });
  }

  createIncentiveForm(incentives: Incentive[]) {
    if (!this.autoRenewCheckboxForm) {
      this.createAutoRenewCheckboxForm();
    }
    this.incentiveOptions = incentives;
    this.incentiveForm = this.formBuilder.group({
      incentiveOption: ['', Validators.required],
      subcategoryOption: ['', this.subcategoryValidator.bind(this)],
      noGiftOption: '',
    });

    // If there are no incentives, just check the no gift option - this
    // is required for successful submission elsewhere in the code.
    if (incentives.length === 0) {
      this.incentiveForm.patchValue({ noGiftOption: true });
    }

    this.incentiveFormControl.valueChanges.subscribe(() => {
      if (this.incentiveFormControl.value) {
        this.incentiveForm.patchValue({ noGiftOption: '', subcategoryOption: '' });
      }
    });
    this.autoRenewCheckboxForm.get('autoRenew').valueChanges.subscribe(() => {
      this.incentiveFormControl.reset();
      this.noGiftOptionFormControl.reset();
      if (this.incentiveService.autoRenewIncentives.length === 0 && this.isAutoRenewBoxChecked()) {
        this.incentiveForm.patchValue({ noGiftOption: true });
      }
    });
  }

  createPaymentForm() {
    this.paymentFormService.createForm();
    this.billingAddressForm = this.formBuilder.group({
      billingAddressOptions: this.defaultBillingId,
      billingAddressType: [null, Validators.required],
      billingLineOne: [null, Validators.required],
      billingLineTwo: null,
      billingLineThree: null,
      billingCity: [null, Validators.required],
      billingStateTerritory: [null, Validators.required],
      billingZipCode: [null, Validators.required],
    });
  }

  createShippingAddressForm() {
    this.shippingAddressForm = this.formBuilder.group({
      shippingAddressOptions: null,
      shippingAddressType: [null, Validators.required],
      shippingLineOne: [null, Validators.required],
      shippingLineTwo: null,
      shippingLineThree: null,
      shippingCity: [null, Validators.required],
      shippingStateTerritory: [null, Validators.required],
      shippingZipCode: [null, Validators.required],
    })
  }

  updateCreditCardDetails(): Observable<any> {
    let cardUpdate: Observable<any> = of({});
    const addressUpdate: Observable<any> = of({});
    const billingAddress = this.toMailingAddressParams(this.primaryAddress);

    if (this.paymentFormService.creditCardDetailsChanged()) {
      cardUpdate = this.paymentFormService.submitWithBillingAddress(billingAddress);
    }

    return cardUpdate.pipe(switchMap(() => addressUpdate));
  }

  get selectedBillingAddressString(): string {
    return this.createFullAddress(this.selectedBillingAddress);
  }

  editBillingAddress() {
    this.selectedBillingAddress = null;
  }

  get shippingOptionControl(): AbstractControl {
    return this.shippingAddressForm.get('shippingAddressOptions');
  }

  get shippingType(): AbstractControl {
    return this.shippingAddressForm.get('shippingAddressType');
  }

  get shippingAddressLineOne(): AbstractControl {
    return this.shippingAddressForm.get('shippingLineOne');
  }

  get shippingAddressLineTwo(): AbstractControl {
    return this.shippingAddressForm.get('shippingLineTwo');
  }

  get shippingAddressLineThree(): AbstractControl {
    return this.shippingAddressForm.get('shippingLineThree');
  }

  get shippingCity(): AbstractControl {
    return this.shippingAddressForm.get('shippingCity');
  }

  get shippingState(): AbstractControl {
    return this.shippingAddressForm.get('shippingStateTerritory');
  }

  get shippingZipCode(): AbstractControl {
    return this.shippingAddressForm.get('shippingZipCode');
  }

  submitShippingAddressForm() {
      const selectedAddress = this.getSelectedShippingAddress();
      this.enteredShippingAddress = selectedAddress ? this.toMailingAddressParams(selectedAddress) : this.getShippingAddressValues();

      this.addressService.normalizeShippingAddress(this.enteredShippingAddress).subscribe(
        (result) => {
          if (result.normalized) {
            this.alertService.removeAllAlerts();
            this.normalizedShippingAddress = { ...result.normalized, type: result.editedType };
            this.hasNormalizedAddress = true;
          } else {
            this.membershipService.autorenewToggleState = false;
            this.membershipService.paymentInfo.autoRenew = false;
            this.alertService.displayError('Invalid Address, please try again');
          }
        },
        () => {
          this.membershipService.autorenewToggleState = false;
          this.membershipService.paymentInfo.autoRenew = false;
          this.alertService.displayError('Invalid Address, please try again');
        }
      );
  }

  confirmShippingAddress(chosenShippingAddress: MailingAddressParams) {
    this.selectedShippingAddress = chosenShippingAddress;
    this.updateCreditCardDetails()
      .pipe(switchMap(this.orderIncentives.bind(this)))
      .pipe(switchMap(this.enableAutorenew.bind(this)))
      .pipe(switchMap(this.trackEnabledAutoRenew.bind(this)))
      .subscribe(
        () => {
          this.hasNormalizedAddress = false;
          this.membershipService.paymentInfo.autoRenew = true;
          this.membershipService.paymentInfo.isEligible = true;
          this.setupAddresses();
          this.userService.requestNewAdInformationRefresh$.next();
          this.alertService.displaySuccess('Automatic renewal is on! You will receive your free gift in approximately 3 weeks.');
          // Reload Wallet fragment
          const walletListCompOneObj = new CreditCardsWalletListComponent(
            this.modalService, this.alertService, this.userService, this.walletService, this.membershipService,this.dialog );
            walletListCompOneObj.ngOnInit();
        },
        () => {
          this.hasNormalizedAddress = false;
          this.membershipService.autorenewToggleState = false;
          this.membershipService.paymentInfo.autoRenew = false;
          this.alertService.displayError(creditCardAndAutoRenewErrorMessage);
        }
      );
  }

  orderIncentives(): Observable<any> {
    const incentiveRequest: OrderIncentivesRequest = {
      entityId: this.membershipService.paymentInfo.entityId,
      mbrYear: this.membershipService.paymentInfo.autoRnwYear,
      inctvId: this.getSelectedIncentive() ? this.getSelectedIncentive().id : 0,
      inctvSubcatId: this.getSelectedIncentiveSubcategory() ? this.getSelectedIncentiveSubcategory().id : 0,
      shippingAddress: this.selectedShippingAddress,
    }
    return this.incentiveService.orderIncentives(incentiveRequest);
  }

  enableAutorenew(): Observable<any> {
    return this.membershipService.enableAutorenew().pipe(map(
      () => {
        this.membershipService.paymentInfo.autoRenew = true;
      }
    ));
  }

  trackEnabledAutoRenew(): Observable<any> {
    const trackingData: UserTrackingData[] = [
      { dataKey: 'Incentive Name', dataValue: this.getSelectedIncentiveName() }
    ];
    return this.autoRenewLoggingService.logEnabledAutoRenew(trackingData);
  }

  private getShippingAddressValues(): MailingAddressParams {
    let shippingAddress: MailingAddressParams;

    shippingAddress = {
      type: this.shippingType.value,
      lineOne: this.shippingAddressLineOne.value,
      city: this.shippingCity.value,
      stateTerritory: this.shippingState.value,
      zipCode: this.shippingZipCode.value,
    }

    if (this.shippingAddressLineTwo.value) {
      shippingAddress.lineTwo = this.shippingAddressLineTwo.value;
    }

    if (this.shippingAddressLineThree.value) {
      shippingAddress.lineThree = this.shippingAddressLineThree.value;
    }

    return shippingAddress;
  }

  private getSelectedShippingAddress(): OtherAddressRecord {
    if (this.shippingOptionControl.value) {
      return this.shippingAddresses.find((address: OtherAddressRecord) => {
        return address.id === this.shippingOptionControl.value;
      });
    }
  }

  private subcategoryValidator(control: AbstractControl): ValidationErrors | null {
    const hasIncentiveOptions = this.incentiveOptions && this.incentiveOptions.length > 0;
    if (!this.incentiveForm || !hasIncentiveOptions) {
      return null;
    }
    const incentive = this.getSelectedIncentive();
    const activeIncentiveHasSubcategories = this.getSelectedIncentive() && incentive.subcategories && incentive.subcategories.length > 0;
    if (activeIncentiveHasSubcategories) {
      if (incentive.subcategories.map(sub => '' + sub.id).includes('' + control.value)) {
        return null;
      } else {
        return { required: true };
      }
    } else {
      return null;
    }
  }

  private getSelectedIncentive(): Incentive {
    if (this.incentiveFormControl.value) {
      return this.incentiveOptions.find((incentive: Incentive) => {
        return incentive.id === +this.incentiveFormControl.value;
      });
    }
  }

  private getSelectedIncentiveSubcategory(): IncentiveSubcategory {
    if (this.getSelectedIncentive() && this.getSelectedIncentive().subcategories) {
      return this.getSelectedIncentive().subcategories.find((subcategory: IncentiveSubcategory) => {
        return subcategory.id === +this.subcategoryFormControl.value;
      });
    }
  }

  private getSelectedIncentiveName(): string {
    const incentive = this.getSelectedIncentive();
    const subcategory = this.getSelectedIncentiveSubcategory();

    if (incentive && subcategory) {
      return `${incentive.name} - ${subcategory.name}`;
    } else if (incentive) {
      return incentive.name;
    } else {
      return 'No Incentive';
    }
  }

  get incentiveFormControl(): AbstractControl {
    return this.incentiveForm.get('incentiveOption');
  }

  get subcategoryFormControl(): AbstractControl {
    return this.incentiveForm.get('subcategoryOption');
  }

  toggleNoGiftOption() {
    if (!this.isNoGiftOptionChecked()) {
      this.incentiveFormControl.reset();
    }
  }

  isNoGiftOptionChecked() {
    return this.incentiveForm && this.noGiftOptionFormControl.value;
  }

  get noGiftOptionFormControl() {
    return this.incentiveForm.get('noGiftOption');
  }

  get billingOptionControl(): AbstractControl {
    return this.billingAddressForm.get('billingAddressOptions');
  }

  get billingAddressType(): AbstractControl {
    return this.billingAddressForm.get('billingAddressType');
  }

  get billingAddressLineOne(): AbstractControl {
    return this.billingAddressForm.get('billingLineOne');
  }

  get billingAddressLineTwo(): AbstractControl {
    return this.billingAddressForm.get('billingLineTwo');
  }

  get billingAddressLineThree(): AbstractControl {
    return this.billingAddressForm.get('billingLineThree');
  }

  get billingCity(): AbstractControl {
    return this.billingAddressForm.get('billingCity');
  }

  get billingState(): AbstractControl {
    return this.billingAddressForm.get('billingStateTerritory');
  }

  get billingZipCode(): AbstractControl {
    return this.billingAddressForm.get('billingZipCode');
  }

  isIncentiveFormComplete() {
    return this.isAnIncentiveSelected() || this.isNoGiftOptionChecked();
  }

  isAnIncentiveSelected() {
    return this.incentiveFormControl.value;
  }

  shouldShowPaymentForm(): boolean {
    if (!this.incentiveForm) {
      return false;
    }
    return this.isNoGiftOptionChecked() || (this.isAnIncentiveSelected() && this.selectedBillingAddress === null);
  }

  shouldShowShippingForm(): boolean {
    if (!this.incentiveForm) {
      return false;
    }
    return this.isAnIncentiveSelected() && this.selectedBillingAddress !== null;
  }

  shouldShowAutoRenewForms() {
    const isAutoRenewOff = !this.membershipService.paymentInfo.autoRenew;
    const hasIncentives = this.incentiveService.autoRenewIncentives.length > 0;
    return isAutoRenewOff && (hasIncentives || this.isNoGiftOptionChecked());
  }

  submitPaymentAndBilling() {
    if (this.membershipService.paymentInfo.ccDisplayRestrict) {
      this.selectedBillingAddress = this.membershipService.paymentInfo.billingAddress;
    } else {
      const isIncentiveFormValid = this.isNoGiftOptionChecked() || this.incentiveForm.valid;
      if (isIncentiveFormValid && this.paymentFormService.paymentForm.valid && this.isBillingAddressFormValid()) {
        const selectedAddress = this.getSelectedBillingAddress();
        const newBillingAddress = this.getBillingAddressValues();
        if (!selectedAddress) {
          const newAddress = this.toOtherAddressRecord(newBillingAddress, this.idForNewBillingAddress, 3, 'new billing');
          const newBillingAddressWasEnteredPreviously = this.shippingAddresses.find(address => address.id === this.idForNewBillingAddress);
          if (newBillingAddressWasEnteredPreviously) {
            this.shippingAddresses = this.updateNewBillingAddress(this.shippingAddresses, newAddress);
          } else {
            this.shippingAddresses.push(newAddress);
          }
          this.shippingAddresses.sort((n1, n2) => n1.displayOrder - n2.displayOrder);
        } else {
          this.shippingAddresses = this.shippingAddresses.filter(address => address.id !== this.idForNewBillingAddress)
        }

        this.shippingAddressForm.patchValue({ shippingAddressOptions: this.defaultShippingId })
        this.selectedBillingAddress = selectedAddress ? this.toMailingAddressParams(selectedAddress) : newBillingAddress;
      } else {
        this.selectedBillingAddress = null;
        this.showFormErrors(this.incentiveForm);
        this.showFormErrors(this.paymentFormService.paymentForm);
        this.showFormErrors(this.billingAddressForm);
      }
    }
  }

  private updateNewBillingAddress(addresses: OtherAddressRecord[], newAddress: OtherAddressRecord) {
    return addresses.map(address => {
      return address.id === this.idForNewBillingAddress ? newAddress : address;
    });
  }

  submitPaymentBillingAutorenew() {
    this.updateCreditCardDetails()
      .pipe(switchMap(this.enableAutorenew.bind(this)))
      .pipe(switchMap(this.trackEnabledAutoRenew.bind(this)))
      .subscribe(
        () => {
          this.selectedBillingAddress = null;
          this.selectedShippingAddress = null;
          this.membershipService.paymentInfo.autoRenew = true;
          this.membershipService.paymentInfo.isEligible = true;
          this.userService.requestNewAdInformationRefresh$.next();
          this.alertService.displaySuccess('Card added and automatic renewal is on.');
          // Reload Wallet fragment
          const walletListCompOneObj = new CreditCardsWalletListComponent(
            this.modalService, this.alertService, this.userService, this.walletService, this.membershipService,this.dialog );
            walletListCompOneObj.ngOnInit();
        },
        () => {
          this.membershipService.paymentInfo.autoRenew = false;
          this.membershipService.autorenewToggleState = false;
          this.alertService.displayError(creditCardAndAutoRenewErrorMessage);
        }
      );
  }

  private getSelectedBillingAddress(): OtherAddressRecord {
    if (this.billingOptionControl.value) {
      return this.billingAddresses.find((address: OtherAddressRecord) => {
        return address.id === this.billingOptionControl.value;
      });
    }
  }

  private getBillingAddressValues(): MailingAddressParams {
    let address: MailingAddressParams;

    address = {
      type: this.billingAddressType.value,
      lineOne: this.billingAddressLineOne.value,
      city: this.billingCity.value,
      stateTerritory: this.billingState.value,
      zipCode: this.billingZipCode.value,
    }

    if (this.billingAddressLineTwo.value) { address.lineTwo = this.billingAddressLineTwo.value; }
    if (this.billingAddressLineThree.value) { address.lineThree = this.billingAddressLineThree.value; }

    return address;
  }

  createFullAddress(address: MailingAddress, type?: string): string {
    const fields = [address.lineOne, address.lineTwo, address.lineThree,
      address.city, address.stateTerritory, address.zipCode];

    if (type) {
      fields.push(`(${type} address)`);
    }

    return this.joinValues(fields, ', ');
  }

  setupAddresses() {
    const fullPrimary = this.toOtherAddressRecord(this.primaryAddress, this.primaryAddress.id, 1, 'primary');
    this.selectedBillingAddress = this.primaryAddress;
    this.selectedShippingAddress = this.primaryAddress;
    this.billingAddresses = [fullPrimary];

    /* AR-31: Set default to be the primary - told by Nilesh that turning on Autorenew doesn't need billing info
       but the PaymentTech service does require an address for the Pre-authorization call so one needs to be set */
    this.defaultBillingId = this.primaryAddress.id;

    if (this.membershipService.paymentInfo && !_.isEmpty(this.membershipService.paymentInfo.billingAddress)) {
      const savedBilling = this.membershipService.paymentInfo.billingAddress;
      const billingAddress = this.toOtherAddressRecord(savedBilling, this.defaultBillingId, 2, 'current billing');
      this.billingAddresses.push(billingAddress);
      this.billingAddresses.sort((n1, n2) => n1.displayOrder - n2.displayOrder);
    }

    // AR-31: Gets only the primary address for shipping, keeping as array to stay consistent with Billing Address in case that gets brought back in
    this.shippingAddresses = [fullPrimary];
    this.defaultShippingId = this.primaryAddress.id;
  }

  private toMailingAddressParams(address: OtherAddressRecord): MailingAddressParams {
    let newAddress: MailingAddressParams;

    if (address) {
      newAddress = {
        type: address.type,
        lineOne: address.lineOne,
        city: address.city,
        stateTerritory: address.stateTerritory,
        zipCode: address.zipCode
      }

      if (address.lineTwo) { newAddress.lineTwo = address.lineTwo; }
      if (address.lineThree) { newAddress.lineThree = address.lineThree; }

      return newAddress;
    }
  }

  private toOtherAddressRecord(address: MailingAddressParams, id: string, order: number, label: string): OtherAddressRecord {
    return {
      ...address,
      id: id,
      usage: id,
      displayOrder: order,
      fullAddress: this.createFullAddress(address, label),
    };
  }

  private joinValues(values: (string | null | undefined)[], separator: string): string {
    return _.compact(values).join(separator);
  }

  showFormErrors(form: FormGroup) {
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        form.controls[i].markAsTouched();
        form.controls[i].markAsDirty();
      }
    }
  }

  isBillingAddressFormValid(): boolean {
    return this.isABillingAddressSelected() || this.billingAddressForm.valid;
  }

  isABillingAddressSelected(): boolean {
    return this.billingOptionControl.value && _.some(this.billingAddresses, address => {
      return address.id === this.billingOptionControl.value
    });
  }
}
