export function renderList(strings: string[]): string {
  switch (strings.length) {
    case 0:
      return '';
    case 1:
      return strings[0];
    case 2:
      return `${strings[0]} and ${strings[1]}`;
    default:
      const first = strings[0];
      const middle = strings.slice(1, strings.length - 1).reduce((acc, s) => acc + ', ' + s);
      const last = strings[strings.length - 1];
      return first + ', ' + middle + ', and ' + last;
  }
}
