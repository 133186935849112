import { Component, OnInit, } from '@angular/core';
import { EmailRecord } from './email-address';
import { IHomeComponent  } from '../IHomeComponent';
import { EmailAddressesService } from './email-addresses.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';

import {
  ActiveSection,
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { SubscriptionsData } from '../subscriptions/subscriptions';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { serverError } from 'app/utils/alerts.utils';

@Component({
  selector: 'app-email-addresses',
  templateUrl: './home.component.html'
})
export class EmailAddressesHomeComponent implements IHomeComponent, OnInit {
  editingEmail: Partial<EmailRecord>;
  name: ContactType = 'email';
  subscriptionData: SubscriptionsData = {
    numberOfSubscriptions: 0,
    categories: [],
  };

  get activeSection(): string {
    return this.navService.getActiveSection(this.name);
  }

  get disableAdd() {
    return this.service.disableAdd;
  }

  constructor(
    private navService: ContactPreferencesNavigationService,
    private service: EmailAddressesService,
    private subscriptionsService: SubscriptionsService,
    private alertService: AlertsService,
  ) { }

  ngOnInit() {
    this.subscriptionsService.getSubscriptions().subscribe(
      (response) => {
        this.subscriptionData = response;
      },
      (err) => {
        this.alertService.displayError(serverError);
      }
    );
    this.service.isChangePrimaryModal = false;
  }

  setActiveSection(newSection: ActiveSection): void {
    this.navService.setActiveSection(this.name, newSection);
  }

  onEdit(email: Partial<EmailRecord>) {
    this.editingEmail = email;
    this.setActiveSection('edit');
  }

  isVisible(sectionName: ActiveSection): boolean {
    return this.navService.getActiveSection(this.name) === sectionName;
  }
}
