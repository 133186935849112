import { Component } from '@angular/core';

import { FaxNumbersService } from './fax-numbers.service';
import { FaxNumberType, dropdownFaxNumberTypes } from './fax-number-types';
import { NewContactNumberComponent } from '../contact-numbers/new.component';
import {
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-new-fax-number',
  templateUrl: '../contact-numbers/form.component.html',
})
export class NewFaxNumberComponent extends NewContactNumberComponent {
  readonly title = 'Fax';
  readonly typesLabel = 'Fax Number Type*';
  readonly numberPlaceholder = 'Fax Number*';
  readonly extPlaceholder = 'Extension';
  numberTypes: FaxNumberType[] = dropdownFaxNumberTypes;
  name: ContactType = 'fax number';
  public isEditMode: boolean;
  public subscribed: boolean;

  constructor(
    protected service: FaxNumbersService,
    protected navService: ContactPreferencesNavigationService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected analyticsService: AnalyticsService,
    protected dialogRef: MatDialogRef<NewFaxNumberComponent>,
  ) {
    super(service, navService, alertService, formBuilder, analyticsService);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isPhone(phone?): boolean {
    return false;
  }

  isFax(fax?): boolean {
    return true;
  }
}
