<section id="mailing-addresses">
  <app-card-header title="Address" hasPrimary="true">
    <app-mailing-addresses-list
      (openEdit)='selectModal($event.type, $event.data)'>
    </app-mailing-addresses-list>

    <div class= "addButton" (click)="setActiveSection('new')">
      <a class="addr__add-button"></a>
      <span>Add New Address</span>
    </div>

  </app-card-header>
</section>
