import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform { 
    transform(value: string, truncationLimit: number): string {
        if (truncationLimit === undefined) {
            return value;
        }   
        if (value.length > truncationLimit) {
        return `${value.substring(0, truncationLimit)}...`;
        } else {
        return value;
        }
    }
}