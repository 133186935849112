<div class="generic-form-modal-title" [ngClass]="{'autorenew-generic-form-modal-with-incentive-title': paymentInformation.incentiveEligible}">{{modalTitle}}</div>
<div id="auto-renew-on-modal" class="generic-form-modal-body">
	<div class="gift-title" *ngIf="paymentInformation.incentiveEligible">Select a free gift</div>
	<app-incentives location="Auto-Renew Modal" [paymentInformation]="paymentInformation" [preSelectedIncentive]="data.selectedIncentive" class="modal-incentive"></app-incentives>

	<div *ngIf="paymentInformation.ccDisplayRestrict">
		<p class="no-margin centered">Your stored card information has been hidden at your request.
		To manage your card information and enable automatic renewal, call Member Service at (800) 262-3211 M-F from 8 a.m. to 5 p.m. CT.</p>
	</div>

	<div *ngIf="!paymentInformation.ccDisplayRestrict">
		<app-payment
			[formGroup]="paymentFormService.paymentForm"
			[paymentInformation]="paymentInformation">
		</app-payment>

		<app-membership-addresses
			[formGroup]='autoRenewFormService.billingAddressForm'
			[addresses]='autoRenewFormService.billingAddresses'
			[defaultId]='autoRenewFormService.defaultBillingId'
			addressName='Billing'
			type='billing'
			*ngIf="displayBilling">
		</app-membership-addresses>

    	<app-membership-addresses
	      *ngIf="paymentInformation.incentiveEligible"
			[formGroup]='autoRenewFormService.shippingAddressForm'
			[addresses]='autoRenewFormService.shippingAddresses'
			[defaultId]='autoRenewFormService.defaultShippingId'
			addressName='Shipping'
			type='shipping'>
		</app-membership-addresses>

		<div class="modal-buttons">
			<button (click)="turnAutorenewOn()">{{buttonTitle}}</button>
			<app-link (click)="closeDialog()">Cancel</app-link>
		</div>
	</div>
</div>
