import {Directive, OnInit} from '@angular/core';
import { EmailRecord, SubscriptionEmails } from '../../email-addresses/email-address';
import { AlertsService } from '../../ui-components/alerts/alerts.service';
import { EmailAddressesService } from '../../email-addresses/email-addresses.service';
import { SubscribableCategory, SubscribableRecord, SubscriptionsData } from '../subscriptions';
import { SubscriptionsService } from '../subscriptions.service';
import { Repo } from '../../utils/repo';
import { serverError } from '../../utils/alerts.utils';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { UserService } from 'app/users/user.service';

@Directive()
export class SubscribablesComponent implements OnInit {
  /**
   * Whether there is a pending deep link.
   */
  private hasDeepLink: boolean = false;

  /**
   * The ID of the newsletter to subscribe to for the pending deep link.
   */
  private deepLinkId: string = '';
  emailRepo: Repo<EmailRecord> = {primary: undefined, others: []};
  dataLoaded = false;
  showSpinner = true;
  filteredCategories: SubscribableCategory[];

  subscriptionData: SubscriptionsData = {
    numberOfSubscriptions: 0,
    categories: [],
  };

  readonly externalLinks = ['AMA Morning Rounds', 'JAMA Network™'];

  subscriptionEmails: SubscriptionEmails = {
    primary: null,
    alternate: null,
  };

  constructor(protected service: SubscriptionsService,
              protected alertService: AlertsService,
              protected emailService: EmailAddressesService,
              protected userService: UserService,
              ) {
  }

  get primaryEmail() {
    return this.subscriptionEmails.primary;
  }

  get secondaryEmail() {
    return this.subscriptionEmails.alternate;
  }

  get subscriptionEmailAddresses(): EmailRecord[] {
    return [this.primaryEmail, this.secondaryEmail].filter(e => !!e);
  }

  ngOnInit() {
    // Before we load subscriptions, read in any deep link information.
    // This needs to occur before loading data as we will leverage the
    // deep link info from local storage while creating our newsletter
    // objects to mark the one that should be subscribed to in a deep
    // link scenario.
    this.readDeepLink();

    forkJoin(
      this.service.getSubscriptions(),
      this.emailService.getEmails(),
      this.emailService.getSubscriptionEmails()
    ).subscribe(([_subscriptions, _emails, _subscriptionEmails]) => {
      this.subscriptionData = _subscriptions;
      this.emailRepo = _emails;
      this.subscriptionEmails = _subscriptionEmails;
      this.filteredCategories = this.getFilteredCategories();
      this.dataLoaded = true;
      this.autoExpandFirstCategory();
      this.hideSpinner();
    },
    (err) => {
      this.alertService.displayError(err.message || serverError);
      this.hideSpinner();
    })
  }

  userIsSubscribed(subscribable: SubscribableRecord): boolean {
    return subscribable.subscribedEmailAddressIds.length > 0;
  }

  onSubscriptionEmailsChanged(emails: SubscriptionEmails) {
    forkJoin(
      this.service.getSubscriptions(),
      this.emailService.getEmails()
    ).subscribe(([_subscriptions, _emails]) => {
      this.subscriptionData = _subscriptions;
      this.subscriptionEmails = emails;
      this.emailRepo = _emails;
      this.filteredCategories = this.getFilteredCategories();
    },
    (err) => {
      this.alertService.displayError(serverError);
    })
  }

  subscriptionsUpdated() {
    this.service.getSubscriptions().subscribe(
      (response) => {
        this.subscriptionData = response;
      },
      (err) => {
        this.alertService.displayError(serverError);
      }
    );
  }

  updateSubscriptionData() {
    this.service.getSubscriptions().subscribe(
      (response) => {
        this.subscriptionData = response;
      },
      (err) => {
        this.alertService.displayError(serverError);
      }
    );
  }

  /**
   * Scrolls the user to the new position of the subscribed newsletter.
   */
  scrollToNewsletter(title: string): void {
    // Setting a timeout so we can detect where to scroll to after the newsletters are reordered.
    setTimeout(() => {
      const allNewsletters = document.getElementsByClassName('newsletter-card');

      for (let i = 0; i < allNewsletters.length; i++) {
        if (allNewsletters[i].querySelector('.newsletter-title').innerHTML.trim() === title) {
          if (allNewsletters[i].getBoundingClientRect().top < 20) {
            allNewsletters[i].scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        }
      }
    });
  }

  protected filterCategories(categories: SubscribableCategory[]): SubscribableCategory[] {
    return categories;
  }

  protected isExternalLink(title: string): boolean {
    return !_.isUndefined(_.find(this.externalLinks, (t) => _.includes(title, t)));
  }

  protected autoExpandFirstCategory() {
  };

  private hideSpinner() {
    this.showSpinner = false;
  }

  private getFilteredCategories(): SubscribableCategory[] {
    return this.filterCategories(this.subscriptionData.categories);
  }

  /**
   * AR:13
   * return ALL of the newsletters to view them (we no longer display categories)
   * logic: for every category, for every subscribable in that category, add the subscribable (aka newsletter)
   * into the array that we return
   */
  protected get allNewsletters(): SubscribableRecord[] {
    if (this.filteredCategories === undefined) {
      return [];
    }
    const allNewsletters = new Array();
    this.filteredCategories.forEach(category => {
      category.subscribables.forEach(subscribable => {
        if (subscribable !== undefined) {
          // First case we handle is if we have deep link information and the
          // newsletter has already been subscribed to.
          // In this case, set hasDeepLink to false, set the deep link id to an empty string,
          // reset our flag on the newsletter to false, and remove the item from Local Storage
          // *if it has not been already*!
          // This block of code is important for two reasons:
          // 1. If the user was not already subscribed, they will have followed the normal flow and
          //    this will need to fire in order to allow the user to unsubscribe as normal.
          // 2. If the user was already subscribed, they will not need to subscribe
          //    again, but we should clean as though they had.
          if (this.hasDeepLink && this.deepLinkId === subscribable.id
              && subscribable.subscribedEmailAddressIds.length > 0) {
            this.hasDeepLink = false;
            this.deepLinkId = '';
            subscribable.shouldSubscribeOnLogin = false;
            localStorage.removeItem('subscribeToNewsletterAction');
          }

          // Second case we need to handle is if we have deep link information and
          // the newletter is not already subscribed to.
          // In this case, set shouldSubscribeOnLogin to true (this will be reacted to in
          // the newsletter-card.component.ts) and remove the item from Local Storage.
          if (this.hasDeepLink && this.deepLinkId === subscribable.id) {
            subscribable.shouldSubscribeOnLogin = true;
            localStorage.removeItem('subscribeToNewsletterAction');
          }

          allNewsletters.push(subscribable);
        }
      });
    });
    return allNewsletters;
  }

  /**
   *AR:13
   *sort the newsletter array in alphabetical order
   *this function is used to sort both unsubscribed and subscribed newsletters.
   * @param newsletters, returns SubsrcibableRecord[]
   */
  public sortNewslettersByAlpha(newsletters: SubscribableRecord | SubscribableRecord[]): any[] {
    if (newsletters === undefined || newsletters === null) {
      return [];
    }

    const titleSorter = news => news.title.toLowerCase();
    return _.orderBy(newsletters, [titleSorter], ['asc']); // will always return a subscribable record array.
  }

  /**
   * AR:12
   * return ONLY the subscribed newsletters
   */
  public get subscribedNewsletters(): SubscribableRecord[]  {
    if (!this.allNewsletters || this.allNewsletters.length === 0) {
      return [];
    }
    return _.filter(this.allNewsletters, function(o) { return o.subscribedEmailAddressIds.length > 0; });
  }

  /**
  * AR:12
  * return ONLY the unsubscribed newsletters
  */
  public get unsubscribedNewsletters(): SubscribableRecord[] {
    if (!this.allNewsletters || this.allNewsletters.length === 0) {
      return [];
    }
    return _.filter(this.allNewsletters, function(o) { return !(o.subscribedEmailAddressIds.length > 0); });
  }

  /**
   * Reads in the deep link information from Local Storage, if there is any.
   */
  private readDeepLink(): void {
    if (!!localStorage.getItem('subscribeToNewsletterAction')) {
      this.hasDeepLink = true;
      const deepLinkData = JSON.parse(localStorage.getItem('subscribeToNewsletterAction'));
      this.deepLinkId = deepLinkData.id;
    }
  }
}
