<div class="form-item form-item-no-padding form-item-radio-label" [formGroup]="formGroup">
  <legend *ngIf="label">{{ label }}</legend>
  <div [class]="buttonsClass">
    <div *ngFor="let option of options">
      <input [formControlName]="fieldName"
             type="radio"
             [attr.data-id]="tag || fieldName"
             [value]="option.value"
             [id]="option.id" >
      <label [for]="option.id">{{ option.text || option.value }}</label>
      <span
        *ngIf="option.description"
        class="secondary-label_deprioritized">
        {{ option.description }}
      </span>
    </div>
  </div>
</div>
