export type Nothing =
  | undefined
  | null
  ;

export type Maybe<T> =
  | T
  | Nothing
  ;

export function assertNotUndefinedOrNull<T>(m: Maybe<T>, selector: string, attribute: string): T {
  if (m === undefined || m === null) {
    throw new Error(`${selector}[${attribute}] must not be undefined or null.`);
  }
  return m;
}

export function getOrElse<T>(m: Maybe<T>, t: T): T {
  if (m === undefined || m === null) {
    return t;
  }
  return m;
}
