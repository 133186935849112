<span class="membership_modal_close" (click)="close()"></span>
<div class="generic-form-modal-title">{{ formTitle }}</div>
<div id="join-now-modal" class="generic-form-modal-body">
  <div class="card__spinner" *ngIf="showSpinner">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!showSpinner">
    <div class="text-margin-23 form-message">
      <span class="text-font-smaller" *ngIf="isProductAutorenewable(), else nonrenewal_msg">
        <p class="align-left">For your convenience, we've set your account to auto renew.
          Select "View Additional Rates" below to see more dues and payment options.
        </p>
        <p class="align-left">Your dues (
            <span *ngIf="isHalfYearDiscount()" class="crossed-off-price">
              ${{ getOriginalPrice() }}&nbsp;
            </span>
            <span [ngClass]="isHalfYearDiscount() ? 'color-price': 'product-price-same-column'" >
              ${{ getPriceDisplayed() }}
            </span>
          ) will be charged to your preferred payment method:</p>
      </span>
      <ng-template #nonrenewal_msg>
        Your dues (
          <span *ngIf="isHalfYearDiscount()" class="crossed-off-price">
            ${{ getOriginalPrice() }}&nbsp;
          </span>
          <span [ngClass]="isHalfYearDiscount() ? 'color-price': 'product-price-same-column'" >
            ${{ getPriceDisplayed() }}
          </span>
        ) will be charged to your card.
      </ng-template>
    </div>

    <div class="payment">
      <div class="show-card-info">

        <div class="primary-card-info-modal">
          <span class="card-type">{{ creditCardsInfo.primary.creditCard.type }} ending in {{ creditCardsInfo.primary.creditCard.lastFourDigits }}</span>
          <span class="card-expiration">Expires {{ joinRenewCardExpirationMonth }}/{{ joinRenewCardExpirationYear }}</span>
        </div>

        <app-link class="change-method-link change-method-link_mbr" style="font-size: 0.9em;" (click)="changePaymentMethod()">
          <span *ngIf="isProductAutorenewable()">
            Add New Credit Card
          </span>
          <span *ngIf="!isProductAutorenewable()">
            Change Payment Method
          </span>
        </app-link>
      </div>
    </div>

    <div class="button-container" >
      <button type="button" (click)="join()" class="button" [disabled]="buttonsDisabled" >{{ buttonText }}</button>
      <div (click)="this.dialogRef.close()" class="close-link"> Cancel </div>
      <button *ngIf="showAlternateRates" (click)="showIframe()" class="button-secondary">{{ secondaryButtonText }}</button>
    </div>

    <div class="product-footer">
      <div class="terms" *ngIf="isProductAutorenewable()">
        <div  class="footer-msg" *ngIf="isHalfYearDiscount()">
          *The discounted dues are valid only for the {{getProductYear()}} membership year.
           Automatic renewal of your {{getProductYear() + 1}} membership will be at the full dues rate.
        </div>
        <div class="footer-terms">
          Please read the <a class="link" (click)="openTocModal()">terms and conditions</a> of automatic renewal authorization.
        </div>
      </div>
    </div>
  </div>
</div>
