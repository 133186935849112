import { Component, Inject, OnInit } from '@angular/core';

import { MailingAddressRecord } from './mailing-address';
import { MailingAddressesService } from './mailing-addresses.service';
import { IFormComponent } from '../IFormComponent';
import { ContactPreferencesNavigationService } from '../contact-preferences/contact-preferences-navigation.service';
import { AlertsService } from '../ui-components/alerts/alerts.service';
import { NewMailingAddressComponent } from './new.component';
import { FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '../ui-components/modals/modal.service';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-edit-unsaved-mailing-address',
  templateUrl: './form.component.html',
})
export class EditUnsavedMailingAddressComponent extends NewMailingAddressComponent implements IFormComponent, OnInit {
  // @ts-ignore
  model: Partial<MailingAddressRecord>;
  formType = 'edit-unsaved';
  isEditMode: boolean;

  constructor(
    protected service: MailingAddressesService,
    protected navService: ContactPreferencesNavigationService,
    protected modalService: ModalService,
    protected userService: UserService,
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<EditUnsavedMailingAddressComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super(service, navService, modalService, userService, alertService, formBuilder, dialogRef);
  }

  ngOnInit() {
    this.addressForm.patchValue(this.data.model);
    this.model = this.data.model;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // no reason to be able to delete new ones
  canDelete(address?: MailingAddressRecord): boolean {
    return false;
  }

  onConfirmDelete(address: MailingAddressRecord) {
    return;
  }
}
