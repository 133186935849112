<div id="section-structure">
  <header class="section__header">
    <div class="section__title">
      {{ title }}
    </div>
    <div *ngIf="hasPrimary" class="primary-desc">
      <div class="primary-indicator-shell">
        <div class="primary-indicator"></div>
      </div>
      {{primaryDescription}}
    </div>
    <ng-content select="[header]"></ng-content>
  </header>
  <ng-content></ng-content>
</div>
