import { Component } from '@angular/core';

import { MailingAddressesService } from './mailing-addresses.service';
import { MailingAddressRecord } from './mailing-address';
import {
  ActiveSection,
  ContactPreferencesNavigationService,
  ContactType
} from '../contact-preferences/contact-preferences-navigation.service';

import { MatDialog } from '@angular/material/dialog';
import { NewMailingAddressComponent } from './new.component';
import { EditMailingAddressComponent } from './edit.component';
import { EditUnsavedMailingAddressComponent } from './edit-unsaved.component';
import { VerifyExistingMailingAddressComponent } from './verification/verify-existing.component';
import { VerifyUnsavedMailingAddressComponent } from './verification/verify-unsaved.component';
import { AlertsService } from 'app/ui-components/alerts/alerts.service';
import { getDeleteSuccessMessage, serverError } from '../utils/alerts.utils';
import { finalize } from 'rxjs/operators';
import { ModalService } from 'app/ui-components/modals/modal.service';
import { AnalyticsService } from 'app/analytics/analytics.service';

@Component({
  selector: 'app-mailing-addresses',
  templateUrl: './home.component.html'
})
export class MailingAddressesHomeComponent {
  deleteAddress: MailingAddressRecord;
  editingAddress: Partial<MailingAddressRecord>;
  name: ContactType = 'mailing address';

  constructor(
    private navService: ContactPreferencesNavigationService,
    private service: MailingAddressesService,
    private alertService: AlertsService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private analyticsService: AnalyticsService
  ) { }

  setActiveSection(newSection: ActiveSection): void {
    this.navService.setActiveSection(this.name, newSection);
    if (newSection === 'new') {
      this.selectModal('new');
    }
  }

  onDelete(address: MailingAddressRecord) {
    this.service.deleteMailingAddress(address.id).pipe(
      finalize(() => {})
    ).subscribe(
      () => {
        this.analyticsService.contactInfoUpdate('address', 'remove', address.type, JSON.parse(JSON.stringify(address)));
        this.alertService.displaySuccess(getDeleteSuccessMessage('mailing address'));
      },
      (err) => {
        this.alertService.displayError(err.message || serverError);
      }
    );
  }

  /**
   * AR-14: Selects which type of modal to open
   */
  selectModal(type: string, data?: any) {
    switch (type) {
      case 'new': {
        this.openModal(NewMailingAddressComponent);
        break;
      }
      case 'edit': {
        this.setActiveSection(type);
        const address = Object.assign({}, data);
        this.openModal(EditMailingAddressComponent, null, {model: address});
        break;
      }
      case 'edit-unsaved': {
        this.setActiveSection(type);
        const address = Object.assign({}, data);
        this.openModal(EditUnsavedMailingAddressComponent, null, {model: address});
        break;
      }
      case 'verify-existing': {
        this.setActiveSection(type);
        this.openModal(VerifyExistingMailingAddressComponent, 'edit');
        break;
      }
      case 'verify-unsaved': {
        this.setActiveSection(type);
        this.openModal(VerifyUnsavedMailingAddressComponent, 'edit-unsaved');
        break;
      }
      case 'delete': {
        this.onDelete(data);
        break;
      }
      default: {
        this.setActiveSection('list');
        break;
      }
    }
  }

  /**
   * AR-14: Opens the given modal and calls the next modal that needs to open
   */
  private openModal(component: any, type?: string, address?: any) {
    this.dialog.open(component, {
      width: this.modalService.getAppropriateWidthProperty(520),
      maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: address || {},
    }).afterClosed().subscribe(data => {
      if ((data === 'verify-existing') || (data === 'verify-unsaved')) {
        this.selectModal(data);
      } else if (data === 'delete') {
        this.selectModal(data, address.model);
      } else if (!data) {
        this.selectModal('done');
      } else {
        this.selectModal(type, data);
      }
    });
  }
}
