import { NgModule } from '@angular/core';

import { ModalsModule } from '../ui-components/modals/modals.module';
import { ButtonsModule } from '../ui-components/buttons/buttons.module';

@NgModule({
  imports: [
    ModalsModule,
    ButtonsModule,
  ],
})
export class ContactNumbersModule { }
