import { getDeleteSuccessMessage, getUpdateSuccessMessage } from 'app/utils/alerts.utils';
import { UserProfileRecord } from './../../users/user-credentials';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, FormControl } from '@angular/forms';

import { AlertsService } from '../../ui-components/alerts/alerts.service';
import { PaymentFormService } from '../payment/payment-form.service';
import { ContactType, ContactPreferencesNavigationService } from '../../contact-preferences/contact-preferences-navigation.service';
import { IFormComponent } from '../../IFormComponent';
import { WalletService,
         AddCreditCardToWalletRequest,
         EndDateCreditCardRequest,
         CCardInfoFromWallet } from './wallet.service';
import { creditCardErrorMessage, getAddSuccessMessage, serverError } from '../../utils/alerts.utils';
import { fieldMasks } from '../../utils/form.utilities';
import { UserService } from '../../users/user.service';
import { ModalService } from '../../ui-components/modals/modal.service';

import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { MembershipService } from '../membership.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDeleteCardInWalletModalComponent } from './modals/confirm-delete-card-modal.component';
import { AlertMaxlimitCardsUpdateComponent } from './modals/alert-maxlimit-cards-update.component';
import { AnalyticsService } from 'app/analytics/analytics.service';
import {ConfirmModalComponent} from './modals/confirm-modal.component';

@Component({
  selector: 'app-new-wallet-creditcard',
  templateUrl: './form.component.html',
})

export class WalletCartFormComponent implements IFormComponent, OnInit {

  model: Partial<CCardInfoFromWallet>;
  walletCardForm: FormGroup;
  fields: AbstractControl[];
  name: ContactType = 'wallet credit number';
  buttonsDisabled: boolean = false;
  title = 'Add Payment';
  formType = 'add';
  showSpinner: boolean;
  checkedPrimary: boolean = true;

  constructor(
    protected alertService: AlertsService,
    protected formBuilder: FormBuilder,
    public paymentFormService: PaymentFormService,
    protected walletService: WalletService,
    protected navService: ContactPreferencesNavigationService,
    protected userService: UserService,
    protected modalService: ModalService,
    protected membershipService: MembershipService,
    protected analyticsService : AnalyticsService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<WalletCartFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {};
  readonly dateMask = fieldMasks.expirationDate;
  readonly zipCodeMask = fieldMasks.zipCode;
  readonly creditCardNumberMask = fieldMasks.creditCardNumber;

  ngOnInit() {
    this.model = this.data.model;
    this.paymentFormService.createForm();
    this.walletCardForm = this.paymentFormService.paymentForm;
    const creditCard = this.model ?  this.model.creditCard || {} : {};
    this.title = _.isEmpty(creditCard) ? 'Add Payment' : 'Modify Payment';
    this.formType = _.isEmpty(creditCard) ? 'add' : 'edit';
    if (_.isEmpty(creditCard) ) {
      this.walletCardForm.addControl('primaryInd', new FormControl());
    }
    this.walletCardForm.patchValue({
      nameOnCard: _.get(creditCard, 'nameOnCard', ''),
      expirationDate: _.isEmpty(creditCard) ? '' : `${this.editCardExpirationMonth}-${this.editCardExpirationYear}`,
    });
  }

  validateForm() {
    //not sure why it is happening sometime empty card number is valid
    if (this.walletCardForm.valid && this.number.value === '') {
      this.number.setErrors({'required': true});
    }
    if (this.walletCardForm.valid) {
      this.onFormSubmit();
    } else {
      this.nameOnCard.markAsTouched();
      this.number.markAsTouched();
      this.expirationDate.markAsTouched();
      if (this.primaryIndicatorCheckBox) {
        this.primaryIndicatorCheckBox.markAsTouched();
      }
    }
  }
  onFormSubmit() {
    this.alertService.removeAllAlerts();
    this.isLoading();
    this.disableButton();
    this.walletService.addCardToWallet(this.buildAddCreditCardToWalletRequest())
      .pipe(
        finalize(() => {
          this.isFinishedLoading();
          window.scrollTo(0, 0);
        }),
      )
      .subscribe(
        (data) => {
          if (!Array.isArray(data.errors) || !data.errors.length) {
            this.onNoClick('success');
            this.navService.setActiveSection(this.name, 'list');
            if (this.formType === 'add') {
              this.analyticsService.creditCardChange("add");
              this.alertService.displaySuccess(getAddSuccessMessage('credit card'));
            } else if (this.formType === 'edit') {
              this.analyticsService.creditCardChange("edit");
              this.alertService.displaySuccess(getUpdateSuccessMessage('credit card'));
            }
          } else {
            if (data.errors[0].subject === 'Invalid Credit Card') {
              this.handleErrors(creditCardErrorMessage);
            } else if (data.errors[0].subject === 'Update Credit Card Limit') {
              this.openUpdateLimitAlertModal();
            } else {
              this.handleErrors(serverError);
            }
           // this.onNoClick('failed');
          }
        },
        (err) => {
          this.handleErrors(err.message || serverError);
          this.onNoClick('failed');
        }
      );
  }
  public openUpdateLimitAlertModal() {
    const dialogRef = this.dialog.open(AlertMaxlimitCardsUpdateComponent, {
     width: this.modalService.getAppropriateWidthProperty(704),
     maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
     height: this.modalService.getAppropriateHeightProperty(),
     maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
     autoFocus: false,
     // data: {isConfirmModal: true}
    });
  }
  get nameOnCard(): AbstractControl {
    return this.walletCardForm.get(`nameOnCard`);
  }
  get number(): AbstractControl {
    return this.walletCardForm.get(`cardNumber`);
  }
  get expirationDate(): AbstractControl {
    return this.walletCardForm.get(`expirationDate`);
  }
  get primaryIndicatorCheckBox(): AbstractControl {
    return this.walletCardForm.get(`primaryInd`);
  }

  get lastFourDigits(): string {
    return _.get(this.model, 'creditCard.lastFourDigits', '1234');
  }
  private get editCardExpirationMonth(): string {
     return this.model.creditCard.expiration.split('-')[1];
  }

  private get editCardExpirationYear(): string {
    return this.model.creditCard.expiration.split('-')[0];
  }
  get user(): UserProfileRecord {
    return this.userService.profile;
  }
  private buildAddCreditCardToWalletRequest(): AddCreditCardToWalletRequest {
    let req = {
      entityId: this.walletService.entityId? this.walletService.entityId : this.membershipService.paymentInfo.entityId,
      name: this.nameOnCard.value,
      number: this.number.value,
      expiration: this.paymentFormService.switchExpirationDate(this.expirationDate.value),
      profileFirstName:  this.user.firstName,
      profileLastName:  this.user.lastName,
      profileLabelName:  this.user.labelName,
    };
    if (this.model) {
      req = Object.assign(req, { replaceOldId: this.model.walletId});
      req = Object.assign(req, { primaryInd: this.model.primaryInd});
    }
    if (this.primaryIndicatorCheckBox && this.primaryIndicatorCheckBox.value) {
      req = Object.assign(req, { primaryInd: 'Y'});
    }
     return req;
  }
  protected disableButton() {
    this.buttonsDisabled = true;
  }
  private handleErrors(error: string) {
    this.buttonsDisabled = false;
    this.alertService.displayError(error);
  }

  isNotPresent(field): boolean {
    if (field) {
      return field.touched && field.errors && field.errors['required'];
    } else {return false;}
  }

  closeDialog() {
    this.dialogRef.close(`noupdate`);
  }

  isLastCC(): boolean {
    const walletId: number = this.model && this.model.creditCard ?  this.model.walletId : 0;
    if (walletId === 0) {
        return false;
    }
    return this.walletService.hasOnlyOneCreditCard;
  }

  isDefault(): boolean {
    const walletId: number = this.model && this.model.creditCard ?  this.model.walletId : 0;
    if (walletId === 0) {
        return false;
     }
    return this.walletService.creditCardsInfoSaved.primary ? this.walletService.creditCardsInfoSaved.primary.walletId  === walletId : false;
  }

  onConfirmDelete(cCardInfo: CCardInfoFromWallet) {
    return;
  }

  onDelete(): void {
     this.alertService.removeAllAlerts();
     this.isLoading();
       this.walletService.endDateCardToWallet(this.buildEndDateCreditCardRequest())
        .pipe(
          finalize(() => {
            this.onNoClick('success');
            this.isFinishedLoading();
          }),
        ).subscribe(
          () => {
            this.alertService.displaySuccess(getDeleteSuccessMessage('credit card'));
            this.onNoClick('success');
          },
          (err) => {
            this.alertService.displayError(err.message || serverError);
            this.onNoClick('failed');
          }
        );
  }

  onNoClick(data?: string): void {
    this.dialogRef.close(data);
  }
  public isFinishedLoading(): void {
    this.showSpinner = false;
    this.buttonsDisabled = false;
  }
  public isLoading(): void {
    this.showSpinner = true;
    this.buttonsDisabled = true;
  }
  openDeleteConfirmationDialog(): void {
    if (!this.canDelete()){return;}
    const dialogRef = this.dialog.open(ConfirmDeleteCardInWalletModalComponent, {
     // width: this.modalService.getAppropriateWidthProperty(),
     // maxWidth: this.modalService.getAppropriateMaxWidthProperty(),
      height: this.modalService.getAppropriateHeightProperty(),
      maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
      autoFocus: false,
      data: {model: this.model}
    });
      dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.onDelete();
      }
    });
  }
  private buildEndDateCreditCardRequest(): EndDateCreditCardRequest {
    return {
      entityId: this.model.entityId,
      endDateId: this.model.walletId,
    };
  }
  canDelete(): boolean {
    if (this.model && this.model.walletId && (!this.isDefault() || !this.isAutoRenew())){
      return true;
    }
    return false;
  }

  isAutoRenew():boolean {
    return this.membershipService.paymentInfo.autoRenew;
  }

  public displayWarningForRemovingDigital(event) {
    if (!event.checked) {
      return;
    }

    if(!this.isDigital(this.walletService.creditCardsInfoSaved.primary)) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
          height: this.modalService.getAppropriateHeightProperty(),
          maxHeight: this.modalService.getAppropriateMaxHeightProperty(),
          autoFocus: false,
          data: {displaySave: false, model: {}}
        });
  }

  public isDigital(cCardInfo: CCardInfoFromWallet): boolean {
    if(!cCardInfo.creditCard) return false;

    return ['Apple Pay', 'Google Pay'].includes(cCardInfo.creditCard.type);
  }

}
