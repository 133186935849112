<div class="generic-form-modal-title">Turn Off Autorenewal</div>
<div id="auto-renew-off-modal" class="generic-form-modal-body">
  <div>Keep automatic renewal to save time renewing your membership each year and never lose your benefits!</div>
  <!-- TODO: add remaining balance price with join/renew story -->
  <!-- <div>Note: turning off autorenewal eliminates the ability to pay in monthly installments. 
    You will be charged <b>ADD PRICE HERE</b> in the next billing cycle to clear your remaining balance.</div> -->
    <div class="auto-renew-off-notice">Note: turning off autorenewal eliminates the ability to pay in monthly installments. 
      You will be charged your remaining balance in the next billing cycle.</div>
    <div>
    <button (click)="disableAutorenew()">Turn Off Autorenewal</button>
  </div>
  <div>
    <app-link (click)="closeAutorenewModal()">Keep Autorenewal</app-link>
  </div>
</div>