import { Component } from '@angular/core';
import { ModalService } from '../../ui-components/modals/modal.service';
import { ReferralFormService } from '../referral-form.service';

@Component({
  selector: 'app-referral-no-match-modal',
  templateUrl: './referral-no-match-modal.component.html',
})
export class ReferralNoMatchModalComponent {

  constructor(
    private modalService: ModalService,
    public formService: ReferralFormService,
  ) { }
}
