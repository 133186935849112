import { Component, Inject } from '@angular/core';
import { AutoRenewFormService } from '../auto-renew-form.service';
import { MembershipService, PaymentInformation } from '../membership.service';
import { PaymentFormService } from '../payment/payment-form.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-turn-on-autorenew-modal',
  templateUrl: './turn-on-autorenew-modal.component.html',
})
export class TurnOnAutorenewModalComponent {
  // AR-31: As per sprint planning conversation this morning on 1/27/2020, billing address is always hidden
  displayBilling: boolean = false;
  modalTitle: string = "Select Free Gift";
  buttonTitle: string = "Confirm Gift";

  get paymentInformation(): PaymentInformation {
    return this.membershipService.paymentInfo;
  }

  constructor(
    private membershipService: MembershipService,
    public autoRenewFormService: AutoRenewFormService,
    public paymentFormService: PaymentFormService,
    public dialogRef: MatDialogRef<TurnOnAutorenewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if(!this.paymentInformation.incentiveEligible){
      this.modalTitle = "Confirm Auto-renewal";
      this.buttonTitle = "Confirm Auto-renewal";
    }
  }

  /**
	 * AR-31: Checks for errors and calls the autorenew function if there are no errors
	 */
  turnAutorenewOn() {
    const isIncentiveFormValid = this.autoRenewFormService.isNoGiftOptionChecked() || this.autoRenewFormService.incentiveForm.valid;
    const isShippingAddressFormValid = !!this.autoRenewFormService.shippingOptionControl.value
                                        || this.autoRenewFormService.shippingAddressForm.valid
                                        // Because if the no gift option is checked, we aren't shipping anything anywhere!
                                        || this.autoRenewFormService.isNoGiftOptionChecked();
             
    if (!this.paymentFormService.paymentForm.valid || !isIncentiveFormValid || !isShippingAddressFormValid) {
      this.autoRenewFormService.showFormErrors(this.autoRenewFormService.incentiveForm);
      this.autoRenewFormService.showFormErrors(this.paymentFormService.paymentForm);
      this.autoRenewFormService.showFormErrors(this.autoRenewFormService.shippingAddressForm);
    } else {
      this.autoRenewFormService.hasSubmittedAutorenew = true;
      if (this.paymentFormService.hasChangedCard) {
        this.autoRenewFormService.hasChangedCardOnSubmit = true;
      }
      if (this.autoRenewFormService.isNoGiftOptionChecked()) {
        this.autoRenewFormService.submitPaymentBillingAutorenew();
      } else {
        this.autoRenewFormService.submitShippingAddressForm();
      }
      this.closeDialog('turn-on');
    }
  }

  /**
	 * AR-25: Closes modal when user clicks the cancel button
	 */
  closeDialog(data?: string) {
    this.dialogRef.close(data);
  }
}
